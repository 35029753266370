import { Theme } from "../../../hooks";
import DurationSelector from "../DurationSelector";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  duration: string | null;
  isSelectorOpen: boolean;
  numberOfSessions: number;
  errors?: { [key: string]: string[] };
  setIsSelectorOpen: (val: boolean) => void;
  onChange: (val: any, field: string) => void;
}

const AppFormAgendaSelected: React.FC<IProps> = ({
  errors,
  onChange,
  duration,
  isSelectorOpen,
  numberOfSessions,
  setIsSelectorOpen,
}) => {
  const { textColor } = Theme.useTheme();

  const onChangeNumberOfSessions = (val: string) => {
    const normalizedValue = +val.replace(/([^0-9-])/g, "");

    if (normalizedValue < 0) return 0;

    onChange(normalizedValue, "numberOfSessions");
  };

  const onChangeSessionDuration = (val: string) => {
    onChange(val, "duration");
  };

  const currErrors =
    errors?.duration || errors?.numberOfSessions
      ? [errors?.duration, errors?.numberOfSessions].filter((i) => !!i)
      : null;

  return (
    <S.FormAgendaSelected>
      <S.Quantity>
        <P color={textColor}>Quantidade de sessões por cliente</P>

        <S.NumberSelector>
          <S.Button
            onClick={() =>
              onChangeNumberOfSessions(String((numberOfSessions || 0) - 1))
            }
          >
            -
          </S.Button>

          <input
            placeholder="0"
            value={numberOfSessions}
            onChange={({ target }) => onChangeNumberOfSessions(target.value)}
          />

          <S.Button
            onClick={() =>
              onChangeNumberOfSessions(String((numberOfSessions || 0) + 1))
            }
          >
            +
          </S.Button>
        </S.NumberSelector>
      </S.Quantity>

      <div>
        <DurationSelector
          durationLabel={duration}
          isSelectorOpen={isSelectorOpen}
          setIsSelectorOpen={(val) => setIsSelectorOpen(val)}
          onChangeDuration={(val) => onChangeSessionDuration(val)}
        />
      </div>

      {currErrors && currErrors.length && (
        <Small color="#FF4D4F">Erros: {currErrors.join(", ")}</Small>
      )}
    </S.FormAgendaSelected>
  );
};

export default AppFormAgendaSelected;
