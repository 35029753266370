import { TSchedulingOption } from "../../../types/calendar";
import Switcher from "../Switcher";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  description: string;
  isSwitcherOn: boolean;
  setSchedulingOption: (val: TSchedulingOption) => void;
}

const SchedulingOptionSwitcher: React.FC<IProps> = ({
  description,
  isSwitcherOn,
  setSchedulingOption,
}) => {
  const setSwitcherOn = (val: boolean) => {
    if (val === true) {
      return setSchedulingOption("agenda");
    }
    return setSchedulingOption("no-date");
  };

  return (
    <S.ShcedulingSwitcher>
      <P color={isSwitcherOn ? "#FAFAFA" : "#71726F"}>{description}</P>

      <Switcher isOn={isSwitcherOn} setIsOn={(val) => setSwitcherOn(val)} />
    </S.ShcedulingSwitcher>
  );
};

export default SchedulingOptionSwitcher;
