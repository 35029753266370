import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const VideoOneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="46"
      height="27"
      viewBox="0 0 46 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9 0C3.08924 0 0 3.02208 0 6.75V20.25C0 23.9779 3.08924 27 6.9 27H25.3C29.1108 27 32.2 23.9779 32.2 20.25V13.5L44.7935 25.8197C45.243 26.2594 46 25.941 46 25.3122V1.6878C46 1.05902 45.243 0.740564 44.7935 1.18027L32.2 13.5V6.75C32.2 3.02208 29.1108 0 25.3 0H6.9Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default VideoOneIcon;
