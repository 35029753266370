import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AddContentBox from "../../../../components/atoms/AddContentBox";
import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import AppFormSocialMedia from "../../../../components/atoms/AppFormSocialMedia";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import Page from "../../../../components/atoms/Page";
import AppFormSocialMediaFooter from "../../../../components/molecules/AppFormSocialMediaFooter";
import appConfig from "../../../../config/app-redes-sociais.json";
import { App } from "../../../../hooks";
import {
  IAppItem,
  TAvailableSocialMedia,
  TIconOptions,
} from "../../../../types";

import { capitalizeFirstLetter } from "../../../../utils/capitalizeLetter";

import * as S from "./styles";

const validate = (
  data: IAppItem,
  rede: string
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (
    (["linkedin", "youtube"].includes(rede as string) ||
      ["linkedin", "youtube"].includes(data.socialMedia as string)) &&
    !data.url?.trim()
  ) {
    errors.url = [...(errors.url || []), "A url é obrigatória"];

    return errors;
  }

  if (
    !["linkedin", "youtube"].includes(rede as string) &&
    !["linkedin", "youtube"].includes(data.socialMedia as string) &&
    !data.username?.trim()
  )
    errors.username = [...(errors.username || []), "O username é obrigatório"];

  if (
    (rede === "whatsapp" || data.socialMedia === "whatsapp") &&
    data.username?.trim().match(/[a-z]/g)
  )
    errors.username = [...(errors.username || []), "Número inválido"];

  return errors;
};

const normalizeData = (data: IAppItem): IAppItem => {
  return data;
};

const Form: React.FC = () => {
  const { id, rede } = useParams();

  const [item, setItem] = useState<IAppItem>({});
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const navigate = useNavigate();
  const { getItem, saveFreeItemHandler, removeItemHandler } = App.useApp();

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      const itemData = await getItem(appConfig.id, id);

      setItem(itemData || {});
    };

    run();
  }, [getItem, id, rede]);

  const onSave = useCallback(
    (isDraft: boolean) => {
      const normalizedData = normalizeData(item);

      if (!isDraft) {
        const currErrors = validate(normalizedData, rede as string);

        if (currErrors && Object.keys(currErrors).length) {
          setErrors(currErrors);

          window.scrollTo({ top: 0, behavior: "smooth" });
          return alert(
            "O cadastro possui erros, por favor verifique os campos para continuar"
          );
        }
      }

      normalizedData.socialMedia =
        (rede as TAvailableSocialMedia) || item.socialMedia;
      if (normalizedData.socialMedia === "whatsapp") {
        normalizedData.username = normalizedData.username?.replace(
          /[(]*[)]*[-]*[\s]*[a-z]*/g,
          ""
        );
      }

      saveFreeItemHandler(appConfig.id, normalizedData, isDraft, id);
    },
    [item, saveFreeItemHandler, id, rede]
  );

  const onChangeHandler = (value: any, field: keyof IAppItem) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setItem((curr) => ({ ...curr, [field]: value }));
  };

  const isEditingItem = !!id;
  const shouldBeCompleteLink =
    ["linkedin", "youtube"].includes(rede as string) ||
    ["linkedin", "youtube"].includes(item.socialMedia as string);

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps/${appConfig.id}`)}
      />

      <S.Description>
        <h2>Reúna suas redes em um só lugar</h2>
        <p>Adicione links para as suas principais contas!</p>
      </S.Description>

      <S.ContentBox>
        <AddContentBox
          label={
            capitalizeFirstLetter(rede as string) ||
            capitalizeFirstLetter(item.socialMedia as string) ||
            ""
          }
          containedBox
          route="/"
          icon={
            <AvailableIcons
              option={
                (rede === "email" || item.socialMedia === "email"
                  ? "emailTwo"
                  : (rede as TIconOptions)) || item.socialMedia
              }
            />
          }
        />
      </S.ContentBox>

      <S.InputGroup>
        <AppFormSocialMedia
          socialMedia={(rede as string) || (item.socialMedia as string)}
          errors={shouldBeCompleteLink ? errors.url : errors.username}
          value={shouldBeCompleteLink ? item.url || "" : item.username || ""}
          onChange={(val) =>
            onChangeHandler(val, shouldBeCompleteLink ? "url" : "username")
          }
        />

        <AppFormSocialMediaFooter
          onSave={onSave}
          saveDraft={false}
          isEditingItem={isEditingItem}
          onRemove={() => id && removeItemHandler(appConfig.id, id)}
        />
      </S.InputGroup>
    </Page>
  );
};

export default Form;
