import styled from "styled-components";
import H5 from "../../../../components/atoms/Typography/H5";
import P from "../../../../components/atoms/Typography/P";

export const LinkedAccount = styled.div`
  display: flex;
  margin-top: 12px;
  flex-direction: column;

  .newPost {
    margin-top: 26px;
  }
`;

export const Subtitle = styled(P)`
  padding: 30px 0 15px;
`;

export const NoPostsText = styled(H5)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SaveButton = styled.div`
  left: 50%;
  width: 100%;
  bottom: 40px;
  display: flex;
  position: fixed;
  padding: 0 24px;
  max-width: 960px;
  flex-direction: column;

  button {
    height: 56px;
    transform: translate(calc(-50% - 24px), 0%);
    box-shadow: 4px 10px 10px -2px rgba(0, 0, 0, 0.6);
    animation: buttonPop 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;

    @keyframes buttonPop {
      0% {
        transform: translate(calc(-50% - 24px), 200%);
      }
      60% {
        transform: translate(calc(-50% - 24px), -60%);
      }
      100% {
        transform: translate(calc(-50% - 24px), 0%);
      }
    }
  }
`;
