import { useState } from "react";

import { Theme } from "../../../hooks";
import Chevron from "../../icons/Chevron";
import Small from "../Typography/Small";

import * as S from "./styles";

type TOption = {
  id: string;
  label: string;
};

interface IProps {
  options: TOption[];
  selectedOptionId: string;
  onClickOption: (optionId: string) => void;
}

const Dropdown: React.FC<IProps> = ({
  options,
  onClickOption,
  selectedOptionId,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  return (
    <>
      {isOpen && <S.Backdrop onClick={() => setIsOpen(false)} />}
      <S.Container isOpen={isOpen}>
        <S.Dropdown
          isOpen={isOpen}
          borderColor={primaryColor}
          backgroundColor={backgroundColor}
          onClick={() => setIsOpen((val) => !val)}
        >
          <S.SelectedItem>
            <Small color={isOpen ? primaryColor : textColor}>
              {options.find((item) => item.id === selectedOptionId)?.label ||
                ""}
            </Small>

            <div className="chevronIcon">
              <Chevron />
            </div>
          </S.SelectedItem>

          {isOpen &&
            options
              .filter((option) => option.id !== selectedOptionId)
              .map((option) => (
                <li onClick={() => onClickOption(option.id)} key={option.id}>
                  <Small color={textColor}>{option.label}</Small>
                </li>
              ))}
        </S.Dropdown>
      </S.Container>
    </>
  );
};

export default Dropdown;
