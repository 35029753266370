export interface IInterval {
  to: string;
  from: string;
}

export interface IAppointments {
  id: string;
  title: string;
  endAt?: string;
  startAt?: string;
  callUrl?: string;
  interval: IInterval;
  customerName?: string;
  customerPhone?: string;
  customerEmail?: string;
  type: "APPOINTMENT" | "BLOCK";
}

export type TRepeatType = "WEEK_DAY" | "MONTH_DAY";

export interface IBlock {
  label: string;
  endAt?: string;
  startAt?: string;
  timeZone?: string;
  calendarId: string;
  isLunchTime: boolean;
  isRepeatable: boolean;
  repeatType: TRepeatType;
  intervals: { [key in TWeekDays]?: IInterval[] };
}

export interface ISchedule {
  type: TWeekDays;
  category: "WEEK_DAY";
  intervals: IInterval[];
}

export interface ICalendar {
  id: string;
  updatedAt: string;
  createdAt: string;
  schedules: ISchedule[];
}

export type TWeekDays =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type TSchedulingOption = "defined-dates" | "agenda" | "no-date";

export const workDayLabel: { [key: string]: string } = {
  monday: "Segunda-feira",
  tuesday: "Terça-feira",
  wednesday: "Quarta-feira",
  thursday: "Quinta-feira",
  friday: "Sexta-feira",
  saturday: "Sábado",
  sunday: "Domingo",
};

export interface ICalendarResponse {
  id: string;
  entity: string;
  creatorId: string;
  schedules: ISchedule[];
}

export interface ILunchBlockResponse {
  label: string;
  id: string;
  endAt?: string;
  startAt?: string;
  updatedAt: string;
  createdAt: string;
  calendarId: string;
  isLunchTime: boolean;
  isRepeatable: boolean;
  repeatType: TRepeatType;
  intervals: { [key in TWeekDays]: IInterval[] };
}

export interface IGetAppointmentsAndBlocksResponse {
  [key: string]: IAppointments[];
}
