import styled, { css } from "styled-components";

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  position: fixed;
`;

type ContainerProps = {
  isOpen: boolean;
};

export const Container = styled.div<ContainerProps>`
  z-index: 10;
  width: 100%;
  min-height: 28px;
  position: relative;
  border-radius: 14px;
  mix-blend-mode: ${({ isOpen }) => (isOpen ? "screen" : "normal")};
`;

type DropdownProps = {
  isOpen: boolean;
  borderColor: string;
  backgroundColor: string;
};

export const Dropdown = styled.ul<DropdownProps>`
  ${({ isOpen, borderColor, backgroundColor }) => css`
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0 16px;
    cursor: pointer;
    list-style: none;
    color: #fafafa;
    user-select: none;
    position: absolute;
    border-radius: 14px;
    flex-direction: column;
    border: ${isOpen ? `1px solid ${borderColor}` : "1px solid transparent"};
    background-color: ${isOpen ? backgroundColor : "#fafafa18"};

    li {
      display: flex;
      padding-top: 6px;
      transition: none;
      align-items: center;
      padding-bottom: 6px;
      border-bottom: 1px solid #fafafa30;

      &:last-of-type {
        border-bottom: none;

        &:not(.selectedItem) {
          margin-bottom: 4px;
        }
      }
    }
  `}
`;

export const SelectedItem = styled.li`
  align-items: center;
  justify-content: space-between;

  .chevronIcon {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
