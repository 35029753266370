import { Theme } from "../../../hooks";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  isSelectedOnline: boolean;
  onSelectOnline: () => void;
  isSelectedPresential: boolean;
  onSelectPresential: () => void;
}

const AppFormServiceType: React.FC<IProps> = ({
  onSelectOnline,
  isSelectedOnline,
  onSelectPresential,
  isSelectedPresential,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.ServiceType>
      <S.Type onClick={onSelectOnline}>
        <S.InputOut isSelected={isSelectedOnline} borderColor={primaryColor}>
          <S.InputIn
            isSelected={isSelectedOnline}
            backgroundColor={primaryColor}
          ></S.InputIn>
        </S.InputOut>

        <P color={textColor}>Online</P>
      </S.Type>

      <S.Type onClick={onSelectPresential}>
        <S.InputOut
          isSelected={isSelectedPresential}
          borderColor={primaryColor}
        >
          <S.InputIn
            isSelected={isSelectedPresential}
            backgroundColor={primaryColor}
          ></S.InputIn>
        </S.InputOut>

        <P color={textColor}>Presencial</P>
      </S.Type>
    </S.ServiceType>
  );
};

export default AppFormServiceType;
