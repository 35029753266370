import styled from "styled-components";
import Button from "../../../../components/atoms/Button";
import P from "../../../../components/atoms/Typography/P";

export const Container = styled.div`
  gap: 20px;
  display: flex;
  max-width: 500px;
  border-radius: 16px;
  align-items: center;
  flex-direction: column;
  padding: 20px 16px 28px;
  backdrop-filter: blur(14.5px);
  border-top: 1px solid #fafafa0f;
  border-left: 1px solid #fafafa0f;
  border-right: 1px solid #fafafa0f;
  -webkit-backdrop-filter: blur(14.5px);
  background: linear-gradient(
      128.33deg,
      rgba(255, 255, 255, 0) 3%,
      rgba(255, 255, 255, 0.1) 18%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0.15) 93%,
      rgba(255, 255, 255, 0) 97%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  background-blend-mode: hard-light, soft-light;

  .icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 18px;
    mix-blend-mode: screen;
    box-sizing: border-box;
    justify-content: center;
    background: linear-gradient(
      271.06deg,
      rgba(250, 250, 250, 0.17) -3.98%,
      rgba(113, 114, 111, 0) 203.38%
    );

    svg {
      width: 50%;
      height: 50%;
    }
  }
`;

export const Texts = styled.div`
  gap: 10px;
  display: flex;
  max-width: 250px;
  text-align: center;
  flex-direction: column;
`;

export const Subtitle = styled(P)`
  font-size: 12px;
`;

export const DefineAgendaButton = styled(Button)`
  width: 100%;
  padding: 12px 0;
  border-radius: 16px;
`;
