import Input from "../../../../../../components/atoms/Input";
import Label from "../../../../../../components/atoms/Typography/Label";
import CloseIcon from "../../../../../../components/icons/CloseIcon";
import YoutubeIcon from "../../../../../../components/icons/YoutubeIcon";
import { Theme } from "../../../../../../hooks";
import { IYoutubeAppItem } from "../../../../types";
import ChannelVideoSelector from "../ChannelVideoSelector";

import * as S from "./styles";

interface IProps {
  url: string;
  index: number;
  hasChanges: boolean;
  type: IYoutubeAppItem["type"];
  onSave: (index: number) => void;
  onRemove: (index: number) => void;
  onChangeUrl: (value: string) => void;
  onChangeType: (type: IYoutubeAppItem["type"]) => void;
}

const YoutubeItem: React.FC<IProps> = ({
  url,
  type,
  index,
  onSave,
  onRemove,
  hasChanges,
  onChangeUrl,
  onChangeType,
}) => {
  const { primaryColor } = Theme.useTheme();

  const isSaveButtonDisabled = !hasChanges || !url;

  return (
    <S.YoutubeItem>
      <S.Header>
        <Label color={primaryColor}>{`Conteúdo ${index + 1}`}</Label>

        <S.RemoveButton onClick={() => onRemove(index)}>
          <CloseIcon />
        </S.RemoveButton>
      </S.Header>

      <ChannelVideoSelector selected={type} onSelect={onChangeType} />

      <Input
        value={url}
        onChange={onChangeUrl}
        leftIcon={<YoutubeIcon color={url ? primaryColor : "#fafafa80"} />}
        placeholder={`Link do ${
          type === "channel" ? "canal no" : "vídeo do"
        } Youtube`}
      />

      <S.SaveButton
        onClick={() => onSave(index)}
        disabled={isSaveButtonDisabled}
        color={isSaveButtonDisabled ? "#fafafa80" : primaryColor}
      >
        Salvar
      </S.SaveButton>
    </S.YoutubeItem>
  );
};

export default YoutubeItem;
