import styled from "styled-components";

export const MyCalendar = styled.div`
  display: flex;
  cursor: pointer;
  margin-top: 14px;
  user-select: none;
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
  mix-blend-mode: normal;
  padding: 10px 22px 10px 20px;
  border-top: 1px solid #ffffff1a;
  border-left: 1px solid #fafafa00;
  border-right: 1px solid #fbfbfb0d;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) -9.56%,
    rgba(255, 255, 255, 0) 102%
  );
  justify-content: space-between;
  background-blend-mode: soft-light;
`;

export const Container = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;

  .icon {
    width: 14px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-size: 12px;
  }
`;

export const ChevronIcon = styled.div`
  width: 10px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
