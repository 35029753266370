import P from "../../../../components/atoms/Typography/P";
import Small from "../../../../components/atoms/Typography/Small";
import LinkIcon from "../../../../components/icons/LinkIcon";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  disabled: boolean;
  linkOrCoupon?: string;
  onCopyCode: () => void;
  type?: "link" | "coupon";
  onLeaveCampaign: () => void;
  onApplyForCampaign: () => void;
  status?: "pending" | "approved" | "rejected";
  campaignStatus?: "active" | "paused" | "expired" | "upcoming";
}

const campaignTypeParser = {
  link: "link",
  coupon: "cupom",
};

const AffiliationInfo: React.FC<IProps> = ({
  type,
  status,
  disabled,
  onCopyCode,
  linkOrCoupon,
  campaignStatus,
  onLeaveCampaign,
  onApplyForCampaign,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const isApproved = status && status === "approved";

  const onClickHandler = () => {
    if (disabled) return;

    if (isApproved) onLeaveCampaign();
    if (!status) onApplyForCampaign();
  };

  const buttonLabels = {
    approved: "Deixar programa de afiliados",
    rejected: "Os cupons desta campanha esgotaram",
    pending: "Aguarde, sua solicitação está em análise",
    paused: "Essa campanha encontra-se pausada momentaneamente",
    expired: "Ops! Essa campanha já finalizou :(",
    upcoming: "A campanha será lançada em breve!",
  };

  const isDisabled =
    disabled ||
    (status && ["rejected", "pending"].includes(status)) ||
    (campaignStatus !== "active" && status !== "approved");

  const label = (() => {
    if (status) return buttonLabels[status];

    if (campaignStatus && campaignStatus !== "active")
      return buttonLabels[campaignStatus];

    return "Solicitar código de afiliado";
  })();

  return (
    <S.AffiliationInfo>
      {type ? (
        <P
          color={textColor}
        >{`Seu ${campaignTypeParser[type]} de afiliado:`}</P>
      ) : null}

      {isApproved ? (
        <Small color={primaryColor}>
          Parabéns, solicitação aprovada! Clique para copiar.
          <br />
          Esse cupom é exclusivamente seu!
        </Small>
      ) : null}

      {isApproved && linkOrCoupon ? (
        <S.CopyCoupon borderColor={primaryColor} onClick={onCopyCode}>
          <P color={textColor}>{linkOrCoupon}</P>

          <div className="icon">
            <LinkIcon />
          </div>
        </S.CopyCoupon>
      ) : null}

      <S.Button
        onClick={(e) => {
          e.stopPropagation();
          onClickHandler();
        }}
        disabled={isDisabled}
        width={isApproved ? "fit-content" : "100%"}
        textColor={isApproved ? "#fafafa" : primaryColor}
        borderColor={isApproved ? "#fafafa" : primaryColor}
      >
        {label}
      </S.Button>

      {(campaignStatus && campaignStatus === "active" && !status) ||
      status === "pending" ? (
        <S.PendingDescription color={textColor}>
          Entraremos em contato por e-mail caso seu perfil seja aprovado
        </S.PendingDescription>
      ) : null}
    </S.AffiliationInfo>
  );
};

export default AffiliationInfo;
