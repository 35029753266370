import axios, { AxiosError } from "axios";

interface ILogin {
  user: string;
  password: string;
}

const { REACT_APP_CREATOR_API_URL } = process.env;

const login = async (loginData: ILogin) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CREATOR_API_URL}/login`,
      loginData
    );

    return data;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    switch (errorCode) {
      case "LOGN001":
        throw new Error("Email ou senha incorretos");
      case "BODY001":
        throw new Error("Por favor informe login e senha");
      default:
        throw new Error("Houve um erro ao fazer o login");
    }
  }
};

export default {
  login,
};
