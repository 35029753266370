import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PersonGroupIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="26"
      height="19"
      viewBox="0 0 26 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.06161 19.0005C8.63813 18.8755 8.20869 18.7714 7.83231 18.5205C6.87128 17.8808 6.34862 16.9956 6.34366 15.8352C6.34019 15.0716 6.29357 14.3064 6.47854 13.5492C6.99773 11.4189 8.74623 9.79683 10.9346 9.56377C12.2011 9.42889 13.4899 9.39368 14.7549 9.60939C16.9467 9.98378 18.7036 11.8657 18.957 14.0833C19.0522 14.9169 19.1177 15.7683 18.9079 16.5895C18.5757 17.8897 17.7104 18.6702 16.3963 18.9529C16.3581 18.9608 16.3234 18.9841 16.2872 19.0005H9.06161Z"
        fill={color || primaryColor}
      />
      <path
        d="M25.3435 13.0152C25.288 13.1769 25.2468 13.345 25.1749 13.4992C24.7896 14.3224 24.1326 14.7548 23.2286 14.786C22.627 14.8069 22.024 14.7781 21.4225 14.796C21.181 14.8029 21.1007 14.7464 21.0943 14.491C21.0581 13.0361 20.5904 11.72 19.7603 10.5294C19.2456 9.79098 18.6133 9.16715 17.8606 8.67622C17.6439 8.5349 17.6766 8.45952 17.8343 8.30679C18.3897 7.77123 19.0482 7.43799 19.8134 7.40279C20.7342 7.36014 21.662 7.31749 22.5804 7.4256C23.9615 7.58825 25.0286 8.6182 25.2934 9.99082C25.3014 10.0315 25.3028 10.0731 25.3435 10.0969V13.0152Z"
        fill={color || primaryColor}
      />
      <path
        d="M16.9012 4.23402C16.8928 6.57907 14.9836 8.47336 12.6425 8.45947C10.3277 8.44559 8.43938 6.54089 8.44533 4.22212C8.45128 1.76401 10.5038 -0.0390289 12.7531 0.000642031C15.0277 0.0413047 16.9091 1.93212 16.9012 4.23353V4.23402Z"
        fill={color || primaryColor}
      />
      <path
        d="M0.00375228 11.4612C0.0315219 10.9896 -0.0577376 10.4179 0.0766476 9.85654C0.404924 8.48194 1.55042 7.4743 2.9756 7.39991C3.9034 7.35132 4.84013 7.31859 5.76744 7.43413C6.48648 7.52389 7.08848 7.86853 7.59082 8.3907C7.69644 8.50078 7.67462 8.5464 7.55462 8.62723C5.87158 9.76232 4.81286 11.3234 4.38243 13.3074C4.29317 13.7185 4.24209 14.1355 4.24408 14.5565C4.24507 14.7311 4.20044 14.804 4.00704 14.7951C3.25826 14.7613 2.505 14.8541 1.75919 14.745C0.769897 14.6007 0.0121824 13.715 0.00424817 12.7183C0.00127285 12.331 0.00375228 11.9432 0.00375228 11.4612Z"
        fill={color || primaryColor}
      />
      <path
        d="M7.3967 3.17306C7.39571 4.92503 5.97847 6.3383 4.22303 6.33731C2.47156 6.33681 1.04638 4.9156 1.04688 3.17058C1.04737 1.41564 2.47552 0.00533633 4.24981 0.00781576C5.98392 0.0102952 7.3972 1.432 7.3967 3.17355V3.17306Z"
        fill={color || primaryColor}
      />
      <path
        d="M17.9454 3.18398C17.9335 1.4702 19.3458 0.0410555 21.0318 0.000888687C22.7485 -0.039774 24.2759 1.31945 24.2937 3.15919C24.3106 4.87397 22.8899 6.32543 21.2048 6.33683C19.3849 6.34923 17.9578 4.96868 17.9454 3.18349V3.18398Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PersonGroupIcon;
