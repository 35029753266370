import styled from "styled-components";

export const ButtonContainer = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  position: fixed;
  padding-bottom: 20px;
  justify-content: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
`;

export const Container = styled.div`
  gap: 30px;
  width: 100%;
  display: flex;
  max-width: 960px;
  padding: 0 24px;
  align-items: center;
  justify-content: flex-end;
`;
