import styled from "styled-components";

type DragZoneProps = {
  isHovered: boolean;
};

export const DragZone = styled.div<DragZoneProps>`
  width: 100%;
  height: 80px;
  transition: none;
  position: relative;
  height: ${({ isHovered }) => (isHovered ? "104px" : "12px")};

  &::after {
    content: "";
    top: 12px;
    left: 0;
    right: 0;
    bottom: 12px;
    position: absolute;
    border-radius: 22px;
    background-blend-mode: multiply;
    animation: opacity 0.3s ease infinite forwards;
    background: linear-gradient(
      275deg,
      rgba(250, 250, 250, 0.39) -160%,
      rgba(113, 114, 111, 0) 120%
    );

    @keyframes opacity {
      0% {
        opacity: 0.6;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.6;
      }
    }
  }
`;
