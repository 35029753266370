import AppLinkButton from "../../../../components/atoms/AppLinkButton";
import P from "../../../../components/atoms/Typography/P";
import { Theme } from "../../../../hooks";
import { IFileType } from "../../../AppVendaArquivos/types";
import { IChannelProvider } from "../Form";

import * as S from "./styles";

interface IChoiceChannel {
  handleClick: (socialMedia: IChannelProvider) => void;
}

const ChoiceChannel: React.FC<IChoiceChannel> = ({ handleClick }) => {
  const { textColor } = Theme.useTheme();

  const socialMedia: IFileType[] = [
    {
      label: "Tenho um grupo de WhatsApp",
      typeId: "other",
      icon: "whatsapp",
    },
    {
      label: "Tenho um grupo do Telegram",
      typeId: "other",
      icon: "telegram",
    },
  ];

  return (
    <S.Container>
      <P color={textColor}>Qual plataforma seu grupo está criado?</P>

      <S.SocialMediaButton>
        {socialMedia.map((media) => {
          return (
            <AppLinkButton
              key={media.icon}
              title={media.label}
              icon={media.icon}
              onClick={() => handleClick(media.icon as IChannelProvider)}
            />
          );
        })}
      </S.SocialMediaButton>
    </S.Container>
  );
};

export default ChoiceChannel;
