import { useEffect } from "react";

import CreateAgendaModal from "../../../pages/Calendar/components/CreateAgendaModal";
import { ICalendar, TSchedulingOption } from "../../../types/calendar";
import AppFormAgendaSelected from "../AppFormAgendaSelected";
import AppFormDefinedDatesSelected from "../AppFormDefinedDateSelected";
import SchedulingOptionSelector from "../SchedulingOptionSelector";

import * as S from "./styles";

export interface IDate {
  day: string;
  year: string;
  month: string;
}

export interface ITime {
  hours: string;
  minutes: string;
}

interface IProps {
  appId: string;
  dates?: IDate[];
  time?: {
    start: ITime;
    end: ITime;
  };
  isAllDay?: boolean;
  onSaveDraft: () => void;
  duration: string | null;
  numberOfSessions: number;
  isSelectorOpen?: boolean;
  agendaModalOpen?: boolean;
  calendar?: ICalendar | null;
  errors?: { [key: string]: string[] };
  schedulingOption?: TSchedulingOption;
  setIsSelectorOpen: (val: boolean) => void;
  setAgendaModalOpen?: (val: boolean) => void;
  onChange: (val: any, field: string) => void;
  setSchedulingOption?: (val: TSchedulingOption) => void;
}

const AppFormDateAndTime: React.FC<IProps> = ({
  time,
  dates,
  appId,
  errors,
  onChange,
  isAllDay,
  calendar,
  duration,
  onSaveDraft,
  isSelectorOpen,
  agendaModalOpen,
  numberOfSessions,
  schedulingOption,
  setIsSelectorOpen,
  setAgendaModalOpen,
  setSchedulingOption,
}) => {
  useEffect(() => {
    if (schedulingOption === "agenda" && !calendar) {
      setAgendaModalOpen && setAgendaModalOpen(true);
    }
  }, [calendar, schedulingOption, setAgendaModalOpen]);

  const onCloseModalHandler = () => {
    setAgendaModalOpen && setAgendaModalOpen(false);
    setSchedulingOption && setSchedulingOption("defined-dates");
  };

  return (
    <S.FormDateAndTime>
      <S.SchedulingOption>
        <SchedulingOptionSelector
          description="Data e horário pré definido"
          isSelected={schedulingOption === "defined-dates"}
          onSelect={() =>
            setSchedulingOption && setSchedulingOption("defined-dates")
          }
        />

        <SchedulingOptionSelector
          isSelected={schedulingOption === "agenda"}
          description="Liberar meu calendário para agendamentos"
          onSelect={() => setSchedulingOption && setSchedulingOption("agenda")}
        />
      </S.SchedulingOption>

      {schedulingOption === "defined-dates" ? (
        <AppFormDefinedDatesSelected
          time={time}
          dates={dates}
          isAllDay={isAllDay}
          errors={errors?.dates}
          onChange={(val, field) => onChange(val, field)}
        />
      ) : (
        <>
          <CreateAgendaModal
            appId={appId}
            onSaveDraft={onSaveDraft}
            isOpen={!!agendaModalOpen}
            onCloseModal={() => onCloseModalHandler()}
          />

          <AppFormAgendaSelected
            errors={errors}
            duration={duration}
            isSelectorOpen={!!isSelectorOpen}
            numberOfSessions={numberOfSessions}
            onChange={(val, field) => onChange(val, field)}
            setIsSelectorOpen={(val) => setIsSelectorOpen(val)}
          />
        </>
      )}
    </S.FormDateAndTime>
  );
};

export default AppFormDateAndTime;
