import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LearnIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="47"
      height="40"
      viewBox="0 0 47 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.43525 11.5448L20.2735 1.26532C21.6143 0.550231 23.2232 0.550231 24.564 1.26532L45.838 12.8632C46.5531 13.2655 47.0001 14.0029 47.0001 14.8297V29.1539C47.0001 30.383 45.9945 31.3886 44.7654 31.3886C43.5363 31.3886 42.5307 30.383 42.5307 29.1539V15.9471L24.564 25.7572C23.2232 26.4947 21.6143 26.4947 20.2735 25.7572L1.43525 15.4778C-0.106671 14.6286 -0.106671 12.394 1.43525 11.5448ZM6.77588 29.1317V22.8523L20.2733 30.2266C21.614 30.9641 23.223 30.9641 24.5638 30.2266L38.0612 22.8523V29.1317C38.0612 30.763 37.1673 32.2825 35.7371 33.0647L24.5638 39.1653C23.223 39.9027 21.614 39.9027 20.2733 39.1653L9.09993 33.0647C7.66974 32.2825 6.77588 30.763 6.77588 29.1317Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default LearnIcon;
