import H2 from "../../../../components/atoms/Typography/H2";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

const Header: React.FC = () => {
  const { primaryColor } = Theme.useTheme();

  return (
    <S.Header>
      <H2 color={primaryColor}>Dashboard</H2>
    </S.Header>
  );
};

export default Header;
