import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CheckOneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="49"
      height="40"
      viewBox="0 0 49 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.409 22.6878L37.8346 1.26223C39.5176 -0.420744 42.2362 -0.420744 43.9408 1.26223L46.9615 4.28296C48.6445 5.96594 48.6445 8.70617 46.9615 10.3891L19.4513 37.8778C17.7684 39.5607 15.0281 39.5607 13.3451 37.8778L1.26223 25.7949C-0.420744 24.1119 -0.420744 21.3716 1.26223 19.6887L4.28296 16.6679C5.96594 14.985 8.70617 14.985 10.3891 16.6679L16.409 22.6878ZM42.3864 8.85715C43.2279 8.01566 43.2279 6.67791 42.3864 5.83642C41.5449 4.99494 40.2072 4.99494 39.3657 5.83642L16.4081 28.794L8.85632 21.2421C8.01483 20.4006 6.67708 20.4006 5.8356 21.2421C5.43179 21.641 5.20452 22.1849 5.20452 22.7525C5.20452 23.3201 5.43179 23.864 5.8356 24.2629L14.8978 33.325C15.7393 34.1665 17.077 34.1665 17.9185 33.325L42.3864 8.85715Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CheckOneIcon;
