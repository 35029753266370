import styled from "styled-components";
import Small from "../../../../components/atoms/Typography/Small";

export const YourAppointments = styled.div`
  display: flex;
  flex-direction: column;

  small {
    font-size: 10px;
  }
`;

export const Disclaimer = styled(Small)`
  padding-top: 20px;
`;

export const AppointmentList = styled.div`
  display: flex;
  padding-top: 20px;
  flex-direction: column;
`;

export const NoAppointments = styled.div`
  gap: 16px;
  display: flex;
  padding-top: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .calendarIcon {
    width: 20px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
