import { useEffect } from "react";
import { Auth, Loading } from "../../hooks";

import * as S from "./styles";

const Preview: React.FC = () => {
  const { user } = Auth.useAuth();
  const { showLoading, hideLoading } = Loading.useLoading();

  const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

  useEffect(() => {
    showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.ResponsiveIFrame
      src={`https://${REACT_APP_STOREFRONT_BASE_URL}/${user.username}`}
      width="100%"
      onLoad={() => hideLoading()}
      loading="lazy"
      sandbox="allow-scripts"
    ></S.ResponsiveIFrame>
  );
};

export default Preview;
