import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import Button from "../../../../components/atoms/Button";
import Link from "../../../../components/atoms/Link";
import NavigationArrow from "../../../../components/atoms/NavigationArrow";
import P from "../../../../components/atoms/Typography/P";
import UsernameInput from "../../../../components/atoms/UsernameInput";
import StantiIcon from "../../../../components/icons/StantiIcon";
import { Theme } from "../../../../hooks";

import H5 from "../../../../components/atoms/Typography/H5";
import * as RegisterStyles from "../styles";
import * as UsernameStepStyles from "./styles";

const S = {
  ...UsernameStepStyles,
  ...RegisterStyles,
};

interface IProps {
  username: string;
  onNext: () => void;
  onLogin: () => void;
  onChangeUsername: (value: string) => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const UsernameStep: React.FC<IProps> = ({
  onNext,
  onLogin,
  username,
  onChangeUsername,
}) => {
  const isReady = username.length >= 3;
  const { primaryColor, textColor } = Theme.useTheme();

  const socialMediaIcons = [
    {
      icon: "instagram",
      link: "https://instagram.com/stanti.co",
    },
    {
      icon: "linkedin",
      link: "https://www.linkedin.com/company/stanti",
    },
    {
      icon: "tiktok",
      link: "https://www.tiktok.com/@stanti.co",
    },
    {
      icon: "facebook",
      link: "https://www.facebook.com/Stantioficial",
    },
    {
      icon: "whatsapp",
      link: "https://wa.me/5511915657971",
    },
  ] as const;

  return (
    <S.Register>
      <S.RegisterFormHeader>
        <div className="stantiLogo">
          <StantiIcon color="#fafafa" />
        </div>

        <Button onClick={onLogin}>Entrar</Button>
      </S.RegisterFormHeader>

      <S.RegisterFormFirstStep>
        <S.TextContainer>
          <S.Title color={primaryColor}>Muito mais que um link in bio</S.Title>

          <S.Subtitle color="#fafafa">
            Vamos começar?!
            <br />
            Qual vai ser o nome da <span>sua</span> Stanti?
          </S.Subtitle>
        </S.TextContainer>

        <UsernameInput
          value={username}
          onChange={onChangeUsername}
          baseUrl={`${REACT_APP_STOREFRONT_BASE_URL}/`}
        />

        <S.LoginText color={textColor}>
          Já possui uma conta? <Link to="/login">Faça o login</Link>
        </S.LoginText>

        <S.PrivacyTerms>
          <P color="#fafafa80">
            Ao clicar em avançar, você concorda com os nossos{" "}
            <a
              href="https://www.stanti.com.br/termos/termos-de-uso"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termos de Uso
            </a>{" "}
            e{" "}
            <a
              href="https://www.stanti.com.br/termos/politica-de-privacidade"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de privacidade
            </a>
            .
          </P>
        </S.PrivacyTerms>
      </S.RegisterFormFirstStep>

      <S.RegisterFormFooter>
        <S.Contact>
          <H5 color="#fafafa">Fale conosco</H5>

          <S.SocialMedia>
            {socialMediaIcons.map((socialMedia) => (
              <a
                target="_blank"
                rel="noreferrer"
                href={socialMedia.link}
                key={socialMedia.icon}
              >
                <AvailableIcons option={socialMedia.icon} />
              </a>
            ))}
          </S.SocialMedia>
        </S.Contact>

        <NavigationArrow
          angle={isReady ? 0 : -90}
          onClick={() => isReady && onNext()}
          color={isReady ? primaryColor : "#ffffff33"}
          buttonStyle="solid"
        />
      </S.RegisterFormFooter>
    </S.Register>
  );
};

export default UsernameStep;
