import Label from "../../../components/atoms/Typography/Label";
import Small from "../../../components/atoms/Typography/Small";
import Chevron from "../../../components/icons/Chevron";
import AppImage from "../../../components/molecules/AppImage";
import { Theme } from "../../../hooks";
import { TIconOptions } from "../../../types";

import * as S from "./styles";

interface IProps {
  appColor: string;
  onClick: () => void;
  productTitle: string;
  appGradient?: string;
  productLabel: string;
  iconOpacity?: number;
  appIcon: TIconOptions;
  iconBlendMode?: string;
}

const ProductListItem: React.FC<IProps> = ({
  onClick,
  appIcon,
  appColor,
  appGradient,
  iconOpacity,
  productTitle,
  productLabel,
  iconBlendMode,
}) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.ProductItem onClick={onClick}>
      <AppImage
        size={36}
        icon={appIcon}
        color={appColor}
        gradient={appGradient}
        iconOpacity={iconOpacity}
        iconBlendMode={iconBlendMode}
      />

      <S.ProductDetails>
        <S.ProductText>
          <Label color={"#FAFAFA80"}>{productLabel}</Label>
          <Small color={textColor}>{productTitle}</Small>
        </S.ProductText>

        <Chevron />
      </S.ProductDetails>
    </S.ProductItem>
  );
};

export default ProductListItem;
