import styled, { css } from "styled-components";

type NavigationArrowProps = {
  borderColor: string;
  backgroundColor: string;
  angle: number;
};

export const NavigationArrow = styled.button<NavigationArrowProps>`
  ${({ borderColor, backgroundColor, angle }) => css`
    width: 88px;
    height: 88px;
    padding: 20px;
    cursor: pointer;
    border-radius: 50%;
    transition: all ease 0.3s;

    border-color: ${borderColor};
    background-color: ${backgroundColor};
    transform: rotateZ(${angle}deg);
  `}
`;
