export const normalizeDateToLocale = (date: string) => {
  const parsedDate = new Date(date).toLocaleString("pt-BR");

  const [normalizedDate, time] = parsedDate.split(" ");

  const normalizedTime = time.substring(0, 5);

  return `${normalizedDate} às ${normalizedTime}`;
};

export const maskHourAndMinute = (value: string) => {
  if (!value) return "";

  const onlyNumbers = value.replace(/[^0-9]/g, "");

  if (onlyNumbers.length <= 2) return onlyNumbers;

  const firstPart = onlyNumbers.substring(0, 2);
  const secondPart = onlyNumbers.substring(2);

  return `${firstPart}:${secondPart}`;
};

const validateSchedule = (schedule: {
  to: string;
  from: string;
  isSelected: boolean;
}): string[] => {
  const errors: string[] = [];

  const toScheduleMasked = maskHourAndMinute(schedule.to);
  const fromScheduleMasked = maskHourAndMinute(schedule.from);

  if (schedule.isSelected && (!schedule.to || !schedule.from))
    errors.push("O preenchimento dos horários selecionados é obrigatório");

  if (
    schedule.isSelected &&
    ((schedule.to && schedule.to.length !== 5) ||
      (schedule.from && schedule.from.length !== 5))
  )
    errors.push("Um ou mais horários informados são inválidos");

  const [scheduleTimeToHour, scheduleTimeToMinutes] =
    toScheduleMasked.split(":");
  const [scheduleTimeFromHour, scheduleTimeFromMinutes] =
    fromScheduleMasked.split(":");

  const scheduleToLaterThanFrom =
    +scheduleTimeToHour < +scheduleTimeFromHour ||
    (+scheduleTimeToHour === +scheduleTimeFromHour &&
      +scheduleTimeToMinutes < +scheduleTimeFromMinutes);

  const allowedScheduleTimeHour =
    +scheduleTimeToHour <= 23 &&
    +scheduleTimeFromHour <= 23 &&
    +scheduleTimeToMinutes <= 59 &&
    +scheduleTimeFromMinutes <= 59;

  if (schedule.to !== "" && schedule.from !== "" && scheduleToLaterThanFrom) {
    errors.push("Horário final deve ser maior que o horário inicial");
  }

  if (schedule.to !== "" && schedule.from !== "" && !allowedScheduleTimeHour) {
    errors.push("Horário deve estar entre 00:00 e 23:59");
  }

  return errors;
};

export const validateDaysWithError = (
  weekDays: string[],
  schedule: {
    [key: string]: { to: string; from: string; isSelected: boolean };
  }
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  weekDays.map((day) => {
    const currentErrors = validateSchedule(schedule[day]);

    if (currentErrors.length) errors[day] = currentErrors;
  });

  return errors;
};

export const validateLunchTime = (lunchTime: {
  to: string;
  from: string;
  isSelected: boolean;
}): string[] => {
  return validateSchedule(lunchTime);
};
