import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PinIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="27"
      height="30"
      viewBox="0 0 27 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13.5C0 6.03643 6.03643 0 13.5 0C20.9636 0 27 6.03643 27 13.5C27 21.5421 18.4757 32.6314 14.985 36.855C14.2136 37.7807 12.8057 37.7807 12.0343 36.855C8.52429 32.6314 0 21.5421 0 13.5ZM8.67871 13.5C8.67871 16.1614 10.8387 18.3214 13.5001 18.3214C16.1616 18.3214 18.3216 16.1614 18.3216 13.5C18.3216 10.8386 16.1616 8.67859 13.5001 8.67859C10.8387 8.67859 8.67871 10.8386 8.67871 13.5Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PinIcon;
