import styled from "styled-components";

export const CategoriesSelector = styled.div`
  position: relative;
`;

export const Input = styled.div`
  flex-grow: 1;
  display: flex;
  cursor: pointer;
  min-height: 40px;
  padding: 8px 16px;
  border-radius: 40px;
  flex-direction: row;
  align-items: center;
  mix-blend-mode: screen;
  background-color: #fafafa18;
  justify-content: space-between;
`;

type ChevronProps = {
  isSelectorOpen: boolean;
};

export const ChevronContainer = styled.div<ChevronProps>`
  flex-shrink: 0;

  transform: ${({ isSelectorOpen }) =>
    isSelectorOpen ? "rotateZ(90deg)" : "rotateZ(0deg)"};
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: fixed;
`;

export const Selector = styled.div`
  gap: 4px;
  top: 54px;
  left: 50%;
  z-index: 100;
  display: flex;
  padding: 16px 20px;
  position: absolute;
  border-radius: 16px;
  flex-direction: column;
  background-color: #181818;
  transform: translateX(-50%);

  &::before {
    top: -6px;
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    left: calc(50% - 6px);
    transform: rotateZ(45deg);
    background-color: #181818;
  }
`;

export const Option = styled.div`
  gap: 8px;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
`;

type IndicatorProps = {
  borderColor: string;
};

export const Indicator = styled.div<IndicatorProps>`
  width: 16px;
  height: 16px;
  padding: 2px;
  border: 1px solid;
  border-radius: 20px;

  border-color: ${({ borderColor }) => borderColor};

  div {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

type IndicatorBackgroundProps = {
  backgroundColor: string;
};

export const IndicatorBackground = styled.div<IndicatorBackgroundProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
