import styled, { css } from "styled-components";
import Page from "../../components/atoms/Page";

type StantiAppsProps = {
  backgroundColor: string;
};

export const StantiApps = styled(Page)<StantiAppsProps>`
  background: ${({ backgroundColor }) =>
    backgroundColor
      ? backgroundColor
      : css`linear-gradient(
    163.13deg,
    #ffffff60 -44.01%,
    rgba(0, 0, 0, 0) 75.55%
  )`};
`;

export const AppSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;

  &:not(:last-of-type) {
    margin-bottom: 42px;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
