import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const VideoTwoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 0H26.25C27.2125 0 28 0.7875 28 1.75C28 2.7125 27.2125 3.5 26.25 3.5H8.75C7.7875 3.5 7 2.7125 7 1.75C7 0.7875 7.7875 0 8.75 0ZM5.25 10.5H29.75C30.7125 10.5 31.5 9.7125 31.5 8.75C31.5 7.7875 30.7125 7 29.75 7H5.25C4.2875 7 3.5 7.7875 3.5 8.75C3.5 9.7125 4.2875 10.5 5.25 10.5ZM35 17.5V31.5C35 33.425 33.425 35 31.5 35H3.5C1.575 35 0 33.425 0 31.5V17.5C0 15.575 1.575 14 3.5 14H31.5C33.425 14 35 15.575 35 17.5ZM14 18.7775L23.0825 23.73C23.695 24.0625 23.695 24.9375 23.0825 25.27L14 30.205V18.7775Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default VideoTwoIcon;
