import styled from "styled-components";

export const SocialMediaContainer = styled.div`
  position: relative;
`;

export const Icon = styled.span`
  position: absolute;
  top: 38px;
  left: 10px;

  svg {
    width: 15px;
    height: auto;
  }
`;

export const InputLabel = styled.label`
  font-size: 14px;
  color: #fafafa;
  display: block;
  margin-bottom: 8px;
`;
