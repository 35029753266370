import { CSSProperties, HTMLAttributes } from "react";
import { Theme } from "../../hooks";

interface IProps extends HTMLAttributes<SVGSVGElement> {
  color?: string;
  opacity?: number;
  style?: CSSProperties;
  blendMode?: "normal" | "overlay";
}

const StantiIcon: React.FC<IProps> = ({ color, style, blendMode, opacity }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        ...style,
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="225"
      height="216"
      viewBox="0 0 225 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.0087 60.3341C46.0087 46.1756 61.3421 37.5255 79.8256 37.5255C96.7341 37.5255 110.893 45.3923 122.684 58.3674C129.368 65.8341 136.051 68.5925 141.951 68.5925C151.785 68.5925 159.251 59.9424 159.251 49.7173C159.251 44.6006 157.676 38.7089 153.351 33.5922C138.801 15.1087 109.701 3.31696 80.2173 3.31696C37.3586 3.31696 2.35835 25.7338 2.35835 64.2674C2.35835 136.618 123.076 114.993 123.076 155.493C123.076 170.827 108.134 179.877 85.3257 179.877C63.7005 179.877 49.9337 171.227 38.9253 154.71C33.8169 146.843 27.5252 143.701 21.2335 143.701C10.2251 143.701 0 153.535 0 165.327C0 170.043 1.575 174.76 5.10836 179.877C20.8418 201.893 47.1837 215.269 84.934 215.269C133.693 215.269 168.293 193.252 168.293 152.751C168.301 76.8509 46.0087 100.443 46.0087 60.3341Z"
        fill={color || primaryColor}
      />
      <path
        d="M205.86 41.8602C216.404 39.8297 223.305 29.636 221.275 19.0919C219.245 8.54773 209.051 1.64601 198.507 3.67646C187.963 5.70691 181.061 15.9006 183.091 26.4448C185.122 36.9889 195.315 43.8906 205.86 41.8602Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default StantiIcon;
