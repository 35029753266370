import { useNavigate } from "react-router-dom";

import { IForm } from "../..";
import AppFormAboutAuthor from "../../../../../../components/atoms/AppFormAboutAuthor";
import AppFormAddIcon from "../../../../../../components/atoms/AppFormAddIcon";
import AppFormDescription from "../../../../../../components/atoms/AppFormDescription";
import AppFormFAQ from "../../../../../../components/atoms/AppFormFAQ";
import AppFormFile from "../../../../../../components/atoms/AppFormFile";
import AppFormHeader from "../../../../../../components/atoms/AppFormHeader";
import AppFormMedia from "../../../../../../components/atoms/AppFormMedia";
import AppFormPrice from "../../../../../../components/atoms/AppFormPrice";
import AppFormTags from "../../../../../../components/atoms/AppFormTags";
import AppFormTargetAudience from "../../../../../../components/atoms/AppFormTargetAudience";
import AppFormTitle from "../../../../../../components/atoms/AppFormTitle";
import Input from "../../../../../../components/atoms/Input";
import Page from "../../../../../../components/atoms/Page";
import H3 from "../../../../../../components/atoms/Typography/H3";
import LinkIcon from "../../../../../../components/icons/LinkIcon";
import AppFormFooter from "../../../../../../components/molecules/AppFormFooter";
import AppFormSection from "../../../../../../components/molecules/AppFormSection";
import appConfig from "../../../../../../config/app-arquivo.json";
import { Theme } from "../../../../../../hooks";

import * as S from "./styles";

interface IProps {
  item: IForm;
  onSave: () => void;
  providedURL: string;
  onDelete: () => void;
  isEditingItem: boolean;
  uploadedFileURL: string;
  isUploadingFile: boolean;
  onRemoveFile: () => void;
  fileUploadProgress: number;
  onAddMedia: (url: string) => void;
  onUploadFile: (file: File) => void;
  errors: { [key: string]: string[] };
  setProvidedUrl: (val: string) => void;
  onRemoveMedia: (index: number) => void;
  onChangeHandler: (value: any, field: keyof IForm) => void;
}

const FormOutro: React.FC<IProps> = ({
  item,
  errors,
  onSave,
  onDelete,
  onAddMedia,
  providedURL,
  onRemoveFile,
  onUploadFile,
  isEditingItem,
  onRemoveMedia,
  setProvidedUrl,
  isUploadingFile,
  uploadedFileURL,
  onChangeHandler,
  fileUploadProgress,
}) => {
  const navigate = useNavigate();
  const { textColor } = Theme.useTheme();

  const fileTitle = uploadedFileURL
    ? decodeURI(uploadedFileURL.split("/").reverse()[0])
    : fileUploadProgress > 0
    ? String(fileUploadProgress)
    : "";

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps/${appConfig.id}`)}
      />

      <AppFormSection>
        <S.UploadSection>
          <H3 color="#FAFAFA">Outro tipo de arquivo</H3>

          {!providedURL && (
            <AppFormFile
              accept="*"
              maxFileSizeMB={35}
              errors={errors.url}
              fileTitle={fileTitle}
              onUploadFile={onUploadFile}
              onRemoveFile={onRemoveFile}
              isUploading={isUploadingFile}
              enableUpload={!isUploadingFile}
              description="Insira abaixo o link ou o arquivo (Tamanho maximo 35MB) que deseja vender para seus clientes."
            />
          )}

          {!providedURL && !uploadedFileURL && !isUploadingFile && (
            <S.OrText color={textColor}>ou</S.OrText>
          )}

          {!uploadedFileURL && !isUploadingFile && (
            <Input
              leftIcon={<LinkIcon />}
              value={providedURL || ""}
              onChange={setProvidedUrl}
              placeholder="Link para download (Drive/Dropbox)"
            />
          )}
        </S.UploadSection>
      </AppFormSection>

      <AppFormSection title="Sobre o produto">
        <AppFormTitle
          errors={errors.title}
          value={item.title || ""}
          placeholder="Título do produto"
          onChange={(val) => onChangeHandler(val, "title")}
        />

        <AppFormDescription
          errors={errors.description}
          value={item.description || ""}
          placeholder="Uma breve descrição sobre o material"
          onChange={(val) => onChangeHandler(val, "description")}
        />

        <AppFormAddIcon
          errors={errors.icon}
          icon={item.image || ""}
          onClear={() => onChangeHandler("", "image")}
          onChange={(image) => onChangeHandler(image, "image")}
          description="Este ícone ou imagem aparecerá na sua Stanti"
        />
      </AppFormSection>

      <AppFormSection title="Informações adicionais">
        <AppFormTargetAudience
          textLabelType="arquivo"
          errors={errors.targetAudience}
          value={item.targetAudience || ""}
          onChange={(val) => onChangeHandler(val, "targetAudience")}
        />

        <AppFormAboutAuthor
          errors={errors.aboutAuthor}
          value={item.aboutAuthor || ""}
          onChange={(val) => onChangeHandler(val, "aboutAuthor")}
        />
      </AppFormSection>

      <AppFormSection title="Mídia">
        <AppFormMedia
          errors={errors.media}
          onChange={onAddMedia}
          images={item.media || []}
          onRemoveImage={onRemoveMedia}
          description="Você pode adicionar fotos para deixar sua página de vendas mais bonita e informativa. O formato da imagem é o mesmo do feed vertical do Instagram."
        />
      </AppFormSection>

      <AppFormSection>
        <AppFormFAQ
          errors={errors.faq}
          faqs={item.faq || []}
          onChange={(val) => onChangeHandler(val, "faq")}
        />
      </AppFormSection>

      <AppFormSection title="Tags">
        <AppFormTags
          errors={errors.tags}
          value={item.tags || []}
          onChange={(val) => onChangeHandler(val, "tags")}
        />
      </AppFormSection>

      <AppFormSection title="Preço">
        <AppFormPrice
          appId={appConfig.id}
          errors={errors.price}
          value={item.price || 0}
          onChange={(val) => onChangeHandler(val, "price")}
        />
      </AppFormSection>

      <AppFormFooter
        onSave={onSave}
        saveDraft={false}
        appTitle="Arquivo"
        onRemove={onDelete}
        isEditingItem={isEditingItem}
      />
    </Page>
  );
};

export default FormOutro;
