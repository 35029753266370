import AppLinkButton from "../../../../components/atoms/AppLinkButton";
import { TIconOptions } from "../../../../types";

import * as S from "./styles";

const links = [
  {
    app: "mentoria",
    icon: "bookTwo",
    link: "/apps/mentoria/new",
    title: "Venda mentorias e encontros online",
  },
  {
    app: "arquivo",
    icon: "folder",
    title: "Venda cursos gravados",
    link: "/apps/arquivo/course/new",
  },
  {
    app: "arquivo",
    icon: "files",
    link: "/apps/arquivo",
    title: "Venda e-books, PDFs e arquivos",
  },
  {
    app: "servico",
    title: "Serviço",
    icon: "call",
    link: "/apps/servico",
  },
  {
    app: "curso-live",
    icon: "onlineCall",
    link: "/apps/curso-live/new",
    title: "Venda cursos transmitidos online",
  },
  {
    app: "stanti-pay",
    icon: "moneyTwo",
    link: "/apps/stanti-pay",
    title: "Crie links de pagamentos",
  },
  {
    app: "grupo",
    link: "/apps/grupo/new",
    icon: "group",
    title: "Venda grupos pagos e privados",
  },
  {
    app: "afiliados",
    icon: "receipt",
    link: "/apps/afiliados",
    title: "Programa de afiliados da Stanti",
  },
];

interface IProps {
  onClick: (app: string) => void;
}

const MonetizedAppList: React.FC<IProps> = ({ onClick }) => {
  return (
    <S.MonetizedAppListContainer>
      {links.map((link) => (
        <AppLinkButton
          key={link.title}
          link={link.link}
          icon={link.icon as TIconOptions}
          title={link.title}
          onClick={() => onClick(link.app)}
        />
      ))}
    </S.MonetizedAppListContainer>
  );
};

export default MonetizedAppList;
