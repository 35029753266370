import { Navigate, Route } from "react-router-dom";

import Home from "./components/Home";
import Details from "./components/Details";

const AppAfiliados = () => {
  return (
    <Route path="afiliados">
      <Route index element={<Home />} />
      <Route path=":campaignId" element={<Details />} />
      <Route index path="*" element={<Navigate to="/apps/afiliados" />} />
    </Route>
  );
};

export default AppAfiliados;
