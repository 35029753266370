import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import AppHomeProducts from "../../../../components/atoms/AppHomeProducts";
import AppLinkToPay from "../../../../components/atoms/AppLinkToPay";
import Button from "../../../../components/atoms/Button";
import ExternalLinkSkeleton from "../../../../components/atoms/ExternalLinkSkeleton";
import Page from "../../../../components/atoms/Page";
import SeparatorLine from "../../../../components/atoms/SeparatorLine";
import P from "../../../../components/atoms/Typography/P";
import appConfig from "../../../../config/app-stanti-pay.json";
import { GA, StantiPay, Theme } from "../../../../hooks";
import { IAppProduct, TIconOptions } from "../../../../types";

import * as S from "./styles";

const Home = () => {
  const [productsList, setProductsList] = useState<IAppProduct[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  const navigate = useNavigate();
  const { sendEvents } = GA.useGA();
  const { getProducts } = StantiPay.useStantiPay();
  const { textColor } = Theme.useTheme();

  useEffect(() => {
    const run = async () => {
      const products = await getProducts();

      setIsLoadingProducts(false);

      if (products) setProductsList(products);
    };

    run();
  }, [getProducts]);

  const onClickHandler = () => {
    sendEvents({ name: "new-sku", category: appConfig.id });
    navigate(`/apps/${appConfig.id}/new`);
  };

  const onItemClickHandler = (id: string) => {
    sendEvents({
      name: "edit-sku",
      category: appConfig.id,
      buttonLocation: `home-app-${appConfig.id}`,
    });
    navigate(`/apps/${appConfig.id}/${id}`);
  };

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate("/apps")}
        showLiveLabel={appConfig.showIsLiveLabel}
      />

      <S.LinkToPay>
        <P color={textColor}>Seu link para receber pagamentos</P>

        <AppLinkToPay />
      </S.LinkToPay>

      <SeparatorLine />

      <S.MyProducts>
        <P color={textColor}>Meus produtos</P>

        <S.NewProduct>
          <Button onClick={onClickHandler}>Novo produto</Button>
        </S.NewProduct>

        {isLoadingProducts && <ExternalLinkSkeleton />}

        {productsList && productsList.length > 0 && (
          <>
            <AppHomeProducts
              items={productsList}
              appAlias={appConfig.id}
              sectionTitle=""
              appTitle={appConfig?.title}
              appIcon={appConfig?.icon as TIconOptions}
              onItemClick={(id: string) => onItemClickHandler(id)}
            />
          </>
        )}
      </S.MyProducts>
    </Page>
  );
};

export default Home;
