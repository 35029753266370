import styled from "styled-components";

export const ProductItem = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  border-bottom: 1px solid #fafafa40;

  &:last-of-type {
    border: none;
  }
`;

export const ProductDetails = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 12px 16px;
  justify-content: space-between;
`;

export const ProductText = styled.div`
  gap: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
