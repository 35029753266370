import styled from "styled-components";

export const Container = styled.div`
  z-index: 10;
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
`;

type CallToActionProps = {
  color: string;
};

export const OtherSocialMediaStepProgressBar = styled.div`
  gap: 18px;
  display: flex;
  padding-top: 80px;
  flex-direction: column;
`;

export const Highlight = styled.span`
  font-weight: 500;
`;

export const CallToAction = styled.div<CallToActionProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 50px;

  span {
    font-size: 14px;
    color: ${({ color }) => color};
  }

  h2 {
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 300;
  }
`;

export const Form = styled.div`
  gap: 12px;
  display: flex;
  padding-top: 30px;
  flex-direction: column;

  .text {
    display: flex;
    margin-top: -100px;
    margin-bottom: 20px;
    justify-content: center;
  }
`;

type InputContainerProps = {
  color: string;
  isSuccess: boolean;
};

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;

  & > span {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 6px;
    box-shadow: 0px 0px 29px 7px;
    border-radius: 40px;

    color: ${({ color }) => color};
    background-color: ${({ color }) => color};
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 53px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #fafafa1a;
  mix-blend-mode: screen;
  border-top: 1px solid #fafafa1a;
  border-right: 1px solid #fafafa1a;
  border-left: 1px solid #fafafa1a;

  small {
    font-size: 6px;
  }

  svg {
    width: 20px;
    height: auto;
  }
`;
