import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const AlertIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="33"
      height="41"
      viewBox="0 0 33 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2397 29.0775L31.6597 31.4975C32.8416 32.6793 32.0161 34.7054 30.3278 34.7054H1.86936C0.199748 34.7054 -0.625678 32.6793 0.556181 31.4975L2.97618 29.0775V17.8217C2.97618 11.5372 7.40346 6.28449 13.294 5.00883V2.81395C13.294 1.2569 14.5509 0 16.1079 0C17.665 0 18.9219 1.2569 18.9219 2.81395V5.00883C24.8124 6.28449 29.2397 11.5372 29.2397 17.8217V29.0775ZM19.8412 36.6001C19.8412 38.6637 18.1716 40.3333 16.1081 40.3333C14.0445 40.3333 12.3749 38.6637 12.3749 36.6001H19.8412ZM17.9845 23.4496V27.2015H14.2325V23.4496H17.9845ZM16.1085 19.6976C17.1403 19.6976 17.9845 18.8534 17.9845 17.8216V14.0697C17.9845 13.0379 17.1403 12.1938 16.1085 12.1938C15.0767 12.1938 14.2325 13.0379 14.2325 14.0697V17.8216C14.2325 18.8534 15.0767 19.6976 16.1085 19.6976Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default AlertIcon;
