import styled, { css } from "styled-components";

type BoxProps = {
  width: string;
  height: string;
  borderRadius: string;
};

export const Box = styled.div<BoxProps>`
  ${({ width, height, borderRadius }) => css`
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    mix-blend-mode: screen;
    justify-content: center;
    border-top: 1px solid #fafafa0f;
    border-left: 1px solid #fafafa0f;
    border-right: 1px solid #fafafa0f;
    background: linear-gradient(
      271.06deg,
      rgba(250, 250, 250, 0.17) -3.98%,
      rgba(113, 114, 111, 0) 203.38%
    );
    width: ${width};
    height: ${height};
    border-radius: ${borderRadius};
  `}
`;

type IconProps = {
  width: string;
  height: string;
};

export const Icon = styled.div<IconProps>`
  ${({ width, height }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${width};
    height: ${height};

    svg {
      width: 100%;
      height: 100%;
    }
  `}
`;

type SelectedLightProps = {
  backgroundColor: string;
  shadowColor: string;
};

export const SelectedLight = styled.span<SelectedLightProps>`
  ${({ backgroundColor, shadowColor }) => css`
    bottom: 0;
    width: 5px;
    height: 2px;
    position: absolute;
    border-radius: 42px;
    background: ${backgroundColor};
    box-shadow: 0px 0px 29px 7px ${shadowColor};
  `}
`;
