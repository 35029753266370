import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const WebIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.5C0 9.184 9.1635 0 20.4795 0C31.816 0 41 9.184 41 20.5C41 31.816 31.816 41 20.4795 41C9.1635 41 0 31.816 0 20.5ZM28.6384 12.2999H34.6859C32.7179 8.91735 29.5814 6.29335 25.8094 5.00185C27.0394 7.27735 27.9824 9.73735 28.6384 12.2999ZM20.5001 4.18211C22.2016 6.64211 23.5341 9.36861 24.4156 12.3001H16.5846C17.4661 9.36861 18.7986 6.64211 20.5001 4.18211ZM4.09979 20.4999C4.09979 21.9144 4.30479 23.2879 4.63279 24.5999H11.5618C11.3978 23.2469 11.2748 21.8939 11.2748 20.4999C11.2748 19.1059 11.3978 17.7529 11.5618 16.3999H4.63279C4.30479 17.7119 4.09979 19.0854 4.09979 20.4999ZM6.31458 28.7H12.3621C13.0181 31.2625 13.9611 33.7225 15.1911 35.998C11.4191 34.7065 8.28258 32.103 6.31458 28.7ZM6.31458 12.2999H12.3621C13.0181 9.73735 13.9611 7.27735 15.1911 5.00185C11.4191 6.29335 8.28258 8.89685 6.31458 12.2999ZM20.5001 36.818C18.7986 34.358 17.4661 31.6315 16.5846 28.7H24.4156C23.5341 31.6315 22.2016 34.358 20.5001 36.818ZM15.703 24.5999H25.297C25.4815 23.2469 25.625 21.8939 25.625 20.4999C25.625 19.1059 25.4815 17.7324 25.297 16.3999H15.703C15.5185 17.7324 15.375 19.1059 15.375 20.4999C15.375 21.8939 15.5185 23.2469 15.703 24.5999ZM25.8094 35.998C27.0394 33.7225 27.9824 31.2625 28.6384 28.7H34.6859C32.7179 32.0825 29.5814 34.7065 25.8094 35.998ZM29.7262 20.4999C29.7262 21.8939 29.6032 23.2469 29.4392 24.5999H36.3682C36.6962 23.2879 36.9012 21.9144 36.9012 20.4999C36.9012 19.0854 36.6962 17.7119 36.3682 16.3999H29.4392C29.6032 17.7529 29.7262 19.1059 29.7262 20.4999Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default WebIcon;
