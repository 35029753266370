import styled from "styled-components";

type H4Props = {
  color: string;
};

const H4 = styled.h4<H4Props>`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: ${({ color }) => color};
`;

export default H4;
