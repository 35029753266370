import Small from "../Typography/Small";
import InputWithLabel from "../../molecules/InputWithLabel";

interface IProps {
  value: string;
  errors?: string[];
  onChange: (val: string) => void;
}

const AppFormLinkUrl: React.FC<IProps> = ({ onChange, value, errors }) => {
  return (
    <>
      <InputWithLabel
        value={value}
        inputLabel="Url"
        onChange={onChange}
        placeholder="https://url"
      />

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </>
  );
};

export default AppFormLinkUrl;
