import styled from "styled-components";

type H1Props = {
  color: string;
};

const H1 = styled.h1<H1Props>`
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  font-family: "Nunito", "Poppins", sans-serif;
  color: ${({ color }) => color};
`;

export default H1;
