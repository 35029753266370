import Button from "../../../../components/atoms/Button";
import H2 from "../../../../components/atoms/Typography/H2";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  onBack: () => void;
}

const EditProfileHeader: React.FC<IProps> = ({ onBack }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <S.EditProfileHeader>
      <H2 color={primaryColor}>Editar</H2>

      <Button type="outline" onClick={onBack} color={primaryColor}>
        Voltar
      </Button>
    </S.EditProfileHeader>
  );
};

export default EditProfileHeader;
