import { MouseEvent } from "react";

import { Auth, Snackbar, Theme } from "../../../hooks";
import LinkIcon from "../../icons/LinkIcon";
import Small from "../Typography/Small";

import * as S from "./styles";

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const AppLinkToPay = () => {
  const { primaryColor } = Theme.useTheme();

  const { newSuccess } = Snackbar.useSnackbar();

  const { user } = Auth.useAuth();
  const urlBase = REACT_APP_STOREFRONT_BASE_URL;

  const urlPay = urlBase + "/" + user.username + "/pay";

  const onCopy = (
    e: MouseEvent<HTMLDivElement>,
    dataToCopy: string,
    message: string
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(dataToCopy);

    newSuccess(message);
  };

  return (
    <S.LinkToPay textDecorationColor={primaryColor}>
      <Small color={primaryColor}>{urlPay}</Small>

      <S.CopyIcon onClick={(e) => onCopy(e, urlPay, "Link copiado")}>
        <LinkIcon />
      </S.CopyIcon>
    </S.LinkToPay>
  );
};

export default AppLinkToPay;
