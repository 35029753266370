import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CheckCircleIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 17.5C0 7.84 7.84 0 17.5 0C27.16 0 35 7.84 35 17.5C35 27.16 27.16 35 17.5 35C7.84 35 0 27.16 0 17.5ZM6.47465 18.7252L12.7572 25.0077C13.4397 25.6902 14.5597 25.6902 15.2247 25.0077L28.5071 11.7252C29.1896 11.0427 29.1896 9.94023 28.5071 9.25773C27.8246 8.57523 26.7221 8.57523 26.0396 9.25773L13.9997 21.2977L8.94215 16.2577C8.25965 15.5752 7.15715 15.5752 6.47465 16.2577C6.14696 16.5847 5.9628 17.0286 5.9628 17.4915C5.9628 17.9544 6.14696 18.3983 6.47465 18.7252Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CheckCircleIcon;
