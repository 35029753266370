import { MP_CREDIT_CARD_FEE, MP_PIX_FEE } from "../../../constants";
import { Theme } from "../../../hooks";
import CreditCardIcon from "../../icons/CreditCardIcon";
import PixIcon from "../../icons/PixIcon";
import Input from "../Input";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  value: number;
  appId: string;
  errors?: string[];
  onChange: (value: number) => void;
}

const baseFees = {
  grupo: 0.04,
  arquivo: 0.04,
  servico: 0.04,
  mentoria: 0.04,
  "curso-live": 0.04,
};

const AppFormPrice: React.FC<IProps> = ({ value, onChange, errors, appId }) => {
  const { primaryColor } = Theme.useTheme();

  const onChangePrice = (value: string) => {
    const onlyNumbersValue = value.replace(/[^0-9]/g, "");

    if (!onlyNumbersValue.length) return onChange(0);

    onChange(+onlyNumbersValue / 100);
  };

  const getCreditCardValue = () => {
    if (!value) return 0;

    const itemFee = baseFees[appId as keyof typeof baseFees];

    const gatewayFee = +value * MP_CREDIT_CARD_FEE;
    const stantiFee = +value * itemFee;

    const creditCardPrice = +value - gatewayFee - stantiFee;

    const normalizedCreditCardPrice = (
      Math.round((creditCardPrice + Number.EPSILON) * 100) / 100
    )
      .toFixed(2)
      .replace(".", ",");

    return normalizedCreditCardPrice;
  };

  const getPixValue = () => {
    if (!value) return 0;

    const itemFee = baseFees[appId as keyof typeof baseFees];

    const gatewayFee = +value * MP_PIX_FEE;
    const stantiFee = +value * itemFee;

    const pixPrice = +value - gatewayFee - stantiFee;

    const normalizedPixPrice = (
      Math.round((pixPrice + Number.EPSILON) * 100) / 100
    )
      .toFixed(2)
      .replace(".", ",");

    return normalizedPixPrice;
  };

  const parseValue = (val: number) => {
    if (!val) return "";

    return value.toFixed(2).replace(".", ",");
  };

  return (
    <S.FormPrice>
      <Input
        placeholder="0,00"
        onChange={onChangePrice}
        value={parseValue(value)}
        leftIcon={
          <S.PriceIcon>
            <Small color={primaryColor}>R$</Small>
          </S.PriceIcon>
        }
      />

      <S.TotalValues>
        <S.TotalValue>
          <div className="icon">
            <PixIcon />
          </div>

          <P color="#FAFAFA70">
            No <S.Highlight textColor={primaryColor}>pix</S.Highlight>, você
            receberá <S.Price>R$ {getPixValue()}</S.Price>
          </P>
        </S.TotalValue>

        <S.TotalValue>
          <div className="icon">
            <CreditCardIcon />
          </div>

          <P color="#FAFAFA70">
            No <S.Highlight textColor={primaryColor}>cartão</S.Highlight>, você
            receberá <S.Price>R$ {getCreditCardValue()}</S.Price>
          </P>
        </S.TotalValue>
      </S.TotalValues>

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </S.FormPrice>
  );
};

export default AppFormPrice;
