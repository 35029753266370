import styled, { css } from "styled-components";
import ShowPasswordIcon from "../../icons/ShowPasswordIcon";
import P from "../Typography/P";
import Small from "../Typography/Small";

export const InputWithLabel = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled(P)`
  color: white;
`;

type InputContainerProps = {
  isDisabled: boolean;
  size: "regular" | "large";
  variant: "regular" | "outline";
  borderColor: string;
};

const inputModifier = {
  regular: () => css`
    background-color: #fafafa18;
  `,
  outline: (borderColor: string) => css`
    border: 1px solid ${borderColor};
  `,
  large: () => css`
    padding: 16px 16px 16px 70px;
    border-radius: 20px;
  `,
};

export const InputContainer = styled.div<InputContainerProps>`
  ${({ isDisabled, size, variant, borderColor }) => css`
    flex-grow: 1;
    cursor: text;
    display: flex;
    overflow: hidden;
    padding: 8px 16px;
    min-height: 40px;
    border-radius: 40px;
    flex-direction: row;
    align-items: center;
    mix-blend-mode: screen;
    justify-content: flex-start;

    ${isDisabled &&
    css`
      color: #71726f;
      cursor: default;
      border-color: #71726f !important;

      input {
        color: #71726f !important;
      }
    `}

    ${inputModifier[variant](borderColor)}
    ${inputModifier[size]()}
  `}
`;

type InputProps = {
  textColor: string;
  isSocialMedia?: boolean;
};

export const Input = styled.input<InputProps>`
  ${({ isSocialMedia, textColor }) => css`
    width: 100%;
    outline: none;
    font-size: 14px;
    margin-right: 4px;
    text-overflow: ellipsis;
    color: ${textColor};
    padding-left: ${isSocialMedia ? "18px" : "0px"};

    &::placeholder {
      font-size: 14px;
      color: #71726f;
    }
  `}
`;

export const Icons = styled.div`
  gap: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const PasswordIcon = styled(ShowPasswordIcon)`
  opacity: 0.5;
`;

export const RightBar = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  justify-content: space-between;
`;

export const CharLimit = styled(Small)`
  font-size: 10px;
`;

export const LeftIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
