import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SmileTwoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22.0001C0 9.85604 9.83404 0 21.9781 0C34.1441 0 44.0002 9.85604 44.0002 22.0001C44.0002 34.1441 34.1441 44.0002 21.9781 44.0002C9.83404 44.0002 0 34.1441 0 22.0001ZM14.3004 13.2C12.4779 13.2 11.0004 14.6775 11.0004 16.5001C11.0004 18.3226 12.4779 19.8001 14.3004 19.8001C16.123 19.8001 17.6005 18.3226 17.6005 16.5001C17.6005 14.6775 16.123 13.2 14.3004 13.2ZM29.7001 13.2C27.8776 13.2 26.4001 14.6775 26.4001 16.5001C26.4001 18.3226 27.8776 19.8001 29.7001 19.8001C31.5227 19.8001 33.0001 18.3226 33.0001 16.5001C33.0001 14.6775 31.5227 13.2 29.7001 13.2ZM22.0005 39.6002C12.2764 39.6002 4.40041 31.7241 4.40041 22.0001C4.40041 12.2761 12.2764 4.40004 22.0005 4.40004C31.7245 4.40004 39.6005 12.2761 39.6005 22.0001C39.6005 31.7241 31.7245 39.6002 22.0005 39.6002ZM29.4355 26.6861C29.8975 25.9161 30.9315 25.6741 31.7015 26.1581C32.4715 26.6421 32.7135 27.6541 32.2295 28.4241C29.9855 31.9661 26.1795 34.1001 21.9995 34.1001C17.8195 34.1001 14.0135 31.9881 11.7695 28.4021C11.2855 27.6321 11.5275 26.6201 12.2975 26.1361C13.0675 25.6521 14.0795 25.8941 14.5635 26.6641C16.1915 29.2601 18.9635 30.8001 21.9995 30.8001C25.0355 30.8001 27.8075 29.2601 29.4355 26.6861Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SmileTwoIcon;
