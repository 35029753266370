import { Theme } from "../../../hooks";
import EditIcon from "../../icons/EditIcon";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  index: number;
  productTitle: string;
  onClick?: () => void;
}

const StantiPayProduct: React.FC<IProps> = ({
  index,
  onClick,
  productTitle,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const onClickHandler = () => {
    !!onClick && onClick();
  };

  return (
    <S.StantiPayProduct onClick={onClickHandler}>
      <S.Index>
        <Small color={primaryColor}>{index}</Small>
      </S.Index>

      <S.Content>
        <P color={textColor}>{productTitle}</P>

        <S.EditIcon>
          <EditIcon />
        </S.EditIcon>
      </S.Content>
    </S.StantiPayProduct>
  );
};

export default StantiPayProduct;
