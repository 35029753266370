import { useEffect } from "react";

import CreateAgendaModal from "../../../pages/Calendar/components/CreateAgendaModal";
import { ICalendar, TSchedulingOption } from "../../../types/calendar";
import AppFormAgendaSelected from "../AppFormAgendaSelected";
import SchedulingOptionSwitcher from "../SchedulingOptionSwitcher";

import * as S from "./styles";

export interface IDate {
  day: string;
  year: string;
  month: string;
}

export interface ITime {
  hours: string;
  minutes: string;
}

interface IProps {
  appId: string;
  onSaveDraft: () => void;
  duration: string | null;
  numberOfSessions: number;
  isSelectorOpen?: boolean;
  agendaModalOpen?: boolean;
  calendar?: ICalendar | null;
  errors?: { [key: string]: string[] };
  schedulingOption?: TSchedulingOption;
  setIsSelectorOpen: (val: boolean) => void;
  setAgendaModalOpen?: (val: boolean) => void;
  onChange: (val: any, field: string) => void;
  setSchedulingOption?: (val: TSchedulingOption) => void;
}

const AppFormCalendar: React.FC<IProps> = ({
  appId,
  errors,
  calendar,
  duration,
  onChange,
  onSaveDraft,
  isSelectorOpen,
  agendaModalOpen,
  numberOfSessions,
  schedulingOption,
  setIsSelectorOpen,
  setAgendaModalOpen,
  setSchedulingOption,
}) => {
  useEffect(() => {
    if (schedulingOption === "agenda" && !calendar) {
      setAgendaModalOpen && setAgendaModalOpen(true);
    }
  }, [calendar, schedulingOption, setAgendaModalOpen]);

  const onCloseModalHandler = () => {
    setAgendaModalOpen && setAgendaModalOpen(false);
    setSchedulingOption && setSchedulingOption("no-date");
  };

  return (
    <S.FormCalendar>
      <S.SchedulingOption>
        <SchedulingOptionSwitcher
          isSwitcherOn={schedulingOption === "agenda"}
          description="Liberar meu calendário para agendamentos"
          setSchedulingOption={(val) =>
            setSchedulingOption && setSchedulingOption(val)
          }
        />
      </S.SchedulingOption>

      {schedulingOption === "agenda" && (
        <>
          <CreateAgendaModal
            appId={appId}
            onSaveDraft={onSaveDraft}
            isOpen={!!agendaModalOpen}
            onCloseModal={() => onCloseModalHandler()}
          />

          <AppFormAgendaSelected
            errors={errors}
            duration={duration}
            isSelectorOpen={!!isSelectorOpen}
            numberOfSessions={numberOfSessions}
            onChange={(val, field) => onChange(val, field)}
            setIsSelectorOpen={(val) => setIsSelectorOpen(val)}
          />
        </>
      )}
    </S.FormCalendar>
  );
};

export default AppFormCalendar;
