import { duration } from "../../../constants/calendar";
import Chevron from "../../icons/Chevron";
import ClockTwoIcon from "../../icons/ClockTwoIcon";

import * as S from "./styles";

interface IProps {
  isSelectorOpen: boolean;
  durationLabel: string | null;
  setIsSelectorOpen: (val: any) => void;
  onChangeDuration: (val: string) => void;
}

const DurationSelector: React.FC<IProps> = ({
  durationLabel,
  isSelectorOpen,
  onChangeDuration,
  setIsSelectorOpen,
}) => {
  const onClickHandler = (index: number) => {
    onChangeDuration(duration[index].durationFormat);
    setIsSelectorOpen(false);
  };

  const durationIndex = duration.findIndex(
    (item) => item.durationFormat === durationLabel
  );

  return (
    <>
      {isSelectorOpen && (
        <S.Backdrop onClick={() => setIsSelectorOpen(false)} />
      )}

      <S.DurationSelector>
        <S.SelectedDuration
          onClick={() => setIsSelectorOpen((curr: boolean) => !curr)}
        >
          <S.ClockAndText>
            <div className="clockIcon">
              <ClockTwoIcon />
            </div>

            {durationLabel
              ? `${duration[durationIndex].text} de duração por sessão`
              : "Selecione a duração de cada sessão"}
          </S.ClockAndText>

          <S.Icon isSelectorOpen>
            <Chevron />
          </S.Icon>
        </S.SelectedDuration>

        {isSelectorOpen && (
          <S.Selector>
            {duration.map((item, index) => (
              <S.SelectorItem
                key={item.text}
                onClick={() => onClickHandler(index)}
              >
                {`${item.text} de duração por sessão`}
              </S.SelectorItem>
            ))}
          </S.Selector>
        )}
      </S.DurationSelector>
    </>
  );
};

export default DurationSelector;
