import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BookOneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="44"
      height="34"
      viewBox="0 0 44 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 0H4C1.8 0 0 1.8 0 4V30C0 32.2 1.8 34 4 34H40C42.2 34 44 32.2 44 30V4C44 1.8 42.2 0 40 0ZM40.0004 28C40.0004 29.1 39.1004 30 38.0004 30H22.0004V4.00003H38.0004C39.1004 4.00003 40.0004 4.90003 40.0004 6.00003V28ZM25.5002 11H36.5002C37.3202 11 38.0002 11.68 38.0002 12.5C38.0002 13.32 37.3202 14 36.5002 14H25.5002C24.6802 14 24.0002 13.32 24.0002 12.5C24.0002 11.68 24.6802 11 25.5002 11ZM36.5002 16H25.5002C24.6802 16 24.0002 16.68 24.0002 17.5C24.0002 18.32 24.6802 19 25.5002 19H36.5002C37.3202 19 38.0002 18.32 38.0002 17.5C38.0002 16.68 37.3202 16 36.5002 16ZM25.5002 21H36.5002C37.3202 21 38.0002 21.68 38.0002 22.5C38.0002 23.32 37.3202 24 36.5002 24H25.5002C24.6802 24 24.0002 23.32 24.0002 22.5C24.0002 21.68 24.6802 21 25.5002 21Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BookOneIcon;
