import { Theme } from "../../../hooks";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  value: string;
  onChangeValue: (val: string) => void;
}

const AppFormPagesQuantity: React.FC<IProps> = ({ value, onChangeValue }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const onChangeHandler = (val: string) => {
    if (+val < 0) return;

    const normalizedValue = val.replace(/([^0-9])/g, "");
    onChangeValue(normalizedValue);
  };

  return (
    <S.Quantity>
      <P color={textColor}>Número de páginas</P>

      <S.NumberSelector>
        <S.Button onClick={() => onChangeHandler(String((+value || 0) - 1))}>
          -
        </S.Button>

        <S.Input
          value={value}
          placeholder="0"
          textColor={primaryColor}
          onChange={({ target }) => onChangeHandler(target.value)}
        />

        <S.Button onClick={() => onChangeHandler(String((+value || 0) + 1))}>
          +
        </S.Button>
      </S.NumberSelector>
    </S.Quantity>
  );
};

export default AppFormPagesQuantity;
