import { Navigate, Route, Routes } from "react-router-dom";

import Login from "../../pages/Login";
import IconsPage from "../../pages/Icons";
import Register from "../../pages/Register";
import ForgotPassword from "../../pages/ForgotPassword";
import NewPassword from "../../pages/ForgotPassword/components/NewPassword";

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/icons" element={<IconsPage />} />

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default PublicRoutes;
