import { useRef } from "react";

import { Theme } from "../../../hooks";
import { TAvailableSocialMedia } from "../../../types";
import { normalizeUsername } from "../../../utils";
import SuccessIcon from "../../icons/CheckIcon";
import ErrorIcon from "../../icons/CloseIcon";
import LoadingIcon from "../../icons/LoadingIcon";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  value: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  socialMedia: TAvailableSocialMedia | "";
  status?: "error" | "loading" | "success" | "";
}

const SocialMediaImageInput: React.FC<IProps> = ({
  value,
  status,
  onBlur,
  onChange,
  socialMedia,
}) => {
  const input = useRef<HTMLInputElement | null>(null);

  const { primaryColor, textColor } = Theme.useTheme();

  const iconToRender = {
    loading: <LoadingIcon />,
    success: <SuccessIcon />,
    error: <ErrorIcon />,
  };

  const onFocusHandler = () => {
    if (window.navigator.userAgent.includes("Mobi")) {
      document.body.style.paddingBottom = "400px";

      setTimeout(() => {
        if (input.current)
          window.scrollTo({
            left: 0,
            top: input.current?.offsetTop - 100,
            behavior: "smooth",
          });
      }, 10);
    }
  };

  const onBlurHandler = () => {
    onBlur();

    if (window.navigator.userAgent.includes("Mobi"))
      document.body.style.paddingBottom = "0";
  };

  const keyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && input.current && input.current.blur)
      input.current.blur();
  };

  const baseUrl = (() => {
    if (socialMedia === "instagram") {
      return "instagram.com/";
    }
    if (socialMedia === "tiktok") {
      return "tiktok.com/@";
    }
    return "linkedin.com/in/";
  })();

  return (
    <>
      <S.SocialMediaInput
        onClick={() => (input.current as HTMLInputElement)?.focus()}
      >
        <Small color={primaryColor}>{baseUrl}</Small>

        <S.Input
          ref={input}
          value={value}
          autoComplete="off"
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          onKeyDown={keyPressHandler}
          placeholder="Nome_Do_Seu_Usuário"
          textColor={textColor}
          onChange={({ target }) =>
            onChange(normalizeUsername(target.value.trim()))
          }
        />

        {!!status && <S.Icons>{status && iconToRender[status]}</S.Icons>}
      </S.SocialMediaInput>
    </>
  );
};

export default SocialMediaImageInput;
