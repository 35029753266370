import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LoveTagIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8495 18.681L20.2995 1.131C19.5975 0.429 18.6225 0 17.55 0H3.9C1.755 0 0 1.755 0 3.9V17.55C0 18.6225 0.429 19.5975 1.1505 20.319L18.7005 37.869C19.4025 38.571 20.3775 39 21.45 39C22.5225 39 23.4975 38.571 24.1995 37.8495L37.8495 24.1995C38.571 23.4975 39 22.5225 39 21.45C39 20.3775 38.5515 19.383 37.8495 18.681ZM6.825 9.75C5.2065 9.75 3.9 8.4435 3.9 6.825C3.9 5.2065 5.2065 3.9 6.825 3.9C8.4435 3.9 9.75 5.2065 9.75 6.825C9.75 8.4435 8.4435 9.75 6.825 9.75ZM22.133 33.5205L29.777 25.8765C30.6545 24.999 31.2005 23.7705 31.2005 22.425C31.2005 19.734 29.0165 17.55 26.3255 17.55C24.98 17.55 23.7515 18.096 22.874 18.9735L21.4505 20.397L19.988 18.993C18.8375 17.8035 17.063 17.238 15.23 17.7255C13.5335 18.174 12.1685 19.5975 11.798 21.3135C11.408 23.088 11.993 24.7455 13.1045 25.8765L20.7485 33.5205C21.1385 33.9105 21.743 33.9105 22.133 33.5205Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default LoveTagIcon;
