import { ChangeEvent, useRef } from "react";

import { Snackbar, Theme } from "../../../hooks";
import CloseIcon from "../../icons/CloseIcon";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  accept?: string;
  errors?: string[];
  fileTitle?: string;
  description?: string;
  enableUpload: boolean;
  isUploading?: boolean;
  maxFileSizeMB?: number;
  onRemoveFile?: () => void;
  onUploadFile: (file: File) => void;
}

const AppFormFile: React.FC<IProps> = ({
  errors,
  accept,
  fileTitle,
  isUploading,
  description,
  enableUpload,
  onUploadFile,
  onRemoveFile,
  maxFileSizeMB,
}) => {
  const { newError } = Snackbar.useSnackbar();
  const { textColor } = Theme.useTheme();

  const input = useRef<HTMLInputElement | null>(null);

  const MAX_FILE_SIZE = (maxFileSizeMB || 35) * 1024 * 1024;

  const onUploadHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file.size > MAX_FILE_SIZE) {
      if (input.current) input.current.value = "";
      return newError(`Arquivo muito grande: máx ${maxFileSizeMB || 35}Mb`);
    }

    onUploadFile(file);
  };

  if (isUploading) return <S.UploadingSkeleton />;

  if (fileTitle)
    return (
      <S.UploadedFile>
        <S.FileTitle>
          <P color={textColor}>{fileTitle}</P>
        </S.FileTitle>

        <S.RemoveFile onClick={onRemoveFile}>
          <CloseIcon />
        </S.RemoveFile>
      </S.UploadedFile>
    );

  return (
    <S.File>
      {description && description !== undefined && (
        <S.Description color={textColor}>{description}</S.Description>
      )}

      <S.AddFileButton
        isUploadDisabled={!enableUpload}
        onClick={() => enableUpload && input.current?.click()}
      >
        <div className="addIcon">
          <P color="#71726F">+</P>
        </div>

        <P color="#71726F">Selecione o arquivo</P>

        <input
          type="file"
          ref={input}
          accept={accept || ".pdf"}
          onChange={onUploadHandler}
        />
      </S.AddFileButton>

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </S.File>
  );
};

export default AppFormFile;
