import { BrowserRouter } from "react-router-dom";

import { Auth } from "../hooks";
import PublicRoutes from "./public";
import PrivateRoutes from "./private";

const Router: React.FC = () => {
  const { isLogged } = Auth.useAuth();

  return (
    <BrowserRouter>
      {isLogged ? <PrivateRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  );
};

export default Router;
