import styled, { css } from "styled-components";

type AddButtonProps = {
  textColor: string;
  borderColor: string;
};
export const AddButton = styled.button<AddButtonProps>`
  ${({ textColor, borderColor }) => css`
    gap: 12px;
    width: 100%;
    display: flex;
    padding: 16px;
    cursor: pointer;
    user-select: none;
    border: 1px dashed ${borderColor};
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    color: ${textColor};
  `}
`;

type AddIconProps = {
  borderColor: string;
};

export const AddIcon = styled.div<AddIconProps>`
  width: 32px;
  height: 32px;
  display: flex;
  flex-shrink: 0;
  border: 1px solid ${({ borderColor }) => borderColor};
  align-items: center;
  border-radius: 16px;
  justify-content: center;
`;
