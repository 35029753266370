import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PersonPinIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="31"
      height="36"
      viewBox="0 0 31 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5556 0H3.44444C1.53278 0 0 1.55 0 3.44444V27.5556C0 29.45 1.55 31 3.44444 31H10.3333L14.2772 34.9439C14.9489 35.6156 16.0339 35.6156 16.7056 34.9439L20.6667 31H27.5556C29.45 31 31 29.45 31 27.5556V3.44444C31 1.55 29.45 0 27.5556 0ZM15.4997 5.68331C18.0658 5.68331 20.1497 7.7672 20.1497 10.3333C20.1497 12.8994 18.0658 14.9833 15.4997 14.9833C12.9336 14.9833 10.8497 12.8994 10.8497 10.3333C10.8497 7.7672 12.9336 5.68331 15.4997 5.68331ZM5.16667 22.5611V24.1111H25.8333V22.5611C25.8333 19.1167 18.9444 17.2222 15.5 17.2222C12.0556 17.2222 5.16667 19.1167 5.16667 22.5611Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PersonPinIcon;
