import { ChangeEvent, useEffect, useRef, useState } from "react";

import {
  LOCAL_STORAGE_INFO_STEP_DURATION,
  LOCAL_STORAGE_AUTHENTICATION_METHOD,
} from "../../../../constants";
import Page from "../../../../components/atoms/Page";
import { Analytics, Creator } from "../../../../services";
import P from "../../../../components/atoms/Typography/P";
import StantiIcon from "../../../../components/icons/StantiIcon";
import Small from "../../../../components/atoms/Typography/Small";
import { App, Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import NavigationArrow from "../../../../components/atoms/NavigationArrow";
import StepsProgressBar from "../../../../components/atoms/StepsProgressBar";
import ImageReposition from "../../../../components/molecules/ImageReposition";
import SocialMediaIconBox from "../../../../components/atoms/SocialMediaIconBox";
import EditableProfileImage from "../../../../components/atoms/EditableProfileImage";
import SocialMediaImageInput from "../../../../components/atoms/SocialMediaImageInput";
import SocialMediaErrorModal from "../../../../components/molecules/SocialMediaErrorModal";
import BackgroundProfileImageIcon from "../../../../components/icons/BackgroundProfileImageIcon";
import socialMediaAppConfig from "../../../../config/app-redes-sociais.json";

import * as ProfileImageStepStyles from "./styles";
import * as RegisterStyles from "../styles";

const S = {
  ...ProfileImageStepStyles,
  ...RegisterStyles,
};

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const authenticationMethod =
  localStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_METHOD) || "";

const ProfileImageStep: React.FC = () => {
  const [username, setUsername] = useState("");
  const [initialImage, setInitialImage] = useState("");
  const [notAvailableSocialMediaModal, setNotAvailableSocialMediaModal] =
    useState<"tiktok" | "linkedin" | null>(null);

  const input = useRef<HTMLInputElement | null>(null);

  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const { saveFreeItemHandler } = App.useApp();
  const { user, token, setUserHandler } = Auth.useAuth();
  const { showLoading, hideLoading, isLoading } = Loading.useLoading();

  useEffect(() => {
    const stepDurationInSeconds =
      localStorage.getItem(LOCAL_STORAGE_INFO_STEP_DURATION) || "";

    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: username,
      description: "information-step-concluded",
      meta: { stepDuration: stepDurationInSeconds },
      actionDescription: `information-step-concluded-${username}`,
    });

    localStorage.removeItem(LOCAL_STORAGE_INFO_STEP_DURATION);

    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "profile-image-step",
      creatorUsername: user.username || "",
      actionDescription: "import-profile-image-step",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  setTimeout(() => {
    const manuallyImport = document.getElementById("addImageManually");
    if (manuallyImport) {
      manuallyImport.style.display = "flex";
    }
  }, 6000);

  const onUploadProfileImage = async (image: string) => {
    showLoading();

    try {
      setInitialImage("");

      const imageUrl = await Creator.uploadProfileImage(image);
      const user = await Creator.updateProfile(
        { profileImage: imageUrl },
        token
      );

      setUserHandler(user);
    } catch (error) {
      newError("Houve um erro ao enviar a sua foto de perfil");
    } finally {
      hideLoading();
    }
  };

  const onManuallyChangeProfileImage = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file.size > MAX_FILE_SIZE) {
      if (input.current) input.current.value = "";
      return newError("Arquivo muito grande: máx 5Mb");
    }

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) {
          setInitialImage(base64);
        }

        if (input.current) input.current.value = "";
      };
    }
  };

  const getInstagramProfileImage = async (alias: string) => {
    if (!alias) return;

    try {
      showLoading();
      const imageUrl = await Creator.getInstagramProfileImage(alias, token);

      const user = await Creator.updateProfile(
        { profileImage: imageUrl, formStep: "profile-image" },
        token
      );

      setUserHandler(user);
    } catch (error) {
      newError("Houve um erro ao importar a imagem");
    } finally {
      hideLoading();
    }
  };

  const onInstagramImport = () => {
    setNotAvailableSocialMediaModal(null);
  };

  const onManuallyImport = () => {
    setNotAvailableSocialMediaModal(null);
    input.current?.click();
  };

  const onChangeHandler = (val: string) => {
    const normalizedValue = val.replace(/[^a-zA-Z0-9_.]/g, "");

    setUsername(normalizedValue);
  };

  const onNextHandler = async () => {
    try {
      showLoading();
      const newFormStep = hasProfileImage ? "colors" : "profile-image";

      if (newFormStep === user.formStep) return;

      const importFromEmailOrManually =
        authenticationMethod === "authenticate-with-google"
          ? "google-email"
          : "manually";

      Analytics.submitClickEvent({
        creator: user.id || "",
        origin: document.referrer,
        creatorUsername: user.username || "",
        description: "profile-image-step-concluded",
        actionDescription: `profile-image-step-concluded-${user.username}`,
        meta: {
          importMode: username ? "instagram" : importFromEmailOrManually,
        },
      });

      localStorage.removeItem(LOCAL_STORAGE_AUTHENTICATION_METHOD);

      const updatedUser = await Creator.updateProfile(
        {
          formStep: newFormStep,
        },
        token
      );

      if (username)
        await saveFreeItemHandler(
          socialMediaAppConfig.id,
          {
            socialMedia: "instagram",
            username,
          },
          false
        );

      setUserHandler(updatedUser);
    } catch (error) {
      newError("Houve um erro ao avançar");
    } finally {
      hideLoading();
    }
  };

  const hasProfileImage = !!user.profileImage;

  const isReady = hasProfileImage && !isLoading;

  return (
    <Page>
      <S.Container>
        <S.ProfileImageContainer>
          <S.RegisterFormHeader>
            <div className="stantiLogo">
              <StantiIcon color="#fafafa" />
            </div>
          </S.RegisterFormHeader>

          <S.ProgressBar>
            <S.Text>
              {hasProfileImage ? (
                <P color={textColor}>
                  Quase lá, <span>{user.name?.split(" ")[0]}!</span>
                </P>
              ) : (
                <>
                  <P color={primaryColor}>Stanti criada!</P>

                  <P color={textColor}>Vamos personalizá-la!</P>
                </>
              )}
            </S.Text>

            <StepsProgressBar
              quantityOfBars={6}
              progress={!hasProfileImage ? (2.5 / 6) * 100 : (3 / 6) * 100}
            />
          </S.ProgressBar>

          <S.Form>
            <S.Image hasProfileImage={hasProfileImage}>
              {!hasProfileImage && (
                <div className="background">
                  <BackgroundProfileImageIcon />
                </div>
              )}

              <EditableProfileImage
                showPlaceholder
                isLoading={false}
                customColor={primaryColor}
                hideEditIcon={!user.profileImage}
                customBackgroundColor="#fafafa10"
                onChangeImage={onUploadProfileImage}
                currentImage={user.profileImage || ""}
                widthProfile={hasProfileImage ? "172px" : "134px"}
                heightProfile={hasProfileImage ? "172px" : "134px"}
              />
            </S.Image>

            <S.Cta>
              <Small color={primaryColor}>Sua Stanti com a sua cara!</Small>

              <P color={textColor}>
                Importe sua foto da sua rede social favorita!
              </P>
            </S.Cta>

            <S.SocialMedia>
              <SocialMediaIconBox isSelected={true} socialMedia="instagram" />

              <SocialMediaIconBox
                isSelected={false}
                socialMedia="linkedin"
                onClick={() => {
                  setNotAvailableSocialMediaModal("linkedin");
                }}
              />

              <SocialMediaIconBox
                isSelected={false}
                socialMedia="tiktok"
                onClick={() => {
                  setNotAvailableSocialMediaModal("tiktok");
                }}
              />
            </S.SocialMedia>

            <SocialMediaImageInput
              value={username}
              socialMedia={"instagram"}
              onChange={(val) => onChangeHandler(val)}
              onBlur={() => getInstagramProfileImage(username)}
            />

            <S.AddImageManually
              id="addImageManually"
              onClick={() => input.current?.click()}
            >
              <Small color="#fafafa50">Subir uma foto manualmente</Small>

              <input
                ref={input}
                type="file"
                onChange={onManuallyChangeProfileImage}
                accept="image/png, image/jpeg, image/jpg"
              />
            </S.AddImageManually>
          </S.Form>

          <S.Footer>
            <NavigationArrow
              buttonStyle="solid"
              angle={hasProfileImage ? 0 : -90}
              onClick={() => isReady && onNextHandler()}
              color={isReady ? primaryColor : "#ffffff33"}
            />
          </S.Footer>
        </S.ProfileImageContainer>

        {notAvailableSocialMediaModal && (
          <SocialMediaErrorModal
            socialMedia={notAvailableSocialMediaModal}
            onManuallyImport={() => onManuallyImport()}
            onInstagramImport={() => onInstagramImport()}
            onClose={() => setNotAvailableSocialMediaModal(null)}
          />
        )}

        {initialImage && (
          <ImageReposition
            initialImage={initialImage}
            onChangeImage={onUploadProfileImage}
            onDiscardImage={() => setInitialImage("")}
          />
        )}
      </S.Container>
    </Page>
  );
};

export default ProfileImageStep;
