import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PlusIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8 3.4C19.8 1.53 18.3152 0 16.5001 0C14.6851 0 13.2001 1.53 13.2001 3.4V13.6H3.3C1.485 13.6 0 15.13 0 17C0 18.87 1.48499 20.4 3.3 20.4H13.2001V30.6C13.2001 32.47 14.6851 34 16.5001 34C18.3152 34 19.8 32.47 19.8 30.6V20.4H29.7C31.515 20.4 33 18.87 33 17C33 15.13 31.515 13.6 29.7 13.6H19.8V3.4Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PlusIcon;
