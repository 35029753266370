import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TimerIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="41"
      viewBox="0 0 39 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.56174 3.59885L3.32456 8.79988C2.47127 9.51096 1.19133 9.40938 0.480248 8.53577C-0.251147 7.68247 -0.129248 6.40253 0.724046 5.67114L6.98154 0.470105C7.83483 -0.240974 9.11477 -0.139391 9.82585 0.73422C10.5572 1.58751 10.4353 2.86746 9.56174 3.59885ZM38.0244 5.67114L31.7669 0.470105C30.9136 -0.240974 29.6336 -0.139391 28.9022 0.73422C28.1911 1.58751 28.313 2.86746 29.1663 3.59885L35.4035 8.79988C36.2568 9.51096 37.5368 9.40938 38.2682 8.53577C38.9995 7.68247 38.8776 6.40253 38.0244 5.67114ZM27.2372 26.4752L20.3905 22.4119V13.2085C20.3905 12.3959 19.7404 11.7457 18.9277 11.7457H18.8058C17.9932 11.7457 17.343 12.3959 17.343 13.2085V22.7979C17.343 23.509 17.7087 24.1795 18.3385 24.5451L25.7541 28.9945C26.4448 29.4008 27.3388 29.1976 27.7451 28.5069C28.1717 27.7958 27.9483 26.8815 27.2372 26.4752ZM19.374 3.61914C9.27668 3.61914 1.08911 11.8067 1.08911 21.904C1.08911 32.0013 9.27668 40.1889 19.374 40.1889C29.4713 40.1889 37.6589 32.0013 37.6589 21.904C37.6589 11.8067 29.4713 3.61914 19.374 3.61914ZM5.1523 21.904C5.1523 29.7462 11.5317 36.1256 19.3739 36.1256C27.2161 36.1256 33.5954 29.7462 33.5954 21.904C33.5954 14.0619 27.2161 7.68247 19.3739 7.68247C11.5317 7.68247 5.1523 14.0619 5.1523 21.904Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TimerIcon;
