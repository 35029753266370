import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SealIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
    >
      <path
        d="M3.10613 9.29607C2.76556 8.00805 3.67034 6.72473 4.99791 6.61279C6.01758 6.52682 6.83422 5.73193 6.94767 4.71495C7.09539 3.39088 8.40265 2.52106 9.68101 2.89626C10.6629 3.18444 11.7115 2.73823 12.1847 1.8309C12.8007 0.649605 14.3339 0.310761 15.3904 1.12242C16.2019 1.74583 17.3409 1.70856 18.1099 1.03344C19.1111 0.154453 20.6632 0.392347 21.3551 1.53085C21.8866 2.4053 22.9621 2.78201 23.9231 2.43026C25.1742 1.9723 26.5355 2.7548 26.7695 4.06638C26.9491 5.07378 27.816 5.81358 28.8391 5.83271C30.1712 5.85763 31.1579 7.07906 30.9022 8.38658C30.7059 9.39087 31.247 10.3939 32.1941 10.7813C33.4272 11.2857 33.906 12.7811 33.1953 13.908C32.6494 14.7735 32.7916 15.9042 33.5348 16.6076C34.5024 17.5235 34.4088 19.0909 33.339 19.8849C32.5173 20.4948 32.2414 21.6005 32.6803 22.5249C33.2518 23.7284 32.5982 25.1561 31.3138 25.5101C30.3273 25.782 29.6707 26.7134 29.746 27.7339C29.8441 29.0626 28.7189 30.1578 27.3934 30.0239C26.3753 29.921 25.4265 30.5523 25.1281 31.5311C24.7396 32.8055 23.2948 33.4203 22.1071 32.8166C21.1949 32.3529 20.0821 32.5989 19.4503 33.4038C18.6277 34.4518 17.0583 34.5031 16.1689 33.5112C15.4858 32.7492 14.3594 32.5766 13.4794 33.0989C12.3338 33.779 10.8519 33.2599 10.3809 32.0137C10.0191 31.0564 9.03112 30.4885 8.02191 30.6577C6.70796 30.878 5.5136 29.8587 5.52463 28.5264C5.53311 27.5032 4.81696 26.6167 3.81478 26.4099C2.50999 26.1406 1.7645 24.7587 2.25605 23.5204C2.6336 22.5693 2.28605 21.484 1.42625 20.9291C0.306835 20.2067 0.110903 18.6487 1.01658 17.6716C1.71221 16.9211 1.78019 15.7836 1.1789 14.9556C0.396042 13.8775 0.776128 12.354 1.97362 11.7701C2.89338 11.3216 3.36772 10.2854 3.10613 9.29607Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SealIcon;
