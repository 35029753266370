import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FormPDF from "./components/FormPDF";
import { Apps } from "../../../../services";
import FormEbook from "./components/FormEbook";
import FormOutro from "./components/FormOutro";
import { IAppItem, IFAQ } from "../../../../types";
import FormAcessoLink from "./components/FormAcessoLink";
import appConfig from "../../../../config/app-arquivo.json";
import { App, Auth, GA, Snackbar } from "../../../../hooks";
import FormCursoGravado from "./components/FormCursoGravado";

const { REACT_APP_FILES_APP_BUCKET } = process.env;

export interface IForm {
  faq?: IFAQ[];
  price?: number;
  title?: string;
  pages?: string;
  image?: string;
  tags?: string[];
  media?: string[];
  duration?: string;
  description?: string;
  aboutAuthor?: string;
  targetAudience?: string;
  url?: string;
}

const validate = (
  data: IForm,
  type: string,
  url?: string
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!url)
    errors.url = ["pdf", "ebook", "other"].includes(type)
      ? [
          ...(errors.url || []),
          "O upload de um arquivo ou link para acesso é obrigatório",
        ]
      : [...(errors.url || []), "O link para acesso é obrigatório"];

  if (!data.title)
    errors.title = [...(errors.title || []), "Título obrigatório"];

  if (!data.description)
    errors.description = [
      ...(errors.description || []),
      "Descrição obrigatória",
    ];

  if (!data.price || data.price < 1)
    errors.price = [...(errors.price || []), "O valor mínimo é R$ 1"];

  return errors;
};

const Form: React.FC = () => {
  const [item, setItem] = useState<IForm>({});
  const [providedURL, setProvidedURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadedFileURL, setUploadedFileURL] = useState("");
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const navigate = useNavigate();
  const { token } = Auth.useAuth();
  const { id, type } = useParams();
  const { sendEvents } = GA.useGA();
  const { newError } = Snackbar.useSnackbar();
  const { getItem, saveItemHandler, removeItemHandler } = App.useApp();

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      const data = await getItem(appConfig.id, id);
      setItem({
        aboutAuthor: data?.aboutAuthor || "",
        description: data?.description || "",
        duration: data?.duration || "",
        faq: data?.faq || [],
        image: data?.image || "",
        media: data?.media || [],
        pages: data?.pages,
        price: data?.price,
        tags: data?.tags || [],
        targetAudience: data?.targetAudience || "",
        title: data?.title || "",
      });

      if (
        REACT_APP_FILES_APP_BUCKET &&
        data?.url?.includes(REACT_APP_FILES_APP_BUCKET)
      )
        setUploadedFileURL(data.url);
      else setProvidedURL(data?.url || "");
    };

    if (!id) {
      sendEvents({
        category: type,
        name: "new-sku",
        buttonLocation: "home-stanti",
      });
    } else {
      sendEvents({
        category: type,
        name: "edit-sku",
        buttonLocation: "home-stanti",
      });
    }

    run();
  }, [getItem, id, sendEvents, type]);

  const onChangeHandler = (value: any, field: keyof IForm) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setItem((curr) => ({ ...curr, [field]: value }));
  };

  const onSave = () => {
    const currErrors = validate(
      item,
      type || "",
      uploadedFileURL || providedURL
    );

    if (currErrors && Object.keys(currErrors).length) {
      setErrors(currErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return alert(
        "O cadastro possui erros, por favor verifique os campos para continuar"
      );
    }

    saveItemHandler(
      appConfig.id,
      {
        ...item,
        type: type as IAppItem["type"],
        url: uploadedFileURL || providedURL,
        fileFormat:
          (uploadedFileURL || "").split(".").reverse()[0] || undefined,
      },
      false,
      id
    );
  };

  const addMediaHandler = (url: string) => {
    setItem((curr) => ({ ...curr, media: [...(curr.media || []), url] }));
  };

  const removeMediaHandler = (index: number) => {
    setItem((curr) => {
      const newMedia = curr.media || [];
      newMedia.splice(index, 1);
      return {
        ...curr,
        media: newMedia,
      };
    });
  };

  const onUploadProgressHandler = (event: ProgressEvent) => {
    const { total, loaded } = event;

    const progress = Math.round((loaded / total) * 100);
    setFileUploadProgress(progress);
  };

  const onUploadFileHandler = async (file: File) => {
    try {
      setUploadingFile(true);

      const [fileExtension, ...reversedFileName] = file.name
        .split(".")
        .reverse();
      const fileName = reversedFileName
        .reverse()
        .join("_")
        .replace(/[\s./]/g, "_");

      const url = await Apps.getUploadURL(
        `${fileName}.${fileExtension}`,
        token
      );

      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
          "Content-Disposition": "attachment",
        },
        onUploadProgress: onUploadProgressHandler,
      });

      setFileUploadProgress(0);

      const fileUrl = url.split("?")[0];

      if (
        file.type === "application/pdf" &&
        ["ebook", "pdf"].includes(type || "")
      ) {
        const coverUrl = await Apps.triggerCoverGenerator(fileUrl, token);

        const pages =
          (await file.text())
            .match(/\/Type[\s]*\/Page[^s]/g)
            ?.length?.toString() || "";

        setItem((curr) => ({ ...curr, media: [coverUrl], pages }));
      }

      setProvidedURL("");
      setUploadedFileURL(fileUrl);
    } catch (error) {
      newError("Houve um erro ao fazer o upload do arquivo");
    } finally {
      setUploadingFile(false);
    }
  };

  const onRemoveFileHandler = () => {
    setUploadedFileURL("");

    if (["ebook", "pdf"].includes(type || "")) {
      onChangeHandler([], "media");
    }
  };

  const isEditingItem = !!id;

  if (type === "ebook")
    return (
      <FormEbook
        item={item}
        errors={errors}
        onSave={onSave}
        providedURL={providedURL}
        isEditingItem={isEditingItem}
        setProvidedUrl={setProvidedURL}
        isUploadingFile={uploadingFile}
        uploadedFileURL={uploadedFileURL}
        onChangeHandler={onChangeHandler}
        onUploadFile={onUploadFileHandler}
        onRemoveFile={onRemoveFileHandler}
        fileUploadProgress={fileUploadProgress}
        onDelete={() => id && removeItemHandler(appConfig.id, id, "/")}
      />
    );

  if (type === "pdf")
    return (
      <FormPDF
        item={item}
        errors={errors}
        onSave={onSave}
        providedURL={providedURL}
        isEditingItem={isEditingItem}
        setProvidedUrl={setProvidedURL}
        isUploadingFile={uploadingFile}
        uploadedFileURL={uploadedFileURL}
        onChangeHandler={onChangeHandler}
        onUploadFile={onUploadFileHandler}
        onRemoveFile={onRemoveFileHandler}
        onDelete={() => id && removeItemHandler(appConfig.id, id, "/")}
      />
    );

  if (type === "course")
    return (
      <FormCursoGravado
        item={item}
        errors={errors}
        onSave={onSave}
        providedURL={providedURL}
        onAddMedia={addMediaHandler}
        isEditingItem={isEditingItem}
        setProvidedUrl={setProvidedURL}
        onChangeHandler={onChangeHandler}
        onRemoveMedia={removeMediaHandler}
        onDelete={() => id && removeItemHandler(appConfig.id, id, "/")}
      />
    );

  if (type === "link")
    return (
      <FormAcessoLink
        item={item}
        errors={errors}
        onSave={onSave}
        providedURL={providedURL}
        onAddMedia={addMediaHandler}
        isEditingItem={isEditingItem}
        setProvidedUrl={setProvidedURL}
        onChangeHandler={onChangeHandler}
        onRemoveMedia={removeMediaHandler}
        onDelete={() => id && removeItemHandler(appConfig.id, id, "/")}
      />
    );

  if (type === "other")
    return (
      <FormOutro
        item={item}
        errors={errors}
        onSave={onSave}
        providedURL={providedURL}
        onAddMedia={addMediaHandler}
        isEditingItem={isEditingItem}
        isUploadingFile={uploadingFile}
        setProvidedUrl={setProvidedURL}
        uploadedFileURL={uploadedFileURL}
        onChangeHandler={onChangeHandler}
        onRemoveMedia={removeMediaHandler}
        onUploadFile={onUploadFileHandler}
        onRemoveFile={onRemoveFileHandler}
        fileUploadProgress={fileUploadProgress}
        onDelete={() => id && removeItemHandler(appConfig.id, id, "/")}
      />
    );

  navigate(`/apps/${appConfig.id}`);

  return null;
};

export default Form;
