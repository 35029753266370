import styled from "styled-components";
import Button from "../../../../components/atoms/Button";

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  h2 {
    font-weight: 200;
    font-size: 24px;
    font-family: "Poppins";
    line-height: 30px;
  }
`;

export const BackButton = styled(Button)`
  font-size: 12px;
  padding: 10px 24px 10px 24px;
`;
