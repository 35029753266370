import { Theme } from "../../../hooks";
import CheckIcon from "../../icons/CheckIcon";

import * as S from "./styles";

interface IProps {
  images: string;
  isSelected: boolean;
  onClick: () => void;
}

const AppImageSelector: React.FC<IProps> = ({
  images,
  onClick,
  isSelected,
}) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <S.AppImageSelector
      onClick={onClick}
      imageUrl={images}
      isSelected={isSelected}
    >
      {isSelected && <CheckIcon color={primaryColor} />}
    </S.AppImageSelector>
  );
};

export default AppImageSelector;
