import styled from "styled-components";
import P from "../../../../../../components/atoms/Typography/P";

export const UploadSection = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;

  p {
    line-height: 140%;
  }
`;

export const OrText = styled(P)`
  text-align: center;
`;
