import styled from "styled-components";

export const AddLinkCover = styled.div`
  display: flex;
  margin-top: 12px;
  flex-direction: column;

  p {
    text-align: left;
  }
`;

export const FileContainer = styled.div`
  gap: 28px;
  display: flex;
  margin-top: 12px;
  min-height: 140px;
  align-items: center;
  justify-content: center;

  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const InputBox = styled.div`
  width: 108px;
  height: 100%;
  min-height: 140px;
  background-color: yellowgreen;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 22px;
  mix-blend-mode: screen;
  justify-content: center;
  background-color: #fafafa18;

  input {
    display: none;
  }
`;

type AddIconProps = {
  borderColor: string;
};

export const AddIcon = styled.div<AddIconProps>`
  width: 36px;
  height: 36px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ borderColor }) => borderColor};
`;

export const RemoveFile = styled.div`
  width: 40px;
  height: 40px;
  padding: 16px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  background-color: #fafafa18;
  mix-blend-mode: screen;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const LoadingSkeleton = styled.div`
  width: 96px;
  height: 100%;
  display: flex;
  min-height: 140px;
  border-radius: 8px;
  align-items: center;
  mix-blend-mode: overlay;
  justify-content: center;
  background-color: #fafafa;
  animation: skeleton 1.2s ease-in-out infinite;

  @keyframes skeleton {
    0% {
      opacity: 0.6;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.6;
    }
  }
`;
