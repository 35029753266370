import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PlayIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="48"
      height="56"
      viewBox="0 0 48 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.6073 30.9215C44.9632 28.1754 44.9632 21.8246 40.6073 19.0785L14.7331 2.76655C10.0718 -0.172102 4 3.17772 4 8.68803V41.312C4 46.8223 10.0718 50.1722 14.7331 47.2335L40.6073 30.9215Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PlayIcon;
