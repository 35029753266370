import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ArrowBottomRightIcon: React.FC<IProps> = ({
  color,
  opacity,
  blendMode,
}) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 6.63317L26 21.4431C26 23.9494 23.9485 26 21.4411 26L6.62478 26C4.73285 26 3.20563 24.4734 3.20563 22.5823C3.20563 20.6912 4.73285 19.1646 6.62478 19.1646L14.0785 19.1646L0.926194 5.60786C-0.327496 4.30914 -0.304701 2.23575 0.971783 0.959817C2.24827 -0.316116 4.32256 -0.316116 5.62183 0.937033L19.1617 14.266L19.1617 6.63316C19.1617 4.74205 20.6889 3.21549 22.5808 3.21549C24.4728 3.21549 26 4.74205 26 6.63317Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ArrowBottomRightIcon;
