import { useNavigate } from "react-router-dom";

import AddButton from "../AddButton";
import { GA, Theme } from "../../../hooks";
import appConfig from "../../../config/app-link-to-post-instagram.json";

interface IProps {
  color?: string;
}

const AppAddPost: React.FC<IProps> = ({ color }) => {
  const navigate = useNavigate();

  const { sendEvents } = GA.useGA();
  const { primaryColor } = Theme.useTheme();

  const onClickHandler = () => {
    sendEvents({ name: "new-sku", category: appConfig.id });
    navigate(`/apps/${appConfig.id}/new`);
  };

  return (
    <AddButton
      label="Adicionar Post"
      onClick={onClickHandler}
      color={color || primaryColor}
    />
  );
};

export default AppAddPost;
