import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LinkedinIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        marginTop: "-4px",
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.55738 12H0.196721V3.64345H2.55738V12ZM1.37705 2.68017C0.616918 2.68017 0 2.08002 0 1.34009C0 0.600152 0.616918 0 1.37705 0C2.13718 0 2.7541 0.600152 2.7541 1.34009C2.7541 2.08002 2.13797 2.68017 1.37705 2.68017ZM12 12H9.63934V7.74272C9.63934 5.1841 6.4918 5.37782 6.4918 7.74272V12H4.13115V3.64345H6.4918V4.9843C7.59029 3.01975 12 2.87465 12 6.86528V12Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default LinkedinIcon;
