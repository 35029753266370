import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import Page from "../../../../components/atoms/Page";
import PaymentOption from "../../../../components/atoms/PaymentOption";
import SeparatorLine from "../../../../components/atoms/SeparatorLine";
import P from "../../../../components/atoms/Typography/P";
import Small from "../../../../components/atoms/Typography/Small";
import AppFormFooter from "../../../../components/molecules/AppFormFooter";
import InputWithLabel from "../../../../components/molecules/InputWithLabel";
import TextAreaWithLabel from "../../../../components/molecules/TextAreaWithLabel";
import appConfig from "../../../../config/app-stanti-pay.json";
import { StantiPay, Theme } from "../../../../hooks";
import { IAppProduct } from "../../../../types";

import * as S from "./styles";

const validate = (data: IAppProduct): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.title)
    errors.title = [...(errors.title || []), "Título obrigatório"];

  if (!data.allowPix && !data.allowCreditCard)
    errors.payment = [
      ...(errors.payment || []),
      "Escolha pelo menos um meio de pagamento",
    ];

  return errors;
};

const emptyProduct: IAppProduct = {
  order: 0,
  title: "",
  allowPix: true,
  description: "",
  allowCreditCard: true,
};

const Form = () => {
  const { id } = useParams();
  const isEditingItem = !!id;

  const navigate = useNavigate();

  const [product, setProduct] = useState<IAppProduct>(emptyProduct);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const { textColor } = Theme.useTheme();

  const { getProduct, saveProductHandler, removeProductHandler } =
    StantiPay.useStantiPay();

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      const productData = await getProduct(id);
      !!productData && setProduct(productData);
    };

    run();
  }, [getProduct, id]);

  const onSave = useCallback(() => {
    const currErrors = validate(product);

    if (currErrors && Object.keys(currErrors).length) {
      setErrors(currErrors);

      window.scrollTo({ top: 0, behavior: "smooth" });
      return alert(
        "O cadastro possui erros, por favor verifique os campos para continuar"
      );
    }

    saveProductHandler(product, id);
  }, [id, saveProductHandler, product]);

  const onChangeHandler = (value: any, field: keyof IAppProduct) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setProduct((curr) => ({ ...curr, [field]: value }));
  };

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        showLiveLabel={appConfig.showIsLiveLabel}
        onBack={() => navigate(`/apps/${appConfig.id}`)}
      />

      <S.Info>
        <P color={textColor}>Cadastro de produto</P>

        <InputWithLabel
          inputLabel=""
          value={product.title || ""}
          placeholder="Nome do produto ou serviço"
          onChange={(val) => onChangeHandler(val, "title")}
        />

        {errors.title && errors.title.length > 0 && (
          <Small color="#FF4D4F">Erros: {errors.title.join(", ")}</Small>
        )}

        <TextAreaWithLabel
          charLimit={300}
          textAreaLabel=""
          value={product.description || ""}
          placeholder="Descrição do produto/serviço (opcional)"
          onChange={(val) => onChangeHandler(val, "description")}
        />
      </S.Info>

      <SeparatorLine />

      <PaymentOption
        item={product}
        paymentLabel="PIX"
        onChange={() => onChangeHandler(!product.allowPix, "allowPix")}
      />

      <SeparatorLine />

      <PaymentOption
        item={product}
        paymentLabel="cartão"
        onChange={() =>
          onChangeHandler(!product.allowCreditCard, "allowCreditCard")
        }
      />

      {errors.payment && errors.payment.length > 0 && (
        <Small color="#FF4D4F">Erros: {errors.payment.join(", ")}</Small>
      )}

      <AppFormFooter
        onSave={onSave}
        saveDraft={false}
        appTitle="produto"
        isEditingItem={isEditingItem}
        onRemove={() => id && removeProductHandler(id)}
      />
    </Page>
  );
};

export default Form;
