import styled, { css } from "styled-components";
import Small from "../../../../components/atoms/Typography/Small";

export const AffiliationInfo = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 20px;
  padding-top: 28px;
  flex-direction: column;
  border-top: 1px solid #fafafa40;
`;

type CopyCouponProps = {
  borderColor: string;
};

export const CopyCoupon = styled.div<CopyCouponProps>`
  width: 100%;
  height: 36px;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ borderColor }) => borderColor};
  padding: 8px 20px;
  position: relative;
  padding-right: 40px;
  border-radius: 16px;
  margin-top: 8px;
  justify-content: space-between;

  p {
    flex-grow: 1;
  }

  .icon {
    top: 50%;
    right: 16px;
    width: 15px;
    height: 15px;
    position: absolute;
    transform: translateY(-50%);

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

type ButtonProps = {
  width: string;
  textColor: string;
  borderColor: string;
};

export const Button = styled.button<ButtonProps>`
  ${({ width, textColor, borderColor }) => css`
    height: 36px;
    font-size: 12px;
    cursor: pointer;
    margin-top: 20px;
    font-weight: 400;
    padding: 8px 20px;
    align-self: center;
    border-radius: 16px;
    border: 1px solid ${borderColor};
    width: ${width};
    color: ${textColor};

    &:disabled {
      cursor: not-allowed;
      color: #fafafa !important;
      border-color: #fafafa !important;
    }
  `}
`;

export const PendingDescription = styled(Small)`
  opacity: 0.5;
  margin-top: 12px;
  text-align: center;
`;
