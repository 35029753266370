import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PersonTwoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9999 19C37.9999 29.4879 29.4879 37.9999 19 37.9999C8.51198 37.9999 0 29.4879 0 19C0 8.51198 8.51198 0 19 0C29.4879 0 37.9999 8.51198 37.9999 19ZM13.3005 23.2749C14.6115 23.2749 15.6755 22.2109 15.6755 20.8999C15.6755 19.5889 14.6115 18.5249 13.3005 18.5249C11.9895 18.5249 10.9255 19.5889 10.9255 20.8999C10.9255 22.2109 11.9895 23.2749 13.3005 23.2749ZM22.3254 20.8999C22.3254 19.5889 23.3894 18.5249 24.7004 18.5249C26.0114 18.5249 27.0754 19.5889 27.0754 20.8999C27.0754 22.2109 26.0114 23.2749 24.7004 23.2749C23.3894 23.2749 22.3254 22.2109 22.3254 20.8999ZM29.4499 11.4C23.9399 11.4 19.1139 8.43597 16.4539 4.02798C17.2709 3.89498 18.1259 3.79998 18.9999 3.79998C24.5099 3.79998 29.3359 6.76397 31.9959 11.172C31.1789 11.305 30.3239 11.4 29.4499 11.4ZM4.59794 14.193C7.84694 12.35 10.3549 9.34804 11.5519 5.75704C8.30293 7.60004 5.79494 10.602 4.59794 14.193ZM33.5735 14.7439C33.9725 16.0929 34.2005 17.5179 34.2005 18.9999C34.2005 27.3789 27.3795 34.1999 19.0005 34.1999C10.6215 34.1999 3.80054 27.3789 3.80054 18.9999C3.80054 18.9675 3.80275 18.9351 3.80491 18.9035C3.80908 18.8423 3.81306 18.784 3.80054 18.7339C8.74053 16.8719 12.7115 13.0529 14.7065 8.18895C18.2025 12.4639 23.5035 15.1999 29.4505 15.1999C30.8755 15.1999 32.2435 15.0289 33.5735 14.7439Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PersonTwoIcon;
