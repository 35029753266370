import styled from "styled-components";

export const AppFormSection = styled.section`
  gap: 12px;
  display: flex;
  margin-top: 24px;
  padding-bottom: 24px;
  flex-direction: column;
  border-bottom: 1px solid #fafafa30;
`;
