import styled from "styled-components";
import H2 from "../../../../components/atoms/Typography/H2";
import P from "../../../../components/atoms/Typography/P";

export const Description = styled(P)`
  margin-bottom: 24px;
`;

export const Body = styled.div`
  gap: 24px;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
`;

export const NoPosts = styled(H2)`
  text-align: center;
`;
