import { useRef } from "react";

import { Theme } from "../../../hooks";
import { normalizeUsername } from "../../../utils";
import SuccessIcon from "../../icons/CheckIcon";
import ErrorIcon from "../../icons/CloseIcon";
import LoadingIcon from "../../icons/LoadingIcon";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  value: string;
  baseUrl: string;
  onBlur?: () => void;
  errorMessage?: string;
  compactErrorMessage?: boolean;
  onChange: (value: string) => void;
  status?: "error" | "loading" | "success" | "";
}

const UsernameInput: React.FC<IProps> = ({
  value,
  status,
  onBlur,
  baseUrl,
  onChange,
  compactErrorMessage,
}) => {
  const input = useRef<HTMLInputElement | null>(null);

  const { primaryColor, textColor } = Theme.useTheme();

  const iconToRender = {
    loading: <LoadingIcon />,
    success: <SuccessIcon />,
    error: <ErrorIcon />,
  };

  const onFocusHandler = () => {
    if (window.navigator.userAgent.includes("Mobi")) {
      document.body.style.paddingBottom = "400px";

      setTimeout(() => {
        if (input.current)
          window.scrollTo({
            left: 0,
            top: input.current?.offsetTop - 100,
            behavior: "smooth",
          });
      }, 10);
    }
  };

  const onBlurHandler = () => {
    if (window.navigator.userAgent.includes("Mobi"))
      document.body.style.paddingBottom = "0";

    onBlur && onBlur();
  };

  return (
    <>
      <S.UsernameInput
        onClick={() => (input.current as HTMLInputElement)?.focus()}
      >
        <P color={primaryColor}>{baseUrl}</P>

        <S.Input
          ref={input}
          value={value}
          autoComplete="off"
          placeholder="Nome_Do_Seu_Usuário"
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          textColor={textColor}
          onChange={({ target }) =>
            onChange(normalizeUsername(target.value.trim()))
          }
        />

        {!!status && <S.Icons>{status && iconToRender[status]}</S.Icons>}
      </S.UsernameInput>

      {status === "error" && (
        <S.ErrorText
          isCompactError={!!compactErrorMessage}
          color={primaryColor}
        >
          Ops! Nome indisponível
        </S.ErrorText>
      )}
    </>
  );
};

export default UsernameInput;
