import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const UserIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.55275 8.79457C8.55275 4.07122 12.3818 0.242188 17.1051 0.242188C21.8285 0.242188 25.6575 4.07122 25.6575 8.79457C25.6575 13.5179 21.8285 17.347 17.1051 17.347C12.3818 17.347 8.55275 13.5179 8.55275 8.79457ZM0 30.1757C0 24.4884 11.3961 21.6234 17.1048 21.6234C22.8135 21.6234 34.2095 24.4884 34.2095 30.1757V32.3138C34.2095 33.4898 33.2474 34.4519 32.0714 34.4519H2.1381C0.962143 34.4519 0 33.4898 0 32.3138V30.1757Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default UserIcon;
