import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const DownloadIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="33"
      height="41"
      viewBox="0 0 33 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.572 14.1429H27.3199C29.4177 14.1429 30.4549 16.6886 28.9699 18.1736L18.1506 28.9929C17.2313 29.9121 15.7463 29.9121 14.827 28.9929L4.00773 18.1736C2.52273 16.6886 3.58345 14.1429 5.68131 14.1429H9.42916V2.35714C9.42916 1.06071 10.4899 0 11.7863 0H21.2149C22.5113 0 23.572 1.06071 23.572 2.35714V14.1429ZM2.35714 40.0715C1.06071 40.0715 0 39.0108 0 37.7143C0 36.4179 1.06071 35.3572 2.35714 35.3572H30.6429C31.9393 35.3572 33 36.4179 33 37.7143C33 39.0108 31.9393 40.0715 30.6429 40.0715H2.35714Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default DownloadIcon;
