import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import P from "../../atoms/Typography/P";

import * as S from "./styles";

interface IProps {
  appTitle: string;
  saveDraft: boolean;
  onRemove: () => void;
  isEditingItem: boolean;
  onSave: (isDraft: boolean) => void;
}

const AppFormFooter: React.FC<IProps> = ({
  onSave,
  appTitle,
  onRemove,
  saveDraft,
  isEditingItem,
}) => {
  const { textColor, primaryColor } = Theme.useTheme();

  return (
    <S.Container>
      <Button onClick={() => onSave(false)}>Publicar {appTitle}</Button>

      {saveDraft && (
        <Button color={textColor} onClick={() => onSave(true)}>
          Salvar como rascunho
        </Button>
      )}

      {isEditingItem && (
        <S.RemoveItem onClick={onRemove}>
          <P color={primaryColor}>Apagar item</P>
        </S.RemoveItem>
      )}
    </S.Container>
  );
};

export default AppFormFooter;
