import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const FastFoodIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0458 8.12851H38.168C39.2455 8.12851 40.0855 9.05986 39.9759 10.1373L37.1636 38.3155C36.981 39.813 35.7209 41 34.1869 41H31.0824L31.0458 26.3904C31.0458 18.6656 25.3846 13.8993 18.6643 12.347L18.4451 10.1373C18.3538 9.05986 19.1939 8.12851 20.2713 8.12851H27.3935V2.64992C27.3935 1.64552 28.2152 0.82373 29.2197 0.82373C30.2241 0.82373 31.0458 1.64552 31.0458 2.64992V8.12851Z"
        fill={color || primaryColor}
      />
      <path
        d="M1.82619 37.3477H25.5667C26.5711 37.3477 27.3929 38.1694 27.3929 39.1739C27.3929 40.1783 26.5711 41 25.5667 41H1.82619C0.821787 41 0 40.1783 0 39.1739C0 38.1694 0.821787 37.3477 1.82619 37.3477Z"
        fill={color || primaryColor}
      />
      <path
        d="M2.00845 26.3905H25.3837C26.516 26.3905 27.4108 25.3678 27.1917 24.2721C26.0046 18.3735 19.8504 15.415 13.6961 15.415C7.54181 15.415 1.4058 18.3735 0.200513 24.2721C-0.0186299 25.3678 0.894467 26.3905 2.00845 26.3905Z"
        fill={color || primaryColor}
      />
      <path
        d="M1.82619 30.0428H25.5667C26.5711 30.0428 27.3929 30.8646 27.3929 31.869C27.3929 32.8734 26.5711 33.6952 25.5667 33.6952H1.82619C0.821787 33.6952 0 32.8734 0 31.869C0 30.8646 0.821787 30.0428 1.82619 30.0428Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default FastFoodIcon;
