import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const MoneyTwoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="28"
      height="28"
      viewBox="0 0 24 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.72 18.4333C7.272 17.0567 5.52 15.6333 5.52 13.4167C5.52 10.8733 7.944 9.1 12 9.1C15.408 9.1 17.112 10.36 17.736 12.3667C18.024 13.3 18.816 14 19.824 14H20.544C22.128 14 23.256 12.4833 22.704 11.0367C21.696 8.28333 19.344 5.99667 15.6 5.11V3.5C15.6 1.56333 13.992 0 12 0C10.008 0 8.4 1.56333 8.4 3.5V5.04C3.744 6.02 0 8.96 0 13.4633C0 18.8533 4.584 21.5367 11.28 23.1C17.28 24.5 18.48 26.5533 18.48 28.7233C18.48 30.3333 17.304 32.9 12 32.9C8.04 32.9 6 31.5233 5.208 29.5633C4.848 28.6533 4.032 28 3.048 28H2.376C0.768 28 -0.36 29.5867 0.24 31.0333C1.608 34.2767 4.8 36.19 8.4 36.9367V38.5C8.4 40.4367 10.008 42 12 42C13.992 42 15.6 40.4367 15.6 38.5V36.9833C20.28 36.12 24 33.4833 24 28.7C24 22.0733 18.168 19.81 12.72 18.4333Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default MoneyTwoIcon;
