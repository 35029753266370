import styled, { css } from "styled-components";

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
`;

export const DurationSelector = styled.div`
  flex-grow: 1;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  color: #fafafa;
  user-select: none;
  position: relative;
  z-index: 0;
  border-radius: 16px;
  box-sizing: border-box;
  flex-direction: column;
  mix-blend-mode: normal;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) -9.56%,
    rgba(255, 255, 255, 0) 116.18%
  );
  background-blend-mode: soft-light;
`;

export const SelectedDuration = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ClockAndText = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;

  .clockIcon {
    width: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

type IconProps = {
  isSelectorOpen: boolean;
};

export const Icon = styled.div<IconProps>`
  width: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isSelectorOpen }) =>
    isSelectorOpen &&
    css`
      transform: rotate(90deg);
    `}

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Selector = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px 0 16px;
`;

export const SelectorItem = styled.div`
  padding: 2px 0 6px 10px;
  border-bottom: 1px solid #fafafa33;

  &:last-of-type {
    border: none;
    padding-bottom: 8px;
  }
`;
