import Button from "../../../components/atoms/Button";
import H2 from "../../../components/atoms/Typography/H2";
import { Theme } from "../../../hooks";

import * as S from "./styles";

interface IProps {
  onBack: () => void;
}

const Header: React.FC<IProps> = ({ onBack }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <S.Header>
      <H2 color={primaryColor}>Vendas</H2>

      <Button type="outline" onClick={onBack}>
        Voltar
      </Button>
    </S.Header>
  );
};

export default Header;
