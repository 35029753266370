import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CameraIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="35"
      viewBox="0 0 38 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 25.2778C22.148 25.2778 24.7 22.6661 24.7 19.4444C24.7 16.2228 22.148 13.6111 19 13.6111C15.852 13.6111 13.3 16.2228 13.3 19.4444C13.3 22.6661 15.852 25.2778 19 25.2778Z"
        fill={color || primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.07 1.35075C11.264 0.556903 11.9609 0 12.7605 0H25.2395C26.0391 0 26.736 0.556903 26.93 1.35075L27.55 3.88889H32.3C35.448 3.88889 38 6.50056 38 9.72222L38 29.1667C38 32.3883 35.448 35 32.3 35H5.7C2.55198 35 0 32.3883 0 29.1667V9.72222C0 6.50056 2.55198 3.88889 5.7 3.88889H10.45L11.07 1.35075ZM19 29.1667C24.2467 29.1667 28.5 24.8139 28.5 19.4444C28.5 14.075 24.2467 9.72222 19 9.72222C13.7533 9.72222 9.5 14.075 9.5 19.4444C9.5 24.8139 13.7533 29.1667 19 29.1667ZM34.2 7.77778H30.4V11.6667H34.2V7.77778Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CameraIcon;
