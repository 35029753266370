import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    z-index: 100;
    font-weight: 300;
    font-family: "Poppins";
  }
`;

type ProfileImageProps = {
  imageUrl: string;
};

export const ProfileImage = styled.div<ProfileImageProps>`
  width: 59px;
  height: 59px;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  background: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
`;

export const Highlight = styled.span`
  font-weight: 600;
`;
