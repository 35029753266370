import { ChangeEvent, useRef, useState } from "react";

import { Theme } from "../../../hooks";
import { TIconOptions } from "../../../types";
import AvailableIcons from "../../atoms/AvailableIcons";
import P from "../../atoms/Typography/P";
import Chevron from "../../icons/Chevron";
import ImageReposition from "../ImageReposition";

import * as S from "./styles";

interface IProps {
  onUploadImage: (base64: string) => void;
  onSelectIcon: (icon: TIconOptions) => void;
}

const EditLinkImage: React.FC<IProps> = ({ onSelectIcon, onUploadImage }) => {
  const [initialImage, setInitialImage] = useState("");

  const { primaryColor, textColor } = Theme.useTheme();
  const input = useRef<HTMLInputElement | null>(null);

  const onChangeImageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) setInitialImage(base64);

        if (input.current) input.current.value = "";
      };
    }
  };

  const onDiscardImage = () => {
    setInitialImage("");
  };

  return (
    <S.ImageStep>
      <P color={textColor}>Ícones e Pictogramas</P>

      <S.IconOptions>
        {icons.map((icon) => (
          <div
            key={icon}
            className="iconOption"
            onClick={() => onSelectIcon(icon)}
          >
            <AvailableIcons option={icon} />
          </div>
        ))}
      </S.IconOptions>

      <S.UploadButton
        borderColor={primaryColor}
        onClick={() => input.current?.click()}
      >
        <P color={textColor}>Usar imagem personalizada</P>

        <S.UploadButtonChevron>
          <Chevron />
        </S.UploadButtonChevron>

        <input
          type="file"
          ref={input}
          onChange={onChangeImageHandler}
          accept="image/png, image/jpeg, image/jpg, image/webp"
        />
      </S.UploadButton>

      {initialImage && (
        <ImageReposition
          initialImage={initialImage}
          onChangeImage={onUploadImage}
          onDiscardImage={onDiscardImage}
        />
      )}
    </S.ImageStep>
  );
};

export default EditLinkImage;

export const icons: TIconOptions[] = [
  "flower",
  "ticket",
  "fingerPrint",
  "link",
  "heart",
  "heartOutline",
  "messenger",
  "mouse",
  "smartphone",
  "notebook",
  "cart",
  "star",
  "starOutline",
  "thumb",
  "fastFood",
  "tag",
  "movie",
  "pizza",
  "phone",
  "phoneMessage",
  "near",
  "label",
  "personPin",
  "arrowLeft",
  "arrowTopLeft",
  "arrowTopRight",
  "arrowRight",
  "arrowTop",
  "arrowBottomLeft",
  "arrowBottomRight",
  "arrowBottom",
  "smileOne",
  "smileTwo",
  "stanti",
  "smileThree",
  "world",
  "learn",
  "work",
  "diamond",
  "dice",
  "music",
  "restaurant",
  "download",
  "moneyOne",
  "chart",
  "moneyTwo",
  "chartBars",
  "calendar",
  "game",
  "headset",
  "timer",
  "pet",
  "searchOne",
  "loveTag",
  "place",
  "microphone",
  "document",
  "puzzle",
  "personOne",
  "personTwo",
  "question",
  "web",
  "notifications",
  "home",
  "clock",
  "store",
  "pin",
  "bus",
  "beach",
  "baby",
  "gym",
  "coffee",
  "infoOne",
  "infoOutline",
  "alert",
  "warning",
  "warningOutline",
  "checkOne",
  "bookOne",
  "volume",
  "camera",
  "cameraSpecial",
  "videoOne",
  "night",
  "song",
  "gift",
  "at",
  "message",
  "emailTwo",
  "bookTwo",
  "call",
  "chat",
  "clockWatch",
  "videoTwo",
  "todo",
  "keyDown",
  "lockOpen",
  "lockClosed",
  "voiceOver",
  "keyUp",
  "explore",
  "forward",
  "idea",
  "files",
  "checkCircle",
  "bubbles",
  "editTwo",
  "attachment",
  "twitch",
  "tiktok",
  "twitter",
  "youtube",
  "behance",
  "telegram",
  "whatsapp",
  "dribbble",
  "linkedin",
  "facebook",
  "pinterest",
  "instagram",
];
