import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const InstagramTwoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6757 9.55802C14.1422 9.55802 9.67886 14.0224 9.67886 19.5571C9.67886 25.0919 14.1422 29.5562 19.6757 29.5562C25.2093 29.5562 29.6726 25.0919 29.6726 19.5571C29.6726 14.0224 25.2093 9.55802 19.6757 9.55802ZM19.6757 26.0579C16.0998 26.0579 13.1765 23.1425 13.1765 19.5571C13.1765 15.9717 16.0911 13.0564 19.6757 13.0564C23.2603 13.0564 26.175 15.9717 26.175 19.5571C26.175 23.1425 23.2516 26.0579 19.6757 26.0579V26.0579ZM32.4133 9.14901C32.4133 10.4457 31.3692 11.4813 30.0815 11.4813C28.7852 11.4813 27.7498 10.437 27.7498 9.14901C27.7498 7.86105 28.7939 6.81675 30.0815 6.81675C31.3692 6.81675 32.4133 7.86105 32.4133 9.14901ZM39.0344 11.5161C38.8864 8.39189 38.173 5.62452 35.8848 3.34448C33.6052 1.06444 30.8385 0.350836 27.715 0.194192C24.4958 0.0114411 14.847 0.0114411 11.6278 0.194192C8.51299 0.342134 5.74623 1.05573 3.458 3.33577C1.16977 5.61581 0.465029 8.38319 0.30842 11.5074C0.125709 14.7273 0.125709 24.3783 0.30842 27.5982C0.456328 30.7224 1.16977 33.4897 3.458 35.7698C5.74623 38.0498 8.50429 38.7634 11.6278 38.9201C14.847 39.1028 24.4958 39.1028 27.715 38.9201C30.8385 38.7721 33.6052 38.0585 35.8848 35.7698C38.1643 33.4897 38.8777 30.7224 39.0344 27.5982C39.2171 24.3783 39.2171 14.736 39.0344 11.5161V11.5161ZM34.8755 31.0531C34.1969 32.7587 32.8831 34.0728 31.1691 34.7603C28.6025 35.7785 22.5121 35.5435 19.6757 35.5435C16.8394 35.5435 10.7403 35.7698 8.18237 34.7603C6.47708 34.0815 5.1633 32.7674 4.47596 31.0531C3.458 28.4858 3.69292 22.3941 3.69292 19.5571C3.69292 16.7201 3.4667 10.6197 4.47596 8.0612C5.1546 6.35552 6.46837 5.04145 8.18237 4.35396C10.749 3.33577 16.8394 3.57074 19.6757 3.57074C22.5121 3.57074 28.6112 3.34448 31.1691 4.35396C32.8744 5.03275 34.1882 6.34682 34.8755 8.0612C35.8935 10.6284 35.6586 16.7201 35.6586 19.5571C35.6586 22.3941 35.8935 28.4945 34.8755 31.0531Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default InstagramTwoIcon;
