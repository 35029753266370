import { TIconOptions } from "../../../types";
import AvailableIcons from "../../atoms/AvailableIcons";

import * as S from "./styles";

interface IProps {
  size: number;
  color: string;
  gradient?: string;
  icon: TIconOptions;
  iconOpacity?: number;
  iconBlendMode?: string;
}

const AppImage: React.FC<IProps> = ({
  size,
  icon,
  color,
  gradient,
  iconBlendMode,
  iconOpacity,
}) => {
  return (
    <S.AppImage size={size} color={color} gradient={gradient}>
      <AvailableIcons
        option={icon}
        color="#FAFAFA"
        opacity={iconOpacity}
        blendMode={iconBlendMode as "normal" | "overlay"}
      />

      <S.Span size={size} />
      <S.Span size={size} />
    </S.AppImage>
  );
};

export default AppImage;
