import React, { createContext, useContext, useEffect, useState } from "react";

import { Auth } from "..";

interface IThemeContext {
  textColor: string;
  primaryColor: string;
  backgroundColor: string;
}

const defaultContext: IThemeContext = {
  textColor: "#FAFAFA",
  primaryColor: "#DDFF66",
  backgroundColor: "#000000",
};

const Context = createContext(defaultContext);
const useTheme = () => useContext(Context);

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState(defaultContext);

  const { user } = Auth.useAuth();

  useEffect(() => {
    const currentTheme = {
      primaryColor: user.primaryColor || "#DDFF66",
      backgroundColor: user.backgroundColor || "#000000",
      textColor: user.backgroundColor === "#FAFAFA" ? "#000000" : "#FAFAFA",
    };

    document.body.style.backgroundColor = currentTheme.backgroundColor;
    setTheme(currentTheme);
  }, [user]);

  const providerValue = {
    ...theme,
    setTheme,
  };

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

export default {
  useTheme,
  Provider,
};
