import styled, { css } from "styled-components";

type NavBarProps = {
  backgroundColor: string;
};

export const NavBar = styled.div<NavBarProps>`
  left: 50%;
  width: 100%;
  bottom: 0px;
  z-index: 200;
  display: flex;
  position: fixed;
  max-width: 960px;
  overflow: visible;
  flex-direction: row;
  border-width: 0.2px;
  padding: 0px 30px 16px;
  transform: translateX(-50%);
  backdrop-filter: blur(3.5px);
  border-top: 1px solid #fafafa33;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(3.5px);
  background-color: ${({ backgroundColor }) => `${backgroundColor}99`};
`;

type ButtonProps = {
  isSelected: boolean;
  textColor: string;
};

export const Button = styled.div<ButtonProps>`
  ${({ isSelected, textColor }) => css`
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    opacity: ${isSelected ? 1 : 0.5};
    color: ${isSelected ? textColor : "#fafafa"};

    svg {
      width: 20px;
      height: auto;
      margin-top: 16px;
    }

    p {
      font-size: 8px;
      margin-top: 6px;
      letter-spacing: 1.2px;
    }
  `}
`;

type SpanProps = {
  backgroundColor: string;
  textColor: string;
};

export const Span = styled.span<SpanProps>`
  ${({ backgroundColor, textColor }) => css`
    position: absolute;
    content: " ";
    top: -2px;
    width: 6px;
    height: 3px;
    box-shadow: 0px 0px 29px 7px;
    border-radius: 40px;
    overflow: hidden;
    background-color: ${backgroundColor};
    color: ${textColor};
  `}
`;
