import styled from "styled-components";
import P from "../Typography/P";

export const UsernameInput = styled.div`
  cursor: text;
  display: flex;
  margin-top: 8px;
  overflow: hidden;
  padding: 8px 16px;
  border-radius: 40px;
  flex-direction: row;
  align-items: baseline;
  mix-blend-mode: screen;
  background-color: #fafafa10;
  justify-content: flex-start;

  p {
    flex-shrink: 0;
  }
`;

type InputProps = {
  textColor: string;
};

export const Input = styled.input<InputProps>`
  flex-grow: 1;
  outline: none;
  font-size: 14px;
  margin-left: 4px;
  margin-right: 4px;
  color: ${({ textColor }) => textColor};

  &::placeholder {
    font-size: 14px;
    color: #71726f;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-self: center;
`;

type ErrorTextProps = {
  isCompactError: boolean;
};

export const ErrorText = styled(P)<ErrorTextProps>`
  text-align: center;
  margin-top: ${({ isCompactError }) => (isCompactError ? "0px" : "20px")};
`;
