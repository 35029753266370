import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PersonPicsIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="53"
      height="34"
      viewBox="0 0 53 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40908 0H31.3181C32.6431 0 33.7272 1.08409 33.7272 2.40908V31.3181C33.7272 32.6431 32.6431 33.7272 31.3181 33.7272H2.40908C1.08409 33.7272 0 32.6431 0 31.3181V2.40908C0 1.08409 1.08409 0 2.40908 0ZM40.9539 33.7272C42.2789 33.7272 43.363 32.6431 43.363 31.3181V2.40908C43.363 1.08409 42.2789 0 40.9539 0C39.6289 0 38.5448 1.08409 38.5448 2.40908V31.3181C38.5448 32.6431 39.6289 33.7272 40.9539 33.7272ZM48.1821 2.40908V31.3181C48.1821 32.6431 49.2662 33.7272 50.5912 33.7272C51.9162 33.7272 53.0002 32.6431 53.0002 31.3181V2.40908C53.0002 1.08409 51.9162 0 50.5912 0C49.2662 0 48.1821 1.08409 48.1821 2.40908ZM16.8628 6.62498C19.85 6.62498 22.2832 9.05816 22.2832 12.0454C22.2832 15.0327 19.85 17.4659 16.8628 17.4659C13.8755 17.4659 11.4423 15.0327 11.4423 12.0454C11.4423 9.05816 13.8755 6.62498 16.8628 6.62498ZM6.02287 28.9085H27.7046V27.1017C27.7046 23.4881 20.4774 21.6813 16.8637 21.6813C13.2501 21.6813 6.02287 23.4881 6.02287 27.1017V28.9085Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PersonPicsIcon;
