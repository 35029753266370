import styled, { css } from "styled-components";

export const BackgroundSelectorList = styled.div`
  gap: 8px;
  display: flex;
`;

export const BackgroundSelectorItem = styled.div`
  gap: 6px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
`;

type ColorPreviewProps = {
  backgroundColor: string;
  borderColor: string;
};

export const ColorPreview = styled.div<ColorPreviewProps>`
  ${({ borderColor, backgroundColor }) => css`
    width: 44px;
    height: 44px;
    border-radius: 22px;
    border: 1px solid ${borderColor};
    background-color: ${backgroundColor};
  `}
`;

type SelectorProps = {
  isSelected: boolean;
  borderColor: string;
};

export const Selector = styled.div<SelectorProps>`
  width: 16px;
  height: 16px;
  overflow: hidden;
  border: 1px solid;
  position: relative;
  border-radius: 20px;

  border-color: ${({ borderColor }) => borderColor};
  ${({ isSelected }) =>
    !isSelected &&
    css`
      background-color: #fafafa18;
      border-color: #212121;
    `}
`;

type IndicatorProps = {
  backgroundColor: string;
};

export const Indicator = styled.div<IndicatorProps>`
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 20px;

  background-color: ${({ backgroundColor }) => backgroundColor};
`;
