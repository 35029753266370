import { useNavigate } from "react-router-dom";

import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import Page from "../../../../components/atoms/Page";
import BehanceIcon from "../../../../components/icons/BehanceIcon";
import DribbbleIcon from "../../../../components/icons/DribbbleIcon";
import EmailTwoIcon from "../../../../components/icons/EmailTwoIcon";
import FacebookIcon from "../../../../components/icons/FacebookIcon";
import InstagramIcon from "../../../../components/icons/InstagramIcon";
import LinkedinIcon from "../../../../components/icons/LinkedinIcon";
import PinterestIcon from "../../../../components/icons/PinterestIcon";
import TelegramIcon from "../../../../components/icons/TelegramIcon";
import TiktokIcon from "../../../../components/icons/TiktokIcon";
import TwitchIcon from "../../../../components/icons/TwitchIcon";
import TwitterIcon from "../../../../components/icons/TwitterIcon";
import WhatsappIcon from "../../../../components/icons/WhatsappIcon";
import YoutubeIcon from "../../../../components/icons/YoutubeIcon";
import AddContent from "../../../../components/molecules/AddContent";
import appConfig from "../../../../config/app-redes-sociais.json";
import { Auth, Theme } from "../../../../hooks";
import { TIconOptions } from "../../../../types";

import * as S from "./styles";

const displaySocialMedia = [
  "instagram",
  "twitter",
  "linkedin",
  "facebook",
  "whatsapp",
];

const socialMedia = [
  {
    route: "redes-sociais/new/instagram",
    label: "Instagram",
    icon: <InstagramIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/twitter",
    label: "Twitter",
    icon: <TwitterIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/tiktok",
    label: "TikTok",
    icon: <TiktokIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/youtube",
    label: "YouTube",
    icon: <YoutubeIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/facebook",
    label: "Facebook",
    icon: <FacebookIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/linkedin",
    label: "LinkedIn",
    icon: <LinkedinIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/whatsapp",
    label: "WhatsApp",
    icon: <WhatsappIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/telegram",
    label: "Telegram",
    icon: <TelegramIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/behance",
    label: "Behance",
    icon: <BehanceIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/pinterest",
    label: "Pinterest",
    icon: <PinterestIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/twitch",
    label: "Twitch",
    icon: <TwitchIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/dribbble",
    label: "Dribbble",
    icon: <DribbbleIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "redes-sociais/new/email",
    label: "E-mail",
    icon: <EmailTwoIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
];

const Home: React.FC = () => {
  const {
    user: { profileImage },
  } = Auth.useAuth();
  const { primaryColor, backgroundColor } = Theme.useTheme();

  const navigate = useNavigate();

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate("/apps")}
        showLiveLabel={appConfig.showIsLiveLabel}
      />

      <S.Description>
        <h2>Reúna suas redes em um só lugar</h2>
        <p>Adicione links para as suas principais contas!</p>
      </S.Description>

      <S.Content>
        <S.ProfileImage
          color={primaryColor}
          imageUrl={profileImage}
          data-testid="profile-image"
        >
          {!profileImage && <S.ImagePlaceholder color={backgroundColor} />}
        </S.ProfileImage>

        <S.CreatorSocialMedia>
          {displaySocialMedia.map((socialMedia) => (
            <AvailableIcons
              key={socialMedia}
              option={socialMedia as TIconOptions}
            />
          ))}
        </S.CreatorSocialMedia>
      </S.Content>

      <AddContent items={socialMedia} />
    </Page>
  );
};

export default Home;
