import styled from "styled-components";

export const Media = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const Images = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  height: 72px;
  display: flex;
  padding: 6px 16px;
  border-radius: 20px;
  align-items: center;
  mix-blend-mode: screen;
  background-color: #fafafa18;
  justify-content: space-between;
`;

export const ImageAndRemoveButton = styled.div`
  gap: 8px;
  height: 100%;
  display: flex;
  align-items: center;
`;

type ImageProps = {
  imageUrl: string;
};

export const Image = styled.div<ImageProps>`
  width: 52px;
  height: 100%;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
`;

export const RemoveImage = styled.div`
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ImageSkeleton = styled.div`
  height: 72px;
  border-radius: 20px;
  mix-blend-mode: screen;
  background-color: #fafafa18;

  @for $i from 1 through 30 {
    &:nth-child(#{$i}n) {
      animation: opacity 0.6s ease #{$i * 0.2}s infinite forwards;
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
`;

export const AddMediaButton = styled.div`
  gap: 12px;
  display: flex;
  padding: 16px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  border: 1px dashed #71726f;

  .addIcon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    border: 1px solid #71726f;
  }

  input {
    display: none;
  }
`;
