import { TProductType } from "../../../types";
import ProductListItem from "../ProductListItem";

import * as S from "./styles";

type TProduct = {
  id: string;
  title: string;
  app: TProductType;
};

interface IProps {
  products: Array<TProduct>;
  onProductClick: (productId: string) => void;
}

const ProductList: React.FC<IProps> = ({ products, onProductClick }) => {
  return (
    <S.ProductList>
      {products.map((product) => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const appData = require(`../../../config/app-${product.app}.json`);

        return (
          <ProductListItem
            key={product.id}
            appIcon={appData.icon}
            appColor={appData.color}
            productLabel={appData.title}
            productTitle={product.title}
            appGradient={appData.gradient}
            iconOpacity={appData.iconOpacity}
            iconBlendMode={appData.iconBlendMode}
            onClick={() => onProductClick(`${product.app}||${product.id}`)}
          />
        );
      })}
    </S.ProductList>
  );
};

export default ProductList;
