import P from "../Typography/P";
import Small from "../Typography/Small";

interface IProps {
  errors?: string[];
}

const AppFormProductLinkInfo: React.FC<IProps> = ({ errors }) => (
  <>
    <P color="#FAFAFA70">
      O link para se conectar a mentoria será enviado ao cliente logo após a
      confirmação do pagamento.
    </P>

    {errors && errors.length && (
      <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
    )}
  </>
);

export default AppFormProductLinkInfo;
