import styled from "styled-components";
import Button from "../../../../../../components/atoms/Button";

export const YoutubeItem = styled.div`
  gap: 16px;
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  border-bottom: 1px solid #fafafa20;

  &:last-of-type {
    border: none;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RemoveButton = styled.button`
  padding: 4px;
  cursor: pointer;
  user-select: none;
  margin-bottom: -4px;
`;

export const SaveButton = styled(Button)`
  width: 100%;
`;
