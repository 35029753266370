import { ICreatorData } from "../../../../types";
import UsernameInput from "../../../../components/atoms/UsernameInput";

interface IProps {
  hasError: boolean;
  isUsernameLoading: boolean;
  username: ICreatorData["username"];
  onChangeUsername: (username: string) => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const EditUsername: React.FC<IProps> = ({
  hasError,
  username,
  onChangeUsername,
  isUsernameLoading,
}) => {
  const status = isUsernameLoading ? "loading" : hasError ? "error" : "";

  return (
    <UsernameInput
      status={status}
      value={username || ""}
      compactErrorMessage
      onChange={onChangeUsername}
      baseUrl={`${REACT_APP_STOREFRONT_BASE_URL}/`}
    />
  );
};

export default EditUsername;
