import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ArrowTopRightIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.63316 0L21.4431 1.29473e-06C23.9494 1.51383e-06 26 2.05149 26 4.55888V19.3752C26 21.2671 24.4734 22.7944 22.5823 22.7944C20.6912 22.7944 19.1646 21.2671 19.1646 19.3752V11.9215L5.60786 25.0738C4.30914 26.3275 2.23575 26.3047 0.959817 25.0282C-0.316116 23.7517 -0.316116 21.6774 0.937032 20.3782L14.266 6.83831L6.63316 6.83831C4.74205 6.83831 3.21549 5.31109 3.21549 3.41915C3.21549 1.52722 4.74205 -1.65327e-07 6.63316 0Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ArrowTopRightIcon;
