import { useState } from "react";

import P from "../../../../components/atoms/Typography/P";
import Chevron from "../../../../components/icons/Chevron";
import { Snackbar, Theme } from "../../../../hooks";
import { ICreatorData, TCategories } from "../../../../types";

import * as S from "./styles";

const options: { [key in TCategories]: { title: string } } = {
  beleza: { title: "Beleza" },
  eSports: { title: "eSports" },
  fitness: { title: "Fitness" },
  fotografia: { title: "Fotografia" },
  lifestyle: { title: "Lifestyle" },
  moda: { title: "Moda" },
  arte: { title: "Arte" },
  culinária: { title: "Culinária" },
  espiritualidade: { title: "Espiritualidade" },
  finanças: { title: "Finanças" },
  música: { title: "Música" },
  tecnologia: { title: "Tecnologia" },
  livros: { title: "Livros" },
  negócios: { title: "Negócios" },
  dança: { title: "Dança" },
  esportes: { title: "Esportes" },
  games: { title: "Games" },
  saúde: { title: "Saúde" },
  viagem: { title: "Viagem" },
  entretenimento: { title: "Entretenimento" },
  empreendedorismo: { title: "Empreendedorismo" },
  tattoo: { title: "Tattoo" },
  nutrição: { title: "Nutrição" },
  pet: { title: "Pet" },
  maquiagem: { title: "Maquiagem" },
  criatividade: { title: "Criatividade" },
};

interface IProps {
  categories: ICreatorData["categories"];
  onChangeCategories: (categories: ICreatorData["categories"]) => void;
}

const EditCategories: React.FC<IProps> = ({
  categories,
  onChangeCategories,
}) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();

  const selectHandler = (option: TCategories) => {
    const newCategories = [...(categories || [])];
    const indexOfOption = newCategories.findIndex((val) => val === option);

    if (indexOfOption !== -1) newCategories.splice(indexOfOption, 1);
    else newCategories.push(option);

    if (newCategories.length <= 3) onChangeCategories(newCategories);
    else newError("Já alcançou o limite de 3 interesses");
  };

  return (
    <S.CategoriesSelector>
      <S.Input onClick={() => setIsSelectorOpen((curr) => !curr)}>
        <P color={categories && categories.length ? primaryColor : "#71726F"}>
          {categories && categories.length
            ? categories.map((i) => options[i].title).join(", ")
            : "Selecione de 1 a 3 interesses"}
        </P>

        <S.ChevronContainer isSelectorOpen={isSelectorOpen}>
          <Chevron />
        </S.ChevronContainer>
      </S.Input>

      {isSelectorOpen && (
        <>
          <S.Backdrop onClick={() => setIsSelectorOpen(false)} />
          <S.Selector>
            {(Object.keys(options) as TCategories[]).map((key) => (
              <S.Option key={key} onClick={() => selectHandler(key)}>
                <S.Indicator
                  borderColor={
                    categories?.includes(key) ? primaryColor : "#DADADA"
                  }
                >
                  <S.IndicatorBackground
                    backgroundColor={
                      categories?.includes(key) ? primaryColor : "none"
                    }
                  />
                </S.Indicator>

                <P color={textColor}>{options[key].title}</P>
              </S.Option>
            ))}
          </S.Selector>
        </>
      )}
    </S.CategoriesSelector>
  );
};

export default EditCategories;
