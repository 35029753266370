import axios, { AxiosError, AxiosResponse } from "axios";

import { logoutUserIfTokenInvalid } from "../../utils";
import { INewLink, IExternalLink, IApp, IAppItem } from "../../types";

const { REACT_APP_APPS_API_URL, REACT_APP_FILES_APP_BUCKET } = process.env;

const uploadImage = async (base64: string): Promise<string> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/image`,
      base64
    );

    return data.imageUrl;
  } catch (error) {
    throw new Error("Houve um erro ao enviar a sua imagem");
  }
};

const getUploadURL = async (
  fileName: string,
  token: string
): Promise<string> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/upload-file/presigned-url`,
      {
        fileName,
        bucket: REACT_APP_FILES_APP_BUCKET,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.url;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao fazer o upload");
  }
};

const triggerCoverGenerator = async (
  url: string,
  token: string
): Promise<string> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/upload-file/generate-preview`,
      {
        pdfUrl: url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.url;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao gerar a thumbnail");
  }
};

const saveLink = async (
  newLink: INewLink,
  token: string
): Promise<IExternalLink> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/external-link`,
      newLink,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.externalLink;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar o link");
  }
};

const updateLink = async (
  updatedData: unknown,
  id: string,
  token: string
): Promise<IExternalLink> => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_APPS_API_URL}/external-link/${id}`,
      updatedData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.externalLink;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar o link");
  }
};

const deleteLink = async (id: string, token: string): Promise<string> => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_APPS_API_URL}/external-link/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.id;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao remover o link");
  }
};

const getAllAppItems = async (token: string): Promise<IAppItem[]> => {
  try {
    const { data } = await axios.get(`${REACT_APP_APPS_API_URL}/app`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.items;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os itens");
  }
};

const getLinkData = async (
  link: string,
  token: string
): Promise<IExternalLink> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_APPS_API_URL}/external-link/${link}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.externalLink;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os dados do link");
  }
};

const importLinktree = async (
  username: string,
  token: string,
  retry = true
): Promise<IExternalLink[]> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/import-links/linktree`,
      { link: `https://linktr.ee/${username}` },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.externalLinks;
  } catch (error) {
    if (retry) return importLinktree(username, token, false);
    else {
      logoutUserIfTokenInvalid(error);
      throw new Error("Houve um erro ao obter os dados do link");
    }
  }
};

const getApp = async (id: string, token: string): Promise<IApp> => {
  try {
    const { data } = await axios.get(`${REACT_APP_APPS_API_URL}/apps/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.app;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter o app");
  }
};

const getApps = async (token: string): Promise<IApp[]> => {
  try {
    const { data } = await axios.get(`${REACT_APP_APPS_API_URL}/apps`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.apps;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os apps");
  }
};

const getMyApps = async (token: string): Promise<IApp[]> => {
  try {
    const { data } = await axios.get(`${REACT_APP_APPS_API_URL}/my-apps`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.apps;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os apps");
  }
};

const removeApp = async (appId: string, token: string) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_APPS_API_URL}/apps/${appId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao remover o app");
  }
};

const reorderStorefront = async (
  items: { id: string; app: string }[],
  token: string
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/storefront/reorder`,
      { items },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao reordenar os links");
  }
};

const createAppItem = async (
  appAlias: string,
  token: string,
  item: IAppItem
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/app/${appAlias}`,
      item,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar o novo item");
  }
};

const updateAppItem = async (
  appAlias: string,
  itemId: string,
  token: string,
  item: IAppItem
) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_APPS_API_URL}/app/${appAlias}/${itemId}`,
      item,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item;
  } catch (error) {
    logoutUserIfTokenInvalid(error);

    if (error instanceof AxiosError) {
      const { data } = error.response as AxiosResponse<{
        code: string;
        message: string;
      }>;

      if (data && data.code && data.code === "FUNC032")
        throw new Error(
          "Não foi possivel publicar pois existem pendências no cadastro"
        );
    }
    throw new Error("Houve um erro ao salvar as alteraçoes do item");
  }
};

const removeAppItem = async (
  appAlias: string,
  itemId: string,
  token: string
) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_APPS_API_URL}/app/${appAlias}/${itemId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.id;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao remover o item");
  }
};

const getAppItems = async (
  appAlias: string,
  token: string
): Promise<IAppItem[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_APPS_API_URL}/app/${appAlias}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.items;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os itens");
  }
};

const getAppItem = async (
  appAlias: string,
  itemId: string,
  token: string
): Promise<IAppItem> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_APPS_API_URL}/app/${appAlias}/${itemId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter o item");
  }
};

export default {
  getApp,
  getApps,
  saveLink,
  getMyApps,
  removeApp,
  getAppItem,
  deleteLink,
  updateLink,
  uploadImage,
  getLinkData,
  getAppItems,
  getUploadURL,
  removeAppItem,
  createAppItem,
  updateAppItem,
  getAllAppItems,
  importLinktree,
  reorderStorefront,
  triggerCoverGenerator,
};
