import styled, { css } from "styled-components";

type AppImageSelectorProps = {
  imageUrl: string;
  isSelected?: boolean;
};

export const AppImageSelector = styled.div<AppImageSelectorProps>`
  ${({ imageUrl, isSelected }) => css`
    width: 106px;
    height: 106px;
    display: flex;
    cursor: pointer;
    user-select: none;
    border-radius: 16px;
    align-items: flex-end;
    padding: 0 10px 10px;
    background-size: cover;
    background-color: gray;
    background-position: center;
    background-image: url(${imageUrl});
    justify-content: flex-end;
    opacity: ${isSelected ? 0.5 : 1};
  `}
`;
