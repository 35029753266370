import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BookThreeIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="46"
      height="35"
      viewBox="0 0 46 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.174 32.5828V33.2165C24.2269 33.1717 24.2699 33.1363 24.306 33.1065C24.37 33.0537 24.4127 33.0186 24.4518 32.9799C26.6003 30.8532 29.1879 29.5117 32.081 28.7157C34.2185 28.1279 36.3991 27.8701 38.6125 27.8369C39.7535 27.8194 40.4332 27.1623 40.434 26.0804C40.4383 17.9644 40.4387 9.8481 40.4336 1.73215C40.4329 0.661547 39.7076 -0.0269064 38.6454 0.00080674C37.065 0.0420119 35.493 0.164168 33.9443 0.501832C30.5188 1.24826 27.5218 2.72946 25.2635 5.50661C24.5466 6.38833 24.1415 7.30214 24.1488 8.50255C24.1854 14.5608 24.1811 20.6194 24.1768 26.6777C24.1754 28.6461 24.174 30.6144 24.174 32.5828Z"
        fill={color || primaryColor}
      />
      <path
        d="M21.6143 33.2278C20.6943 32.3027 19.7776 31.546 18.7679 30.9079C16.3361 29.3702 13.6628 28.5173 10.8295 28.1158C9.64845 27.9484 8.46261 27.8558 7.26949 27.8383C6.00489 27.8201 5.37624 27.1765 5.37588 25.9257C5.37478 17.9141 5.37478 9.90202 5.37588 1.89036C5.37624 0.597322 6.07199 -0.032423 7.36539 0.00586494C9.65501 0.0736893 11.9034 0.363584 14.0793 1.11111C17.0559 2.13395 19.5132 3.84305 21.2628 6.50132C21.5101 6.87691 21.6369 7.27547 21.6369 7.71706C21.638 16.1158 21.6377 24.5145 21.6373 32.9136C21.6373 32.9566 21.6335 33.0001 21.6275 33.0682C21.6238 33.11 21.6193 33.1612 21.6143 33.2278Z"
        fill={color || primaryColor}
      />
      <path
        d="M39.1943 34.1114C40.8465 34.3232 42.4852 34.6098 44.1053 34.9996C44.1651 34.9996 44.2246 34.9996 44.284 35C45.0399 34.8348 45.5563 34.3987 45.8046 33.6592V6.93311C45.5537 6.33254 45.1898 5.83553 44.5086 5.70826C44.3356 5.67592 44.1566 5.67748 43.9773 5.67904C43.8271 5.68035 43.6766 5.68166 43.5292 5.66305C43.081 5.60653 42.9603 5.75785 42.9614 6.21731C42.976 11.877 42.9743 17.537 42.9727 23.1967L42.972 25.7828C42.972 26.2744 42.968 26.767 42.8294 27.2447C42.2934 29.0927 40.8501 30.2476 38.9215 30.3548C38.681 30.3682 38.4404 30.3799 38.1998 30.3917C37.2807 30.4366 36.3618 30.4815 35.4486 30.6173C32.8133 31.0093 30.3136 31.7714 28.0871 33.284C27.5566 33.6442 27.0271 34.0114 26.5337 34.5723C28.1969 34.1723 29.786 33.9433 31.3912 33.8408C33.9999 33.6745 36.6016 33.7792 39.1943 34.1114Z"
        fill={color || primaryColor}
      />
      <path
        d="M1.47177 34.9615C1.52171 34.9739 1.57163 34.9863 1.62134 34.9994C1.65124 34.9994 1.68114 34.9994 1.71031 35.0001C1.72238 34.9936 1.73424 34.9861 1.7461 34.9786C1.7722 34.962 1.7983 34.9455 1.82664 34.94C4.14871 34.4787 6.47552 34.0601 8.84281 33.8883C11.4369 33.7002 14.0212 33.7246 16.6014 34.0655C17.4305 34.1752 18.2491 34.3445 19.1667 34.5344L19.3082 34.5637C19.2381 34.4976 19.18 34.4419 19.1292 34.3932C19.0369 34.3047 18.9688 34.2395 18.8976 34.1779C17.09 32.6164 14.9744 31.6687 12.6847 31.0729C10.8735 30.6014 9.02514 30.4074 7.15851 30.3698C6.17797 30.3505 5.2762 30.1091 4.4933 29.498C3.31112 28.575 2.83598 27.3422 2.83853 25.8668C2.84625 21.2128 2.84503 16.5588 2.84381 11.9047C2.84336 10.1847 2.84291 8.46472 2.84291 6.74472C2.84291 6.19693 2.84291 5.92272 2.70582 5.78813C2.56842 5.65323 2.2933 5.65861 1.74241 5.66938C0.683471 5.69016 0.0263732 6.34944 0.0125166 7.4113C0.00872976 7.71021 0.00966214 8.00929 0.0105943 8.30831C0.0110086 8.44119 0.0114227 8.57406 0.0114227 8.70689L0.0113773 14.7912C0.011309 20.8795 0.0112407 26.968 0.0117878 33.0562C0.0117878 34.0203 0.397949 34.6355 1.15095 34.8747C1.25654 34.9081 1.36418 34.9348 1.47177 34.9615Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BookThreeIcon;
