import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const MessageIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2 0C36.29 0 37.981 1.71 37.981 3.8L38 38L30.4 30.4H3.8C1.71 30.4 0 28.69 0 26.6V3.8C0 1.71 1.71 0 3.8 0H34.2ZM9.5 22.8H28.5C29.545 22.8 30.4 21.945 30.4 20.9C30.4 19.855 29.545 19 28.5 19H9.5C8.455 19 7.6 19.855 7.6 20.9C7.6 21.945 8.455 22.8 9.5 22.8ZM28.5 17.1H9.5C8.455 17.1 7.6 16.245 7.6 15.2C7.6 14.155 8.455 13.3 9.5 13.3H28.5C29.545 13.3 30.4 14.155 30.4 15.2C30.4 16.245 29.545 17.1 28.5 17.1ZM9.5 11.4H28.5C29.545 11.4 30.4 10.545 30.4 9.5C30.4 8.455 29.545 7.6 28.5 7.6H9.5C8.455 7.6 7.6 8.455 7.6 9.5C7.6 10.545 8.455 11.4 9.5 11.4Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default MessageIcon;
