import { useEffect, useState } from "react";
import Button from "../../../../components/atoms/Button";

import Input from "../../../../components/atoms/Input";
import NavigationArrow from "../../../../components/atoms/NavigationArrow";
import Page from "../../../../components/atoms/Page";
import StepsProgressBar from "../../../../components/atoms/StepsProgressBar";
import P from "../../../../components/atoms/Typography/P";
import RegisterPhoneStepIcon from "../../../../components/icons/RegisterPhoneStepIcon";
import StantiIcon from "../../../../components/icons/StantiIcon";
import { LOCAL_STORAGE_AUTHENTICATION_METHOD } from "../../../../constants";
import { Auth, GA, Theme } from "../../../../hooks";
import { Analytics, Creator } from "../../../../services";
import { TSteps } from "../../../../types";
import { maskPhoneNumber } from "../../../../utils/numbers";

import * as RegisterStyles from "../styles";
import * as PhoneStepStyles from "./styles";

const S = {
  ...PhoneStepStyles,
  ...RegisterStyles,
};

export interface IInformationStepForm {
  name: string;
  email: string;
  phone: string;
  password: string;
}

const authenticationMethod =
  localStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_METHOD) || "";

const PhoneStep: React.FC = () => {
  const [phone, setPhone] = useState<string>("");
  const isCellphoneValid = phone && phone.length >= 11;

  const { sendEvents } = GA.useGA();
  const { primaryColor, textColor } = Theme.useTheme();
  const { user, setUserHandler, token, logout } = Auth.useAuth();

  useEffect(() => {
    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "information-step",
      creatorUsername: user.username || "",
      actionDescription: "phone-information-step",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNextHandler = async () => {
    setPhone(phone.trim());

    const payload = {
      phone: phone,
      formStep: "profile-image" as TSteps,
    };

    (Object.keys(payload) as (keyof typeof payload)[]).forEach((key) => {
      if (!payload[key]) delete payload[key];
    });

    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      description: "information-step-concluded",
      actionDescription: `information-step-concluded-${user.username}`,
    });

    const authentication = authenticationMethod.split("-")[2];

    sendEvents({
      name: "Lead",
      buttonLocation: "login",
      category: `${authentication}-button`,
    });
    window.fbq("track", "Lead");

    const updateUser = await Creator.updateProfile(payload, token);

    setUserHandler(updateUser);
  };

  return (
    <Page>
      <S.Wrapper>
        <S.Container>
          <S.RegisterFormHeader>
            <div className="stantiLogo">
              <StantiIcon color="#fafafa" />
            </div>

            <Button
              style={{ color: "#fafafa" }}
              color="#fafafa05"
              onClick={logout}
            >
              Sair
            </Button>
          </S.RegisterFormHeader>

          <S.ProgressBar>
            <P color={textColor}>Criando sua Stanti</P>

            <StepsProgressBar progress={(2 / 6) * 100} quantityOfBars={6} />
          </S.ProgressBar>

          <S.Form>
            <div className="icon">
              <RegisterPhoneStepIcon />
            </div>

            <div className="text">
              <P color={textColor}>
                Informe o número do seu celular para continuar
              </P>
            </div>

            <Input
              type="tel"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="Celular"
              value={maskPhoneNumber(phone)}
              status={isCellphoneValid ? "success" : ""}
              onChange={(val) => setPhone((val || "").replace(/[^0-9]/g, ""))}
            />
          </S.Form>

          <S.Footer>
            <NavigationArrow
              buttonStyle="solid"
              angle={isCellphoneValid ? 0 : -90}
              onClick={() => isCellphoneValid && onNextHandler()}
              color={isCellphoneValid ? primaryColor : "#ffffff33"}
            />
          </S.Footer>
        </S.Container>
      </S.Wrapper>
    </Page>
  );
};

export default PhoneStep;
