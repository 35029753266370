import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../../../components/atoms/Button";
import Input from "../../../../components/atoms/Input";
import Page from "../../../../components/atoms/Page";
import P from "../../../../components/atoms/Typography/P";
import { Loading, Snackbar, Theme } from "../../../../hooks";
import { Creator } from "../../../../services";
import SuccessfulChange from "../SuccessfulChange";

import H1 from "../../../../components/atoms/Typography/H1";
import * as ForgotPasswordStyles from "../../styles";
import * as NewPasswordStyles from "./styles";

const S = {
  ...NewPasswordStyles,
  ...ForgotPasswordStyles,
};

const NewPassword: React.FC = () => {
  const [userData, setUserData] = useState<{
    name: string;
    username: string;
    profileImage: string;
  } | null>(null);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const { primaryColor, textColor } = Theme.useTheme();

  const navigate = useNavigate();
  const { newError } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const resetToken = urlParams.get("reset_token");

  useEffect(() => {
    if (!resetToken) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResetPasswordHandler = async () => {
    if (!password || !repeatPassword || !resetToken) return;

    if (password !== repeatPassword) return newError("As senhas não coincidem");

    showLoading();

    try {
      const { name, username, profileImage } = await Creator.resetPassword(
        resetToken,
        password
      );

      setUserData({ name, profileImage, username });
    } catch (error) {
      if (
        error &&
        ["LGOT003", "TOKE001"].includes((error as { message: string }).message)
      ) {
        newError("Expirou! Clique em esqueci minha senha e comece novamente");
      } else {
        newError("Houve um erro ao redefinir a senha");
      }
    } finally {
      hideLoading();
    }
  };

  if (userData)
    return (
      <SuccessfulChange
        username={userData.username}
        profileImage={userData.profileImage || ""}
      />
    );

  return (
    <Page>
      <S.LoginFormHeader>
        <img alt="stanti icon" src="/stanti-icon.svg" className="stantiLogo" />

        <Button onClick={() => navigate("/")} type="outline">
          Voltar
        </Button>
      </S.LoginFormHeader>

      <S.Cta>
        <H1 color={primaryColor}>Esqueceu? Acontece :)</H1>
        <P color={textColor}>Vamos te ajudar a criar uma nova senha.</P>
      </S.Cta>

      <S.Form>
        <S.FormPassword>
          <Input
            type="password"
            value={password}
            onChange={setPassword}
            placeholder="Digite aqui uma nova senha"
          />

          <Input
            type="password"
            value={repeatPassword}
            onChange={setRepeatPassword}
            placeholder="Confirmar nova senha"
          />
        </S.FormPassword>

        <Button
          onClick={onResetPasswordHandler}
          disabled={!password || !(password === repeatPassword)}
        >
          Definir nova senha
        </Button>
      </S.Form>
    </Page>
  );
};

export default NewPassword;
