import axios from "axios";
import { BTN_CLICK_ACTION, PAGE_VIEW_ACTION } from "../../constants";

export interface IEventData {
  origin: string;
  creator: string;
  description?: string;
  creatorUsername: string;
  actionDescription: string;
  meta?: { [key: string]: unknown };
}

const ANALYTICS_API_BASE_URL = process.env.REACT_APP_ANALYTICS_API_BASE_URL;

const submitPageViewEvent = async (eventData: IEventData) => {
  const {
    meta,
    origin,
    creator,
    description,
    creatorUsername,
    actionDescription,
  } = eventData;

  const { data } = await axios.post(`${ANALYTICS_API_BASE_URL}/new/page_view`, {
    meta,
    origin,
    creator,
    creatorUsername,
    application: "creator",
    description: description || "",
    action: `${PAGE_VIEW_ACTION}:${actionDescription}`,
  });

  return data.event;
};

const submitClickEvent = async (eventData: IEventData) => {
  const {
    meta,
    origin,
    creator,
    description,
    creatorUsername,
    actionDescription,
  } = eventData;

  const { data } = await axios.post(`${ANALYTICS_API_BASE_URL}/new/click`, {
    meta,
    origin,
    creator,
    creatorUsername,
    application: "creator",
    description: description || "",
    action: `${BTN_CLICK_ACTION}:${actionDescription}`,
  });

  return data.event;
};

export default {
  submitClickEvent,
  submitPageViewEvent,
};
