import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SmartphoneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="25"
      height="40"
      viewBox="0 0 25 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7853 0.39209L21.4643 0.409769C23.4089 0.409769 25.0001 1.9832 25.0001 3.92788V35.75C25.0001 37.6947 23.4089 39.2858 21.4643 39.2858H3.7853C1.84062 39.2858 0.249512 37.6947 0.249512 35.75V3.92788C0.249512 1.9832 1.84062 0.39209 3.7853 0.39209ZM3.78565 32.2143H21.4646V7.46376H3.78565V32.2143Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SmartphoneIcon;
