import { useRef } from "react";

import { Theme } from "../../../hooks";
import CheckIcon from "../../icons/CheckIcon";
import CloseIcon from "../../icons/CloseIcon";
import LoadingIcon from "../../icons/LoadingIcon";

import * as S from "./styles";

interface IProps {
  rows?: number;
  value: string;
  disabled?: boolean;
  charLimit?: number;
  placeholder?: string;
  onChange: (value: string) => void;
  status?: "success" | "error" | "loading" | "";
}

const TextArea: React.FC<IProps> = ({
  rows,
  value,
  status,
  onChange,
  charLimit,
  placeholder = "",
  disabled = false,
}) => {
  const input = useRef<HTMLTextAreaElement>(null);

  const { textColor, primaryColor } = Theme.useTheme();

  const iconToRender = {
    loading: <LoadingIcon />,
    success: <CheckIcon />,
    error: <CloseIcon />,
  };

  const onChangeHandler = (val: string) => {
    if (charLimit) onChange(val.substring(0, charLimit));
    else onChange(val);
  };

  const onFocusHandler = () => {
    if (window.navigator.userAgent.includes("Mobi")) {
      document.body.style.paddingBottom = "400px";

      setTimeout(() => {
        if (input.current)
          window.scrollTo({
            left: 0,
            top: input.current?.offsetTop - 100,
            behavior: "smooth",
          });
      }, 10);
    }
  };

  const onBlurHandler = () => {
    if (window.navigator.userAgent.includes("Mobi"))
      document.body.style.paddingBottom = "0";
  };

  return (
    <>
      <S.TextAreaContainer
        isDisabled={disabled}
        onClick={() => (input.current as HTMLTextAreaElement)?.focus()}
      >
        <S.TextArea
          ref={input}
          value={value}
          rows={rows || 5}
          disabled={disabled}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          placeholder={placeholder}
          onChange={({ target }) => onChangeHandler(target.value)}
          textColor={status === "success" ? primaryColor : textColor}
          isDisabled={disabled}
        />

        <S.RightBar>
          {charLimit && (
            <S.CharLimit
              color={textColor}
            >{`${value.length}/${charLimit}`}</S.CharLimit>
          )}

          {!!status && iconToRender[status]}
        </S.RightBar>
      </S.TextAreaContainer>
    </>
  );
};

export default TextArea;
