import { Theme } from "../../hooks";

interface IProps {
  color?: string;
}

const BackgroundProfileImageIcon: React.FC<IProps> = ({ color }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <svg
      width="240"
      height="163"
      viewBox="0 0 240 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="4.5"
        transform="matrix(0.415698 -0.909503 -0.909503 -0.415698 19.626 57.6264)"
        stroke={color || primaryColor}
        strokeLinejoin="round"
      />
      <circle
        cx="37.7535"
        cy="131.754"
        r="2"
        transform="rotate(-58.2434 37.7535 131.754)"
        fill={color || primaryColor}
      />
      <path
        d="M3.48179 127.832C2.47718 127.373 1.29056 127.815 0.831392 128.819C0.372225 129.824 0.814393 131.011 1.819 131.47C2.82361 131.929 4.01024 131.487 4.4694 130.482C4.92857 129.478 4.4864 128.291 3.48179 127.832Z"
        fill={color || primaryColor}
      />
      <circle
        cx="12.7535"
        cy="17.7539"
        r="2"
        transform="rotate(-58.2434 12.7535 17.7539)"
        fill={color || primaryColor}
      />
      <circle
        r="2"
        transform="matrix(0.415698 -0.909503 -0.909503 -0.415698 63.6504 152.651)"
        fill={color || primaryColor}
      />
      <circle
        cx="42.7535"
        cy="40.7539"
        r="2"
        transform="rotate(-58.2434 42.7535 40.7539)"
        fill={color || primaryColor}
      />
      <path
        d="M71.0133 5.02368L67.0824 8.95449L71.0485 12.9206L69.9028 14.0663L65.9367 10.1002L62.0235 14.0134L60.9659 12.9558L64.8791 9.04263L60.913 5.07656L62.0588 3.93081L66.0248 7.89687L69.9556 3.96606L71.0133 5.02368Z"
        fill={color || primaryColor}
      />
      <path
        d="M21.0062 99.5117L19.0409 101.477L21.0238 103.46L20.4509 104.033L18.468 102.05L16.5115 104.006L15.9828 103.478L17.9392 101.521L15.9563 99.5381L16.5292 98.9653L18.5121 100.948L20.4774 98.9829L21.0062 99.5117Z"
        fill={color || primaryColor}
      />
      <path
        d="M35.0062 148.512L33.0409 150.477L35.0238 152.46L34.4509 153.033L32.468 151.05L30.5115 153.006L29.9828 152.478L31.9392 150.521L29.9563 148.538L30.5292 147.965L32.5121 149.948L34.4774 147.983L35.0062 148.512Z"
        fill={color || primaryColor}
      />
      <circle
        r="4.5"
        transform="matrix(-0.415698 0.909503 0.909503 0.415698 220.353 104.675)"
        stroke={color || primaryColor}
        strokeLinejoin="round"
      />
      <circle
        cx="202.226"
        cy="30.5474"
        r="2"
        transform="rotate(121.757 202.226 30.5474)"
        fill={color || primaryColor}
      />
      <path
        d="M236.498 34.4694C237.502 34.9286 238.689 34.4864 239.148 33.4818C239.607 32.4772 239.165 31.2906 238.16 30.8314C237.156 30.3722 235.969 30.8144 235.51 31.819C235.051 32.8236 235.493 34.0102 236.498 34.4694Z"
        fill={color || primaryColor}
      />
      <circle
        cx="227.226"
        cy="144.547"
        r="2"
        transform="rotate(121.757 227.226 144.547)"
        fill={color || primaryColor}
      />
      <circle
        r="2"
        transform="matrix(-0.415698 0.909503 0.909503 0.415698 176.329 9.65039)"
        fill={color || primaryColor}
      />
      <circle
        cx="197.226"
        cy="121.547"
        r="2"
        transform="rotate(121.757 197.226 121.547)"
        fill={color || primaryColor}
      />
      <path
        d="M168.966 157.278L172.897 153.347L168.931 149.381L170.077 148.235L174.043 152.201L177.956 148.288L179.014 149.345L175.1 153.259L179.066 157.225L177.921 158.37L173.955 154.404L170.024 158.335L168.966 157.278Z"
        fill={color || primaryColor}
      />
      <path
        d="M218.973 62.7896L220.939 60.8243L218.956 58.8413L219.529 58.2685L221.511 60.2514L223.468 58.2949L223.997 58.8237L222.04 60.7802L224.023 62.7631L223.45 63.336L221.467 61.353L219.502 63.3183L218.973 62.7896Z"
        fill={color || primaryColor}
      />
      <path
        d="M204.973 13.7896L206.939 11.8243L204.956 9.84134L205.529 9.2685L207.511 11.2514L209.468 9.29494L209.997 9.82372L208.04 11.7802L210.023 13.7631L209.45 14.336L207.467 12.353L205.502 14.3183L204.973 13.7896Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BackgroundProfileImageIcon;
