import { useEffect, useState } from "react";

import ExternalLinkSkeleton from "../../../../components/atoms/ExternalLinkSkeleton";
import P from "../../../../components/atoms/Typography/P";
import Small from "../../../../components/atoms/Typography/Small";
import CalendarIcon from "../../../../components/icons/CalendarIcon";
import { weekDays } from "../../../../constants/calendar";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import { Analytics, Calendar } from "../../../../services";
import {
  IAppointments,
  IGetAppointmentsAndBlocksResponse,
} from "../../../../types/calendar";
import AppointmentsByDate from "../AppointmentsByDate";
import CalendarAppointmentModal from "../CalendarAppointmentModal";
import ExcludeAppointmentModal from "../ExcludeAppointmentModal";

import * as S from "./styles";

const YourAppointments: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] =
    useState<IGetAppointmentsAndBlocksResponse>({});
  const [toBeExcludedAppointment, setToBeExcludedAppointment] = useState<
    string | null
  >(null);
  const [itemToBeAddedToCalendar, setItemToBeAddedToCalendar] =
    useState<IAppointments | null>(null);

  const { token, user } = Auth.useAuth();
  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  useEffect(() => {
    const run = async () => {
      try {
        const appointmentsData = await Calendar.getAppointmentsAndBlocks(token);

        setAppointments(appointmentsData || {});
      } catch (error) {
        newError("Houve um erro ao obter os agendamentos");
      } finally {
        setIsLoading(false);
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const onExcludeAppointment = async () => {
    if (!toBeExcludedAppointment) return;

    Analytics.submitClickEvent({
      origin: "calendar",
      creator: user.id || "",
      description: "exclude-appointment",
      creatorUsername: user.username || "",
      actionDescription: `exclude-appointment-${toBeExcludedAppointment}`,
    });

    try {
      showLoading();

      await Calendar.removeAppointment(toBeExcludedAppointment, token);

      const appointmentsData = await Calendar.getAppointmentsAndBlocks(token);

      setAppointments(appointmentsData || {});
    } catch (error) {
      newError("Houve um erro ao remover o agendamento");
    } finally {
      setToBeExcludedAppointment(null);
      hideLoading();
    }
  };

  const appointmentDates = appointments ? Object.keys(appointments) : null;

  const hasAppointments = Object.values(appointments).flat().length > 0;

  if (isLoading) return <ExternalLinkSkeleton />;

  return (
    <>
      <S.YourAppointments>
        <P color={textColor}>Seus agendamentos</P>

        <S.Disclaimer color={textColor}>
          Abaixo sua lista de agendamentos. Para cancelamentos ou
          reagendamentos, entrar em contato com o cliente pessoalmente.
        </S.Disclaimer>

        {appointmentDates && hasAppointments ? (
          <S.AppointmentList>
            {appointmentDates.map((item) => {
              const date = new Date(item);
              const day = date.getDate();
              const month = date.getMonth() + 1;
              const weekDay = date.getDay() as keyof typeof weekDays;

              const monthDate =
                day.toLocaleString("pt-BR", { minimumIntegerDigits: 2 }) +
                "/" +
                month.toLocaleString("pt-BR", { minimumIntegerDigits: 2 });

              const appointmentsList = appointments && appointments[item];

              return (
                <AppointmentsByDate
                  monthDate={monthDate}
                  weekDay={weekDays[weekDay]}
                  appointments={appointmentsList}
                  key={`appointmentsByDate#${item}`}
                  onAddToCalendar={setItemToBeAddedToCalendar}
                  onRemoveAppointment={setToBeExcludedAppointment}
                />
              );
            })}
          </S.AppointmentList>
        ) : (
          <S.NoAppointments>
            <div className="calendarIcon">
              <CalendarIcon />
            </div>

            <Small color={primaryColor}>
              Você ainda não possui nenhum serviço agendado
            </Small>
          </S.NoAppointments>
        )}
      </S.YourAppointments>

      <ExcludeAppointmentModal
        onCloseModal={() => {
          setToBeExcludedAppointment(null);
        }}
        isOpen={!!toBeExcludedAppointment}
        onExcludeAppointment={onExcludeAppointment}
      />

      <CalendarAppointmentModal
        isOpen={!!itemToBeAddedToCalendar}
        appointment={itemToBeAddedToCalendar}
        onCloseModal={() => setItemToBeAddedToCalendar(null)}
      />
    </>
  );
};

export default YourAppointments;
