import styled from "styled-components";

type SmallProps = {
  color: string;
};

const Small = styled.small<SmallProps>`
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: ${({ color }) => color};
`;

export default Small;
