import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BeachIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3271 10.2661L32.468 13.1252C25.4217 6.07886 15.5613 3.71507 6.71401 6.64167C14.3006 -0.922453 25.8719 -2.02556 34.6292 3.33236C37.0605 4.79566 37.3307 8.26255 35.3271 10.2661ZM26.0282 22.8055L22.809 26.0248L35.7085 38.9243C36.609 39.8023 38.0498 39.8023 38.9278 38.9243C39.8057 38.0238 39.8057 36.583 38.9278 35.7051L26.0282 22.8055ZM6.64619 6.70911C-0.917931 14.2957 -2.02103 25.8671 3.31437 34.6243C4.80018 37.0556 8.24456 37.3483 10.2482 35.3222L13.1072 32.4631C6.08338 25.4168 3.7196 15.5564 6.64619 6.70911ZM6.66959 6.68678L6.6921 6.66426C13.4683 5.8088 22.2031 9.2982 29.2494 16.3445L16.3499 29.2441C9.30352 22.1752 5.81412 13.463 6.66959 6.68678Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BeachIcon;
