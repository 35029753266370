import styled from "styled-components";

export const AddContent = styled.div`
  margin-top: 20px;
`;

export const SectionTitle = styled.div`
  gap: 20px;
  display: flex;
  margin-bottom: 12px;
  align-items: center;

  hr {
    flex-grow: 1;
    height: 1px;
    margin-top: -3px;
    background-color: #fafafa30;
  }
`;

export const Content = styled.div`
  gap: 12px;
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(106px, 1fr));
`;
