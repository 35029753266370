import styled from "styled-components";

export const StantiPayProduct = styled.div`
  gap: 8px;
  width: 100%;
  height: 72px;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 18px;
  align-items: center;
  background-blend-mode: overlay;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 0.1) -40%,
    rgba(113, 114, 111, 0) 140%
  );
`;

export const Index = styled.div`
  z-index: 1;
  display: flex;
  margin-left: 8px;
  padding: 16px 8px;
  position: relative;
  border-radius: 12px;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  height: 100%;
  padding: 8px 16px;
  align-items: center;
  border-radius: 18px;
  mix-blend-mode: normal;
  justify-content: space-between;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0.2) -35%,
    rgba(255, 255, 255, 0) 80%
  );

  div {
    &:first-of-type {
      gap: 8px;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const EditIcon = styled.div`
  width: 16px;
  height: 14px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
