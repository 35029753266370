import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const MovieIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3849 20.1944L40.9179 21.5359L38.4256 18.7929L39.8926 17.4513C40.6994 16.7134 40.7988 15.4926 40.1134 14.7382L32.6365 6.50904C31.9511 5.7547 30.7302 5.74121 29.9234 6.47907L28.4564 7.82063L25.9641 5.07757L27.4311 3.73601C28.2379 2.99815 28.3373 1.77726 27.6519 1.02292C26.9665 0.268579 25.7456 0.255092 24.9388 0.992951L1.46696 22.4579C0.660117 23.1958 0.560741 24.4167 1.24612 25.171C1.93151 25.9253 3.15242 25.9388 3.95926 25.201L5.42625 23.8594L7.91856 26.6025L6.45157 27.944C5.64473 28.6819 5.54535 29.9028 6.23073 30.6571L13.7076 38.8863C14.393 39.6406 15.6139 39.6541 16.4208 38.9163L17.8878 37.5747L20.3801 40.3178L18.9131 41.6593C18.1062 42.3972 18.0069 43.6181 18.6922 44.3724C19.3776 45.1268 20.5985 45.1402 21.4054 44.4024L44.8772 22.9374C45.684 22.1996 45.7834 20.9787 45.098 20.2243C44.4126 19.47 43.1917 19.4565 42.3849 20.1944ZM10.779 23.4903L8.28154 20.7416L11.2216 18.0529L13.719 20.8016L10.779 23.4903ZM13.9706 15.5564L16.4681 18.3051L19.4081 15.6165L16.9107 12.8677L13.9706 15.5564ZM22.1574 13.1191L19.66 10.3703L22.6 7.68165L25.0974 10.4304L22.1574 13.1191ZM21.247 34.9648L23.7444 37.7135L26.6844 35.0248L24.187 32.2761L21.247 34.9648ZM29.4327 32.5272L26.9353 29.7785L29.8753 27.0898L32.3728 29.8385L29.4327 32.5272ZM32.6244 24.5933L35.1218 27.342L38.0619 24.6533L35.5644 21.9046L32.6244 24.5933Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default MovieIcon;
