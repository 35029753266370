import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BusIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="32"
      viewBox="0 0 50 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6818 1.409L48.6591 12.3863C49.5227 13.2499 50 14.409 50 15.6135V20.5454C50 23.0454 47.9545 25.0908 45.4545 25.0908C45.4545 28.8635 42.4091 31.909 38.6364 31.909C34.8636 31.909 31.8182 28.8635 31.8182 25.0908H18.1818C18.1818 28.8635 15.1364 31.909 11.3636 31.909C7.59091 31.909 4.54545 28.8635 4.54545 25.0908C2.04545 25.0908 0 23.0454 0 20.5454V4.63627C0 2.11355 2.04545 0.0908203 4.54545 0.0908203H34.4773C35.6818 0.0908203 36.8409 0.568093 37.6818 1.409ZM4.54545 6.909V11.4545C4.54545 12.7045 5.56818 13.7272 6.81818 13.7272H13.6364V4.63627H6.81818C5.56818 4.63627 4.54545 5.659 4.54545 6.909ZM11.3636 27.9317C9.79545 27.9317 8.52273 26.659 8.52273 25.0908C8.52273 23.5226 9.79545 22.2499 11.3636 22.2499C12.9318 22.2499 14.2045 23.5226 14.2045 25.0908C14.2045 26.659 12.9318 27.9317 11.3636 27.9317ZM18.1818 13.7272H27.2727V4.63627H18.1818V13.7272ZM38.6364 27.9317C37.0682 27.9317 35.7955 26.659 35.7955 25.0908C35.7955 23.5226 37.0682 22.2499 38.6364 22.2499C40.2045 22.2499 41.4773 23.5226 41.4773 25.0908C41.4773 26.659 40.2045 27.9317 38.6364 27.9317ZM31.8182 4.63627V13.7272H43.1818L34.0909 4.63627H31.8182Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BusIcon;
