import { ChangeEvent, useRef, useState } from "react";

import { Snackbar, Theme } from "../../../hooks";
import { Apps } from "../../../services";
import CloseIcon from "../../icons/CloseIcon";
import Label from "../Typography/Label";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  images: string[];
  errors?: string[];
  description?: string;
  onChange: (images: string) => void;
  onRemoveImage: (index: number) => void;
}

/*
  TODO:
    - Limitar tamanho do upload
*/

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const AppFormMedia: React.FC<IProps> = ({
  images,
  errors,
  onChange,
  description,
  onRemoveImage,
}) => {
  const [loadingImages, setLoadingImages] = useState<number>(0);

  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const input = useRef<HTMLInputElement | null>(null);

  const onUploadHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file.size > MAX_FILE_SIZE) {
      if (input.current) input.current.value = "";
      return newError("Arquivo muito grande: máx 5Mb");
    }

    setLoadingImages((current) => current + 1);

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) {
          const imageUrl = await Apps.uploadImage(base64);
          onChange(imageUrl);
          setLoadingImages((current) => current - 1);
        }

        if (input.current) input.current.value = "";
      };
    }
  };

  return (
    <S.Media>
      <Label color="#FAFAFA60">
        Tamanho mínimo 1080x1350 pixels (4:5). Máx 5Mb
      </Label>

      {description && description !== undefined && (
        <P color={textColor}>{description}</P>
      )}

      <S.Images>
        {images.map((image, index) => (
          <S.ImageContainer key={`image#${index}`}>
            <Label color={primaryColor}>{`Foto ${index + 1}`}</Label>

            <S.ImageAndRemoveButton>
              <S.Image imageUrl={image} />

              <S.RemoveImage onClick={() => onRemoveImage(index)}>
                <CloseIcon />
              </S.RemoveImage>
            </S.ImageAndRemoveButton>
          </S.ImageContainer>
        ))}

        {Array(loadingImages)
          .fill(null)
          .map((_, index) => (
            <S.ImageSkeleton key={`imageSkeleton#${index}`} />
          ))}
      </S.Images>

      <S.AddMediaButton onClick={() => input.current?.click()}>
        <div className="addIcon">
          <P color="#71726F">+</P>
        </div>

        <P color="#71726F">Adicionar foto</P>

        <input
          type="file"
          ref={input}
          onChange={onUploadHandler}
          accept="image/png, image/jpeg, image/jpg, image/webp"
        />
      </S.AddMediaButton>

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </S.Media>
  );
};

export default AppFormMedia;
