import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 12px;
  display: flex;
  padding: 12px 10px;
  align-items: center;
  border-radius: 16px;
  box-sizing: border-box;
  background: linear-gradient(
    271.06deg,
    rgba(250, 250, 250, 0.2) -3.98%,
    rgba(113, 114, 111, 0) 203.38%
  );
  mix-blend-mode: screen;

  p {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    font-style: normal;
    font-family: "Poppins", sans-serif;
  }
`;

type InputOutProps = {
  isSelected: boolean;
  borderColor: string;
};

export const InputOut = styled.div<InputOutProps>`
  ${({ isSelected, borderColor }) => css`
    width: 12px;
    height: 12px;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    align-items: center;
    mix-blend-mode: screen;
    justify-content: center;
    background: ${isSelected ? "none" : "#fafafa18"};
    border: ${isSelected ? `1px solid ${borderColor}` : "none"};
  `}
`;

type InputInProps = {
  isSelected: boolean;
  backgroundColor: string;
};

export const InputIn = styled.div<InputInProps>`
  ${({ isSelected, backgroundColor }) => css`
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 50%;
    background: ${isSelected ? backgroundColor : "none"};
  `}
`;
