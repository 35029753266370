import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const VolumeIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="37"
      viewBox="0 0 40 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0036 2.42441V1.98798C24.0036 0.613231 25.3783 -0.368733 26.644 0.13316C34.0414 2.94812 39.2786 10.0837 39.2786 18.4632C39.2786 26.8426 34.0414 33.9782 26.644 36.7932C25.3783 37.2732 24.0036 36.3131 24.0036 34.9383V34.5019C24.0036 33.6727 24.5273 32.9526 25.3129 32.6471C30.9428 30.3995 34.9143 24.8787 34.9143 18.4632C34.9143 12.0477 30.9428 6.52684 25.3129 4.27923C24.5491 3.97373 24.0036 3.25362 24.0036 2.42441ZM0 14.099V22.8276C0 24.0278 0.981965 25.0097 2.18214 25.0097H8.72857L15.9078 32.189C17.2826 33.5637 19.6393 32.5818 19.6393 30.6396V6.2651C19.6393 4.32299 17.2826 3.34103 15.9078 4.71578L8.72857 11.9169H2.18214C0.981965 11.9169 0 12.8988 0 14.099ZM29.4589 18.4632C29.4589 14.6008 27.2332 11.2839 24.0036 9.66914V27.2354C27.2332 25.6424 29.4589 22.3256 29.4589 18.4632Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default VolumeIcon;
