import styled from "styled-components";

export const LinkToPay = styled.div`
  gap: 15px;
  display: flex;
  padding-bottom: 30px;
  flex-direction: column;
`;

export const MyProducts = styled.div`
  display: flex;
  padding: 30px 0;
  flex-direction: column;
`;

export const NewProduct = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  margin-top: 15px;

  button {
    flex-grow: 1;
  }
`;
