import { ChangeEvent, useRef, useState } from "react";

import { Theme } from "../../../hooks";
import EditIcon from "../../icons/EditIcon";
import LoadingIcon from "../../icons/LoadingIcon";
import ImageReposition from "../../molecules/ImageReposition";

import * as S from "./styles";

interface IProps {
  isLoading: boolean;
  currentImage: string;
  customColor?: string;
  shadowColor?: string;
  widthProfile?: string;
  heightProfile?: string;
  hideEditIcon?: boolean;
  showPlaceholder?: boolean;
  customBackgroundColor?: string;
  onChangeImage: (image: string) => void;
}

const EditableProfileImage: React.FC<IProps> = ({
  isLoading,
  shadowColor,
  customColor,
  currentImage,
  hideEditIcon,
  widthProfile,
  heightProfile,
  onChangeImage,
  showPlaceholder,
  customBackgroundColor,
}) => {
  const [initialImage, setInitialImage] = useState("");

  const { primaryColor, backgroundColor } = Theme.useTheme();

  const input = useRef<HTMLInputElement | null>(null);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) setInitialImage(base64);

        if (input.current) input.current.value = "";
      };
    }
  };

  const onDiscardImage = () => {
    setInitialImage("");
  };

  return (
    <>
      <S.EditableProfileImage
        shadowColor={shadowColor}
        width={widthProfile || "116px"}
        height={heightProfile || "116px"}
        imageUrl={currentImage}
        backgroundColor={customBackgroundColor || primaryColor}
      >
        {!isLoading && showPlaceholder && !currentImage && (
          <S.ImagePlaceholder color={customColor || backgroundColor} />
        )}
        {isLoading && <LoadingIcon color={backgroundColor} />}

        {!hideEditIcon && (
          <S.EditButton
            onClick={() => input.current?.click()}
            backgroundColor={primaryColor}
          >
            <EditIcon color={backgroundColor} />

            <input
              type="file"
              ref={input}
              onChange={onChangeHandler}
              accept="image/png, image/jpeg, image/jpg, image/webp"
            />
          </S.EditButton>
        )}
      </S.EditableProfileImage>

      {initialImage && (
        <ImageReposition
          initialImage={initialImage}
          onChangeImage={onChangeImage}
          onDiscardImage={onDiscardImage}
        />
      )}
    </>
  );
};

export default EditableProfileImage;
