import styled, { css } from "styled-components";

type ButtonProps = {
  variant: "solid" | "outline";
  color: string;
  textColor: string;
  isDisabled?: boolean;
};

const buttonModifier = {
  solid: (backgroundColor: string, textColor: string) => css`
    background-color: ${backgroundColor};
    color: ${textColor};
  `,
  outline: (borderColor: string, textColor: string) => css`
    background: none;
    border: 1px solid ${borderColor};
    color: ${textColor};
  `,
};

export const Button = styled.button<ButtonProps>`
  ${({ variant, color, textColor, isDisabled }) => css`
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    user-select: none;
    text-align: center;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    padding: 8px 24px 9px 24px;
    opacity: ${isDisabled ? 0.5 : 1};
    cursor: ${isDisabled ? "not-allowed" : "pointer"};

    ${buttonModifier[variant](color, textColor)}
  `}
`;
