import { Navigate, Route, Routes } from "react-router-dom";

import Home from "../../pages/Home";
import { Auth, NavBar } from "../../hooks";
import Calendar from "../../pages/Calendar";
import Dashboard from "../../pages/Dashboard";
import AppYoutube from "../../pages/AppYoutube";
import AppServico from "../../pages/AppServico";
import StantiPlace from "../../pages/StantiPlace";
import EditProfile from "../../pages/EditProfile";
import AppMentoria from "../../pages/AppMentoria";
import AppCursoLive from "../../pages/AppCursoLive";
import AppStantiPay from "../../pages/AppStantiPay";
import AppAfiliados from "../../pages/AppAffiliados";
import AppVendaGrupo from "../../pages/AppVendaGrupo";
import AppLinkExterno from "../../pages/AppLinkExterno";
import PreviewProfile from "../../pages/PreviewProfile";
import AppRedesSociais from "../../pages/AppRedesSociais";
import AppVendaArquivos from "../../pages/AppVendaArquivos";
import AppGaleriaImagens from "../../pages/AppGaleriaImagens";
import AppPostsInstagram from "../../pages/AppPostsInstagram";
import DashboardStatement from "../../pages/DashboardStatement";
import PhoneStep from "../../pages/Register/components/PhoneStep";
import ColorStep from "../../pages/Register/components/ColorStep";
import AppLinkMercadoPagoAccount from "../../pages/AppLinkMercadoPagoAccount";
import ProfileImageStep from "../../pages/Register/components/ProfileImageStep";
import QualificationStep from "../../pages/Register/components/QualificationStep";
import MissingUsernameStep from "../../pages/Register/components/MissingUsernameStep";
import OtherSocialMediaStep from "../../pages/Register/components/OtherSocialMediaStep";

const PrivateRoutes: React.FC = () => {
  const { user } = Auth.useAuth();

  if (!user.username)
    return (
      <>
        <Routes>
          <Route path="/" element={<MissingUsernameStep />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    );

  if (!user.phone)
    return (
      <>
        <Routes>
          <Route path="/" element={<PhoneStep />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    );

  if (user.formStep === "profile-image")
    return (
      <>
        <Routes>
          <Route path="/" element={<ProfileImageStep />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    );

  if (user.formStep === "colors")
    return (
      <>
        <Routes>
          <Route path="/" element={<ColorStep />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    );

  if (user.formStep === "social-media")
    return (
      <>
        <Routes>
          <Route path="/" element={<OtherSocialMediaStep />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    );

  if (user.formStep === "qualification" && !user.onboarding)
    return (
      <>
        <Routes>
          <Route path="/" element={<QualificationStep />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    );

  return (
    <>
      <NavBar.Provider>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/edit" element={<EditProfile />} />

          <Route path="/apps">
            <Route index element={<StantiPlace />} />

            {AppServico()}
            {AppYoutube()}
            {AppMentoria()}
            {AppStantiPay()}
            {AppCursoLive()}
            {AppAfiliados()}
            {AppVendaGrupo()}
            {AppVendaGrupo()}
            {AppLinkExterno()}
            {AppRedesSociais()}
            {AppVendaArquivos()}
            {AppGaleriaImagens()}
            {AppPostsInstagram()}
          </Route>

          <Route
            path="/mercado-pago/:appId/:itemId"
            element={<AppLinkMercadoPagoAccount />}
          />

          <Route path="/calendar" element={<Calendar />} />

          <Route path="/edit" element={<EditProfile />} />
          <Route path="/preview" element={<PreviewProfile />} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/statement" element={<DashboardStatement />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </NavBar.Provider>
    </>
  );
};

export default PrivateRoutes;
