import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const InfoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="96"
      height="422"
      viewBox="0 0 96 422"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0Z"
        fill={color || primaryColor}
      />
      <path
        d="M48 126C21.4903 126 0 147.49 0 174V374C0 400.51 21.4903 422 48 422C74.5097 422 96 400.51 96 374V174C96 147.49 74.5097 126 48 126Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default InfoIcon;
