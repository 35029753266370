import styled from "styled-components";

export const ExternalLinkSkeleton = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
`;

export const ExternalLink = styled.div`
  height: 86px;
  opacity: 0.4;
  display: flex;
  border-radius: 18px;
  align-items: center;
  justify-content: space-between;
  background-blend-mode: multiply;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    275deg,
    rgba(250, 250, 250, 0.39) -160%,
    rgba(113, 114, 111, 0) 120%
  );

  @for $i from 1 through 3 {
    &:nth-child(#{$i}n) {
      animation: opacity 0.6s ease #{$i * 0.2}s infinite forwards;
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
`;
