export const weekDays = {
  0: "Domingo",
  1: "Segunda-feira",
  2: "Terça-feira",
  3: "Quarta-feira",
  4: "Quinta-feira",
  5: "Sexta-feira",
  6: "Sábado",
};

export const duration = [
  { text: "30min", durationFormat: "0:30" },
  { text: "1h", durationFormat: "1:00" },
  { text: "1h30", durationFormat: "1:30" },
  { text: "2h", durationFormat: "2:00" },
  { text: "2h30", durationFormat: "2:30" },
  { text: "3h", durationFormat: "3:00" },
  { text: "3h30", durationFormat: "3:30" },
  { text: "4h", durationFormat: "4:00" },
];
