import Input from "../../../../components/atoms/Input";
import { ICreatorData } from "../../../../types";
import { maskPhoneNumber } from "../../../../utils/numbers";

interface IProps {
  phone: ICreatorData["phone"];
  onChangePhone: (value: string) => void;
}

const EditPhone: React.FC<IProps> = ({ phone, onChangePhone }) => {
  const normalizePhone = (value: string) => {
    return value.replace(/[^0-9]/g, "");
  };

  return (
    <Input
      placeholder="Telefone: (99) 99999-9999"
      value={maskPhoneNumber(phone || "")}
      onChange={(phone) => onChangePhone(normalizePhone(phone))}
    />
  );
};

export default EditPhone;
