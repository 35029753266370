import axios from "axios";
import { IAppProduct } from "../../types";
import { logoutUserIfTokenInvalid } from "../../utils";

const { REACT_APP_APPS_API_URL } = process.env;

const createAppProduct = async (
  token: string,
  item: IAppProduct | Record<string, never>
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/stanti-pay`,
      item,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar o novo produto");
  }
};

const updateAppProduct = async (
  token: string,
  itemId: string,
  item: IAppProduct | Record<string, never>
) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_APPS_API_URL}/stanti-pay/${itemId}`,
      item,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar as alteraçoes do item");
  }
};

const getAppProduct = async (
  token: string,
  productId: string
): Promise<IAppProduct> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_APPS_API_URL}/stanti-pay/?productId=${productId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter o produto");
  }
};

const getAppProducts = async (token: string): Promise<IAppProduct[]> => {
  try {
    const { data } = await axios.get(`${REACT_APP_APPS_API_URL}/stanti-pay`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.items;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os produtos");
  }
};

const removeAppProduct = async (token: string, itemId: string) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_APPS_API_URL}/stanti-pay/${itemId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.id;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao remover o produto");
  }
};

export default {
  getAppProduct,
  getAppProducts,
  removeAppProduct,
  createAppProduct,
  updateAppProduct,
};
