import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const NotebookIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.98 24C43.98 26.2 42.2 28 40 28H46C47.1 28 48 28.9 48 30C48 31.1 47.1 32 46 32H2C0.9 32 0 31.1 0 30C0 28.9 0.9 28 2 28H8C5.8 28 4 26.2 4 24V4C4 1.8 5.8 0 8 0H40C42.2 0 44 1.8 44 4L43.98 24ZM38 4H10C8.9 4 8 4.9 8 6V22C8 23.1 8.9 24 10 24H38C39.1 24 40 23.1 40 22V6C40 4.9 39.1 4 38 4Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default NotebookIcon;
