import styled from "styled-components";

export const ShcedulingSwitcher = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: space-between;
`;
