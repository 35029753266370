import { Theme } from "../../../hooks";

import * as S from "./styles";

interface IProps {
  color?: string;
  progress: number;
  quantityOfBars: number;
}

const StepsProgressBar: React.FC<IProps> = ({
  color,
  progress,
  quantityOfBars,
}) => {
  const { primaryColor } = Theme.useTheme();

  const currentColor = color || primaryColor;

  const currentProgress = progress < 0 ? 0 : progress > 100 ? 100 : progress;

  const progressByBar = 100 / quantityOfBars;

  const fullBars = Math.floor(currentProgress / progressByBar);

  const halfBar = (currentProgress / progressByBar - fullBars) * 100;

  return (
    <S.StepsProgressBar>
      {Array(quantityOfBars)
        .fill(null)
        .map((_, index) => (
          <S.BarContainer key={`bar#${index}`}>
            <S.Bar
              backgroundColor={currentColor}
              width={
                index + 1 <= fullBars
                  ? "100%"
                  : index + 1 === fullBars + 1 && halfBar
                  ? `${halfBar}%`
                  : "0%"
              }
            />
          </S.BarContainer>
        ))}
    </S.StepsProgressBar>
  );
};

export default StepsProgressBar;
