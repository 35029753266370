import styled from "styled-components";

export const StantiPlaceCta = styled.div`
  gap: 20px;
  display: flex;
  margin-top: 32px;
  max-width: 320px;
  align-self: center;
  text-align: center;
  margin-bottom: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const NoSalesText = styled.div`
  display: flex;
  padding: 30px 0 10px 0;
  justify-content: center;
`;

export const SalesList = styled.div`
  display: flex;
  flex-direction: column;
`;
