import styled from "styled-components";

export const LinkSection = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;

  p {
    line-height: 140%;
  }
`;
