import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CartIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.84931 3.69863H3.69863L10.3562 17.7349L7.85958 22.2472C6.50958 24.7253 8.28492 27.7397 11.0959 27.7397H31.4383C32.4554 27.7397 33.2876 26.9075 33.2876 25.8904C33.2876 24.8733 32.4554 24.0411 31.4383 24.0411H11.0959L13.1301 20.3424H26.9075C28.2945 20.3424 29.515 19.5842 30.1438 18.4377L36.7643 6.43561C37.4486 5.21506 36.5609 3.69863 35.1554 3.69863H7.78561L6.54657 1.05411C6.25068 0.406849 5.58493 0 4.88219 0H1.84931C0.832191 0 0 0.832191 0 1.84931C0 2.86644 0.832191 3.69863 1.84931 3.69863Z"
        fill={color || primaryColor}
      />
      <path
        d="M11.0952 29.589C9.06099 29.589 7.4151 31.2534 7.4151 33.2876C7.4151 35.3219 9.06099 36.9862 11.0952 36.9862C13.1295 36.9862 14.7939 35.3219 14.7939 33.2876C14.7939 31.2534 13.1295 29.589 11.0952 29.589Z"
        fill={color || primaryColor}
      />
      <path
        d="M25.9082 33.2876C25.9082 31.2534 27.5541 29.589 29.5884 29.589C31.6226 29.589 33.287 31.2534 33.287 33.2876C33.287 35.3219 31.6226 36.9862 29.5884 36.9862C27.5541 36.9862 25.9082 35.3219 25.9082 33.2876Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CartIcon;
