import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CreatorImageBackground from "../../components/atoms/CreatorImageBackground";
import Page from "../../components/atoms/Page";
import { Auth } from "../../hooks";
import { Analytics } from "../../services";
import Header from "./components/Header";
import WorkHours from "./components/WorkHours";
import YourAppointments from "./components/YourAppointments";

import * as S from "./styles";

const Calendar: React.FC = () => {
  const navigate = useNavigate();
  const { user } = Auth.useAuth();

  useEffect(() => {
    Analytics.submitPageViewEvent({
      origin: "calendar",
      creator: user.id || "",
      creatorUsername: user.username || "",
      description: "agenda-and-appointments",
      actionDescription: "agenda-and-appointments",
    });
  }, [user.id, user.username]);

  const onClickBack = () => {
    navigate("/");
  };

  return (
    <Page>
      <CreatorImageBackground />

      <Header onClickBack={() => onClickBack()} />

      <WorkHours />

      <S.CalendarSeparatorLine />

      <YourAppointments />
    </Page>
  );
};

export default Calendar;
