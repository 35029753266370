import styled from "styled-components";

type BackgroundImageProps = {
  imageUrl: string;
};

export const BackgroundImage = styled.div<BackgroundImageProps>`
  background-size: cover;
  border-radius: 50%;
  width: 960px;
  height: 960px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: -80px;
  filter: blur(80px);
  opacity: 0.32;
  pointer-events: none;
  background-image: url(${({ imageUrl }) => imageUrl});
`;
