import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const YoutubeIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.251 0.343331C14.9395 0.532246 15.4816 1.08888 15.6656 1.79576C16 3.0769 16 5.75 16 5.75C16 5.75 16 8.42301 15.6656 9.70424C15.4816 10.4111 14.9395 10.9678 14.251 11.1568C13.0034 11.5 8 11.5 8 11.5C8 11.5 2.99664 11.5 1.74891 11.1568C1.06045 10.9678 0.518273 10.4111 0.334273 9.70424C0 8.42301 0 5.75 0 5.75C0 5.75 0 3.0769 0.334273 1.79576C0.518273 1.08888 1.06045 0.532246 1.74891 0.343331C2.99664 0 8 0 8 0C8 0 13.0034 0 14.251 0.343331ZM6.5 3.5V8.5L10.5 6.0001L6.5 3.5Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default YoutubeIcon;
