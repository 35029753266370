import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const EditTwoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3796 6.9052C36.0458 6.23902 36.0458 5.16288 35.3796 4.4967L31.3821 0.499611C31.0629 0.179754 30.6296 0 30.1777 0C29.7258 0 29.2925 0.179754 28.9733 0.499611L25.625 3.8476L32.0312 10.2532L35.3796 6.9052ZM30.3236 11.9613L23.9174 5.55568L7.09029 22.381C6.91945 22.5518 6.83404 22.7568 6.83404 22.996V28.1888C6.83404 28.667 7.20987 29.0428 7.6882 29.0428H12.8815C13.1036 29.0428 13.3257 28.9574 13.4795 28.7866L30.3236 11.9613ZM37.5833 34.1675H3.41667C1.5375 34.1675 0 35.7049 0 37.5839C0 39.4628 1.5375 41.0002 3.41667 41.0002H37.5833C39.4625 41.0002 41 39.4628 41 37.5839C41 35.7049 39.4625 34.1675 37.5833 34.1675Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default EditTwoIcon;
