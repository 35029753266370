import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PinterestIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.48399 0C2.16979 0 0 3.08973 0 5.66556C0 7.22628 0.59003 8.61571 1.85891 9.1296C2.06828 9.21208 2.25227 9.1296 2.31571 8.9012C2.36012 8.74259 2.45529 8.3429 2.4997 8.1716C2.56314 7.9432 2.53776 7.86707 2.36646 7.66404C1.99849 7.23263 1.77009 6.67432 1.77009 5.88761C1.77009 3.59728 3.48308 1.54169 6.23655 1.54169C8.67281 1.54169 10.0115 3.03263 10.0115 5.01843C10.0115 7.63232 8.85679 9.84018 7.13746 9.84018C6.1858 9.84018 5.47522 9.05347 5.70362 8.09546C5.97643 6.94713 6.50302 5.70362 6.50302 4.87885C6.50302 4.13655 6.10332 3.5148 5.27855 3.5148C4.30785 3.5148 3.52749 4.51722 3.52749 5.86223C3.52749 6.71873 3.81933 7.29607 3.81933 7.29607C3.81933 7.29607 2.82326 11.5088 2.65196 12.2447C2.30302 13.7103 2.60121 15.5121 2.62659 15.6897C2.63927 15.7976 2.77885 15.823 2.84229 15.7405C2.93112 15.6263 4.0858 14.1988 4.47915 12.7713C4.59335 12.3653 5.11994 10.2779 5.11994 10.2779C5.43716 10.8807 6.3571 11.4072 7.33414 11.4072C10.2526 11.4072 12.2257 8.74894 12.2257 5.18973C12.2257 2.50604 9.94803 0 6.48399 0Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PinterestIcon;
