import AppLinkButton from "../../../../components/atoms/AppLinkButton";
import { TIconOptions } from "../../../../types";

import * as S from "./styles";

const customizationApps = [
  {
    app: "galeria-imagens",
    icon: "personPics",
    link: "/apps/galeria-imagens/new",
    title: "Adicione galerias de imagens",
  },
  {
    icon: "instagram",
    app: "link-to-post-instagram",
    link: "/apps/link-to-post-instagram",
    title: "Compartilhe seus posts do Instagram",
  },
  {
    app: "youtube",
    icon: "youtube",
    link: "/apps/youtube",
    title: "Exiba vídeos ou canais do Youtube",
  },
];

interface IProps {
  onClick: (app: string) => void;
}

const CustomizeAppList: React.FC<IProps> = ({ onClick }) => {
  return (
    <S.CustomizeAppListContainer>
      {customizationApps.map((app) => (
        <AppLinkButton
          key={app.link}
          link={app.link}
          icon={app.icon as TIconOptions}
          title={app.title}
          onClick={() => onClick(app.app)}
        />
      ))}
    </S.CustomizeAppListContainer>
  );
};

export default CustomizeAppList;
