import { Theme } from "../../../hooks";
import NavigationArrow from "../NavigationArrow";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  onClick: () => void;
  selectedPostsAmount: number;
}

const AheadButton: React.FC<IProps> = ({ onClick, selectedPostsAmount }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const selectedPostsDescription = (() => {
    if (selectedPostsAmount === 1) return "1 post selecionado";

    return `${selectedPostsAmount} posts selecionados`;
  })();

  return (
    <S.ButtonContainer>
      <S.Container>
        <P color={textColor}>{selectedPostsDescription}</P>

        <NavigationArrow
          onClick={onClick}
          buttonStyle="solid"
          color={primaryColor}
        />
      </S.Container>
    </S.ButtonContainer>
  );
};

export default AheadButton;
