import styled from "styled-components";
import P from "../../../../components/atoms/Typography/P";

export const Description = styled(P)`
  padding: 30px 0 40px;
`;

export const Images = styled.div`
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
`;
