import styled from "styled-components";

export const HeaderContainer = styled.header`
  z-index: 1;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;

  img {
    width: 180px;
    margin-bottom: -16px;
  }

  span {
    color: #fafafa;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 50%;

  background-blend-mode: overlay;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(
    155deg,
    rgba(250, 250, 250, 0.17) 10%,
    rgba(250, 250, 250, 0) 35%
  );

  svg {
    width: 16px;
    height: auto;
  }
`;
