import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const VoiceOverIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="48"
      height="40"
      viewBox="0 0 48 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5931 3.89511C37.8876 2.91159 38.0159 1.58597 38.8711 0.730729C39.9188 -0.316938 41.7148 -0.231415 42.6128 0.944539C48.5139 8.74859 48.4925 19.0756 42.6128 26.6445C41.7148 27.8204 39.9401 27.9059 38.8925 26.8583L38.8283 26.7941C37.9945 25.9603 37.8448 24.6346 38.529 23.6725C42.7838 17.6003 42.8052 9.7535 38.5931 3.89511ZM8.55275 13.7945C8.55275 9.0711 12.3818 5.24207 17.1051 5.24207C21.8285 5.24207 25.6575 9.0711 25.6575 13.7945C25.6575 18.5178 21.8285 22.3468 17.1051 22.3468C12.3818 22.3468 8.55275 18.5178 8.55275 13.7945ZM0 35.1756C0 29.4883 11.3961 26.6232 17.1048 26.6232C22.8135 26.6232 34.2095 29.4883 34.2095 35.1756V37.3137C34.2095 38.4897 33.2474 39.4518 32.0714 39.4518H2.1381C0.962143 39.4518 0 38.4897 0 37.3137V35.1756ZM30.9381 11.1647C31.6223 12.8538 31.6223 14.7354 30.9381 16.4245C30.5319 17.4294 30.7029 18.5626 31.4727 19.3323L31.5368 19.3964C32.7769 20.6365 34.8936 20.3799 35.7061 18.8191C37.331 15.7189 37.331 12.0841 35.6633 8.85559C34.8508 7.2734 32.7128 6.97407 31.4513 8.23555L31.4299 8.25693C30.7029 9.00526 30.5319 10.1598 30.9381 11.1647Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default VoiceOverIcon;
