import { useNavigate } from "react-router-dom";
import H2 from "../../../../components/atoms/Typography/H2";

import { Auth } from "../../../../hooks";

import * as S from "./styles";

const Header: React.FC = () => {
  const { user } = Auth.useAuth();

  const navigate = useNavigate();

  const firstName = user.name?.split(" ")[0];

  return (
    <S.Header>
      <H2 color="#fafafa">
        Olá, <S.Highlight>{firstName}</S.Highlight>!
      </H2>

      <S.ProfileImage
        imageUrl={user.profileImage as string}
        onClick={() => navigate("/edit")}
      />
    </S.Header>
  );
};

export default Header;
