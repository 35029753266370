import { ReactElement } from "react";
import { Theme } from "../../../hooks";

import P from "../../atoms/Typography/P";
import LinkedinIcon from "../../icons/LinkedinIcon";
import TiktokIcon from "../../icons/TiktokIcon";

import * as S from "./styles";

interface IProps {
  onClose: () => void;
  onManuallyImport: () => void;
  onInstagramImport: () => void;
  socialMedia: "linkedin" | "tiktok";
}

const SocialMediaErrorModal: React.FC<IProps> = ({
  onClose,
  socialMedia,
  onManuallyImport,
  onInstagramImport,
}) => {
  const { textColor } = Theme.useTheme();

  const modalInfo: {
    [key in IProps["socialMedia"]]: { label: string; icon: ReactElement };
  } = {
    linkedin: {
      label: "LinkedIn",
      icon: <LinkedinIcon color="#fafafa" />,
    },
    tiktok: {
      label: "TikTok",
      icon: <TiktokIcon color="#fafafa" />,
    },
  };

  return (
    <S.Modal onClick={onClose}>
      <S.Container onClick={(e) => e.stopPropagation()}>
        <S.Icon>{modalInfo[socialMedia].icon}</S.Icon>

        <S.Text>
          <P color={textColor}>
            Ainda não é possível importar sua foto do{" "}
            {modalInfo[socialMedia].label}. Estamos trabalhando para implementar
            o mais rápido possível.
          </P>

          <P color={textColor}>Enquanto isso você pode:</P>
        </S.Text>

        <S.ImportButtons>
          <S.InstagramButton onClick={onInstagramImport}>
            Importar foto de perfil do Instagram
          </S.InstagramButton>

          <S.ManualButton onClick={onManuallyImport}>
            Importar outra foto
          </S.ManualButton>
        </S.ImportButtons>
      </S.Container>
    </S.Modal>
  );
};

export default SocialMediaErrorModal;
