import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const KeyUpIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="42"
      height="38"
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.55836 18.4231C6.2854 12.8548 13.1307 11.3848 18.5356 13.9685L30.9343 1.56975C32.5677 -0.0636142 35.2405 -0.0636142 36.8738 1.56975C38.5072 3.20311 38.5072 5.87589 36.8738 7.50925L39.8436 10.479C41.477 12.1124 41.477 14.7851 39.8436 16.4185C38.2102 18.0519 35.5375 18.0519 33.9041 16.4185L30.9343 13.4488L24.4751 19.908C26.7173 24.5853 25.9006 30.3615 22.0251 34.237C16.4716 39.7905 7.01299 39.0629 2.48412 32.0988C-0.203506 27.9857 -0.15896 22.5065 2.55836 18.4231ZM10.1464 28.2979C11.7798 29.9312 14.4526 29.9312 16.0859 28.2979C17.7193 26.6645 17.7193 23.9917 16.0859 22.3584C14.4526 20.725 11.7798 20.725 10.1464 22.3584C8.51306 23.9917 8.51306 26.6645 10.1464 28.2979Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default KeyUpIcon;
