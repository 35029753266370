import { Auth, Theme } from "../../../hooks";
import { Analytics } from "../../../services";
import { TIconOptions } from "../../../types";
import AvailableIcons from "../AvailableIcons";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  icon?: TIconOptions;
  isExternalLink?: boolean;
  label?: string;
  image?: string;
  link?: string;
  id: string;
  onClose: (id: string) => void;
}

const Banner: React.FC<IProps> = ({
  isExternalLink = true,
  icon,
  label,
  image = "https",
  link,
  id,
  onClose,
}) => {
  const { user } = Auth.useAuth();
  const { primaryColor, textColor } = Theme.useTheme();

  const onClickHandler = () => {
    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      description: `click-on-banner-${id}`,
      actionDescription: `click-on-banner-${id}`,
    });

    onClose(id);
  };

  if (image)
    return (
      <S.ImageBanner image={image}>
        <div className="container">
          <a
            href={link || ""}
            onClick={onClickHandler}
            target={isExternalLink ? "_blank" : "_self"}
            rel={isExternalLink ? "noopener noreferrer" : ""}
          />

          <S.CloseButton onClick={() => onClose(id)}>
            <AvailableIcons option="close" />
          </S.CloseButton>
        </div>
      </S.ImageBanner>
    );

  const bannerWithLink = (
    <S.Banner borderColor={primaryColor}>
      <S.BannerLink
        to={link || ""}
        onClick={() => onClickHandler()}
        target={isExternalLink ? "_blank" : "_self"}
        rel={isExternalLink ? "noopener noreferrer" : ""}
      >
        <div className="iconContainer">
          {icon ? <AvailableIcons option={icon} color={primaryColor} /> : null}
        </div>

        <S.Label borderColor={primaryColor} color={primaryColor}>
          {label}
        </S.Label>
      </S.BannerLink>

      <S.CloseButton onClick={() => onClose(id)}>
        <AvailableIcons option="close" />
      </S.CloseButton>
    </S.Banner>
  );
  const bannerWithoutLink = (
    <S.Banner>
      <S.Content as="div">
        <div className="iconContainer">
          {icon ? <AvailableIcons option={icon} color={primaryColor} /> : null}
        </div>

        <P color={textColor}>{label}</P>
      </S.Content>

      <S.CloseButton onClick={() => onClose(id)}>
        <AvailableIcons option="close" />
      </S.CloseButton>
    </S.Banner>
  );

  return link ? bannerWithLink : bannerWithoutLink;
};

export default Banner;
