import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TrashCanIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="36"
      height="49"
      viewBox="0 0 36 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29091 0H27.7091V4.27517H36V10.6331H0V4.27517H8.29091V0Z"
        fill={color || primaryColor}
      />
      <path
        d="M1.58957 17.2047L3.99961 47.1857C4.08199 48.2105 4.93369 49 5.95687 49H29.6299C30.6435 49 31.4905 48.2249 31.585 47.2109L34.3807 17.2299C34.4886 16.0726 33.5824 15.0727 32.4256 15.0727H3.54683C2.4 15.0727 1.49723 16.0561 1.58957 17.2047Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TrashCanIcon;
