import { Theme } from "../../../../hooks";
import { ICreatorData } from "../../../../types";

import * as S from "./styles";

interface IProps {
  onChange: (color: string) => void;
  value: ICreatorData["primaryColor"];
  selectedBackgroundColor: ICreatorData["backgroundColor"];
}

const primaryColors = [
  {
    id: "1",
    color: "#DDFF66",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "2",
    color: "#FF9960",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "3",
    color: "#00FFB2",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "4",
    color: "#A0D7FF",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "5",
    color: "#FFA8BD",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "6",
    color: "#FAFAFA",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "7",
    color: "#A091FF",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "8",
    color: "#FFC700",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
];

const PrimarySelectorList: React.FC<IProps> = ({
  value,
  onChange,
  selectedBackgroundColor,
}) => {
  const { primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <S.PrimarySelectorList>
      {primaryColors
        .filter(({ allowedWith }) =>
          allowedWith.includes(selectedBackgroundColor || "")
        )
        .map(({ id, color }) => (
          <S.PrimarySelectorItem key={id} onClick={() => onChange(color)}>
            <S.Preview backgroundColor={color} />

            <S.Selector
              borderColor={value === color ? primaryColor : backgroundColor}
              isSelected={value === color}
            >
              <S.Indicator
                backgroundColor={value === color ? primaryColor : "transparent"}
              />
            </S.Selector>
          </S.PrimarySelectorItem>
        ))}
    </S.PrimarySelectorList>
  );
};

export default PrimarySelectorList;
