import * as S from "./styles";

interface IProps {
  color: string;
  angle?: number;
  onClick?: () => void;
  buttonStyle: "outline" | "solid";
}

const NavigationArrow: React.FC<IProps> = ({
  color,
  onClick,
  angle = 0,
  buttonStyle,
}) => {
  return (
    <S.NavigationArrow
      data-testid="navigation-arrow"
      onClick={onClick}
      borderColor={color}
      backgroundColor={buttonStyle === "outline" ? "transparent" : color}
      angle={angle}
    >
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 44 43"
        xmlSpace="preserve"
        fill={buttonStyle === "outline" ? color : "black"}
      >
        <path
          d="M2.8,24.3l31.5,0L20.6,38.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0l18.6-18.7c1.1-1.1,1.1-2.9,0-4L24.6,0.8
            c-0.5-0.5-1.2-0.8-2-0.8c-0.7,0-1.5,0.3-2,0.8c-1.1,1.1-1.1,2.9,0,4l13.8,13.8l-31.5,0c-1.6,0-2.8,1.3-2.8,2.8
            C0,23.1,1.3,24.3,2.8,24.3z"
        />
      </svg>
    </S.NavigationArrow>
  );
};

export default NavigationArrow;
