import styled, { css } from "styled-components";

type ImageListProps = {
  isMobileDragOn: boolean;
  coordinateX: number;
  coordinateY: number;
};

export const ImageList = styled.div<ImageListProps>`
  display: flex;
  flex-direction: column;

  ${({ isMobileDragOn, coordinateX, coordinateY }) =>
    isMobileDragOn &&
    (coordinateX || coordinateY) &&
    css`
      position: absolute;
      top: ${coordinateY}px;
      left: ${coordinateX}px;
      transition: none;
      width: calc(100% - 48px);
    `}
`;

type ImageItemProps = {
  isMobileDragOn: boolean;
};

export const ImageItem = styled.div<ImageItemProps>`
  width: 100%;
  border-radius: 16px;
  display: flex;
  border-top: 1px solid #fafafa20;
  border-left: 1px solid #fafafa10;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.3) 200%
  );

  ${({ isMobileDragOn }) =>
    isMobileDragOn &&
    css`
      opacity: 0.5;
    `}
`;

const BaseIcon = styled.div`
  gap: 4px;
  width: 40px;
  display: flex;
  flex-shrink: 0;
  padding: 0 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  span {
    width: 100%;
    height: 2px;
    background-color: #fafafa40;
  }
`;

export const DragIcon = styled(BaseIcon)`
  touch-action: none;
`;

export const RemoveIcon = styled(BaseIcon)`
  cursor: pointer;
`;

export const ImageContent = styled.div`
  gap: 10px;
  flex-grow: 1;
  padding: 16px;
  display: flex;
  border-radius: 16px;
  align-items: center;
  border-top: 1px solid #fafafa20;
  border-left: 1px solid #fafafa10;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.025) 0%,
    rgba(255, 255, 255, 0.15) 200%
  );
`;

export const TextAndImage = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelAndName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

type ImageProps = {
  imageUrl: string;
  width: number;
  height: number;
};

export const Image = styled.div<ImageProps>`
  ${({ imageUrl, width, height }) => css`
    border-radius: 8px;
    align-self: flex-end;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${imageUrl});
    width: ${width}px;
    height: ${height}px;
  `}
`;

export const Link = styled.div`
  flex-grow: 1;
  display: flex;
`;
