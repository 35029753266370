import styled from "styled-components";

export const FormCalendar = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const SchedulingOption = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: column;
`;
