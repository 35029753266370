import { Theme } from "../../../hooks";
import TextArea from "../../atoms/TextArea";
import P from "../../atoms/Typography/P";

import * as S from "./styles";

interface IProps {
  value: string;
  charLimit?: number;
  placeholder?: string;
  textAreaLabel: string;
  onChange: (val: string) => void;
}

const TextAreaWithLabel: React.FC<IProps> = ({
  value,
  onChange,
  charLimit,
  placeholder,
  textAreaLabel,
}) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.TextAreaWithLabel>
      <P color={textColor}>{textAreaLabel}</P>

      <TextArea
        value={value}
        onChange={onChange}
        charLimit={charLimit}
        placeholder={placeholder}
      />
    </S.TextAreaWithLabel>
  );
};

export default TextAreaWithLabel;
