import { DragEventHandler, TouchEventHandler } from "react";

import { formatData } from "../../../pages/AppGaleriaImagens/components/Form";
import { TImageGalleryOrientation } from "../../../types";
import CloseIcon from "../../icons/CloseIcon";
import LinkIcon from "../../icons/LinkIcon";
import DropZone from "../../molecules/DropZone";
import Input from "../Input";

import * as S from "./styles";

interface IProps {
  galleryItem: {
    url: string;
    link?: string;
  };
  isMobileDragOn: boolean;
  onDropHandler: () => void;
  isDropZoneHovered: boolean;
  removeImageHandler: () => void;
  setLink: (value: string) => void;
  coordinates: { x: number; y: number };
  selectedFormat: TImageGalleryOrientation;
  onDragEnd: DragEventHandler<HTMLDivElement>;
  onDragStart: DragEventHandler<HTMLDivElement>;
  onDragEnter: DragEventHandler<HTMLDivElement>;
  onDragLeave: DragEventHandler<HTMLDivElement>;
  touchEndHandler: TouchEventHandler<HTMLDivElement>;
  touchMoveHandler: TouchEventHandler<HTMLDivElement>;
  touchStartHandler: TouchEventHandler<HTMLDivElement>;
  touchCancelHandler: TouchEventHandler<HTMLDivElement>;
}

const AppImagesCard: React.FC<IProps> = ({
  setLink,
  onDragEnd,
  onDragEnter,
  galleryItem,
  onDragLeave,
  onDragStart,
  coordinates,
  onDropHandler,
  selectedFormat,
  isMobileDragOn,
  touchEndHandler,
  touchMoveHandler,
  isDropZoneHovered,
  touchStartHandler,
  touchCancelHandler,
  removeImageHandler,
}) => {
  return (
    <S.ImageList
      onDrop={onDropHandler}
      onDragEnd={onDragLeave}
      onDragEnter={onDragEnter}
      onDragOver={(e) => e.preventDefault()}
      isMobileDragOn={isMobileDragOn}
      coordinateX={coordinates.x}
      coordinateY={coordinates.y}
    >
      <S.ImageItem
        draggable
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        isMobileDragOn={isMobileDragOn}
      >
        <S.DragIcon
          onTouchEnd={touchEndHandler}
          onTouchMove={touchMoveHandler}
          onTouchStart={touchStartHandler}
          onTouchCancel={touchCancelHandler}
        >
          <span />
          <span />
          <span />
        </S.DragIcon>

        <S.ImageContent>
          <S.TextAndImage>
            <S.Image
              imageUrl={galleryItem.url}
              width={
                formatData[selectedFormat].width *
                (selectedFormat === "landscape" ? 0.24 : 0.15)
              }
              height={
                formatData[selectedFormat].height *
                (selectedFormat === "landscape" ? 0.24 : 0.15)
              }
            />
          </S.TextAndImage>

          <S.Link>
            <Input
              onChange={setLink}
              leftIcon={<LinkIcon />}
              placeholder="Link (opcional)"
              value={galleryItem.link || ""}
            />
          </S.Link>
        </S.ImageContent>

        <S.RemoveIcon onClick={removeImageHandler}>
          <CloseIcon />
        </S.RemoveIcon>
      </S.ImageItem>

      <DropZone isDroppable={false} isHovered={isDropZoneHovered} />
    </S.ImageList>
  );
};

export default AppImagesCard;
