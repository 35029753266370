import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LockClosedIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="31"
      height="40"
      viewBox="0 0 31 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6667 13.3333H24.7619V9.52381C24.7619 4.26667 20.4952 0 15.2381 0C9.98095 0 5.71429 4.26667 5.71429 9.52381V13.3333H3.80952C1.71429 13.3333 0 15.0476 0 17.1429V36.1905C0 38.2857 1.71429 40 3.80952 40H26.6667C28.7619 40 30.4762 38.2857 30.4762 36.1905V17.1429C30.4762 15.0476 28.7619 13.3333 26.6667 13.3333ZM15.2381 30.4762C13.1429 30.4762 11.4286 28.7619 11.4286 26.6667C11.4286 24.5714 13.1429 22.8571 15.2381 22.8571C17.3333 22.8571 19.0476 24.5714 19.0476 26.6667C19.0476 28.7619 17.3333 30.4762 15.2381 30.4762ZM9.52381 9.52381V13.3333H20.9524V9.52381C20.9524 6.3619 18.4 3.80952 15.2381 3.80952C12.0762 3.80952 9.52381 6.3619 9.52381 9.52381Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default LockClosedIcon;
