import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AppFormAddIcon from "../../../../components/atoms/AppFormAddIcon";
import AppFormDateAndTime, {
  IDate,
  ITime,
} from "../../../../components/atoms/AppFormDateAndTime";
import AppFormDescription from "../../../../components/atoms/AppFormDescription";
import AppFormFAQ from "../../../../components/atoms/AppFormFAQ";
import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import AppFormMedia from "../../../../components/atoms/AppFormMedia";
import AppFormPrice from "../../../../components/atoms/AppFormPrice";
import AppFormProductLinkInfo from "../../../../components/atoms/AppFormProductLinkInfo";
import AppFormTags from "../../../../components/atoms/AppFormTags";
import AppFormTitle from "../../../../components/atoms/AppFormTitle";
import AppFormVacancySelector from "../../../../components/atoms/AppFormVacancySelector";
import Page from "../../../../components/atoms/Page";
import AppFormFooter from "../../../../components/molecules/AppFormFooter";
import AppFormSection from "../../../../components/molecules/AppFormSection";
import appConfig from "../../../../config/app-mentoria.json";
import { App, Auth } from "../../../../hooks";
import { Calendar } from "../../../../services";
import { IAppItem } from "../../../../types";
import { ICalendar, TSchedulingOption } from "../../../../types/calendar";

const MIN_VACANCY = 2;
const MAX_VACANCY = 100;

const validate = (
  data: IAppItem,
  scheduleOption: TSchedulingOption
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.title)
    errors.title = [...(errors.title || []), "Título obrigatório"];

  if (!data.price || data.price < 1)
    errors.price = [...(errors.price || []), "O valor mínimo é R$ 1"];

  if (scheduleOption !== "agenda") {
    if (!data.isUnlimited && (!data.vacancy || data.vacancy < MIN_VACANCY))
      errors.vacancy = [
        ...(errors.vacancy || []),
        `A quantidade mínima de vagas é ${MIN_VACANCY}`,
      ];

    if (!data.isUnlimited && data.vacancy && data.vacancy > MAX_VACANCY)
      errors.vacancy = [
        ...(errors.vacancy || []),
        `A quantidade máxima de vagas é ${MAX_VACANCY}`,
      ];
  }

  if (scheduleOption === "defined-dates") {
    const datesErrors = (data.dates || []).map((date) => {
      try {
        const start = new Date(date.start);
        const end = new Date(date.end);

        if (end.getTime() <= start.getTime()) return true;

        return false;
      } catch (error) {
        return true;
      }
    });

    if (datesErrors.some((err) => !!err))
      errors.dates = [...(errors.dates || []), "Os horários são inválidos"];

    if (!data.dates || !data.dates.length)
      errors.dates = [
        ...(errors.dates || []),
        "O preenchimento das datas é obrigatório",
      ];
  } else {
    if (!data.duration)
      errors.duration = [
        ...(errors.duration || []),
        "A seleção da duração é obrigatória",
      ];

    if (!data.numberOfSessions || data.numberOfSessions < 1)
      errors.numberOfSessions = [
        ...(errors.numberOfSessions || []),
        "Número de sessões deve ser maior ou igual a 1",
      ];
  }

  return errors;
};

const normalizeData = (
  data: IForm,
  schedulingOption?: TSchedulingOption
): IAppItem => {
  const newData = { ...data };

  if (!newData.faq || !newData.faq.length) delete newData.faq;
  if (!newData.media || !newData.media.length) delete newData.media;
  if (!newData.tags || !newData.tags.length) delete newData.tags;
  if (schedulingOption === "defined-dates") {
    delete newData.numberOfSessions;
    delete newData.duration;
  }

  if (data.isUnlimited) delete newData.vacancy;

  const dates: IAppItem["dates"] =
    schedulingOption === "defined-dates"
      ? (newData.dates || [])
          .filter((date) => !!date.day && !!date.month && !!date.year)
          .map((date) => {
            const day = +date.day;
            const month = +date.month - 1;
            const year = +date.year;

            const startHours = newData.isAllDay
              ? 0
              : +(newData.time?.start.hours || "");
            const startMinutes = newData.isAllDay
              ? 0
              : +(newData.time?.start.minutes || "");

            const endHours = newData.isAllDay
              ? 23
              : +(newData.time?.end.hours || "");
            const endMinutes = newData.isAllDay
              ? 59
              : +(newData.time?.end.minutes || "");

            const startDate = new Date();
            startDate.setMonth(month);
            startDate.setDate(day);
            startDate.setHours(startHours);
            startDate.setMinutes(startMinutes);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            startDate.setFullYear(year);

            const endDate = new Date();
            endDate.setMonth(month);
            endDate.setDate(day);
            endDate.setHours(endHours);
            endDate.setMinutes(endMinutes);
            endDate.setSeconds(0);
            endDate.setMilliseconds(0);
            endDate.setFullYear(year);

            return {
              start: startDate.toJSON(),
              end: endDate.toJSON(),
            };
          })
      : [{ start: "", end: "" }];

  delete newData.isAllDay;
  delete newData.time;
  delete newData.dates;

  return { ...newData, dates, schedulingOption };
};

interface IForm extends Omit<IAppItem, "dates"> {
  dates?: IDate[];
  time?: {
    start: ITime;
    end: ITime;
  };
  isAllDay?: boolean;
}

const Form: React.FC = () => {
  const { id } = useParams();

  const [item, setItem] = useState<IForm>({
    dates: [{ day: "", month: "", year: "" }],
  });
  const [schedulingOption, setSchedulingOption] =
    useState<TSchedulingOption>("defined-dates");
  const [calendar, setCalendar] = useState<ICalendar | null>(null);
  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [agendaModalOpen, setAgendaModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const { token } = Auth.useAuth();
  const { getItem, saveItemHandler, removeItemHandler } = App.useApp();

  useEffect(() => {
    const run = async () => {
      const [itemData, calendar] = await Promise.all([
        id ? getItem(appConfig.id, id) : null,
        Calendar.getCalendar(token),
      ]);

      setCalendar(calendar || null);

      if (!itemData) return;

      const dates = (): IForm["dates"] =>
        (itemData?.dates || []).map((date) => {
          if (!date.start) return { year: "", day: "", month: "" };

          const parsedDate = new Date(date.start);

          const day = String(parsedDate.getDate());
          const month = String(parsedDate.getMonth() + 1);
          const year = String(parsedDate.getFullYear());

          const normalizedDay = day.length === 1 ? `0${day}` : day;
          const normalizedMonth = month.length === 1 ? `0${month}` : month;

          return {
            year,
            day: normalizedDay,
            month: normalizedMonth,
          };
        });

      const time = (): IForm["time"] => {
        const emptyTime: IForm["time"] = {
          start: {
            hours: "",
            minutes: "",
          },
          end: {
            hours: "",
            minutes: "",
          },
        };

        const firstDate = (itemData?.dates || [])[0] || {};
        const startDate = firstDate.start;
        const endDate = firstDate.end;

        if (!startDate || !endDate) return emptyTime;

        try {
          const parsedStart = new Date(startDate);
          const parsedEnd = new Date(endDate);

          const startHours = String(parsedStart.getHours());
          const startMinutes = String(parsedStart.getMinutes());

          const endHours = String(parsedEnd.getHours());
          const endMinutes = String(parsedEnd.getMinutes());

          return {
            start: {
              hours: startHours.length === 1 ? `0${startHours}` : startHours,
              minutes:
                startMinutes.length === 1 ? `0${startMinutes}` : startMinutes,
            },
            end: {
              hours: endHours.length === 1 ? `0${endHours}` : endHours,
              minutes: endMinutes.length === 1 ? `0${endMinutes}` : endMinutes,
            },
          };
        } catch (error) {
          return emptyTime;
        }
      };

      const isAllDay = (): boolean => {
        const firstDate = (itemData?.dates || [])[0] || {};
        const startDate = firstDate.start;
        const endDate = firstDate.end;

        if (!startDate || !endDate) return false;

        try {
          const start = new Date(startDate).getTime();
          const end = new Date(endDate).getTime();

          const tenHoursPeriod = 1000 * 60 * 60 * 10;

          return end - start > tenHoursPeriod;
        } catch (error) {
          return false;
        }
      };

      if (!itemData) setItem({ dates: [{ day: "", month: "", year: "" }] });

      setItem({
        ...itemData,
        time: time(),
        dates: dates(),
        isAllDay: isAllDay(),
      });

      setSchedulingOption(itemData?.schedulingOption || "defined-dates");
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItem, id]);

  const onSave = useCallback(
    (isDraft: boolean) => {
      const normalizedData = normalizeData(item, schedulingOption);

      if (!isDraft) {
        const currErrors = validate(normalizedData, schedulingOption);
        if (currErrors && Object.keys(currErrors).length) {
          setErrors(currErrors);
          window.scrollTo({ top: 0, behavior: "smooth" });
          return alert(
            "O cadastro possui erros, por favor verifique os campos para continuar"
          );
        }
      }

      const redirectPath = agendaModalOpen && !calendar ? "/calendar" : "/";

      saveItemHandler(
        appConfig.id,
        normalizedData,
        isDraft,
        id,
        null,
        redirectPath
      );
    },
    [item, agendaModalOpen, calendar, saveItemHandler, id, schedulingOption]
  );

  const onChangeHandler = (value: any, field: keyof IForm) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setItem((curr) => ({ ...curr, [field]: value }));
  };

  const isEditingItem = !!id;

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        showLiveLabel={appConfig.showIsLiveLabel}
        onBack={() => navigate(`/apps`)}
      />

      <AppFormSection title="Informações básicas">
        <AppFormTitle
          errors={errors.title}
          value={item.title || ""}
          onChange={(val) => onChangeHandler(val, "title")}
          placeholder={`Nome do ${appConfig.title.toLowerCase()}`}
        />

        <AppFormDescription
          errors={errors.description}
          value={item.description || ""}
          onChange={(val) => onChangeHandler(val, "description")}
        />

        <AppFormAddIcon
          errors={errors.icon}
          icon={item.image || ""}
          onChange={(image) => onChangeHandler(image, "image")}
          onClear={() =>
            setItem((curr) => ({ ...curr, buttonImage: "", image: "" }))
          }
        />
      </AppFormSection>

      <AppFormSection title="Mídia">
        <AppFormMedia
          errors={errors.media}
          images={item.media || []}
          onRemoveImage={(index) =>
            setItem((curr) => {
              const newMedia = curr.media || [];
              newMedia.splice(index, 1);

              return { ...curr, media: newMedia };
            })
          }
          onChange={(newImage) =>
            setItem((curr) => ({
              ...curr,
              media: [...(curr.media || []), newImage],
            }))
          }
        />
      </AppFormSection>

      <AppFormSection title="Datas, horários e agendamentos">
        <AppFormDateAndTime
          errors={errors}
          time={item.time}
          calendar={calendar}
          appId={appConfig.id}
          isAllDay={item.isAllDay}
          isSelectorOpen={isSelectorOpen}
          duration={item.duration || null}
          onSaveDraft={() => onSave(true)}
          agendaModalOpen={agendaModalOpen}
          schedulingOption={schedulingOption}
          dates={(item.dates || []) as IDate[]}
          numberOfSessions={item.numberOfSessions || 0}
          setIsSelectorOpen={(val) => setIsSelectorOpen(val)}
          setAgendaModalOpen={(val) => setAgendaModalOpen(val)}
          setSchedulingOption={(val) => setSchedulingOption(val)}
          onChange={(val, field) => onChangeHandler(val, field as keyof IForm)}
        />
      </AppFormSection>

      <AppFormSection title="Link da mentoria">
        <AppFormProductLinkInfo />
      </AppFormSection>

      {schedulingOption !== "agenda" ? (
        <AppFormSection title="Vagas">
          <AppFormVacancySelector
            min={MIN_VACANCY}
            max={MAX_VACANCY}
            errors={errors.vacancy}
            value={item.vacancy || 0}
            isUnlimited={item.isUnlimited || false}
            onChangeValue={(val) => onChangeHandler(val, "vacancy")}
            setIsUnlimited={(val) => {
              setItem((curr) => {
                const newItem = { ...curr, isUnlimited: val };
                if (val) delete newItem.vacancy;
                return newItem;
              });
            }}
          />
        </AppFormSection>
      ) : null}

      <AppFormSection title="Preço">
        <AppFormPrice
          appId={appConfig.id}
          errors={errors.price}
          value={item.price || 0}
          onChange={(val) => onChangeHandler(val, "price")}
        />
      </AppFormSection>

      <AppFormSection>
        <AppFormFAQ
          errors={errors.faq}
          faqs={item.faq || []}
          onChange={(val) => onChangeHandler(val, "faq")}
        />
      </AppFormSection>

      <AppFormSection title="Tags">
        <AppFormTags
          errors={errors.tags}
          value={item.tags || []}
          onChange={(val) => onChangeHandler(val, "tags")}
        />
      </AppFormSection>

      <AppFormFooter
        onSave={onSave}
        saveDraft={true}
        appTitle={appConfig.title}
        isEditingItem={isEditingItem}
        onRemove={() => id && removeItemHandler(appConfig.id, id)}
      />
    </Page>
  );
};

export default Form;
