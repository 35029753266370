import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const KeyDownIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="41"
      viewBox="0 0 39 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8356 1.95745C14.112 5.44129 12.3486 12.2169 14.6972 17.7281L1.77611 29.5815C0.0739347 31.143 -0.0411437 33.8133 1.52038 35.5155C3.0819 37.2177 5.7522 37.3327 7.45438 35.7712L10.2935 38.8661C11.855 40.5682 14.5253 40.6833 16.2275 39.1218C17.9297 37.5603 18.0448 34.89 16.4832 33.1878L13.6441 30.0929L20.3754 23.9178C24.9519 26.3593 30.7579 25.7921 34.7967 22.087C40.5841 16.7778 40.2644 7.29661 33.5018 2.47209C29.5082 -0.390135 24.0322 -0.58154 19.8356 1.95745ZM29.3745 9.96364C30.936 11.6658 30.8209 14.3361 29.1187 15.8976C27.4166 17.4592 24.7463 17.3441 23.1847 15.6419C21.6232 13.9397 21.7383 11.2694 23.4405 9.70791C25.1427 8.14638 27.8129 8.26146 29.3745 9.96364Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default KeyDownIcon;
