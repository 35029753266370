import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SongIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.2928 0.473975C39.7413 0.853982 40 1.41211 40 2.00002V28C40 32.4183 36.4183 36 32 36C27.5817 36 24 32.4183 24 28C24 23.5817 27.5817 20 32 20C33.4571 20 34.8233 20.3896 36 21.0703V4.36094L16 7.69427V32C16 36.4183 12.4183 40 8 40C3.58172 40 0 36.4183 0 32C0 27.5817 3.58172 24 8 24C9.45715 24 10.8233 24.3896 12 25.0703V6.00002C12 5.02234 12.7068 4.18796 13.6712 4.02723L37.6712 0.0272273C38.2511 -0.0694235 38.8442 0.0939683 39.2928 0.473975ZM12 32C12 29.7909 10.2091 28 8 28C5.79086 28 4 29.7909 4 32C4 34.2092 5.79086 36 8 36C10.2091 36 12 34.2092 12 32ZM36 28C36 25.7909 34.2091 24 32 24C29.7909 24 28 25.7909 28 28C28 30.2092 29.7909 32 32 32C34.2091 32 36 30.2092 36 28Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SongIcon;
