import styled, { css } from "styled-components";

export const InputCheckbox = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0px;
  margin-top: 10px;
`;

export const Inputs = styled.div`
  margin-left: -5px;

  &:nth-child(odd) {
    margin-top: 10px;
  }
  &:nth-child(even) {
    margin-top: 30px;
  }
`;

type SelectorProps = {
  borderColor: string;
  backgroundColor: string;
};

export const Selector = styled.div<SelectorProps>`
  ${({ backgroundColor, borderColor }) => css`
    width: 30px;
    height: 30px;
    overflow: hidden;
    border: 1px solid ${borderColor};
    position: relative;
    border-radius: 20px;
    background-color: ${backgroundColor};
  `}
`;

type IndicatorProps = {
  borderColor: string;
  isSelected: boolean;
};

export const Indicator = styled.div<IndicatorProps>`
  ${({ isSelected, borderColor }) =>
    isSelected &&
    css`
      width: 8px;
      height: 12px;
      border: solid;
      border-width: 0 2px 2px 0;
      border-color: ${borderColor};

      margin-top: 6px;
      margin-left: 10px;

      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
    `}
`;
