import { Navigate, Route } from "react-router-dom";

import Home from "./components/Home";
import Form from "./components/Form";

const AppVendaArquivos = () => {
  return (
    <Route path="arquivo">
      <Route index element={<Home />} />

      <Route path=":type">
        <Route path="new" element={<Form />} />
        <Route path=":id" element={<Form />} />
      </Route>

      <Route path="*" element={<Navigate to="/apps/arquivo" />} />
    </Route>
  );
};

export default AppVendaArquivos;
