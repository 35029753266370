import { ReactElement, useEffect, useRef, useState } from "react";

import { Theme } from "../../../hooks";
import CheckIcon from "../../icons/CheckIcon";
import CloseIcon from "../../icons/CloseIcon";
import HidePasswordIcon from "../../icons/HidePasswordIcon";
import LoadingIcon from "../../icons/LoadingIcon";
import Small from "../Typography/Small";

import * as S from "./styles";

type TInputStyles = "regular" | "outline";

interface IProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value: string;
  color?: string;
  large?: boolean;
  limit?: boolean;
  charLimit?: number;
  disabled?: boolean;
  inputLabel?: string;
  autofocus?: boolean;
  placeholder?: string;
  errorMessage?: string;
  allLowerCase?: boolean;
  leftIcon?: ReactElement;
  preventScroll?: boolean;
  inputStyle?: TInputStyles;
  onChange: (value: string) => void;
  status?: "success" | "error" | "loading" | "";
  isSocialMedia?: boolean;
}

const Input: React.FC<IProps> = ({
  color,
  value,
  status,
  onChange,
  leftIcon,
  autofocus,
  errorMessage,
  allLowerCase,
  preventScroll,
  placeholder = "",
  inputLabel,
  large = false,
  charLimit = 50,
  limit = false,
  disabled = false,
  inputStyle = "regular",
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus && input.current) input.current.focus();
  }, [autofocus]);

  const { textColor, primaryColor } = Theme.useTheme();

  const iconToRender = {
    loading: <LoadingIcon />,
    success: <CheckIcon />,
    error: <CloseIcon />,
  };

  const inputTextColor =
    props.type === "password" ? primaryColor : color ? color : textColor;

  const onFocusHandler = () => {
    if (window.navigator.userAgent.includes("Mobi")) {
      document.body.style.paddingBottom = "400px";

      if (!preventScroll) {
        setTimeout(() => {
          if (input.current)
            window.scrollTo({
              left: 0,
              top: input.current?.offsetTop - 100,
              behavior: "smooth",
            });
        }, 10);
      }
    }
  };

  const onBlurHandler = () => {
    if (window.navigator.userAgent.includes("Mobi"))
      document.body.style.paddingBottom = "0";
  };

  const onChangeHandler = (value: string) => {
    allLowerCase ? onChange(value.toLowerCase()) : onChange(value);
  };

  return (
    <S.InputWithLabel>
      {inputLabel && (
        <S.InputLabel color={textColor}>{inputLabel}</S.InputLabel>
      )}

      <S.InputContainer
        isDisabled={disabled}
        size={large ? "large" : "regular"}
        variant={inputStyle}
        borderColor={color || primaryColor}
        onClick={() => (input.current as HTMLInputElement)?.focus()}
      >
        {leftIcon && <S.LeftIconWrapper>{leftIcon}</S.LeftIconWrapper>}

        <S.Input
          ref={input}
          value={value}
          autoComplete="off"
          disabled={disabled}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          placeholder={placeholder}
          onChange={({ target }) => onChangeHandler(target.value)}
          textColor={inputTextColor}
          isSocialMedia={props.isSocialMedia}
          {...props}
          // Manter o type na última posição para que o ...props não o sobrescreva
          type={
            props.type === "password" && isPasswordVisible ? "text" : props.type
          }
        />

        <S.Icons>
          {props.type === "password" &&
            (isPasswordVisible ? (
              <HidePasswordIcon onClick={() => setIsPasswordVisible(false)} />
            ) : (
              <S.PasswordIcon onClick={() => setIsPasswordVisible(true)} />
            ))}

          {!!status && iconToRender[status]}
        </S.Icons>

        {limit && (
          <S.RightBar>
            {charLimit && (
              <S.CharLimit
                color={textColor}
              >{`${value.length}/${charLimit}`}</S.CharLimit>
            )}

            {!!status && iconToRender[status]}
          </S.RightBar>
        )}
      </S.InputContainer>

      {status === "error" && errorMessage && (
        <Small color={textColor}>{errorMessage}</Small>
      )}
    </S.InputWithLabel>
  );
};

export default Input;
