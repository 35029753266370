import Small from "../Typography/Small";
import TextAreaWithLabel from "../../molecules/TextAreaWithLabel";

interface IProps {
  value: string;
  errors?: string[];
  textLabelType: string;
  onChange: (value: string) => void;
}

const AppFormTargetAudience: React.FC<IProps> = ({
  value,
  errors,
  onChange,
  textLabelType,
}) => {
  return (
    <>
      <TextAreaWithLabel
        value={value}
        charLimit={1000}
        onChange={onChange}
        textAreaLabel={`Para quem é este ${textLabelType}?`}
        placeholder="Coloque o público alvo que deseja atingir, faixa etária, profissão, idade, etc. Listas costumam gerar uma alta conversão"
      />

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </>
  );
};

export default AppFormTargetAudience;
