import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CalendarIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="35"
      height="39"
      viewBox="0 0 35 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2778 2.05577V4.00022H9.72222V2.05577C9.72222 0.986328 8.84722 0.111328 7.77778 0.111328C6.70833 0.111328 5.83333 0.986328 5.83333 2.05577V4.00022H3.88889C1.73056 4.00022 0.0194444 5.75022 0.0194444 7.88911L0 35.1113C0 37.2502 1.73056 39.0002 3.88889 39.0002H31.1111C33.25 39.0002 35 37.2502 35 35.1113V7.88911C35 5.75022 33.25 4.00022 31.1111 4.00022H29.1667V2.05577C29.1667 0.986328 28.2917 0.111328 27.2222 0.111328C26.1528 0.111328 25.2778 0.986328 25.2778 2.05577ZM25.2778 21.5002H19.4444C18.375 21.5002 17.5 22.3752 17.5 23.4446V29.278C17.5 30.3474 18.375 31.2224 19.4444 31.2224H25.2778C26.3472 31.2224 27.2222 30.3474 27.2222 29.278V23.4446C27.2222 22.3752 26.3472 21.5002 25.2778 21.5002ZM5.83259 35.1113H29.1659C30.2354 35.1113 31.1104 34.2363 31.1104 33.1668V13.7224H3.88815V33.1668C3.88815 34.2363 4.76315 35.1113 5.83259 35.1113Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CalendarIcon;
