import Small from "../../../../../../components/atoms/Typography/Small";
import { Theme } from "../../../../../../hooks";
import { IYoutubeAppItem } from "../../../../types";

import * as S from "./styles";

interface IProps {
  selected: IYoutubeAppItem["type"];
  onSelect: (type: IYoutubeAppItem["type"]) => void;
}

const ChannelVideoSelector: React.FC<IProps> = ({ selected, onSelect }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.ChannelVideoSelector>
      <Small color={textColor}>Adicionar:</Small>

      <S.Button onClick={() => onSelect("video")}>
        <S.RadioButton
          borderColor={primaryColor}
          isSelected={selected === "video"}
        >
          <S.Indicator
            backgroundColor={primaryColor}
            isSelected={selected === "video"}
          />
        </S.RadioButton>

        <Small color={textColor}>Vídeo</Small>
      </S.Button>

      <S.Button onClick={() => onSelect("channel")}>
        <S.RadioButton
          borderColor={primaryColor}
          isSelected={selected === "channel"}
        >
          <S.Indicator
            backgroundColor={primaryColor}
            isSelected={selected === "channel"}
          />
        </S.RadioButton>

        <Small color={textColor}>Canal</Small>
      </S.Button>
    </S.ChannelVideoSelector>
  );
};

export default ChannelVideoSelector;
