import axios from "axios";
import { IInstagramPosts, ILinkToPostInstagram } from "../../types";

import { logoutUserIfTokenInvalid } from "../../utils";

const { REACT_APP_APPS_API_URL } = process.env;

const getPosts = async (token: string) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_APPS_API_URL}/link-to-post/instagram`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item || {};
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter as publicações");
  }
};

const getPostsFromInstagram = async (code: string, token: string) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_APPS_API_URL}/link-to-post/instagram/posts?code=${code}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter as publicações do usuário");
  }
};

const savePostsSelected = async (
  token: string,
  posts: ILinkToPostInstagram[]
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/link-to-post/instagram`,
      { posts },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item || {};
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar os posts selecionados");
  }
};

const updatePostsSelected = async (token: string, posts: IInstagramPosts[]) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_APPS_API_URL}/link-to-post/instagram`,
      { posts },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao salvar os posts selecionados");
  }
};

export default {
  getPosts,
  savePostsSelected,
  updatePostsSelected,
  getPostsFromInstagram,
};
