import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SmileOneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.978 0C9.834 0 0 9.856 0 22C0 34.144 9.834 44 21.978 44C34.144 44 44 34.144 44 22C44 9.856 34.144 0 21.978 0ZM22 39.6C12.276 39.6 4.4 31.724 4.4 22C4.4 12.276 12.276 4.4 22 4.4C31.724 4.4 39.6 12.276 39.6 22C39.6 31.724 31.724 39.6 22 39.6ZM33 16.5C33 18.326 31.526 19.8 29.7 19.8C27.874 19.8 26.4 18.326 26.4 16.5C26.4 14.674 27.874 13.2 29.7 13.2C31.526 13.2 33 14.674 33 16.5ZM14.3 19.8C16.126 19.8 17.6 18.326 17.6 16.5C17.6 14.674 16.126 13.2 14.3 13.2C12.474 13.2 11 14.674 11 16.5C11 18.326 12.474 19.8 14.3 19.8ZM32.4492 28.05C30.3592 31.658 26.4652 34.1 21.9992 34.1C17.5332 34.1 13.6392 31.658 11.5492 28.05C11.1312 27.324 11.6812 26.4 12.5172 26.4H31.4812C32.3392 26.4 32.8672 27.324 32.4492 28.05Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SmileOneIcon;
