import { useState } from "react";

import Theme from "../../../hooks/useTheme";
import { formatData } from "../../../pages/AppGaleriaImagens/components/Form";
import { TImageGalleryOrientation } from "../../../types";
import Chevron from "../../icons/Chevron";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  disabled?: boolean;
  selectedFormat: TImageGalleryOrientation;
  onSelectFormat: (formatId: TImageGalleryOrientation) => void;
}

const PhotoFormatSelector: React.FC<IProps> = ({
  disabled,
  selectedFormat,
  onSelectFormat,
}) => {
  const [isFormatSelectorOpen, setIsFormatSelectorOpen] = useState(false);

  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.FormatSelector
      isDisabled={disabled}
      onClick={() => !disabled && setIsFormatSelectorOpen((curr) => !curr)}
    >
      <div className="indicator">
        <Chevron />
      </div>

      <S.FormatList>
        <S.SelectedFormat>
          <S.IconContainer>
            <S.Icon
              borderColor={primaryColor}
              width={
                formatData[selectedFormat].width *
                (selectedFormat === "landscape" ? 0.12 : 0.0875)
              }
              height={
                formatData[selectedFormat].height *
                (selectedFormat === "landscape" ? 0.12 : 0.0875)
              }
            />
          </S.IconContainer>

          <P color={textColor}>{formatData[selectedFormat].title}</P>
        </S.SelectedFormat>

        {isFormatSelectorOpen &&
          (Object.keys(formatData) as TImageGalleryOrientation[])
            .filter((i) => i !== selectedFormat)
            .map((key) => (
              <S.FormatItem
                key={key}
                onClick={() => !disabled && onSelectFormat(key)}
              >
                <S.IconContainer>
                  <S.Icon
                    borderColor={primaryColor}
                    width={
                      formatData[key].width *
                      (selectedFormat === "landscape" ? 0.12 : 0.0875)
                    }
                    height={
                      formatData[key].height *
                      (selectedFormat === "landscape" ? 0.12 : 0.0875)
                    }
                  />
                </S.IconContainer>
                <S.FormatTitle color={textColor}>
                  {formatData[key].title}
                </S.FormatTitle>

                <Chevron />
              </S.FormatItem>
            ))}
      </S.FormatList>
    </S.FormatSelector>
  );
};

export default PhotoFormatSelector;
