import styled from "styled-components";

export const SearchInput = styled.div`
  width: 100%;
  margin-top: 24px;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
  border-radius: 20px;
  mix-blend-mode: screen;
  background-color: #fafafa18;

  input {
    width: 100%;
    outline: none;
    padding: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #fafafa;
    padding-left: 44px;

    &::placeholder {
      opacity: 0.5;
      color: inherit;
    }
  }
`;

export const SeachIconContainer = styled.div`
  top: 50%;
  left: 18px;
  width: 15px;
  height: 15px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);

  svg {
    width: 100%;
    height: 100%;
  }
`;
