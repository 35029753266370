import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import Page from "../../../../components/atoms/Page";
import Small from "../../../../components/atoms/Typography/Small";
import appConfig from "../../../../config/app-afiliados.json";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import { Analytics } from "../../../../services";
import affiliates from "../../../../services/affiliates";
import { ICreatorCampaign } from "../../../../services/affiliates/types";
import CampaignsList from "../CampaignsList";
import SearchInput from "../SearchInput";

import * as S from "./styles";

const Home: React.FC = () => {
  const [search, setSearch] = useState("");
  const [campaigns, setCampaigns] = useState<ICreatorCampaign[]>([]);

  const navigate = useNavigate();
  const { token, user } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { hideLoading, showLoading, isLoading } = Loading.useLoading();
  const { textColor } = Theme.useTheme();

  const getCampaigns = useCallback(async () => {
    try {
      showLoading();
      const currentCampaigns = await affiliates.getCreatorCampaigns(token);

      setCampaigns(currentCampaigns);
    } catch (error) {
      newError("Houve um erro ao buscar as campanhas");
    } finally {
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onApplyForCampaign = useCallback(async (campaignId: string) => {
    try {
      showLoading();

      const currentCampaign = campaigns.find((c) => c.id === campaignId);

      if (currentCampaign) {
        Analytics.submitClickEvent({
          creator: user.id || "",
          origin: "home-app-afiliados",
          creatorUsername: user.username || "",
          description: `affiliation-request-${currentCampaign.brand}`,
          actionDescription: `affiliation-request-home-${currentCampaign.brand}`,
        });
      }

      await affiliates.applyForCampaign(campaignId, token);
      await getCampaigns();

      newSuccess("Recebemos sua solicitação! Entraremos em contato em breve");
    } catch (error) {
      newError("Houve um erro ao enviar sua solicitação");
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => {
    Analytics.submitPageViewEvent({
      origin: "add-content",
      creator: user.id || "",
      creatorUsername: user.username || "",
      description: "home-app-afiliados",
      actionDescription: "home-app-afiliados",
    });
  }, [user.id, user.username]);

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps`)}
        showLiveLabel={appConfig.showIsLiveLabel}
      />

      <S.Intro>
        <S.Title color={textColor}>
          Adicione um link de afiliado em sua Stanti
        </S.Title>

        <Small color={textColor}>
          Escolha abaixo uma de nossas marcas parceiras e solicite seu código de
          afiliado.
        </Small>
      </S.Intro>

      <SearchInput onSearch={setSearch} searchTerm={search} />

      <CampaignsList
        searchTerm={search}
        campaigns={campaigns}
        isLoading={isLoading}
        onApplyForCampaign={onApplyForCampaign}
      />
    </Page>
  );
};

export default Home;
