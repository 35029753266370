import { Navigate, Route } from "react-router-dom";

import Form from "./components/Form";

const AppLinkExterno = () => (
  <Route path="link-externo">
    <Route path="new" element={<Form />} />
    <Route path=":id" element={<Form />} />
    <Route index path="*" element={<Navigate to="/apps/link-externo/new" />} />
  </Route>
);

export default AppLinkExterno;
