import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const GameIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="44"
      height="24"
      viewBox="0 0 44 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0H40C42.2 0 44 1.8 44 4V20C44 22.2 42.2 24 40 24H4C1.8 24 0 22.2 0 20V4C0 1.8 1.8 0 4 0ZM14.0003 14H18.0003C19.1003 14 20.0003 13.1 20.0003 12C20.0003 10.9 19.1003 10 18.0003 10H14.0003V6.00002C14.0003 4.90002 13.1003 4.00002 12.0003 4.00002C10.9003 4.00002 10.0003 4.90002 10.0003 6.00002V10H6.00028C4.90028 10 4.00028 10.9 4.00028 12C4.00028 13.1 4.90028 14 6.00028 14H10.0003V18C10.0003 19.1 10.9003 20 12.0003 20C13.1003 20 14.0003 19.1 14.0003 18V14ZM28.9997 18C27.3397 18 25.9997 16.66 25.9997 15C25.9997 13.34 27.3397 12 28.9997 12C30.6597 12 31.9997 13.34 31.9997 15C31.9997 16.66 30.6597 18 28.9997 18ZM34.0003 9C34.0003 10.66 35.3403 12 37.0003 12C38.6603 12 40.0003 10.66 40.0003 9C40.0003 7.34 38.6603 6 37.0003 6C35.3403 6 34.0003 7.34 34.0003 9Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default GameIcon;
