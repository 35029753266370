import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const MusicIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="35"
      viewBox="0 0 39 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9167 0C23.1217 0 21.6667 1.46907 21.6667 3.28125V29.5312C21.6667 31.3434 23.1217 32.8125 24.9167 32.8125C26.7116 32.8125 28.1667 31.3434 28.1667 29.5312V3.28125C28.1667 1.46907 26.7116 0 24.9167 0Z"
        fill={color || primaryColor}
      />
      <path
        d="M14.0833 10.9375C12.2884 10.9375 10.8333 12.4066 10.8333 14.2188V22.9688C10.8333 24.7809 12.2884 26.25 14.0833 26.25C15.8783 26.25 17.3333 24.7809 17.3333 22.9688V14.2188C17.3333 12.4066 15.8783 10.9375 14.0833 10.9375Z"
        fill={color || primaryColor}
      />
      <path
        d="M35.75 15.3125C33.9551 15.3125 32.5 16.7816 32.5 18.5938V25.1562C32.5 26.9684 33.9551 28.4375 35.75 28.4375C37.5449 28.4375 39 26.9684 39 25.1562V18.5938C39 16.7816 37.5449 15.3125 35.75 15.3125Z"
        fill={color || primaryColor}
      />
      <path
        d="M0 22.9688C0 21.1566 1.45507 19.6875 3.25 19.6875C5.04493 19.6875 6.5 21.1566 6.5 22.9688V31.7188C6.5 33.5309 5.04493 35 3.25 35C1.45507 35 0 33.5309 0 31.7188V22.9688Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default MusicIcon;
