import { MouseEvent } from "react";

import { Snackbar, Theme } from "../../../hooks";
import { IOrder, TProductType } from "../../../types";
import { normalizeDateToLocale } from "../../../utils/dates";
import { maskPhoneNumber, normalizeNumber } from "../../../utils/numbers";
import Label from "../../atoms/Typography/Label";
import P from "../../atoms/Typography/P";
import Small from "../../atoms/Typography/Small";
import CopyIcon from "../../icons/CopyIcon";
import EmailTwoIcon from "../../icons/EmailTwoIcon";
import MessageIcon from "../../icons/MessageIcon";
import UserIcon from "../../icons/UserIcon";

import * as S from "./styles";

interface IProduct {
  id: string;
  title: string;
  app: TProductType;
}

interface IProps {
  sale: IOrder;
  isOpen: boolean;
  onClick: () => void;
  focusOn: "lead" | "product";
  product: IProduct | undefined;
}

const SalesListItem: React.FC<IProps> = ({
  sale,
  isOpen,
  product,
  focusOn,
  onClick,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const normalizedProductValue = normalizeNumber(sale.value);
  const normalizedDate = normalizeDateToLocale(sale.createdAt);

  const buyerInitials = (() => {
    const splittedName = sale.name?.trim().split(" ");

    const firstInitial = splittedName[0].substring(0, 1).toUpperCase();
    const lastInitial =
      splittedName.length > 1
        ? splittedName[splittedName.length - 1].substring(0, 1).toUpperCase()
        : "";

    return firstInitial + lastInitial;
  })();

  const { newSuccess } = Snackbar.useSnackbar();

  const title = focusOn === "lead" ? sale.name : product?.title;

  const onCopy = (
    e: MouseEvent<HTMLDivElement>,
    dataToCopy: string,
    message: string
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(dataToCopy);

    newSuccess(message);
  };

  return (
    <S.SalesItem onClick={onClick}>
      <S.BuyerName color={textColor} borderColor={primaryColor}>
        {buyerInitials}
      </S.BuyerName>

      <S.ProductSale>
        <S.Product>
          <Small color={textColor}>{title}</Small>

          <S.ProductValue>
            <Label color={textColor}>R$</Label>

            <P color={textColor}>{normalizedProductValue}</P>
          </S.ProductValue>
        </S.Product>

        <S.Sale isOpen={isOpen}>
          <Small color="#71726F">{normalizedDate}</Small>

          {focusOn === "product" && !isOpen && (
            <Small color="#71726F">{sale.name}</Small>
          )}

          <S.BuyerDetails isOpen={isOpen}>
            {sale.name.length > 0 && focusOn === "product" && (
              <S.Info>
                <UserIcon color="#71726F" />

                <Small color={textColor}>{sale.name}</Small>

                <div onClick={(e) => onCopy(e, sale.name, "Nome copiado")}>
                  <CopyIcon />
                </div>
              </S.Info>
            )}

            <S.Info>
              <MessageIcon color="#71726F" />

              <Small color={textColor}>{maskPhoneNumber(sale.phone)}</Small>

              <div onClick={(e) => onCopy(e, sale.phone, "Telefone copiado")}>
                <CopyIcon />
              </div>
            </S.Info>

            <S.Info>
              <EmailTwoIcon color="#71726F" />

              <Small color={textColor}>{sale.email}</Small>

              <div onClick={(e) => onCopy(e, sale.email, "E-mail copiado")}>
                <CopyIcon />
              </div>
            </S.Info>
          </S.BuyerDetails>
        </S.Sale>
      </S.ProductSale>
    </S.SalesItem>
  );
};

export default SalesListItem;
