import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ChartBarsIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4989 0C18.3139 0 19.7989 1.485 19.7989 3.3V29.7C19.7989 31.515 18.3139 33 16.4989 33C14.6839 33 13.1989 31.515 13.1989 29.7V3.3C13.1989 1.485 14.6839 0 16.4989 0ZM3.3 9.90003H3.77143C5.58644 9.90003 7.07144 11.385 7.07144 13.2V29.7001C7.07144 31.5151 5.58644 33.0001 3.77143 33.0001H3.3C1.485 33.0001 0 31.5151 0 29.7001V13.2C0 11.385 1.485 9.90003 3.3 9.90003ZM33.0002 22.1572C33.0002 20.3422 31.5152 18.8572 29.7002 18.8572C27.8852 18.8572 26.4002 20.3422 26.4002 22.1572V29.7001C26.4002 31.5151 27.8852 33.0001 29.7002 33.0001C31.5152 33.0001 33.0002 31.5151 33.0002 29.7001V22.1572Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ChartBarsIcon;
