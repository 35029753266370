import { Theme } from "../../hooks";

interface IProps {
  color?: string;
}

const BackgroundQualificationIcon: React.FC<IProps> = ({ color }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <svg
      width="182"
      height="162"
      viewBox="0 0 182 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="4.5"
        transform="matrix(0.415698 -0.909503 -0.909503 -0.415698 19.626 57.6264)"
        stroke={color || primaryColor}
        strokeLinejoin="round"
      />
      <circle
        cx="37.7535"
        cy="131.754"
        r="2"
        transform="rotate(-58.2434 37.7535 131.754)"
        fill={color || primaryColor}
      />
      <path
        d="M3.48179 127.832C2.47718 127.373 1.29056 127.815 0.831392 128.819C0.372225 129.824 0.814393 131.011 1.819 131.47C2.82361 131.929 4.01024 131.487 4.4694 130.482C4.92857 129.478 4.4864 128.291 3.48179 127.832Z"
        fill={color || primaryColor}
      />
      <circle
        cx="12.7535"
        cy="17.7539"
        r="2"
        transform="rotate(-58.2434 12.7535 17.7539)"
        fill={color || primaryColor}
      />
      <circle
        r="2"
        transform="matrix(0.415698 -0.909503 -0.909503 -0.415698 63.6504 152.651)"
        fill={color || primaryColor}
      />
      <circle
        cx="42.7535"
        cy="40.7539"
        r="2"
        transform="rotate(-58.2434 42.7535 40.7539)"
        fill={color || primaryColor}
      />
      <path
        d="M71.0133 5.02368L67.0824 8.95449L71.0485 12.9206L69.9028 14.0663L65.9367 10.1002L62.0235 14.0134L60.9659 12.9558L64.8791 9.04263L60.913 5.07656L62.0588 3.93081L66.0248 7.89687L69.9556 3.96606L71.0133 5.02368Z"
        fill={color || primaryColor}
      />
      <path
        d="M21.0062 99.5117L19.0409 101.477L21.0238 103.46L20.4509 104.033L18.468 102.05L16.5115 104.006L15.9828 103.478L17.9392 101.521L15.9563 99.5381L16.5292 98.9653L18.5121 100.948L20.4774 98.9829L21.0062 99.5117Z"
        fill={color || primaryColor}
      />
      <path
        d="M35.0062 148.512L33.0409 150.477L35.0238 152.46L34.4509 153.033L32.468 151.05L30.5115 153.006L29.9828 152.478L31.9392 150.521L29.9563 148.538L30.5292 147.965L32.5121 149.948L34.4774 147.983L35.0062 148.512Z"
        fill={color || primaryColor}
      />
      <circle
        r="4.5"
        transform="matrix(-0.415698 0.909503 0.909503 0.415698 162.353 103.675)"
        stroke={color || primaryColor}
        strokeLinejoin="round"
      />
      <circle
        cx="144.226"
        cy="29.5474"
        r="2"
        transform="rotate(121.757 144.226 29.5474)"
        fill={color || primaryColor}
      />
      <path
        d="M178.498 33.4694C179.502 33.9286 180.689 33.4864 181.148 32.4818C181.607 31.4772 181.165 30.2906 180.16 29.8314C179.156 29.3722 177.969 29.8144 177.51 30.819C177.051 31.8236 177.493 33.0102 178.498 33.4694Z"
        fill={color || primaryColor}
      />
      <circle
        cx="169.226"
        cy="143.547"
        r="2"
        transform="rotate(121.757 169.226 143.547)"
        fill={color || primaryColor}
      />
      <circle
        r="2"
        transform="matrix(-0.415698 0.909503 0.909503 0.415698 118.329 8.65039)"
        fill={color || primaryColor}
      />
      <circle
        cx="139.226"
        cy="120.547"
        r="2"
        transform="rotate(121.757 139.226 120.547)"
        fill={color || primaryColor}
      />
      <path
        d="M110.966 156.278L114.897 152.347L110.931 148.381L112.077 147.235L116.043 151.201L119.956 147.288L121.013 148.345L117.1 152.259L121.066 156.225L119.921 157.37L115.954 153.404L112.024 157.335L110.966 156.278Z"
        fill={color || primaryColor}
      />
      <path
        d="M160.973 61.7896L162.938 59.8243L160.956 57.8413L161.528 57.2685L163.511 59.2514L165.468 57.2949L165.997 57.8237L164.04 59.7802L166.023 61.7631L165.45 62.336L163.467 60.353L161.502 62.3183L160.973 61.7896Z"
        fill={color || primaryColor}
      />
      <path
        d="M146.973 12.7896L148.938 10.8243L146.956 8.84134L147.528 8.2685L149.511 10.2514L151.468 8.29494L151.997 8.82372L150.04 10.7802L152.023 12.7631L151.45 13.336L149.467 11.353L147.502 13.3183L146.973 12.7896Z"
        fill={color || primaryColor}
      />
      <path
        d="M113.686 60.0161C114.761 60.0161 115.857 59.9399 116.907 60.1188C121.175 60.8422 123.994 64.2738 123.997 68.6559C124.003 77.0296 123.997 85.4018 123.999 93.7756C123.999 98.8188 120.376 102.488 115.377 102.504C113.66 102.509 111.945 102.527 110.23 102.493C109.681 102.482 109.547 102.655 109.552 103.192C109.579 105.783 109.569 108.376 109.569 110.968C109.569 111.382 109.573 111.794 109.427 112.195C108.717 114.162 106.484 114.621 104.947 113.077C101.639 109.753 98.3465 106.413 95.0648 103.064C94.6744 102.666 94.2871 102.498 93.726 102.499C85.7594 102.518 77.7942 102.513 69.8276 102.512C64.6445 102.51 61.0232 98.8795 61.0109 93.6278C60.9924 85.3552 61.0001 77.0856 61.014 68.8161C61.014 68.1208 61.0724 67.4099 61.2215 66.7332C62.1253 62.6498 65.367 60.0317 69.5786 60.0192C77.2117 59.999 84.8448 60.013 92.4779 60.013C99.5469 60.013 106.617 60.0084 113.686 60.0161ZM87.3902 75.5892C87.3902 73.8733 89.2255 72.8264 91.4389 72.8264C93.4632 72.8264 95.1586 73.78 96.5696 75.3512C97.3704 76.2565 98.1697 76.5894 98.8768 76.5894C100.054 76.5894 100.949 75.5409 100.949 74.3027C100.949 73.6836 100.761 72.968 100.243 72.3489C98.5017 70.1088 95.0172 68.6808 91.4865 68.6808C86.3557 68.6808 82.1657 71.3968 82.1657 76.0652C82.1657 84.8309 96.6188 82.2113 96.6188 87.1192C96.6188 88.9766 94.8296 90.0733 92.0998 90.0733C89.5098 90.0733 87.8621 89.0248 86.5448 87.0243C85.9331 86.0707 85.1799 85.6896 84.4267 85.6896C83.1079 85.6896 81.8844 86.8812 81.8844 88.3108C81.8844 88.8832 82.0719 89.4542 82.4961 90.0733C84.3791 92.7411 87.5331 94.362 92.0522 94.362C97.89 94.362 102.032 91.6942 102.032 86.7863C102.032 77.5912 87.3917 80.4504 87.3917 75.5892H87.3902ZM106.087 73.3911C107.372 73.3911 108.414 72.3364 108.414 71.0359C108.414 69.7355 107.372 68.6808 106.087 68.6808C104.802 68.6808 103.76 69.7355 103.76 71.0359C103.76 72.3364 104.802 73.3911 106.087 73.3911Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BackgroundQualificationIcon;
