import { CSSProperties } from "react";
import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  style?: CSSProperties;
  blendMode?: "normal" | "overlay";
}

const ProfileImagePlaceholder: React.FC<IProps> = ({
  color,
  style,
  blendMode,
  opacity,
}) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <svg
      width="47"
      height="44"
      viewBox="0 0 47 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...style,
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
    >
      <path
        d="M12.7304 2.36135C14.1794 2.70768 10.3844 19.2623 9.97041 22.5178C17.8364 26.466 18.6644 23.8339 19.2854 25.2885C19.9064 26.7431 14.5244 26.3274 13.0064 26.3274C11.0857 26.068 9.2177 25.4786 7.48644 24.5958C7.24431 24.451 7.03733 24.2545 6.89432 24.0127C6.75004 23.7696 6.66972 23.4926 6.65843 23.2105C6.77384 21.3693 7.02604 19.5394 7.41745 17.7384C8.44617 12.6178 9.94787 7.61425 11.9024 2.77696C11.925 2.69006 11.9652 2.61576 12.0229 2.5465C12.0743 2.47723 12.1433 2.42432 12.2248 2.38402C12.3051 2.34372 12.3904 2.32104 12.477 2.31474C12.5636 2.31474 12.6501 2.32609 12.7304 2.36135Z"
        fill={color || primaryColor}
      />
      <path
        d="M40.9513 8.87239V12.4742C41.0203 12.7286 41.0378 12.9931 40.9914 13.2538C40.945 13.5069 40.8472 13.755 40.6979 13.9691C40.5548 14.1883 40.3591 14.3671 40.1346 14.5006C39.91 14.6328 39.6579 14.7197 39.3982 14.7424C39.1397 14.7713 38.875 14.7373 38.6279 14.6504C38.3807 14.5635 38.1562 14.425 37.9667 14.2399C37.7836 14.056 37.6393 13.8369 37.5477 13.5875C37.4499 13.3457 37.4147 13.08 37.4323 12.8206V8.87239C37.5013 6.03247 40.9513 6.3788 40.9513 8.87239Z"
        fill={color || primaryColor}
      />
      <path
        d="M36.7423 41.566C40.3303 41.1504 43.2972 39.9036 45.5052 31.5917C45.5517 31.4532 45.637 31.3323 45.7587 31.2454C45.8728 31.1585 46.0171 31.1119 46.1601 31.1119C46.3044 31.1119 46.4487 31.1585 46.5691 31.2454C46.6845 31.3323 46.7698 31.4532 46.8162 31.5917C48.3342 42.0509 40.1233 46.2761 34.1203 42.8128C33.9598 42.7549 33.8217 42.639 33.7402 42.4841C33.6662 42.3279 33.6549 42.1491 33.7063 41.9816C33.764 41.8141 33.8782 41.6818 34.0338 41.6C34.1893 41.5257 34.3737 41.5081 34.5343 41.566C35.2707 41.624 36.0059 41.624 36.7423 41.566Z"
        fill={color || primaryColor}
      />
      <path
        d="M3.53591 1.36266C3.59989 1.62209 3.60492 1.89413 3.55349 2.15356C3.4732 3.46962 3.4732 4.78567 3.55349 6.10173C3.60492 6.36116 3.59989 6.63321 3.53591 6.89265C3.47318 7.14578 3.35275 7.38885 3.17962 7.59665C3.01277 7.79815 2.80074 7.96562 2.56489 8.08148C2.32276 8.19734 2.05807 8.25405 1.79336 8.25405C1.52991 8.25405 1.26516 8.19734 1.02931 8.08148C0.787179 7.96562 0.575187 7.79815 0.408334 7.59665C0.24148 7.38885 0.121031 7.14578 0.0570498 6.89265C-0.0119499 6.63321 -0.01697 6.36116 0.0344661 6.10173V2.15356C-0.01697 1.89413 -0.0119499 1.62209 0.0570498 1.36266C0.121031 1.10322 0.24148 0.866496 0.408334 0.658697C0.575187 0.450899 0.787179 0.288427 1.02931 0.173823C1.26516 0.0579599 1.52991 0 1.79336 0C2.05807 0 2.32276 0.0579599 2.56489 0.173823C2.80074 0.288427 3.01277 0.450899 3.17962 0.658697C3.35275 0.866496 3.47318 1.10322 3.53591 1.36266Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ProfileImagePlaceholder;
