import styled from "styled-components";
import P from "../Typography/P";

export const File = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const Description = styled(P)`
  text-align: left;
`;

type AddFileButtonProps = {
  isUploadDisabled: boolean;
};

export const AddFileButton = styled.div<AddFileButtonProps>`
  gap: 12px;
  display: flex;
  padding: 16px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  border: 1px dashed #71726f;
  cursor: ${({ isUploadDisabled }) =>
    isUploadDisabled ? "not-allowed" : "pointer"};

  .addIcon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    border: 1px solid #71726f;
  }

  input {
    display: none;
  }
`;

export const UploadedFile = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

export const FileTitle = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  border: 1px dashed #71726f;
`;

export const RemoveFile = styled.div`
  width: 40px;
  height: 40px;
  padding: 16px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  background-color: #fafafa18;
  mix-blend-mode: screen;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const UploadingSkeleton = styled.div`
  width: 100%;
  height: 64px;
  border-radius: 18px;
  background-color: #333;
  animation: skeleton 1.2s ease-in-out infinite;

  @keyframes skeleton {
    0% {
      opacity: 0.6;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.6;
    }
  }
`;
