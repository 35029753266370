import { AxiosError, AxiosResponse } from "axios";
import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_KEY } from "../constants";

const normalizeUsername = (value: string) => {
  const normalizedUsername = (value || "")
    .replace(/([^a-zA-Z0-9._])/g, "")
    .toLowerCase();

  return normalizedUsername;
};

const logoutUser = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY);

  window.location.reload();
};

const logoutUserIfTokenInvalid = (error: unknown) => {
  if (error instanceof AxiosError) {
    const { data } = error.response as AxiosResponse<{
      code: string;
      message: string;
    }>;

    if (
      data &&
      data.code &&
      ["LGOT001", "LGOT002", "LGOT003", "LGOT004"].includes(data.code)
    )
      logoutUser();
  }
};

export { logoutUser, normalizeUsername, logoutUserIfTokenInvalid };
