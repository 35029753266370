import { Navigate, Route } from "react-router-dom";

import Form from "./components/Form";

const AppCursoLive = () => {
  return (
    <Route path="curso-live">
      <Route path="new" element={<Form />} />
      <Route path=":id" element={<Form />} />
      <Route index path="*" element={<Navigate to="/apps/curso-live/new" />} />
    </Route>
  );
};

export default AppCursoLive;
