import { Theme } from "../../../../hooks";
import { ICreatorData } from "../../../../types";

import * as S from "./styles";

interface IProps {
  onChange: (color: string) => void;
  value: ICreatorData["backgroundColor"];
  selectedPrimaryColor: ICreatorData["primaryColor"];
}

const bgColors = [
  {
    id: "1",
    color: "#000000",
    allowedWith: [
      "#DDFF66",
      "#FF9960",
      "#00FFB2",
      "#A0D7FF",
      "#FFA8BD",
      "#FAFAFA",
      "#A091FF",
      "#FFC700",
    ],
  },
  {
    id: "2",
    color: "#181818",
    allowedWith: [
      "#DDFF66",
      "#FF9960",
      "#00FFB2",
      "#A0D7FF",
      "#FFA8BD",
      "#FAFAFA",
      "#A091FF",
      "#FFC700",
    ],
  },
  {
    id: "3",
    color: "#1D232E",
    allowedWith: [
      "#DDFF66",
      "#FF9960",
      "#00FFB2",
      "#A0D7FF",
      "#FFA8BD",
      "#FAFAFA",
      "#A091FF",
      "#FFC700",
    ],
  },
  {
    id: "4",
    color: "#090223",
    allowedWith: [
      "#DDFF66",
      "#FF9960",
      "#00FFB2",
      "#A0D7FF",
      "#FFA8BD",
      "#FAFAFA",
      "#A091FF",
      "#FFC700",
    ],
  },
];

const BackgroundSelectorList: React.FC<IProps> = ({
  value,
  onChange,
  selectedPrimaryColor,
}) => {
  const { primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <S.BackgroundSelectorList>
      {bgColors
        .filter(({ allowedWith }) =>
          allowedWith.includes(selectedPrimaryColor || "")
        )
        .map(({ id, color }) => (
          <S.BackgroundSelectorItem key={id} onClick={() => onChange(color)}>
            <S.ColorPreview
              backgroundColor={color}
              borderColor={value === color ? primaryColor : backgroundColor}
            />
            <S.Selector
              isSelected={value === color}
              borderColor={value === color ? primaryColor : backgroundColor}
            >
              <S.Indicator
                backgroundColor={value === color ? primaryColor : "transparent"}
              />
            </S.Selector>
          </S.BackgroundSelectorItem>
        ))}
    </S.BackgroundSelectorList>
  );
};

export default BackgroundSelectorList;
