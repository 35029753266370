import AppFormAddCover from "../../../../../../components/atoms/AppFormAddCover";
import AppFormFile from "../../../../../../components/atoms/AppFormFile";
import Input from "../../../../../../components/atoms/Input";
import H3 from "../../../../../../components/atoms/Typography/H3";
import P from "../../../../../../components/atoms/Typography/P";
import Small from "../../../../../../components/atoms/Typography/Small";
import LinkIcon from "../../../../../../components/icons/LinkIcon";
import AppFormSection from "../../../../../../components/molecules/AppFormSection";
import { Theme } from "../../../../../../hooks";
import { TIconOptions } from "../../../../../../types";

import * as S from "./styles";

interface IProps {
  title: string;
  accept?: string;
  errors?: string[];
  icon: TIconOptions;
  coverImage: string;
  providedUrl: string;
  uploadedUrl: string;
  description?: string;
  maxFileSizeMB?: number;
  onRemoveFile: () => void;
  isUploadingFile: boolean;
  onRemoveCover: () => void;
  fileUploadProgress?: number;
  onUploadFile: (file: File) => void;
  onChangeCover: (url: string) => void;
  onChangeProvidedUrl: (value: string) => void;
}

const FormUploadFileOrAddLink: React.FC<IProps> = ({
  icon,
  title,
  errors,
  accept,
  coverImage,
  providedUrl,
  description,
  uploadedUrl,
  onRemoveFile,
  onUploadFile,
  onRemoveCover,
  onChangeCover,
  maxFileSizeMB,
  isUploadingFile,
  fileUploadProgress,
  onChangeProvidedUrl,
}) => {
  const { textColor } = Theme.useTheme();

  const renderFile =
    !!uploadedUrl || isUploadingFile ? (
      <AppFormAddCover
        hideLabel
        iconId={icon}
        onChange={() => null}
        coverURL={coverImage}
        isLoading={isUploadingFile}
        onRemoveCover={onRemoveFile}
        fileUploadProgress={fileUploadProgress}
      />
    ) : (
      <AppFormFile
        accept={accept}
        description={description}
        onUploadFile={onUploadFile}
        maxFileSizeMB={maxFileSizeMB}
        enableUpload={!isUploadingFile}
      />
    );

  const renderLink = isUploadingFile ? null : (
    <Input
      value={providedUrl}
      leftIcon={<LinkIcon />}
      onChange={onChangeProvidedUrl}
      placeholder="Link para download (Drive/Dropbox)"
    />
  );

  const renderCoverSelector = providedUrl ? (
    <AppFormAddCover
      iconId={icon}
      coverURL={coverImage}
      onChange={onChangeCover}
      onRemoveCover={onRemoveCover}
    />
  ) : null;

  return (
    <AppFormSection>
      <S.UploadSection>
        <H3 color="#FAFAFA">{title}</H3>

        {!providedUrl && renderFile}

        {!providedUrl && !uploadedUrl && !isUploadingFile && (
          <P color={textColor}>ou</P>
        )}

        {!uploadedUrl && renderLink}

        {!!providedUrl && renderCoverSelector}

        {errors && errors.length && (
          <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
        )}
      </S.UploadSection>
    </AppFormSection>
  );
};

export default FormUploadFileOrAddLink;
