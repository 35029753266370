export const capitalizeFirstLetter = (word: string) => {
  if (!word) return;

  if (word === "tiktok" || word === "youtube") {
    const firstLetter = word.charAt(0).toUpperCase();
    const restUntilThird = word.slice(1, 3);
    const thirdLetter = word.charAt(3).toUpperCase();
    const otherLetters = word.slice(4);

    return firstLetter + restUntilThird + thirdLetter + otherLetters;
  }

  return word.charAt(0).toUpperCase() + word.slice(1);
};
