import AddPhoneNumberArt from "../../components/icons/AddPhoneNumberArt";
import AlertIcon from "../../components/icons/AlertIcon";
import AppsIcon from "../../components/icons/AppsIcon";
import ArrowBottomIcon from "../../components/icons/ArrowBottomIcon";
import ArrowBottomLeftIcon from "../../components/icons/ArrowBottomLeftIcon";
import ArrowBottomRightIcon from "../../components/icons/ArrowBottomRightIcon";
import ArrowLeftIcon from "../../components/icons/ArrowLeftIcon";
import ArrowRightIcon from "../../components/icons/ArrowRightIcon";
import ArrowTopIcon from "../../components/icons/ArrowTopIcon";
import ArrowTopLeftIcon from "../../components/icons/ArrowTopLeftIcon";
import ArrowTopRightIcon from "../../components/icons/ArrowTopRightIcon";
import AtIcon from "../../components/icons/AtIcon";
import AttachmentIcon from "../../components/icons/AttachmentIcon";
import BabyIcon from "../../components/icons/BabyIcon";
import BackgroundProfileImageIcon from "../../components/icons/BackgroundProfileImageIcon";
import BeachIcon from "../../components/icons/BeachIcon";
import BehanceIcon from "../../components/icons/BehanceIcon";
import BookOneIcon from "../../components/icons/BookOneIcon";
import BookThreeIcon from "../../components/icons/BookThreeIcon";
import BookTwoIcon from "../../components/icons/BookTwoIcon";
import BoxIcon from "../../components/icons/BoxIcon";
import BubblesIcon from "../../components/icons/BubblesIcon";
import BusIcon from "../../components/icons/BusIcon";
import CalendarIcon from "../../components/icons/CalendarIcon";
import CallIcon from "../../components/icons/CallIcon";
import CameraIcon from "../../components/icons/CameraIcon";
import CameraSpecialIcon from "../../components/icons/CameraSpecialIcon";
import CartIcon from "../../components/icons/CartIcon";
import ChartBarsIcon from "../../components/icons/ChartBarsIcon";
import ChartIcon from "../../components/icons/ChartIcon";
import ChatIcon from "../../components/icons/ChatIcon";
import CheckCircleIcon from "../../components/icons/CheckCircleIcon";
import CheckIcon from "../../components/icons/CheckIcon";
import CheckOneIcon from "../../components/icons/CheckOneIcon";
import Chevron from "../../components/icons/Chevron";
import ClockIcon from "../../components/icons/ClockIcon";
import ClockWatchIcon from "../../components/icons/ClockWatchIcon";
import CloseIcon from "../../components/icons/CloseIcon";
import CoffeeIcon from "../../components/icons/CoffeeIcon";
import CopyIcon from "../../components/icons/CopyIcon";
import CreditCardIcon from "../../components/icons/CreditCardIcon";
import CustomStantiArt from "../../components/icons/CustomStantiArt";
import DiamondIcon from "../../components/icons/DiamondIcon";
import DiceIcon from "../../components/icons/DiceIcon";
import DocumentIcon from "../../components/icons/DocumentIcon";
import DownloadIcon from "../../components/icons/DownloadIcon";
import DribbbleIcon from "../../components/icons/DribbbleIcon";
import EditIcon from "../../components/icons/EditIcon";
import EditTwoIcon from "../../components/icons/EditTwoIcon";
import EmailTwoIcon from "../../components/icons/EmailTwoIcon";
import ExploreIcon from "../../components/icons/ExploreIcon";
import FacebookIcon from "../../components/icons/FacebookIcon";
import FastFoodIcon from "../../components/icons/FastFoodIcon";
import FilesIcon from "../../components/icons/FilesIcon";
import FingerPrintIcon from "../../components/icons/FingerPrintIcon";
import FlowerIcon from "../../components/icons/FlowerIcon";
import FolderIcon from "../../components/icons/FolderIcon";
import ForwardIcon from "../../components/icons/ForwardIcon";
import GameIcon from "../../components/icons/GameIcon";
import GiftIcon from "../../components/icons/GiftIcon";
import GoogleIcon from "../../components/icons/GoogleIcon";
import GymIcon from "../../components/icons/GymIcon";
import HeadsetIcon from "../../components/icons/HeadsetIcon";
import HeartIcon from "../../components/icons/HeartIcon";
import HeartOutlineIcon from "../../components/icons/HeartOutlineIcon";
import HidePasswordIcon from "../../components/icons/HidePasswordIcon";
import HomeIcon from "../../components/icons/HomeIcon";
import IdeaIcon from "../../components/icons/IdeaIcon";
import InfoIcon from "../../components/icons/InfoIcon";
import InfoOneIcon from "../../components/icons/InfoOneIcon";
import InfoOutlineIcon from "../../components/icons/InfoOutlineIcon";
import InstagramIcon from "../../components/icons/InstagramIcon";
import InstagramTwoIcon from "../../components/icons/InstagramTwoIcon";
import KeyDownIcon from "../../components/icons/KeyDownIcon";
import KeyUpIcon from "../../components/icons/KeyUpIcon";
import LabelIcon from "../../components/icons/LabelIcon";
import LearnIcon from "../../components/icons/LearnIcon";
import LinkedinIcon from "../../components/icons/LinkedinIcon";
import LinkIcon from "../../components/icons/LinkIcon";
import LoadingIcon from "../../components/icons/LoadingIcon";
import LockClosedIcon from "../../components/icons/LockClosedIcon";
import LockOpenIcon from "../../components/icons/LockOpenIcon";
import LoveTagIcon from "../../components/icons/LoveTagIcon";
import MessageIcon from "../../components/icons/MessageIcon";
import MessengerIcon from "../../components/icons/MessengerIcon";
import MicrophoneIcon from "../../components/icons/MicrophoneIcon";
import MonetizeStantiIcon from "../../components/icons/MonetizeStantiIcon";
import MoneyOneIcon from "../../components/icons/MoneyOneIcon";
import MoneyTwoIcon from "../../components/icons/MoneyTwoIcon";
import MouseIcon from "../../components/icons/MouseIcon";
import MovieIcon from "../../components/icons/MovieIcon";
import MusicIcon from "../../components/icons/MusicIcon";
import NearIcon from "../../components/icons/NearIcon";
import NightIcon from "../../components/icons/NightIcon";
import NotebookIcon from "../../components/icons/NotebookIcon";
import NotificationsIcon from "../../components/icons/NotificationsIcon";
import OnlineCallIcon from "../../components/icons/OnlineCallIcon";
import PersonGroupIcon from "../../components/icons/PersonGroupIcon";
import PersonOneIcon from "../../components/icons/PersonOneIcon";
import PersonPicsIcon from "../../components/icons/PersonPicsIcon";
import PersonPinIcon from "../../components/icons/PersonPinIcon";
import PersonPinTwoIcon from "../../components/icons/PersonPinTwoIcon";
import PersonTwoIcon from "../../components/icons/PersonTwoIcon";
import PetIcon from "../../components/icons/PetIcon";
import PhoneIcon from "../../components/icons/PhoneIcon";
import PhoneMessageIcon from "../../components/icons/PhoneMessageIcon";
import PinIcon from "../../components/icons/PinIcon";
import PinterestIcon from "../../components/icons/PinterestIcon";
import PixIcon from "../../components/icons/PixIcon";
import PizzaIcon from "../../components/icons/PizzaIcon";
import PlaceIcon from "../../components/icons/PlaceIcon";
import PlayIcon from "../../components/icons/PlayIcon";
import PlusIcon from "../../components/icons/PlusIcon";
import ProfileIcon from "../../components/icons/ProfileIcon";
import ProfileIconCircle from "../../components/icons/ProfileIconCircle";
import ProfileImagePlaceholder from "../../components/icons/ProfileImagePlaceholder";
import PuzzleIcon from "../../components/icons/PuzzleIcon";
import QuestionIcon from "../../components/icons/QuestionIcon";
import RestaurantIcon from "../../components/icons/RestaurantIcon";
import SearchIcon from "../../components/icons/SearchIcon";
import SearchOneIcon from "../../components/icons/SearchOneIcon";
import ShowPasswordIcon from "../../components/icons/ShowPasswordIcon";
import SmartphoneIcon from "../../components/icons/SmartphoneIcon";
import SmileOneIcon from "../../components/icons/SmileOneIcon";
import SmileThreeIcon from "../../components/icons/SmileThreeIcon";
import SmileTwoIcon from "../../components/icons/SmileTwoIcon";
import SocialMedia from "../../components/icons/SocialMedia";
import SongIcon from "../../components/icons/SongIcon";
import StantiIcon from "../../components/icons/StantiIcon";
import StarIcon from "../../components/icons/StarIcon";
import StarOutlineIcon from "../../components/icons/StarOutlineIcon";
import StoreBagIcon from "../../components/icons/StoreBagIcon";
import StoreIcon from "../../components/icons/StoreIcon";
import TagIcon from "../../components/icons/TagIcon";
import TelegramIcon from "../../components/icons/TelegramIcon";
import ThumbIcon from "../../components/icons/ThumbIcon";
import TicketIcon from "../../components/icons/TicketIcon";
import TiktokIcon from "../../components/icons/TiktokIcon";
import TimerIcon from "../../components/icons/TimerIcon";
import TodoIcon from "../../components/icons/TodoIcon";
import TransmissionIcon from "../../components/icons/TransmissionIcon";
import TrashCanIcon from "../../components/icons/TrashCanIcon";
import TwitchIcon from "../../components/icons/TwitchIcon";
import TwitterIcon from "../../components/icons/TwitterIcon";
import UserIcon from "../../components/icons/UserIcon";
import VerifiedIcon from "../../components/icons/VerifiedIcon";
import VideoOneIcon from "../../components/icons/VideoOneIcon";
import VideoTwoIcon from "../../components/icons/VideoTwoIcon";
import VoiceOverIcon from "../../components/icons/VoiceOverIcon";
import VolumeIcon from "../../components/icons/VolumeIcon";
import WarningIcon from "../../components/icons/WarningIcon";
import WarningOutlineIcon from "../../components/icons/WarningOutlineIcon";
import WebIcon from "../../components/icons/WebIcon";
import WhatsappIcon from "../../components/icons/WhatsappIcon";
import WorkIcon from "../../components/icons/WorkIcon";
import WorldIcon from "../../components/icons/WorldIcon";
import YoutubeIcon from "../../components/icons/YoutubeIcon";

import ReceiptIcon from "../../components/icons/ReceiptIcon";

import * as S from "./styles";

const IconsPage = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development")
    return null;

  const allIcons = [
    { icon: <AddPhoneNumberArt />, name: "AddPhoneNumberArt" },
    { icon: <AlertIcon />, name: "AlertIcon" },
    { icon: <AppsIcon />, name: "AppsIcon" },
    { icon: <ArrowBottomIcon />, name: "ArrowBottomIcon" },
    { icon: <ArrowBottomLeftIcon />, name: "ArrowBottomLeftIcon" },
    { icon: <ArrowBottomRightIcon />, name: "ArrowBottomRightIcon" },
    { icon: <ArrowLeftIcon />, name: "ArrowLeftIcon" },
    { icon: <ArrowRightIcon />, name: "ArrowRightIcon" },
    { icon: <ArrowTopIcon />, name: "ArrowTopIcon" },
    { icon: <ArrowTopLeftIcon />, name: "ArrowTopLeftIcon" },
    { icon: <ArrowTopRightIcon />, name: "ArrowTopRightIcon" },
    { icon: <AtIcon />, name: "AtIcon" },
    { icon: <AttachmentIcon />, name: "AttachmentIcon" },
    { icon: <BabyIcon />, name: "BabyIcon" },
    { icon: <BeachIcon />, name: "BeachIcon" },
    { icon: <BehanceIcon />, name: "BehanceIcon" },
    { icon: <BookOneIcon />, name: "BookOneIcon" },
    { icon: <BookThreeIcon />, name: "BookThreeIcon" },
    { icon: <BookTwoIcon />, name: "BookTwoIcon" },
    { icon: <BoxIcon />, name: "BoxIcon" },
    { icon: <BubblesIcon />, name: "BubblesIcon" },
    { icon: <BusIcon />, name: "BusIcon" },
    { icon: <CalendarIcon />, name: "CalendarIcon" },
    { icon: <CallIcon />, name: "CallIcon" },
    { icon: <CameraIcon />, name: "CameraIcon" },
    { icon: <CameraSpecialIcon />, name: "CameraSpecialIcon" },
    { icon: <CartIcon />, name: "CartIcon" },
    { icon: <ChartBarsIcon />, name: "ChartBarsIcon" },
    { icon: <ChartIcon />, name: "ChartIcon" },
    { icon: <ChatIcon />, name: "ChatIcon" },
    { icon: <CheckCircleIcon />, name: "CheckCircleIcon" },
    { icon: <CheckIcon />, name: "CheckIcon" },
    { icon: <CheckOneIcon />, name: "CheckOneIcon" },
    { icon: <Chevron />, name: "Chevron" },
    { icon: <ClockIcon />, name: "ClockIcon" },
    { icon: <ClockWatchIcon />, name: "ClockWatchIcon" },
    { icon: <CloseIcon />, name: "CloseIcon" },
    { icon: <CoffeeIcon />, name: "CoffeeIcon" },
    { icon: <CopyIcon />, name: "CopyIcon" },
    { icon: <CreditCardIcon />, name: "CreditCardIcon" },
    { icon: <CustomStantiArt />, name: "CustomStantiArt" },
    { icon: <DiamondIcon />, name: "DiamondIcon" },
    { icon: <DiceIcon />, name: "DiceIcon" },
    { icon: <DocumentIcon />, name: "DocumentIcon" },
    { icon: <DownloadIcon />, name: "DownloadIcon" },
    { icon: <DribbbleIcon />, name: "DribbbleIcon" },
    { icon: <EditIcon />, name: "EditIcon" },
    { icon: <EditTwoIcon />, name: "EditTwoIcon" },
    { icon: <EmailTwoIcon />, name: "EmailTwoIcon" },
    { icon: <ExploreIcon />, name: "ExploreIcon" },
    { icon: <FacebookIcon />, name: "FacebookIcon" },
    { icon: <FastFoodIcon />, name: "FastFoodIcon" },
    { icon: <FilesIcon />, name: "FilesIcon" },
    { icon: <FingerPrintIcon />, name: "FingerPrintIcon" },
    { icon: <FlowerIcon />, name: "FlowerIcon" },
    { icon: <FolderIcon />, name: "FolderIcon" },
    { icon: <ForwardIcon />, name: "ForwardIcon" },
    { icon: <GameIcon />, name: "GameIcon" },
    { icon: <GiftIcon />, name: "GiftIcon" },
    { icon: <GoogleIcon />, name: "GoogleIcon" },
    { icon: <GymIcon />, name: "GymIcon" },
    { icon: <HeadsetIcon />, name: "HeadsetIcon" },
    { icon: <HeartIcon />, name: "HeartIcon" },
    { icon: <HeartOutlineIcon />, name: "HeartOutlineIcon" },
    {
      icon: (
        <HidePasswordIcon
          onClick={() => {
            return;
          }}
        />
      ),
      name: "HidePasswordIcon",
    },
    { icon: <HomeIcon />, name: "HomeIcon" },
    { icon: <IdeaIcon />, name: "IdeaIcon" },
    { icon: <InfoIcon />, name: "InfoIcon" },
    { icon: <InfoOneIcon />, name: "InfoOneIcon" },
    { icon: <InfoOutlineIcon />, name: "InfoOutlineIcon" },
    { icon: <InstagramIcon />, name: "InstagramIcon" },
    { icon: <InstagramTwoIcon />, name: "InstagramTwoIcon" },
    { icon: <KeyDownIcon />, name: "KeyDownIcon" },
    { icon: <KeyUpIcon />, name: "KeyUpIcon" },
    { icon: <LabelIcon />, name: "LabelIcon" },
    { icon: <LearnIcon />, name: "LearnIcon" },
    { icon: <LinkedinIcon />, name: "LinkedinIcon" },
    { icon: <LinkIcon />, name: "LinkIcon" },
    { icon: <LoadingIcon />, name: "LoadingIcon" },
    { icon: <LockClosedIcon />, name: "LockClosedIcon" },
    { icon: <LockOpenIcon />, name: "LockOpenIcon" },
    { icon: <LoveTagIcon />, name: "LoveTagIcon" },
    { icon: <MessageIcon />, name: "MessageIcon" },
    { icon: <MessengerIcon />, name: "MessengerIcon" },
    { icon: <MicrophoneIcon />, name: "MicrophoneIcon" },
    { icon: <MonetizeStantiIcon />, name: "MonetizeStantiIcon" },
    { icon: <MoneyOneIcon />, name: "MoneyOneIcon" },
    { icon: <MoneyTwoIcon />, name: "MoneyTwoIcon" },
    { icon: <MouseIcon />, name: "MouseIcon" },
    { icon: <MovieIcon />, name: "MovieIcon" },
    { icon: <MusicIcon />, name: "MusicIcon" },
    { icon: <NearIcon />, name: "NearIcon" },
    { icon: <NightIcon />, name: "NightIcon" },
    { icon: <NotebookIcon />, name: "NotebookIcon" },
    { icon: <NotificationsIcon />, name: "NotificationsIcon" },
    { icon: <OnlineCallIcon />, name: "OnlineCallIcon" },
    { icon: <PersonOneIcon />, name: "PersonOneIcon" },
    { icon: <PersonPicsIcon />, name: "PersonPicsIcon" },
    { icon: <PersonPinIcon />, name: "PersonPinIcon" },
    { icon: <PersonPinTwoIcon />, name: "PersonPinTwoIcon" },
    { icon: <PersonTwoIcon />, name: "PersonTwoIcon" },
    { icon: <PetIcon />, name: "PetIcon" },
    { icon: <PhoneIcon />, name: "PhoneIcon" },
    { icon: <PhoneMessageIcon />, name: "PhoneMessageIcon" },
    { icon: <PinIcon />, name: "PinIcon" },
    { icon: <PinterestIcon />, name: "PinterestIcon" },
    { icon: <PixIcon />, name: "PixIcon" },
    { icon: <PizzaIcon />, name: "PizzaIcon" },
    { icon: <PlaceIcon />, name: "PlaceIcon" },
    { icon: <PlayIcon />, name: "PlayIcon" },
    { icon: <PlusIcon />, name: "PlusIcon" },
    { icon: <ProfileIcon />, name: "ProfileIcon" },
    { icon: <ProfileImagePlaceholder />, name: "ProfileImagePlaceholder" },
    { icon: <PuzzleIcon />, name: "PuzzleIcon" },
    { icon: <QuestionIcon />, name: "QuestionIcon" },
    { icon: <RestaurantIcon />, name: "RestaurantIcon" },
    { icon: <SearchIcon />, name: "SearchIcon" },
    { icon: <SearchOneIcon />, name: "SearchOneIcon" },
    { icon: <ShowPasswordIcon />, name: "ShowPasswordIcon" },
    { icon: <SmartphoneIcon />, name: "SmartphoneIcon" },
    { icon: <SmileOneIcon />, name: "SmileOneIcon" },
    { icon: <SmileTwoIcon />, name: "SmileTwoIcon" },
    { icon: <SmileThreeIcon />, name: "SmileThreeIcon" },
    { icon: <SongIcon />, name: "SongIcon" },
    { icon: <StantiIcon />, name: "StantiIcon" },
    { icon: <StarIcon />, name: "StarIcon" },
    { icon: <StarOutlineIcon />, name: "StarOutlineIcon" },
    { icon: <StoreBagIcon />, name: "StoreBagIcon" },
    { icon: <StoreIcon />, name: "StoreIcon" },
    { icon: <TagIcon />, name: "TagIcon" },
    { icon: <TelegramIcon />, name: "TelegramIcon" },
    { icon: <ThumbIcon />, name: "ThumbIcon" },
    { icon: <TicketIcon />, name: "TicketIcon" },
    { icon: <TiktokIcon />, name: "TiktokIcon" },
    { icon: <TimerIcon />, name: "TimerIcon" },
    { icon: <TodoIcon />, name: "TodoIcon" },
    { icon: <TransmissionIcon />, name: "TransmissionIcon" },
    { icon: <TrashCanIcon />, name: "TrashCanIcon" },
    { icon: <TwitchIcon />, name: "TwitchIcon" },
    { icon: <TwitterIcon />, name: "TwitterIcon" },
    { icon: <UserIcon />, name: "UserIcon" },
    { icon: <VerifiedIcon />, name: "VerifiedIcon" },
    { icon: <VideoOneIcon />, name: "VideoOneIcon" },
    { icon: <VideoTwoIcon />, name: "VideoTwoIcon" },
    { icon: <VoiceOverIcon />, name: "VoiceOverIcon" },
    { icon: <VolumeIcon />, name: "VolumeIcon" },
    { icon: <WarningIcon />, name: "WarningIcon" },
    { icon: <WarningOutlineIcon />, name: "WarningOutlineIcon" },
    { icon: <WebIcon />, name: "WebIcon" },
    { icon: <WhatsappIcon />, name: "WhatsappIcon" },
    { icon: <WorkIcon />, name: "WorkIcon" },
    { icon: <WorldIcon />, name: "WorldIcon" },
    { icon: <YoutubeIcon />, name: "YoutubeIcon" },
    { icon: <PersonGroupIcon />, name: "PersonGroupIcon" },
    { icon: <ProfileIconCircle />, name: "CricleProfileIcon" },
    { icon: <ReceiptIcon />, name: "ReceiptIcon" },
    {
      icon: <BackgroundProfileImageIcon />,
      name: "BackgroundProfileImageIcon",
    },
    { icon: <SocialMedia />, name: "SocialMedia" },
  ];

  return (
    <S.Container>
      <h2>Icons</h2>

      <S.Content>
        {allIcons.map((icon) => (
          <S.Icon key={icon.name}>
            {icon.name}
            {icon.icon}
          </S.Icon>
        ))}
      </S.Content>
    </S.Container>
  );
};

export default IconsPage;
