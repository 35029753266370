import Label from "../../../../components/atoms/Typography/Label";
import Small from "../../../../components/atoms/Typography/Small";
import CheckIcon from "../../../../components/icons/CheckIcon";
import CloseIcon from "../../../../components/icons/CloseIcon";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  title: string;
  segment: string;
  imageUrl: string;
  disabled: boolean;
  onCopyCode: () => void;
  onClickCard: () => void;
  onApplyForCampaign: () => void;
  status?: "pending" | "approved" | "rejected";
  campaignStatus: "active" | "paused" | "expired" | "upcoming";
}

const buttonLabels = {
  rejected: "Cupons esgotados",
  approved: "Copiar seu código",
  pending: "Solicitação em análise",
  paused: "Campanha pausada",
  expired: "Campanha finalizada",
  upcoming: "Em breve!",
};

const CampaignCard: React.FC<IProps> = ({
  title,
  status,
  segment,
  imageUrl,
  disabled,
  onCopyCode,
  onClickCard,
  campaignStatus,
  onApplyForCampaign,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const isApproved = status && status === "approved";

  const onClickHandler = () => {
    if (disabled) return;

    if (isApproved) onCopyCode();
    if (!status) onApplyForCampaign();
  };

  const iconRender = () => {
    if (campaignStatus === "active" && (!status || status === "pending"))
      return null;

    return (
      <S.Icon
        opacity={isApproved ? 1 : 0.5}
        borderColor={isApproved ? primaryColor : "#fafafa"}
      >
        {isApproved ? <CheckIcon /> : <CloseIcon color="#fafafa" />}
      </S.Icon>
    );
  };

  const isDisabled =
    disabled ||
    (status && ["rejected", "pending"].includes(status)) ||
    (campaignStatus !== "active" && status !== "approved");

  const label = (() => {
    if (status) return buttonLabels[status];

    if (campaignStatus !== "active") return buttonLabels[campaignStatus];

    return "Solicitar código";
  })();

  return (
    <S.CampaignCard onClick={onClickCard}>
      <S.Head>
        <S.Left>
          <Label color={textColor}>{segment}</Label>
          <Small color={textColor}>{title}</Small>
        </S.Left>

        {iconRender()}
      </S.Head>

      <S.Logo imageUrl={imageUrl} />

      <S.Button
        onClick={(e) => {
          e.stopPropagation();
          onClickHandler();
        }}
        disabled={isDisabled}
        borderColor={primaryColor}
        textColor={isApproved ? "black" : primaryColor}
        backgroundColor={isApproved ? primaryColor : "none"}
      >
        {label}
      </S.Button>
    </S.CampaignCard>
  );
};

export default CampaignCard;
