import styled from "styled-components";

export const Extrato = styled.div`
  padding: 15px 0;
`;

export const Filters = styled.div`
  gap: 12px;
  display: flex;
  margin-top: 12px;
  margin-bottom: 20px;
`;
