import styled, { css } from "styled-components";

type IconProps = {
  opacity: number;
  borderColor: string;
};

export const Icon = styled.div<IconProps>`
  ${({ opacity, borderColor }) => css`
    width: 26px;
    height: 26px;
    padding: 6px;
    display: flex;
    border: 1px solid ${borderColor};
    align-items: center;
    border-radius: 12px;
    justify-content: center;

    opacity: ${opacity};
  `}
`;

export const CampaignCard = styled.div`
  gap: 12px;
  display: flex;
  padding: 12px;
  height: 168px;
  cursor: pointer;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid #fafafa20;
  border-right: 1px solid #fafafa20;
  background-color: rgba(255, 255, 255, 0.15);
`;

export const Head = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Left = styled.div`
  gap: 2px;
  display: flex;
  min-height: 26px;
  padding-left: 6px;
  flex-direction: column;

  small:first-of-type {
    opacity: 0.5;
  }
`;

type LogoProps = {
  imageUrl: string;
};

export const Logo = styled.div<LogoProps>`
  width: 100%;
  flex-grow: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
`;

type ButtonProps = {
  borderColor: string;
  textColor: string;
  backgroundColor: string;
};

export const Button = styled.button<ButtonProps>`
  ${({ borderColor, textColor, backgroundColor }) => css`
    height: 36px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid ${borderColor};
    color: ${textColor};
    background-color: ${backgroundColor};

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      color: #fafafa !important;
      border-color: #fafafa !important;
    }
  `}
`;
