import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PetIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.63 0C10.9416 0 8.76217 2.17942 8.76217 4.86787C8.76217 7.55632 10.9416 9.73574 13.63 9.73574C16.3185 9.73574 18.4979 7.55632 18.4979 4.86787C18.4979 2.17942 16.3185 0 13.63 0ZM4.86787 7.78859C2.17942 7.78859 0 9.96801 0 12.6565C0 15.3449 2.17942 17.5243 4.86787 17.5243C7.55632 17.5243 9.73574 15.3449 9.73574 12.6565C9.73574 9.96801 7.55632 7.78859 4.86787 7.78859ZM20.4451 4.86787C20.4451 2.17942 22.6245 0 25.3129 0C28.0014 0 30.1808 2.17942 30.1808 4.86787C30.1808 7.55632 28.0014 9.73574 25.3129 9.73574C22.6245 9.73574 20.4451 7.55632 20.4451 4.86787ZM34.0751 7.78859C31.3866 7.78859 29.2072 9.96801 29.2072 12.6565C29.2072 15.3449 31.3866 17.5243 34.0751 17.5243C36.7635 17.5243 38.943 15.3449 38.943 12.6565C38.943 9.96801 36.7635 7.78859 34.0751 7.78859ZM28.1234 21.04C28.6874 21.7048 29.2604 22.3802 29.8686 23.0932C30.0203 23.2449 30.1741 23.3976 30.329 23.5514C32.779 25.9838 35.5184 28.7035 34.9506 32.4395C34.386 34.4061 32.9645 36.3922 30.4138 36.9569C29.7596 37.0823 28.4456 36.9108 26.7469 36.6892C24.7546 36.4292 22.2331 36.1001 19.6266 36.1001H19.2761C16.6915 36.1001 14.1905 36.4292 12.2061 36.6904C10.4831 36.9171 9.14951 37.0926 8.48889 36.9569C5.93813 36.3922 4.51671 34.4256 3.95204 32.4395C3.4029 28.7236 6.14046 26.0058 8.58967 23.5743C8.74578 23.4193 8.90072 23.2655 9.05357 23.1126C10.1519 21.8395 11.1303 20.6865 12.1374 19.4995C12.7014 18.8348 13.2744 18.1594 13.8825 17.4464C14.7977 16.395 15.927 15.3435 17.29 14.8762C17.5042 14.7983 17.7184 14.7399 17.9326 14.701C18.4388 14.6036 18.9646 14.6036 19.4708 14.6036C19.9771 14.6036 20.5028 14.6036 20.9896 14.6815C21.2038 14.7204 21.418 14.7789 21.6321 14.8567C22.9951 15.3241 24.144 16.3755 25.0397 17.427C26.138 18.7 27.1163 19.8531 28.1234 21.04Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PetIcon;
