import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const RestaurantIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="41"
      height="38"
      viewBox="0 0 41 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7369 14.8065C33.3817 18.1617 28.8448 19.2168 25.6162 17.7185L22.5143 20.8205L35.5552 33.8615C36.3782 34.6844 36.3782 36.0138 35.5552 36.8368C34.7322 37.6598 33.4028 37.6598 32.5798 36.8368L19.5389 23.7959L6.49793 36.7946C5.67496 37.6176 4.34554 37.6176 3.52257 36.7946C2.6996 35.9716 2.6996 34.6422 3.52257 33.8193L22.6198 14.7221C21.1215 11.4935 22.1766 6.95656 25.5318 3.60137C29.5834 -0.429092 35.3653 -1.18876 38.4462 1.89211C41.5481 4.99409 40.7674 10.776 36.7369 14.8065ZM17.2819 15.5661L11.3101 21.538L2.46843 12.7174C-0.127101 10.1007 -0.675749 6.23911 0.822484 3.09493C1.45554 1.78661 3.207 1.51229 4.21988 2.52518L17.2819 15.5661Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default RestaurantIcon;
