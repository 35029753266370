import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const EditIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.71 2.63249C18.1 3.02249 18.1 3.65249 17.71 4.04249L15.88 5.87249L12.13 2.12249L13.96 0.292486C14.1468 0.105233 14.4005 0 14.665 0C14.9295 0 15.1832 0.105233 15.37 0.292486L17.71 2.63249ZM0 17.5025V14.4625C0 14.3225 0.05 14.2025 0.15 14.1025L11.06 3.19249L14.81 6.94249L3.89 17.8525C3.8 17.9525 3.67 18.0025 3.54 18.0025H0.5C0.22 18.0025 0 17.7825 0 17.5025Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default EditIcon;
