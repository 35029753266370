import styled from "styled-components";

export const SocialMediaInput = styled.div`
  cursor: text;
  display: flex;
  margin-top: 8px;
  overflow: hidden;
  padding: 8px 16px;
  border-radius: 40px;
  flex-direction: row;
  align-items: baseline;
  mix-blend-mode: normal;
  background-blend-mode: overlay;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #fafafa18;
  justify-content: flex-start;

  p {
    flex-shrink: 0;
  }
`;

type InputProps = {
  textColor: string;
};

export const Input = styled.input<InputProps>`
  flex-grow: 1;
  outline: none;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 12px;
  color: ${({ textColor }) => textColor};

  &::placeholder {
    font-size: 14px;
    color: #71726f;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-self: center;
`;
