import { useNavigate } from "react-router-dom";

import { IForm } from "../..";
import Page from "../../../../../../components/atoms/Page";
import FormUploadFileOrAddLink from "../FormUploadFileOrAddLink";
import appConfig from "../../../../../../config/app-arquivo.json";
import AppFormFAQ from "../../../../../../components/atoms/AppFormFAQ";
import AppFormTags from "../../../../../../components/atoms/AppFormTags";
import AppFormPrice from "../../../../../../components/atoms/AppFormPrice";
import AppFormTitle from "../../../../../../components/atoms/AppFormTitle";
import AppFormHeader from "../../../../../../components/atoms/AppFormHeader";
import AppFormFooter from "../../../../../../components/molecules/AppFormFooter";
import AppFormSection from "../../../../../../components/molecules/AppFormSection";
import AppFormDescription from "../../../../../../components/atoms/AppFormDescription";
import AppFormAboutAuthor from "../../../../../../components/atoms/AppFormAboutAuthor";
import AppFormPagesQuantity from "../../../../../../components/atoms/AppFormPagesQuantity";
import AppFormTargetAudience from "../../../../../../components/atoms/AppFormTargetAudience";

interface IProps {
  item: IForm;
  onSave: () => void;
  providedURL: string;
  onDelete: () => void;
  isEditingItem: boolean;
  uploadedFileURL: string;
  onRemoveFile: () => void;
  isUploadingFile: boolean;
  fileUploadProgress?: number;
  onUploadFile: (file: File) => void;
  errors: { [key: string]: string[] };
  setProvidedUrl: (val: string) => void;
  onChangeHandler: (value: any, field: keyof IForm) => void;
}

const FormPDF: React.FC<IProps> = ({
  item,
  errors,
  onSave,
  onDelete,
  providedURL,
  onRemoveFile,
  onUploadFile,
  isEditingItem,
  setProvidedUrl,
  uploadedFileURL,
  onChangeHandler,
  isUploadingFile,
  fileUploadProgress,
}) => {
  const navigate = useNavigate();

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps/${appConfig.id}`)}
      />

      <FormUploadFileOrAddLink
        title="PDF"
        icon="document"
        errors={errors.url}
        providedUrl={providedURL}
        onRemoveFile={onRemoveFile}
        onUploadFile={onUploadFile}
        uploadedUrl={uploadedFileURL}
        isUploadingFile={isUploadingFile}
        onChangeProvidedUrl={setProvidedUrl}
        fileUploadProgress={fileUploadProgress}
        coverImage={(item.media || [])[0] || ""}
        onRemoveCover={() => onChangeHandler([], "media")}
        onChangeCover={(val) => onChangeHandler([val], "media")}
      />

      <AppFormSection title="Sobre o PDF">
        <AppFormTitle
          errors={errors.title}
          value={item.title || ""}
          placeholder="Título do arquivo"
          onChange={(val) => onChangeHandler(val, "title")}
        />

        <AppFormDescription
          errors={errors.description}
          value={item.description || ""}
          placeholder="Uma breve descrição sobre o material"
          onChange={(val) => onChangeHandler(val, "description")}
        />

        <AppFormPagesQuantity
          value={item.pages || ""}
          onChangeValue={(val) => onChangeHandler(val, "pages")}
        />
      </AppFormSection>

      <AppFormSection title="Informações adicionais">
        <AppFormTargetAudience
          textLabelType="PDF"
          errors={errors.targetAudience}
          value={item.targetAudience || ""}
          onChange={(val) => onChangeHandler(val, "targetAudience")}
        />

        <AppFormAboutAuthor
          errors={errors.aboutAuthor}
          value={item.aboutAuthor || ""}
          onChange={(val) => onChangeHandler(val, "aboutAuthor")}
        />
      </AppFormSection>

      <AppFormSection>
        <AppFormFAQ
          errors={errors.faq}
          faqs={item.faq || []}
          onChange={(val) => onChangeHandler(val, "faq")}
        />
      </AppFormSection>

      <AppFormSection title="Tags">
        <AppFormTags
          errors={errors.tags}
          value={item.tags || []}
          onChange={(val) => onChangeHandler(val, "tags")}
        />
      </AppFormSection>

      <AppFormSection title="Preço">
        <AppFormPrice
          appId={appConfig.id}
          errors={errors.price}
          value={item.price || 0}
          onChange={(val) => onChangeHandler(val, "price")}
        />
      </AppFormSection>

      <AppFormFooter
        onSave={onSave}
        appTitle="PDF"
        saveDraft={false}
        onRemove={onDelete}
        isEditingItem={isEditingItem}
      />
    </Page>
  );
};

export default FormPDF;
