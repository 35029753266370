import axios from "axios";

import {
  IBlock,
  ISchedule,
  ICalendar,
  ICalendarResponse,
  ILunchBlockResponse,
  IGetAppointmentsAndBlocksResponse,
} from "../../types/calendar";
import { logoutUserIfTokenInvalid } from "../../utils";

const { REACT_APP_CALENDAR_API_URL } = process.env;

const getCalendar = async (token: string): Promise<ICalendar> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    const { data } = await axios.get(
      `${REACT_APP_CALENDAR_API_URL}/calendar?timeZone=${timezone}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data?.calendar;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on getCalendar");
  }
};

const upsertCalendar = async (
  schedules: ISchedule[],
  token: string
): Promise<ICalendarResponse> => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    const { data } = await axios.post(
      `${REACT_APP_CALENDAR_API_URL}/calendar`,
      { timeZone, schedules },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.calendar;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on upsertCalendar");
  }
};

const getLunchTimeBlock = async (
  token: string
): Promise<ILunchBlockResponse> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    const { data } = await axios.get(
      `${REACT_APP_CALENDAR_API_URL}/calendar/lunchBlock?timeZone=${timezone}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data?.creatorLunchBlock;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on getLunchTimeBlock");
  }
};

const createBlock = async (
  block: IBlock,
  token: string
): Promise<ILunchBlockResponse> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALENDAR_API_URL}/calendar/block`,
      block,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.block;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on createBlock");
  }
};

const removeAppointment = async (id: string, token: string) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_CALENDAR_API_URL}/calendar/appointment/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on removeAppointment");
  }
};

const deleteLunchTimeBlock = async (token: string) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_CALENDAR_API_URL}/calendar/lunchBlock`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on deleteLunchTimeBlock");
  }
};

const getAppointmentsAndBlocks = async (
  token: string
): Promise<IGetAppointmentsAndBlocksResponse> => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const start = new Date().toJSON();

  try {
    const { data } = await axios.get(
      `${REACT_APP_CALENDAR_API_URL}/calendar/appointments-and-blocks?timeZone=${timeZone}&start=${start}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.items;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on getAppointmentsAndBlocks");
  }
};

export default {
  getCalendar,
  createBlock,
  upsertCalendar,
  removeAppointment,
  getLunchTimeBlock,
  deleteLunchTimeBlock,
  getAppointmentsAndBlocks,
};
