import H2 from "../../../../components/atoms/Typography/H2";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IHeader {
  onClickBack: () => void;
}

const Header: React.FC<IHeader> = ({ onClickBack }) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.Header>
      <H2 color={textColor}>Horários e agendamentos</H2>

      <S.BackButton
        type="outline"
        onClick={() => {
          onClickBack();
        }}
        color={textColor}
      >
        Voltar
      </S.BackButton>
    </S.Header>
  );
};

export default Header;
