import styled from "styled-components";
import P from "../../../../components/atoms/Typography/P";

export const MonetizeYourStanti = styled.div`
  gap: 12px;
  display: flex;
  padding: 0 20px;
  margin-top: 64px;
  text-align: center;
  position: relative;
  align-items: center;
  margin-bottom: 32px;
  flex-direction: column;

  hr {
    height: 0;
    width: 100%;
    margin-bottom: 32px;
    border-top: 1px solid #fafafa20;
  }
`;

export const IconMonetize = styled.div`
  top: 0;
  left: 50%;
  width: 96px;
  height: 64px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const TextContent = styled(P)`
  margin-bottom: 24px;

  span {
    font-weight: 500;
  }
`;

type HighlightTextProps = {
  highlightColor: string;
};

export const HighlightText = styled.span<HighlightTextProps>`
  color: ${({ highlightColor }) => highlightColor};
`;

type MercadoPagoLinkProps = {
  borderColor: string;
};

export const MercadoPagoLink = styled.a<MercadoPagoLinkProps>`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom: 1px solid;
  justify-content: space-between;

  border-color: ${({ borderColor }) => borderColor};
`;
