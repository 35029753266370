import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SearchOneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="42"
      height="41"
      viewBox="0 0 42 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4985 25.8331H30.3537L40.3106 35.837C41.2734 36.7998 41.2734 38.3732 40.3106 39.336C39.3478 40.2988 37.7744 40.2988 36.8116 39.336L26.8312 29.3556V27.5004L26.1971 26.8429C22.9094 29.6609 18.4241 31.1169 13.657 30.3184C7.12866 29.2147 1.91537 23.7666 1.11693 17.1913C-0.1042 7.25781 8.25586 -1.10225 18.1893 0.118886C24.7646 0.917318 30.2128 6.13061 31.3165 12.659C32.1149 17.4261 30.6589 21.9114 27.8409 25.1991L28.4985 25.8331ZM5.69663 15.2655C5.69663 21.1129 10.4168 25.833 16.2641 25.833C22.1115 25.833 26.8316 21.1129 26.8316 15.2655C26.8316 9.41819 22.1115 4.69805 16.2641 4.69805C10.4168 4.69805 5.69663 9.41819 5.69663 15.2655Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SearchOneIcon;
