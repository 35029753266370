import styled from "styled-components";

export const Loading = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);

  img {
    width: 120px;
    height: 120px;
  }
`;
