import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ChartIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.456 2.72739V14.7504C20.456 15.7214 21.2505 16.5159 22.2215 16.5159H34.2092C35.3391 16.5159 36.1865 15.4742 35.9393 14.362C34.4387 7.72373 29.2305 2.49788 22.6099 0.99721C21.4976 0.750041 20.456 1.59748 20.456 2.72739ZM16.8709 2.72739V33.8707C16.8709 35.0006 15.8292 35.848 14.7346 35.6008C6.84288 33.8353 0.981445 26.7557 0.981445 18.299C0.981445 9.84232 6.84288 2.7627 14.7346 0.99721C15.8292 0.750041 16.8709 1.59748 16.8709 2.72739ZM20.456 33.8706V21.8476C20.456 20.8766 21.2505 20.0821 22.2391 20.0645H34.2268C35.3391 20.0645 36.2042 21.1238 35.957 22.2184C34.4563 28.8566 29.2481 34.1001 22.6099 35.6008C21.4976 35.8479 20.456 35.0005 20.456 33.8706Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ChartIcon;
