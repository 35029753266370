import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TwitchIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.97825 0L0 2.60875V13.0425H3.58525V15H5.54265L7.4981 13.0425H10.4321L14.3449 9.1311V0H0.97825ZM2.28155 1.30355H13.041V8.4776L10.7583 10.7606H7.17245L5.21755 12.7154V10.7606H2.28155V1.30355ZM5.86824 7.82628H7.17244V3.91369H5.86824V7.82628ZM9.45429 7.82628H10.7582V3.91369H9.45429V7.82628Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TwitchIcon;
