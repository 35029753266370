import styled, { css } from "styled-components";
import ProfileImagePlaceholder from "../../icons/ProfileImagePlaceholder";

type EditableProfileImageProps = {
  shadowColor?: string;
  imageUrl: string;
  backgroundColor: string;
  width: string;
  height: string;
};

export const EditableProfileImage = styled.div<EditableProfileImageProps>`
  ${({ shadowColor, imageUrl, backgroundColor, width, height }) => css`
    display: flex;
    border-radius: 50%;
    position: relative;
    align-self: center;
    align-items: center;
    background-size: cover;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${imageUrl});
    background-color: ${backgroundColor};
    width: ${width};
    height: ${height};
    box-shadow: ${shadowColor ? `0px 0px 224px 52px ${shadowColor}80` : "none"};

    input {
      display: none;
    }
  `}
`;

export const ImagePlaceholder = styled(ProfileImagePlaceholder)`
  margin-top: 12px;
  margin-left: -4px;
`;

type EditButtonProps = {
  backgroundColor: string;
};

export const EditButton = styled.div<EditButtonProps>`
  right: -16px;
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  top: calc(50% - 12px);
  justify-content: center;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);
  background-color: ${({ backgroundColor }) => backgroundColor};

  svg {
    width: 14px;
    height: 14px;
  }
`;
