import axios from "axios";

import { logoutUserIfTokenInvalid } from "../../utils";
import { ICreatorCampaign } from "./types";

const { REACT_APP_AFFILIATES_API_URL } = process.env;

const getCreatorCampaign = async (
  campaignId: string,
  token: string
): Promise<ICreatorCampaign> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_AFFILIATES_API_URL}/creator/campaign/${campaignId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.campaign;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os dados da campanha");
  }
};

const getCreatorCampaigns = async (
  token: string
): Promise<ICreatorCampaign[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_AFFILIATES_API_URL}/creator/campaign`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.campaigns;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter as campanhas");
  }
};

const applyForCampaign = async (
  campaignId: string,
  token: string
): Promise<ICreatorCampaign[]> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_AFFILIATES_API_URL}/creator/affiliation/${campaignId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.affiliation;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao aplicar para a campanha");
  }
};

const leaveCampaign = async (
  affiliationId: string,
  token: string
): Promise<ICreatorCampaign[]> => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_AFFILIATES_API_URL}/creator/affiliation/${affiliationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.affiliation;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao sair da campanha");
  }
};

export default {
  leaveCampaign,
  applyForCampaign,
  getCreatorCampaign,
  getCreatorCampaigns,
};
