import styled from "styled-components";

type PProps = {
  color: string;
};

const P = styled.p<PProps>`
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: ${({ color }) => color};
`;

export default P;
