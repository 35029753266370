import axios, { AxiosError } from "axios";

const { REACT_APP_OAUTH_BASE_REDIRECT_URL, REACT_APP_CREATOR_API_URL } =
  process.env;

const getGoogleOAuthURI = (redirectTo: "login" | "register") => {
  const CLIENT_ID =
    "1080736156301-ga6dnigbs2s514e0tfk2r5jgkvphbit1.apps.googleusercontent.com";
  const REDIRECT_URI = encodeURI(
    `${REACT_APP_OAUTH_BASE_REDIRECT_URL}/${redirectTo}`
  );
  const SCOPE = encodeURI("profile email");

  return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=token&scope=${SCOPE}`;
};

const signWithOAuth = async (
  accessToken: string,
  oAuthProvider: string,
  username?: string,
  origin?: { [key: string]: string }
) => {
  try {
    const BASE_URL = `${REACT_APP_CREATOR_API_URL}/oauth/${oAuthProvider}`;
    const queryParams = [`access_token=${accessToken}`];

    if (username) queryParams.push(`username=${username}`);

    const leadData: { [key: string]: unknown } = {};

    if (origin) leadData.origin = origin;

    const { data } = await axios.post(
      `${BASE_URL}?${queryParams.join("&")}`,
      leadData
    );

    return data;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    switch (errorCode) {
      case "NOTR001":
        throw new Error("Parece que você ainda não se cadastrou");
      default:
        throw new Error("Houve um erro ao fazer o login");
    }
  }
};

const getFacebookOAuthURI = (redirectTo: "login" | "register") => {
  const BASE_URL = "https://www.facebook.com/v13.0/dialog/oauth?";
  const CLIENT_ID = "748131913189372";
  const REDIRECT_URI = encodeURI(
    `${REACT_APP_OAUTH_BASE_REDIRECT_URL}/${redirectTo}`
  );
  const STATE = "state";
  const RESPONSE_TYPE = "token";
  const SCOPE = "email";

  return `${BASE_URL}client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${STATE}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}`;
};

export default {
  signWithOAuth,
  getGoogleOAuthURI,
  getFacebookOAuthURI,
};
