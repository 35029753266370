import styled, { css } from "styled-components";
import Small from "../../atoms/Typography/Small";

export const SalesItem = styled.div`
  display: flex;
  padding: 15px 0;
  cursor: pointer;
  color: #fafafa;
  user-select: none;
  align-items: flex-start;
  border-bottom: 0.5px solid #fafafa40;

  &:last-of-type {
    border: none;
  }
`;

type BuyerNameProps = {
  borderColor: string;
};

export const BuyerName = styled(Small)<BuyerNameProps>`
  width: 34px;
  height: 34px;
  display: flex;
  flex-shrink: 0;
  color: #fafafa;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ borderColor }) => borderColor};
`;

export const ProductSale = styled.div`
  gap: 5px;
  width: 100%;
  display: flex;
  padding-left: 12px;
  flex-direction: column;
  justify-content: space-between;
`;

export const Product = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProductValue = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;

  small {
    letter-spacing: 0.1em;
  }
`;

type SaleProps = {
  isOpen: boolean;
};

export const Sale = styled.div<SaleProps>`
  gap: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isOpen }) => (isOpen ? "column" : "row")};
`;

type BuyerDetailsProps = {
  isOpen: boolean;
};

export const BuyerDetails = styled.div<BuyerDetailsProps>`
  display: none;

  ${({ isOpen }) => css`
    ${isOpen &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}

    svg {
      width: 12px;
      height: 12px;
    }
  `}
`;

export const Info = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
`;
