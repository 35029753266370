import { useCallback } from "react";

import { Auth } from "..";
import { IGAEvents } from "../../types";

const useGA = () => {
  const { user } = Auth.useAuth();

  const sendEvents = useCallback(
    async (eventsParams: IGAEvents) => {
      window.gtag("event", eventsParams.name, {
        creatorId: user.id,
        action: eventsParams?.action,
        category: eventsParams?.category,
        pageLocation: window.location.host,
        buttonLocation: eventsParams?.buttonLocation,
      });
    },
    [user.id]
  );

  return {
    sendEvents,
  };
};

export default {
  useGA,
};
