import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PhoneMessageIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0864 0H35.8858C37.0486 0 38 0.951391 38 2.1142V12.6852C38 13.848 37.0486 14.7994 35.8858 14.7994H25.3148L18.9722 21.142V2.1142C18.9722 0.951391 19.9236 0 21.0864 0ZM28.8878 25.307L34.2579 25.9202C36.3932 26.1739 38 27.9709 37.9788 30.1063V33.7638C37.9788 36.1529 35.9915 38.1402 33.6025 37.9922C15.5683 36.8717 1.12829 22.4529 0.00776648 4.39756C-0.140228 2.00851 1.84712 0.0211639 4.23617 0.0211639H7.89374C10.0502 0.0211639 11.8473 1.62796 12.101 3.7633L12.7141 9.09109C12.8621 10.3808 12.4181 11.6493 11.509 12.5584L7.59775 16.4697C10.6422 22.474 15.5472 27.3578 21.5304 30.4023L25.4205 26.5121C26.3296 25.603 27.5981 25.159 28.8878 25.307Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PhoneMessageIcon;
