import styled, { css } from "styled-components";
import Button from "../../atoms/Button";

type CropModalProps = {
  backgroundColor: string;
};

export const CropModal = styled.div<CropModalProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  padding: 24px;
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

type ModalContainerProps = {
  backgroundColor: string;
  borderColor: string;
};

export const ModalContainer = styled.div<ModalContainerProps>`
  ${({ backgroundColor, borderColor }) => css`
    gap: 32px;
    width: 100%;
    display: flex;
    max-width: 400px;
    padding: 48px 24px;
    align-items: center;
    flex-direction: column;
    background-color: ${backgroundColor};
    border-color: ${borderColor};
  `}
`;

export const SaveButton = styled(Button)`
  width: 100%;
`;
