import { Theme } from "../../../hooks";
import { TIconOptions } from "../../../types";
import AvailableIcons from "../../atoms/AvailableIcons";
import EditIcon from "../../icons/EditIcon";

import * as S from "./styles";

interface IProps {
  uploadedImageUrl: string;
  onOpenEditImage: () => void;
  selectedImage: TIconOptions | "";
}

const ImagePreview: React.FC<IProps> = ({
  selectedImage,
  onOpenEditImage,
  uploadedImageUrl,
}) => {
  const { backgroundColor, primaryColor } = Theme.useTheme();

  return (
    <S.ImagePreview isPreview={!uploadedImageUrl} imageUrl={uploadedImageUrl}>
      {!uploadedImageUrl && (
        <S.Icon>
          {selectedImage ? (
            <AvailableIcons option={selectedImage} />
          ) : (
            <S.StantiIconWithMargin color="#555" />
          )}
        </S.Icon>
      )}

      <S.UploadImage backgroundColor={primaryColor} onClick={onOpenEditImage}>
        <EditIcon color={backgroundColor} />
      </S.UploadImage>
    </S.ImagePreview>
  );
};

export default ImagePreview;
