import SearchIcon from "../../../../components/icons/SearchIcon";

import * as S from "./styles";

interface IProps {
  searchTerm: string;
  onSearch: (search: string) => void;
}

const SearchInput: React.FC<IProps> = ({ onSearch, searchTerm }) => {
  return (
    <S.SearchInput>
      <input
        type="text"
        value={searchTerm}
        placeholder="Buscar produto ou marca"
        onChange={({ target }) => onSearch(target.value)}
      />

      <S.SeachIconContainer>
        <SearchIcon color="#fafafa" />
      </S.SeachIconContainer>
    </S.SearchInput>
  );
};

export default SearchInput;
