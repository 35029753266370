import styled from "styled-components";
import P from "../Typography/P";

export const Container = styled.div`
  gap: 8px;
  width: 90%;
  z-index: 10;
  display: flex;
  max-width: 600px;
  align-self: center;
  padding: 24px 12px;
  position: relative;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  border-top: 1px solid #fafafa0f;
  border-left: 1px solid #fafafa0f;
  border-right: 1px solid #fafafa0f;
  background: linear-gradient(
      128.33deg,
      rgba(255, 255, 255, 0) 3%,
      rgba(255, 255, 255, 0.1) 18%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0.15) 93%,
      rgba(255, 255, 255, 0) 97%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  background-blend-mode: hard-light, soft-light;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
`;

type ImageProps = {
  imageUrl: string;
};

export const Image = styled.div<ImageProps>`
  width: 100px;
  height: 100px;
  display: flex;
  border-radius: 50%;
  align-self: center;
  justify-self: center;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: cover;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 16px;
  }
`;

export const Username = styled.div`
  color: #fafafa99;
  font-size: 14px;
  font-weight: 275;
  line-height: 33px;
  font-style: normal;
  font-family: "Poppins", sans-serif;
`;

/* ------------------------------------------ */

export const LinkBox = styled.div`
  gap: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text {
    text-align: center;
  }
`;

export const Link = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  mix-blend-mode: normal;
  padding: 10px 16px 10px 18px;
  border: 1px solid #fafafa80;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  gap: 12px;

  .icon {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const LinkText = styled(P)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;

  span {
    color: #fafafa;
  }
`;

export const CopyLink = styled.div`
  flex-grow: 1;
  display: flex;
  cursor: pointer;
  padding-top: 6px;
  user-select: none;

  button {
    width: 100%;
  }
`;

/* ------------------------------------------ */

export const SocialMedia = styled.div`
  gap: 16px;
  display: flex;
  padding-top: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .text {
    font-weight: 300;
    color: #fafafa;
    line-height: 15px;
    font-style: normal;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
`;

export const SocialMediaBoxes = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    cursor: pointer;
    user-select: none;
  }
`;
