import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ChatIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0001 3.60001V16.2C27.0001 18.18 25.3801 19.8001 23.4001 19.8001H7.20002L0 27.0001V3.60001C0 1.62 1.62 0 3.60001 0H23.4001C25.3801 0 27.0001 1.62 27.0001 3.60001ZM30.6004 7.19999H32.4004C34.3804 7.19999 36.0004 8.81999 36.0004 10.8V36.0001L28.8004 28.8H10.8004C8.82036 28.8 7.20035 27.18 7.20035 25.2V23.4H28.8004C29.7904 23.4 30.6004 22.59 30.6004 21.6V7.19999Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ChatIcon;
