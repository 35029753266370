import { useNavigate } from "react-router-dom";

import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import AppLinkButton from "../../../../components/atoms/AppLinkButton";
import Page from "../../../../components/atoms/Page";
import appConfig from "../../../../config/app-arquivo.json";
import { IFileType } from "../../types";

import * as S from "./styles";

const fileType: IFileType[] = [
  { icon: "bookOne", label: "Ebook", typeId: "ebook" },
  { icon: "document", label: "Arquivo PDF", typeId: "pdf" },
  { icon: "videoTwo", label: "Curso Gravado", typeId: "course" },
  { icon: "link", label: "Acesso a um link", typeId: "link" },
  { icon: "attachment", label: "Outro tipo de arquivo", typeId: "other" },
];

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate("/apps")}
        showLiveLabel={appConfig.showIsLiveLabel}
      />

      <S.FileType>
        {fileType.map((item) => (
          <AppLinkButton
            key={item.typeId}
            link={`/apps/${appConfig.id}/${item.typeId}/new`}
            title={item.label}
            icon={item.icon}
          />
        ))}
      </S.FileType>
    </Page>
  );
};

export default Home;
