import { Navigate, Route } from "react-router-dom";

import Form from "./components/Form";

const AppFotos = () => {
  return (
    <Route path="galeria-imagens">
      <Route path="new" element={<Form />} />
      <Route path=":id" element={<Form />} />
      <Route
        index
        path="*"
        element={<Navigate to="/apps/galeria-imagens/new" />}
      />
    </Route>
  );
};

export default AppFotos;
