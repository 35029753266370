import styled from "styled-components";
import { Wrapper } from "../styles";

export const MissingUsernameWrapper = styled(Wrapper)`
  &::after {
    content: "";
    width: 140%;
    top: -550px;
    left: -175px;
    height: 140%;
    opacity: 0.7;
    cursor: none;
    position: absolute;
    filter: blur(100px);
    pointer-events: none;
    background: url("../../../../components/assets/image_bg.png");
    background-size: cover;
    background-position: top center;

    @media (max-width: 920px) {
      width: 120%;
      left: -40px;
      top: -250px;
      height: 120%;
      filter: blur(70px);
    }

    @media (max-width: 600px) {
      left: 40px;
      top: -150px;
      filter: blur(60px);
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
`;

export const Form = styled.div`
  gap: 12px;
  display: flex;
  padding-top: 30px;
  flex-direction: column;

  .icon {
    display: flex;
    margin-top: -80px;
    justify-content: center;
  }

  .text {
    display: flex;
    margin-top: -100px;
    margin-bottom: 20px;
    justify-content: center;
  }
`;
