import LinkIcon from "../../icons/LinkIcon";
import Small from "../Typography/Small";

import AtIcon from "../../icons/AtIcon";
import Input from "../Input";

import { capitalizeFirstLetter } from "../../../utils/capitalizeLetter";
import EmailTwoIcon from "../../icons/EmailTwoIcon";
import WhatsappIcon from "../../icons/WhatsappIcon";

import * as S from "./styles";

interface IProps {
  value: string;
  socialMedia: string;
  errors?: string[];
  onChange: (val: string) => void;
}

const AppFormSocialMedia: React.FC<IProps> = ({
  onChange,
  value,
  errors,
  socialMedia,
}) => {
  const shouldBeCompleteLink = ["linkedin", "youtube"].includes(socialMedia);

  const isWhatsapp = socialMedia === "whatsapp";
  const isEmail = socialMedia === "email";

  const getLabel = (socialMedia: string) => {
    if (shouldBeCompleteLink) return "Cole aqui o link da sua página ou perfil";

    if (isWhatsapp)
      return `Digite seu número do ${capitalizeFirstLetter(socialMedia) || ""}`;

    if (isEmail) return "Digite seu e-mail";

    return `Digite seu usuário do ${capitalizeFirstLetter(socialMedia) || ""}`;
  };

  const getPlaceholder = (socialMedia: string) => {
    if (shouldBeCompleteLink)
      return `Cole seu link do ${capitalizeFirstLetter(socialMedia) || ""}`;

    if (isWhatsapp) return "Insira seu telefone incluindo DDI e DDD";

    if (isEmail) return "Insira seu e-mail completo";

    return "Insira seu nome de usuário, não o link!  :)";
  };

  const getInputIcon = () => {
    if (shouldBeCompleteLink) return <LinkIcon />;

    if (isWhatsapp) return <WhatsappIcon />;

    if (isEmail) return <EmailTwoIcon />;

    return <AtIcon />;
  };

  return (
    <S.SocialMediaContainer>
      <S.Icon>{getInputIcon()}</S.Icon>

      <div>
        <S.InputLabel htmlFor="socialMedia">
          {getLabel(socialMedia)}
        </S.InputLabel>

        <Input
          isSocialMedia
          value={value}
          onChange={onChange}
          placeholder={getPlaceholder(socialMedia)}
          id="socialMedia"
        />
      </div>

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </S.SocialMediaContainer>
  );
};

export default AppFormSocialMedia;
