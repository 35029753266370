import styled from "styled-components";
import Small from "../Typography/Small";

export const Items = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Small)`
  margin-bottom: 5px;
`;
