import { useEffect, useState } from "react";

import P from "../../../../components/atoms/Typography/P";
import Small from "../../../../components/atoms/Typography/Small";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import { Calendar } from "../../../../services";
import { workDayLabel } from "../../../../types/calendar";
import {
  validateDaysWithError,
  validateLunchTime,
} from "../../../../utils/dates";
import {
  getCalendarPayload,
  getLunchTimePayload,
  normalizeSchedules,
} from "../../utils";
import WeekDay from "../WeekDay";
import { emptyCalendar, emptyLunchTime } from "./utils";

import * as S from "./styles";
import Button from "../../../../components/atoms/Button";

const WorkHours: React.FC = () => {
  const [schedule, setSchedule] = useState(emptyCalendar);
  const [lunchTime, setLunchTime] = useState(emptyLunchTime);
  const [initialSchedule, setInitialSchedule] = useState(emptyCalendar);
  const [initialLunchTime, setInitialLunchTime] = useState(emptyLunchTime);

  const [errors, setErros] = useState<{ [key: string]: string[] }>({});
  const [errorsLunchTime, setErrorsLunchTime] = useState<string[]>([]);

  const [showAllWorkHours, setShowAllWorkHours] = useState<boolean>(false);

  const { token } = Auth.useAuth();
  const { backgroundColor, textColor } = Theme.useTheme();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading, isLoading } = Loading.useLoading();

  useEffect(() => {
    const run = async () => {
      try {
        showLoading();

        const [calendar, lunchBlock] = await Promise.all([
          Calendar.getCalendar(token),
          Calendar.getLunchTimeBlock(token),
        ]);

        if (calendar && calendar.schedules && calendar.schedules.length) {
          setSchedule((curr) => ({
            ...curr,
            ...normalizeSchedules(calendar.schedules),
          }));
          setInitialSchedule((curr) => ({
            ...curr,
            ...normalizeSchedules(calendar.schedules),
          }));
        }

        if (!lunchBlock) return;

        setLunchTime({
          isSelected: true,
          to: lunchBlock.intervals.monday[0].to,
          from: lunchBlock.intervals.monday[0].from,
        });

        setInitialLunchTime({
          isSelected: true,
          to: lunchBlock.intervals.monday[0].to,
          from: lunchBlock.intervals.monday[0].from,
        });

        return;
      } catch (error) {
        newError("Houve um erro ao obter a agenda");
      } finally {
        hideLoading();
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const weekDays = Object.keys(schedule) as Array<keyof typeof schedule>;

  const hasUnsavedWorkHours =
    JSON.stringify(initialSchedule) !== JSON.stringify(schedule);

  const hasUnsavedLunchTime =
    JSON.stringify(lunchTime) !== JSON.stringify(initialLunchTime);

  const hasUnsavedChanges = hasUnsavedWorkHours || hasUnsavedLunchTime;

  const sliceWorkHours = showAllWorkHours ? weekDays.length : 3;

  const onChangeSchedule = (
    weekDay: keyof typeof schedule,
    attribute: "from" | "to" | "isSelected",
    value: string | boolean
  ) => {
    setSchedule((curr) => {
      const newSchedule = { ...curr };

      if (attribute === "isSelected" || typeof value === "boolean") {
        newSchedule[weekDay] = {
          isSelected: value as boolean,
          to: "",
          from: "",
        };

        return newSchedule;
      }

      newSchedule[weekDay][attribute] = value;

      return newSchedule;
    });
  };

  const onChangeLunchTime = (attribute: string, value: string | boolean) => {
    setLunchTime((curr) => ({ ...curr, [attribute]: value }));

    if (attribute === "isSelected" && value === false) {
      setLunchTime({ to: "", from: "", [attribute]: value });
    }
  };

  const onSaveWorkHours = async () => {
    showLoading();

    try {
      setErrorsLunchTime([]);

      const currErrors = validateDaysWithError(weekDays, schedule);
      setErros(currErrors);

      const daysWithError = Object.keys(currErrors);

      const currLunchTimeErrors = validateLunchTime(lunchTime);
      setErrorsLunchTime(currLunchTimeErrors);

      if (daysWithError.length > 0 || currLunchTimeErrors.length > 0) return;

      const calendarData = await Calendar.upsertCalendar(
        getCalendarPayload(schedule),
        token
      );

      const normalizedSchedules = normalizeSchedules(calendarData.schedules);

      setSchedule({ ...emptyCalendar, ...normalizedSchedules });
      setInitialSchedule({ ...emptyCalendar, ...normalizedSchedules });

      if (lunchTime.isSelected) {
        const lunchTimeData = await Calendar.createBlock(
          getLunchTimePayload(
            lunchTime,
            calendarData?.schedules.map(({ type }) => type),
            calendarData.id
          ),
          token
        );

        setLunchTime({
          isSelected: true,
          to: lunchTimeData.intervals.monday[0].to,
          from: lunchTimeData.intervals.monday[0].from,
        });

        setInitialLunchTime({
          isSelected: true,
          to: lunchTimeData.intervals.monday[0].to,
          from: lunchTimeData.intervals.monday[0].from,
        });
      } else {
        await Calendar.deleteLunchTimeBlock(token);

        setLunchTime(emptyLunchTime);
        setInitialLunchTime(emptyLunchTime);
      }

      newSuccess("Calendário salvo com sucesso");

      window.location.reload();
    } catch (error) {
      newError("Houve um erro ao atualizar o calendário");
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <S.WorkHoursText>
        <P color={textColor}>Qual seu horário de trabalho?</P>

        <Small color={textColor}>
          Sua disponibilidade será exibida para seus clientes ao realizar um
          agendamento online com você, de acordo com seu horário de trabalho.
        </Small>
      </S.WorkHoursText>

      {weekDays.slice(0, sliceWorkHours).map((key) => {
        return (
          <WeekDay
            key={key}
            errors={errors[key]}
            to={schedule[key].to}
            from={schedule[key].from}
            label={workDayLabel[key]}
            onSelect={(isSelected) =>
              showAllWorkHours &&
              onChangeSchedule(key, "isSelected", isSelected)
            }
            onChangeTo={(to) =>
              showAllWorkHours && onChangeSchedule(key, "to", to)
            }
            isSelected={schedule[key].isSelected}
            onChangeFrom={(from) =>
              showAllWorkHours && onChangeSchedule(key, "from", from)
            }
          />
        );
      })}

      {showAllWorkHours ? (
        <S.LunchTime>
          <S.Disclaimer color={textColor}>
            Utilize o espaço abaixo caso deseje adicionar um bloqueio para seu
            horário de almoço em todos os dias. Atenção: Seus serviços não
            permitirão agendamentos neste intervalo de tempo.
          </S.Disclaimer>

          <WeekDay
            to={lunchTime.to}
            from={lunchTime.from}
            errors={errorsLunchTime}
            label={"Horário de Almoço"}
            isSelected={lunchTime.isSelected}
            onChangeFrom={(from) =>
              showAllWorkHours && onChangeLunchTime("from", from)
            }
            onSelect={(isSelected) =>
              showAllWorkHours && onChangeLunchTime("isSelected", isSelected)
            }
            onChangeTo={(to) => showAllWorkHours && onChangeLunchTime("to", to)}
          />
        </S.LunchTime>
      ) : null}

      {!showAllWorkHours ? (
        <S.ExpandWorkHours>
          <S.ShadowEffect backgroundColor={backgroundColor} />

          <Button
            type="outline"
            color="#dddddd"
            onClick={() => setShowAllWorkHours(true)}
          >
            Expandir seus horários de trabalho
          </Button>
        </S.ExpandWorkHours>
      ) : null}

      {showAllWorkHours && hasUnsavedChanges ? (
        <Button
          style={{
            left: "50%",
            bottom: "20px",
            zIndex: 1000,
            position: "fixed",
            maxWidth: "960px",
            width: "calc(100% - 40px)",
            transform: "translateX(-50%)",
          }}
          disabled={isLoading}
          onClick={() => onSaveWorkHours()}
        >
          Salvar Horários de trabalho
        </Button>
      ) : null}
    </>
  );
};

export default WorkHours;
