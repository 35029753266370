import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ArrowRightIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="34"
      viewBox="0 0 38 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1549 2.07101L35.5711 13.4872C37.5031 15.4192 37.5024 18.5812 35.5696 20.514L24.1485 31.9352C22.6901 33.3935 20.3361 33.394 18.8783 31.9363C17.4206 30.4785 17.4211 28.1245 18.8795 26.6661L24.6252 20.9205L4.03655 20.6087C2.06904 20.574 0.488344 18.9582 0.48877 16.9906C0.489195 15.0231 2.08815 13.4242 4.05568 13.3886L24.7674 13.226L18.8836 7.34229C17.4259 5.88454 17.4264 3.53054 18.8848 2.07215C20.3432 0.613764 22.6972 0.613255 24.1549 2.07101Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ArrowRightIcon;
