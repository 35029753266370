import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const FilesIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3333 0H3.66667C1.65 0 0 1.65 0 3.66667V16.5C0 18.5167 1.65 20.1667 3.66667 20.1667H29.3333C31.35 20.1667 33 18.5167 33 16.5V3.66667C33 1.65 31.35 0 29.3333 0ZM29.3334 11H23.5768C22.7151 11 22.0368 11.605 21.7984 12.43C21.1384 14.74 18.9751 16.5 16.5001 16.5C14.0251 16.5 11.8618 14.74 11.2018 12.43C10.9634 11.605 10.2851 11 9.42345 11H3.66678V5.49999C3.66678 4.49165 4.49178 3.66665 5.50011 3.66665H27.5001C28.5084 3.66665 29.3334 4.49165 29.3334 5.49999V11ZM31.1667 23.8333H23.595C22.7333 23.8333 22.0367 24.4566 21.7983 25.3C21.1567 27.6283 19.03 29.3333 16.5 29.3333C13.97 29.3333 11.8433 27.6283 11.2017 25.3C10.9633 24.4566 10.2667 23.8333 9.405 23.8333H1.83333C0.825 23.8333 0 24.6583 0 25.6666V29.3333C0 31.35 1.65 33 3.66667 33H29.3333C31.35 33 33 31.35 33 29.3333V25.6666C33 24.6583 32.175 23.8333 31.1667 23.8333Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default FilesIcon;
