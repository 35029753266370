import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PlaceIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="36"
      viewBox="0 0 40 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5719 0.926475C18.6924 -0.308827 21.3076 -0.308824 23.4281 0.926478L40 10.5802L39.346 13.2123C39.1117 14.1553 38.2697 14.8168 37.3036 14.8168H36.8421V29.6451C38.0048 29.6451 38.9474 30.5935 38.9474 31.7634V36H1.05263V31.7634C1.05263 30.5935 1.99519 29.6451 3.15789 29.6451V14.8168H2.69637C1.73034 14.8168 0.888264 14.1553 0.653966 13.2123L0 10.5802L16.5719 0.926475ZM22.1053 7.40273C22.1053 8.57265 21.1627 9.52105 20 9.52105C18.8373 9.52105 17.8947 8.57265 17.8947 7.40273C17.8947 6.23282 18.8373 5.28441 20 5.28441C21.1627 5.28441 22.1053 6.23282 22.1053 7.40273ZM13.1579 12.6985C11.1232 12.6985 9.47368 14.3582 9.47368 16.4056V29.6451H16.8421V16.4056C16.8421 14.3582 15.1926 12.6985 13.1579 12.6985ZM26.8421 12.6985C24.8074 12.6985 23.1579 14.3582 23.1579 16.4056V29.6451H30.5263V16.4056C30.5263 14.3582 28.8768 12.6985 26.8421 12.6985Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PlaceIcon;
