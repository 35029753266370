import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ExploreIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 0C8.512 0 0 8.512 0 19C0 29.488 8.512 38 19 38C29.488 38 38 29.488 38 19C38 8.512 29.488 0 19 0ZM19.0001 16.9096C17.8411 16.9096 16.9101 17.8406 16.9101 18.9996C16.9101 20.1586 17.8411 21.0896 19.0001 21.0896C20.1591 21.0896 21.0901 20.1586 21.0901 18.9996C21.0901 17.8406 20.1591 16.9096 19.0001 16.9096ZM7.60067 30.3999L23.1617 23.1609L30.4007 7.59994L14.8397 14.8389L7.60067 30.3999Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ExploreIcon;
