import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

type LinkProps = {
  textColor: string;
};

export const Link = styled(RouterLink)<LinkProps>`
  font-weight: 500;
  text-decoration: none;
  color: ${({ textColor }) => textColor};
`;
