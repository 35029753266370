import axios, { AxiosError } from "axios";
import { logoutUserIfTokenInvalid } from "../../utils";
import { ICreatorData, ICreateCreatorResponse } from "../../types";

const { REACT_APP_CREATOR_API_URL } = process.env;

const create = async (
  creatorData: ICreatorData
): Promise<ICreateCreatorResponse> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CREATOR_API_URL}/creator`,
      creatorData
    );

    return data;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    switch (errorCode) {
      case "BODY001":
        throw new Error(
          "Verifique se todos os campos obrigatórios estão preenchidos"
        );
      case "BODY002":
        throw new Error(
          "O email informado já foi utilizado em outro cadastro, por favor informe um email diferente"
        );
      case "BODY003":
        throw new Error(
          "O username escolhido já está em uso, por favor tente outra opção"
        );
      default:
        throw new Error(
          "Houve um erro ao processar seu cadastro, por favor tente novamente ou entre em contato"
        );
    }
  }
};

const uploadProfileImage = async (base64: string): Promise<string> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CREATOR_API_URL}/profile-image`,
      base64
    );

    return data.imageUrl;
  } catch (error) {
    throw new Error("Houve um erro ao enviar a sua foto de perfil");
  }
};

const updatePassword = async (
  currentPassword: string,
  newPassword: string,
  token: string
): Promise<ICreatorData> => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_CREATOR_API_URL}/creator/password`,
      {
        currentPassword,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao atualizar seu perfil");
  }
};

const updateProfile = async (
  creatorData: ICreatorData,
  token: string
): Promise<ICreatorData> => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_CREATOR_API_URL}/creator`,
      { ...creatorData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao atualizar seu perfil");
  }
};

const addProfileImage = async (
  imageUrl: string,
  token: string
): Promise<ICreatorData> => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_CREATOR_API_URL}/creator`,
      { profileImage: imageUrl },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao atualizar seu perfil");
  }
};

const addPhone = async (
  phone: string,
  token: string
): Promise<ICreatorData> => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_CREATOR_API_URL}/creator`,
      { phone },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao atualizar seu perfil");
  }
};

const addUsername = async (
  username: string,
  token: string
): Promise<ICreatorData> => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_CREATOR_API_URL}/creator`,
      { username },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao atualizar seu perfil");
  }
};

const checkUsernameAvailability = async (
  username: string
): Promise<boolean> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_CREATOR_API_URL}/check/username/${username}`
    );

    return data.isRegistered;
  } catch (error) {
    throw new Error("Error on when checking if username is available");
  }
};

const checkEmailAvailability = async (email: string): Promise<boolean> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_CREATOR_API_URL}/check/email/${email}`
    );

    return data.isRegistered;
  } catch (error) {
    throw new Error("Error on when checking if email is registered");
  }
};

const getCreator = async (id: string, token: string): Promise<ICreatorData> => {
  try {
    const { data } = await axios.get(`${REACT_APP_CREATOR_API_URL}/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.creator;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on getCreator");
  }
};

const removeCreator = async (id: string, token: string): Promise<string> => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_CREATOR_API_URL}/creator`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.id;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on removeCreator");
  }
};

const favoriteApp = async (
  currApps: string[],
  appId: string,
  token: string
) => {
  const newApps = [...currApps];
  newApps.push(appId);

  try {
    const { data } = await axios.put(
      `${REACT_APP_CREATOR_API_URL}/creator`,
      { favoriteApps: newApps },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao favoritar o app");
  }
};

const unfavoriteApp = async (
  currApps: string[],
  appId: string,
  token: string
) => {
  const newApps = currApps.filter((app) => app !== appId);

  try {
    const { data } = await axios.put(
      `${REACT_APP_CREATOR_API_URL}/creator`,
      { favoriteApps: newApps },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao desfavoritar o app");
  }
};

const linkWithMercadoPago = async (code: string, token: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CREATOR_API_URL}/oauth/mercado-pago`,
      { code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao integrar com mercado pago");
  }
};

const sendEmailToResetPassword = async (user: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CREATOR_API_URL}/reset-password`,
      { user }
    );

    return data.email;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    throw new Error(errorCode || "Error on reset password");
  }
};

const resetPassword = async (token: string, password: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CREATOR_API_URL}/reset-password/${token}`,
      { password }
    );

    return data;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    throw new Error(errorCode || "Error on reset password");
  }
};

const getInstagramProfileImage = async (
  username: string,
  token: string,
  retry = true
): Promise<string> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_CREATOR_API_URL}/profile-data/instagram`,
      {
        params: {
          alias: username,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return JSON.parse(data.message).Location;
  } catch (error) {
    if (retry) return getInstagramProfileImage(username, token, false);
    else {
      logoutUserIfTokenInvalid(error);
      throw new Error("Houve um erro ao obter a imagem");
    }
  }
};

const getUserBanners = async (location: string, token: string) => {
  try {
    const { data } = await axios.get(`${REACT_APP_CREATOR_API_URL}/banners`, {
      params: {
        location,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.banners;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    throw new Error(errorCode || "Error on getUserBanners");
  }
};

export default {
  create,
  addPhone,
  getCreator,
  favoriteApp,
  addUsername,
  resetPassword,
  updateProfile,
  unfavoriteApp,
  removeCreator,
  updatePassword,
  addProfileImage,
  uploadProfileImage,
  linkWithMercadoPago,
  checkEmailAvailability,
  getInstagramProfileImage,
  sendEmailToResetPassword,
  checkUsernameAvailability,
  getUserBanners,
};
