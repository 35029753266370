import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const HeartIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="34"
      viewBox="0 0 38 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5635 33.0053C20.12 34.3347 17.8979 34.3347 16.4545 32.9861L16.2455 32.7934C6.27437 23.6417 -0.240123 17.6497 0.0067824 10.1741C0.120739 6.89878 1.7731 3.75829 4.45107 1.90868C9.46515 -1.55935 15.6568 0.0590608 18.9995 4.02803C22.3422 0.0590608 28.5338 -1.57862 33.5479 1.90868C36.2259 3.75829 37.8782 6.89878 37.9922 10.1741C38.2581 17.6497 31.7246 23.6417 21.7534 32.8319L21.5635 33.0053Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default HeartIcon;
