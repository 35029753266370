import styled from "styled-components";

export const ModalBluredBackdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  gap: 20px;
  z-index: 1000;
  display: flex;
  position: fixed;
  padding: 0 30px;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: blur(13px);
  background: rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(13px);
`;
