import styled from "styled-components";
import Button from "../../components/atoms/Button";
import H1 from "../../components/atoms/Typography/H1";
import H5 from "../../components/atoms/Typography/H5";
import P from "../../components/atoms/Typography/P";

import bgImage from "../../components/assets/image_bg.png";

export const Information = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  &::after {
    content: "";
    width: 140%;
    height: 140%;
    top: -550px;
    left: -175px;
    opacity: 0.7;
    cursor: none;
    position: absolute;
    filter: blur(100px);
    pointer-events: none;
    background-image: url(${bgImage});
    background-size: cover;
    background-position: top center;

    @media (max-width: 920px) {
      width: 120%;
      height: 120%;
      left: -40px;
      top: -250px;
      filter: blur(70px);
    }

    @media (max-width: 600px) {
      left: 40px;
      top: -150px;
      filter: blur(60px);
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
`;

export const RegisterFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .stantiLogo {
    svg {
      height: 42px;
      width: auto;
      align-self: flex-start;
    }
  }
`;

export const Login = styled.div`
  gap: 12px;
  display: flex;
  justify-self: center;
  flex-direction: column;
  margin-top: 50px;
`;

export const TextContainer = styled.div`
  width: 50%;

  @media (max-width: 600px) {
    width: 80%;
  }

  span {
    font-weight: 600;
  }
`;

export const Title = styled(H1)`
  font-weight: 200;
  font-family: Poppins;
`;

export const Subtitle = styled(H5)`
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 200;
  font-size: 18px;
`;

export const LoginForm = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;

  .forgotPassword {
    padding: 0 16px;
    cursor: pointer;
    user-select: none;
  }
`;

export const Or = styled(H5)`
  text-align: center;
`;

export const GoogleOrFacebook = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const EnterButton = styled(Button)`
  height: 44px;
`;

export const LinkText = styled(P)`
  text-align: center;
  margin-top: 20px;
`;
