import styled from "styled-components";

export const FormAddImage = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const AddImageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
