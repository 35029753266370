import styled from "styled-components";

export const Appointment = styled.div`
  gap: 22px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  &:not(:last-of-type) {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px dashed #fafafa30;
  }

  p {
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-family: "Poppins", sans-serif;
  }
`;

export const Interval = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

export const Container = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: column;
`;

export const CallUrl = styled.div`
  gap: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;

  .linkIcon {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ActionButtons = styled.div`
  gap: 10px;
  display: flex;
  padding-top: 8px;
  align-items: center;

  span {
    height: 8px;
    border: 1px solid #fafafa1a;
  }
`;
