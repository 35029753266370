import { ChangeEvent, useEffect, useRef, useState } from "react";

import { Snackbar, Theme } from "../../../hooks";
import { Apps } from "../../../services";
import { TIconOptions } from "../../../types";
import CloseIcon from "../../icons/CloseIcon";
import AvailableIcons from "../AvailableIcons";
import H3 from "../Typography/H3";
import H4 from "../Typography/H4";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  coverURL: string;
  hideLabel?: boolean;
  isLoading?: boolean;
  iconId: TIconOptions;
  onRemoveCover: () => void;
  fileUploadProgress?: number;
  onChange: (file: string) => void;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const AppFormAddCover: React.FC<IProps> = ({
  iconId,
  onChange,
  coverURL,
  hideLabel,
  isLoading,
  onRemoveCover,
  fileUploadProgress,
}) => {
  const [loading, setLoading] = useState(!!isLoading);

  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const input = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setLoading(!!isLoading);
  }, [isLoading]);

  const onUploadHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file.size > MAX_FILE_SIZE) {
      if (input.current) input.current.value = "";
      return newError("Arquivo muito grande: máx 5Mb");
    }

    if (file) {
      setLoading(true);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) {
          try {
            const fileUrl = await Apps.uploadImage(base64);
            onChange(fileUrl);
          } catch (error) {
            newError("Houve um erro ao fazer o upload da imagem");
          } finally {
            setLoading(false);
          }
        }

        if (input.current) input.current.value = "";
      };
    }
  };

  return (
    <S.AddLinkCover>
      {!hideLabel && <P color={textColor}>Adicionar imagem de capa</P>}

      <S.FileContainer>
        <div className="icon">
          <AvailableIcons option={iconId} />
        </div>

        {!loading && !coverURL && (
          <S.InputBox onClick={() => input.current?.click()}>
            <S.AddIcon borderColor={primaryColor}>
              <H3 color={primaryColor}>+</H3>
            </S.AddIcon>

            <input
              type="file"
              ref={input}
              onChange={onUploadHandler}
              accept="image/png, image/jpeg, image/jpg"
            />
          </S.InputBox>
        )}

        {!loading && !!coverURL && (
          <>
            <img alt="" width={140} src={coverURL} />

            <S.RemoveFile onClick={onRemoveCover}>
              <CloseIcon />
            </S.RemoveFile>
          </>
        )}

        {loading && (
          <S.LoadingSkeleton>
            {fileUploadProgress && <H4 color="#000">{fileUploadProgress}%</H4>}
          </S.LoadingSkeleton>
        )}
      </S.FileContainer>
    </S.AddLinkCover>
  );
};

export default AppFormAddCover;
