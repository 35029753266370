import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CopyIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1H11.3111C13.3024 1 14.2981 1 15.0586 1.38753C15.7277 1.72842 16.2716 2.27235 16.6125 2.94137C17 3.70194 17 4.69759 17 6.68889V13M3.84444 17H11.0444C12.0401 17 12.5379 17 12.9182 16.8062C13.2527 16.6358 13.5247 16.3638 13.6951 16.0293C13.8889 15.649 13.8889 15.1512 13.8889 14.1556V6.95556C13.8889 5.95991 13.8889 5.46208 13.6951 5.08179C13.5247 4.74728 13.2527 4.47532 12.9182 4.30488C12.5379 4.11111 12.0401 4.11111 11.0444 4.11111H3.84444C2.8488 4.11111 2.35097 4.11111 1.97068 4.30488C1.63617 4.47532 1.36421 4.74728 1.19377 5.08179C1 5.46208 1 5.95991 1 6.95556V14.1556C1 15.1512 1 15.649 1.19377 16.0293C1.36421 16.3638 1.63617 16.6358 1.97068 16.8062C2.35097 17 2.8488 17 3.84444 17Z"
        stroke={color || primaryColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
