import { Navigate, Route } from "react-router-dom";

import Home from "./components/Home";
import Form from "./components/Form";

const AppPostsInstagram = () => {
  return (
    <Route path="link-to-post-instagram">
      <Route index element={<Home />} />
      <Route path="new" element={<Form />} />
      <Route
        path="*"
        element={<Navigate to="/apps/link-to-post-instagram" />}
      />
    </Route>
  );
};

export default AppPostsInstagram;
