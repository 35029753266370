import { DragEventHandler } from "react";

import * as S from "./styles";

interface IProps {
  isHovered: boolean;
  isDroppable: boolean;
  onDrop?: DragEventHandler<HTMLDivElement>;
  onDragEnter?: DragEventHandler<HTMLDivElement>;
  onDragLeave?: DragEventHandler<HTMLDivElement>;
}

const DropZone: React.FC<IProps> = ({
  onDrop,
  isHovered,
  isDroppable,
  onDragEnter,
  onDragLeave,
}) => {
  return (
    <S.DragZone
      onDrop={onDrop}
      isHovered={isHovered}
      onDragEnter={isDroppable ? onDragEnter : undefined}
      onDragLeave={isDroppable ? onDragLeave : undefined}
      onDragOver={isDroppable ? (e) => e.preventDefault() : undefined}
    />
  );
};

export default DropZone;
