import { useCallback } from "react";
import MercadoPagoButton from "../../../../components/atoms/MercadoPagoButton";
import H3 from "../../../../components/atoms/Typography/H3";
import P from "../../../../components/atoms/Typography/P";
import Chevron from "../../../../components/icons/Chevron";
import MonetizeStantiIcon from "../../../../components/icons/MonetizeStantiIcon";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  hasLinkedAccount: boolean;
}

const { REACT_APP_OAUTH_MERCADO_PAGO } = process.env;

const MonetizeSuaStanti: React.FC<IProps> = ({ hasLinkedAccount }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const onClickHandler = useCallback(() => {
    window.location.href = `${REACT_APP_OAUTH_MERCADO_PAGO}&state=edit`;
  }, []);

  return (
    <S.MonetizeYourStanti>
      <hr />
      <S.IconMonetize>
        <MonetizeStantiIcon />
      </S.IconMonetize>

      {!hasLinkedAccount && (
        <>
          <H3 color={primaryColor}>Monetize sua Stanti</H3>

          <S.TextContent color={textColor}>
            Para receber pagamentos pela{" "}
            <S.HighlightText highlightColor={primaryColor}>
              Stanti
            </S.HighlightText>{" "}
            é necessário que você vincule sua conta ao <span>Mercado Pago</span>
            .
          </S.TextContent>
        </>
      )}

      <MercadoPagoButton
        onClick={onClickHandler}
        hasLinkedAccount={hasLinkedAccount}
      />

      {hasLinkedAccount && (
        <S.MercadoPagoLink
          target="_blank"
          rel="noreferrer"
          href="https://mercadopago.com.br"
          borderColor={primaryColor}
        >
          <P color={textColor}>Ir para o Mercado Pago</P>

          <div>
            <Chevron />
          </div>
        </S.MercadoPagoLink>
      )}
    </S.MonetizeYourStanti>
  );
};

export default MonetizeSuaStanti;
