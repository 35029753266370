import { Theme } from "../../../../hooks";
import { maskHourAndMinute } from "../../../../utils/dates";

import * as S from "./styles";

interface IWeekDay {
  to: string;
  from: string;
  label: string;
  errors?: string[];
  isSelected: boolean;
  onSelect: (val: boolean) => void;
  onChangeTo: (val: string) => void;
  onChangeFrom: (val: string) => void;
}

const WeekDay: React.FC<IWeekDay> = ({
  to,
  from,
  label,
  errors,
  onSelect,
  isSelected,
  onChangeTo,
  onChangeFrom,
}) => {
  const { textColor, primaryColor } = Theme.useTheme();

  return (
    <S.WeekDay>
      <S.Container>
        <S.Day>
          <S.InputCheckbox onClick={() => onSelect(!isSelected)}>
            <S.Selected
              borderColor={
                isSelected ? primaryColor : "rgba(250, 250, 250, 0.2)"
              }
            >
              <S.Indicator isSelected={isSelected} color={primaryColor} />
            </S.Selected>
          </S.InputCheckbox>

          <S.Label textColor={textColor} opacity={isSelected ? 1 : 0.6}>
            {label}
          </S.Label>
        </S.Day>

        <S.Hours>
          <S.HourInput
            minLength={4}
            maxLength={5}
            value={from}
            inputMode="numeric"
            placeholder="00:00"
            disabled={!isSelected}
            onChange={(e) =>
              onChangeFrom(maskHourAndMinute(e.target.value || ""))
            }
            textColor={primaryColor}
          />

          <S.Hyphen textColor={textColor} opacity={isSelected ? 1 : 0.6}>
            -
          </S.Hyphen>

          <S.HourInput
            value={to}
            minLength={4}
            maxLength={5}
            inputMode="numeric"
            placeholder="00:00"
            disabled={!isSelected}
            onChange={(e) =>
              onChangeTo(maskHourAndMinute(e.target.value || ""))
            }
            textColor={primaryColor}
          />
        </S.Hours>
      </S.Container>

      {errors && errors.length ? (
        <S.Error color="#FF4D4F">Erros: {errors.join(", ")}</S.Error>
      ) : null}
    </S.WeekDay>
  );
};

export default WeekDay;
