import styled from "styled-components";

export const Modal = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(13px);
  background: rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(13px);
`;

export const Container = styled.div`
  gap: 22px;
  z-index: 2;
  display: flex;
  max-width: 300px;
  position: absolute;
  border-radius: 24px;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 26px 12px 26px;
  border-top: 1px solid #fafafa1f;
  border-left: 1px solid #fafafa1f;
  border-right: 1px solid #fafafa1f;
  background: linear-gradient(
      136.83deg,
      rgba(255, 255, 255, 0) 5%,
      rgba(255, 255, 255, 0.07) 14.32%,
      rgba(255, 255, 255, 0) 33%,
      rgba(255, 255, 255, 0.05) 50.72%,
      rgba(255, 255, 255, 0) 72.92%,
      rgba(255, 255, 255, 0.075) 93.89%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  background-blend-mode: hard-light, soft-light;
  backdrop-filter: blur(46px);
  -webkit-backdrop-filter: blur(46px);
`;

export const Icon = styled.div`
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  border-radius: 22px;
  justify-content: center;
  background-color: #fafafa14;
  border-top: 1px solid #fafafa1f;
  border-left: 1px solid #fafafa1f;
  border-right: 1px solid #fafafa1f;

  svg {
    width: 60%;
    height: 60%;
  }
`;

export const Text = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;
    font-style: normal;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
`;

export const ImportButtons = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InstagramButton = styled.div`
  font-size: 12px;
  cursor: pointer;
  font-weight: 300;
  color: #fafafa;
  user-select: none;
  line-height: 18px;
  padding: 14px 20px;
  text-align: center;
  font-style: normal;
  border-radius: 16px;
  border: 1px solid #fafafa50;
  font-family: "Poppins", sans-serif;
`;

export const ManualButton = styled.div`
  font-size: 12px;
  cursor: pointer;
  color: #ddff66;
  font-weight: 300;
  user-select: none;
  line-height: 18px;
  padding: 14px 20px;
  text-align: center;
  font-style: normal;
  border-radius: 16px;
  border: 1px solid #ddff6650;
  font-family: "Poppins", sans-serif;
`;
