import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SocialMedia: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74842 0C14.0206 0 18.0442 4.33469 17.7147 9.5293C17.4545 13.5481 14.2634 17.0317 10.3266 17.6108C8.21079 17.9267 6.21637 17.5932 4.36069 16.4876C4.16991 16.3736 3.99649 16.3648 3.78837 16.4437C3.16403 16.6719 2.53102 16.8737 1.90667 17.0931C1.51646 17.2335 1.14359 17.251 0.822748 16.9351C0.501905 16.6192 0.510577 16.2419 0.64932 15.8471C0.874776 15.2153 1.09156 14.5835 1.31702 13.9517C1.38639 13.7587 1.36905 13.6008 1.25632 13.4165C-1.83938 8.23942 1.02219 1.47415 7.06617 0.184268C7.65582 0.0614227 8.26282 0.00877468 8.74842 0ZM13.3963 8.33595C13.405 6.9671 12.824 6.05453 11.9048 5.65967C10.951 5.24726 9.85836 5.4754 9.12129 6.25635C8.92185 6.46694 8.84381 6.50204 8.61835 6.25635C7.53442 5.05422 5.6007 5.22971 4.73356 6.60733C4.10921 7.5901 4.2653 8.59919 4.7509 9.5644C5.63538 11.3018 7.10952 12.4513 8.68772 13.4779C8.80912 13.5569 8.91318 13.5657 9.04325 13.4779C10.4047 12.6004 11.662 11.6089 12.5899 10.2488C13.0581 9.5644 13.379 8.82733 13.3876 8.33595H13.3963Z"
        fill={color || primaryColor}
      />
      <path
        d="M18.8941 28.0006C14.7665 27.9567 11.2286 24.9821 10.4048 20.6737C10.3008 20.1121 10.2834 19.5418 10.2661 18.9714C10.2574 18.6117 10.3701 18.4713 10.7257 18.4011C12.8415 18.0062 14.6191 16.9884 16.0759 15.3914C17.2899 14.0664 18.0877 12.5045 18.4172 10.732C18.4866 10.3635 18.6253 10.2669 18.9722 10.2582C23.9843 10.1002 28.1639 14.3472 27.9905 19.4453C27.9384 20.937 27.5482 22.3234 26.7851 23.5957C26.6464 23.8326 26.6204 24.0256 26.7158 24.2801C26.9586 24.9031 27.1493 25.5437 27.3748 26.1754C27.5049 26.5352 27.4702 26.8686 27.21 27.1582C26.9412 27.4565 26.5857 27.5092 26.2128 27.3863C25.5971 27.1845 24.9815 26.9827 24.3745 26.7458C24.0536 26.623 23.7935 26.6405 23.49 26.8248C22.1373 27.6233 20.6631 27.983 18.8941 27.9918V28.0006ZM17.975 20.235C17.975 21.051 17.975 21.8759 17.975 22.6919C17.975 22.9551 18.0443 23.0429 18.3132 23.0429C19.6746 23.0253 21.0273 23.0429 22.3887 23.0341C23.0738 23.0341 23.5247 22.6831 23.6721 22.025C23.8802 21.1125 24.071 20.1999 24.2271 19.2785C24.3745 18.3923 23.8282 17.7693 22.935 17.7605C22.4754 17.7605 22.0072 17.7605 21.5476 17.7605C21.3048 17.7605 21.2441 17.6728 21.3048 17.4446C21.3828 17.1463 21.4696 16.8567 21.5303 16.5584C21.721 15.6283 21.1661 14.7684 20.2642 14.5753C20.0474 14.5314 19.926 14.5402 19.874 14.8122C19.7786 15.251 19.6312 15.6809 19.5185 16.1197C19.3364 16.7865 18.9635 17.2691 18.2785 17.4359C18.0357 17.4973 17.975 17.6377 17.9836 17.8746C18.001 18.6643 17.9836 19.454 17.9836 20.2438L17.975 20.235ZM15.4516 20.4105C15.4516 21.3143 15.4516 22.2181 15.4516 23.1306C15.4516 23.6396 15.625 23.8151 16.128 23.8238C16.2754 23.8238 16.4141 23.8238 16.5615 23.8238C17.1859 23.8238 17.3593 23.6571 17.3593 23.0253C17.3593 21.4196 17.3593 19.8138 17.3593 18.208C17.3593 17.0586 17.2726 16.9708 16.128 17.0059C15.6337 17.0235 15.4603 17.1902 15.4516 17.6991C15.4516 18.6029 15.4516 19.5067 15.4516 20.4193V20.4105Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SocialMedia;
