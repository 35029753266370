import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TwitterIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.05075 3.19219L7.08223 3.71122L6.55762 3.64767C4.64804 3.40404 2.97978 2.57782 1.56334 1.1902L0.870852 0.501686L0.692484 1.01013C0.314766 2.14353 0.556086 3.34048 1.343 4.14552C1.76269 4.5904 1.66826 4.65396 0.944297 4.38914C0.692485 4.3044 0.472149 4.24085 0.451164 4.27263C0.377719 4.34677 0.629531 5.31069 0.828883 5.69202C1.10168 6.22165 1.65777 6.74068 2.26631 7.04787L2.78043 7.2915L2.17188 7.30209C1.58432 7.30209 1.56334 7.31268 1.62629 7.53512C1.83613 8.22364 2.66502 8.95452 3.58833 9.2723L4.23884 9.49474L3.67227 9.8337C2.83289 10.321 1.84663 10.5964 0.86036 10.6175C0.388211 10.6281 0 10.6705 0 10.7023C0 10.8082 1.28005 11.4014 2.02499 11.6344C4.25983 12.3229 6.91435 12.0264 8.90787 10.8506C10.3243 10.0138 11.7408 8.35075 12.4018 6.74068C12.7585 5.88269 13.1152 4.315 13.1152 3.56293C13.1152 3.07567 13.1467 3.01211 13.7343 2.42953C14.0805 2.09056 14.4058 1.71983 14.4687 1.6139C14.5737 1.41264 14.5632 1.41264 14.0281 1.59272C13.1362 1.91049 13.0103 1.86812 13.451 1.39146C13.7762 1.0525 14.1645 0.438131 14.1645 0.258058C14.1645 0.22628 14.0071 0.279243 13.8287 0.374576C13.6398 0.480501 13.2202 0.639389 12.9054 0.734722L12.3388 0.914795L11.8247 0.565241C11.5414 0.374576 11.1427 0.162725 10.9329 0.0991699C10.3978 -0.0491255 9.57937 -0.0279404 9.09673 0.14154C7.7852 0.618204 6.95632 1.84694 7.05075 3.19219Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TwitterIcon;
