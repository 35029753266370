import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TagIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.82 1.16L38.82 19.16C39.54 19.88 40 20.9 40 22C40 23.1 39.56 24.1 38.82 24.82L24.82 38.82C24.1 39.56 23.1 40 22 40C20.9 40 19.9 39.56 19.18 38.84L1.18 20.84C0.44 20.1 0 19.1 0 18V4C0 1.8 1.8 0 4 0H18C19.1 0 20.1 0.44 20.82 1.16ZM3.99957 7C3.99957 8.66 5.33957 10 6.99957 10C8.65957 10 9.99957 8.66 9.99957 7C9.99957 5.34 8.65957 4 6.99957 4C5.33957 4 3.99957 5.34 3.99957 7Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TagIcon;
