import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const WarningIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="45"
      height="41"
      viewBox="0 0 45 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70791 40.0075H40.1342C43.7568 40.0075 46.0151 36.0791 44.2038 32.9505L26.4906 2.34646C24.6793 -0.782155 20.1628 -0.782155 18.3515 2.34646L0.638359 32.9505C-1.17295 36.0791 1.0853 40.0075 4.70791 40.0075ZM22.4208 23.541C21.127 23.541 20.0684 22.4824 20.0684 21.1886V16.4839C20.0684 15.1901 21.127 14.1316 22.4208 14.1316C23.7146 14.1316 24.7731 15.1901 24.7731 16.4839V21.1886C24.7731 22.4824 23.7146 23.541 22.4208 23.541ZM20.0684 32.9503H24.7731V28.2457H20.0684V32.9503Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default WarningIcon;
