import styled from "styled-components";

type PageProps = {
  backgroundColor: string;
};

export const Page = styled.div<PageProps>`
  width: 100%;
  display: flex;
  overflow-y: auto;
  min-height: 100vh;
  padding-top: 40px;
  position: relative;
  overflow-x: hidden;
  align-items: center;
  padding-bottom: 120px;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  padding: 0 24px;
  max-width: 960px;
  position: relative;
  flex-direction: column;
`;
