import P from "../../../../components/atoms/Typography/P";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  weekDay: string;
  monthDate: string;
}

const AppointmentsDate: React.FC<IProps> = ({ weekDay, monthDate }) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.AppointmentsDate>
      <P color={textColor}>{monthDate}</P>

      <div className="weekDay">
        <P color={textColor}>{weekDay}</P>
      </div>
    </S.AppointmentsDate>
  );
};

export default AppointmentsDate;
