import { useNavigate, useParams } from "react-router-dom";

import AppFormHeader from "../../components/atoms/AppFormHeader";
import AppVideo from "../../components/atoms/AppVideo";
import MercadoPagoButton from "../../components/atoms/MercadoPagoButton";
import Page from "../../components/atoms/Page";
import H3 from "../../components/atoms/Typography/H3";
import { Theme } from "../../hooks";

import * as S from "./styles";

const { REACT_APP_OAUTH_MERCADO_PAGO } = process.env;

const AppLinkMercadoPagoAccount: React.FC = () => {
  const navigate = useNavigate();
  const { appId, itemId } = useParams();
  const { primaryColor, textColor } = Theme.useTheme();

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const appConfig = require(`../../config/app-${appId}`);

  if (!appConfig) navigate("/");

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate("/apps")}
        showLiveLabel={appConfig.showIsLiveLabel}
      />

      <S.Content>
        <AppVideo
          thumbUrl="https://production-stanti-apps-images.s3.amazonaws.com/didi-mp-thumb.jpg"
          videoUrl="https://production-stanti-apps-images.s3.amazonaws.com/_Didi_MercadoPago2.mp4"
        />

        <H3 color={primaryColor}>Conecte-se e monetize!</H3>

        <S.TextContent color={textColor}>
          Para receber pagamentos pela <span>Stanti</span> é necessário que você
          vincule sua conta ao <span>Mercado Pago</span>.
        </S.TextContent>

        <S.TextContent color={textColor}>
          Se você estava cadastrando algum produto para venda, ele está salvo
          como rascunho na sua <span>Home</span>!
        </S.TextContent>

        <MercadoPagoButton
          hasLinkedAccount={false}
          onClick={() =>
            (window.location.href = `${REACT_APP_OAUTH_MERCADO_PAGO}&state=mercado-pago/${appId}/${itemId}?linked=true`)
          }
        />
      </S.Content>
    </Page>
  );
};

export default AppLinkMercadoPagoAccount;
