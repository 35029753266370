import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Apps } from "../../services";
import { IAppItem, IUser } from "../../types";
import { Auth, Loading, Snackbar } from "..";

const useApp = () => {
  const navigate = useNavigate();
  const { token, user } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();

  const saveFreeItemHandler = useCallback(
    async (
      appId: string,
      itemData: IAppItem,
      isDraft: boolean,
      itemId?: string
    ) => {
      try {
        showLoading();

        itemId
          ? await Apps.updateAppItem(appId, itemId, token, {
              ...itemData,
              isDraft,
            })
          : await Apps.createAppItem(appId, token, {
              ...itemData,
              isDraft,
            });

        navigate("/");
      } catch (error) {
        newError("Houve um erro ao salvar o item");
      } finally {
        hideLoading();
      }
    },
    [hideLoading, navigate, newError, showLoading, token]
  );

  const saveItemHandler = useCallback(
    async (
      appId: string,
      itemData: IAppItem,
      isDraft: boolean,
      itemId?: string,
      mercadoPagoIntegration?: IUser["mercadoPagoIntegration"],
      redirectPath?: string
    ) => {
      try {
        const hasLinkedMercadoPago = mercadoPagoIntegration
          ? !!mercadoPagoIntegration
          : !!user.mercadoPagoIntegration;

        showLoading();

        const item = itemId
          ? await Apps.updateAppItem(appId, itemId, token, {
              ...itemData,
              isDraft: isDraft || !hasLinkedMercadoPago,
            })
          : await Apps.createAppItem(appId, token, {
              ...itemData,
              isDraft: isDraft || !hasLinkedMercadoPago,
            });

        const navigateTo = redirectPath ? redirectPath : "/";

        hasLinkedMercadoPago
          ? navigate(navigateTo)
          : navigate(`/mercado-pago/${appId}/${item.id}`);
      } catch (error) {
        newError("Houve um erro ao salvar o item");
      } finally {
        hideLoading();
      }
    },
    [
      token,
      navigate,
      newError,
      showLoading,
      hideLoading,
      user.mercadoPagoIntegration,
    ]
  );

  const removeItemHandler = useCallback(
    async (appId: string, itemId: string, navigateTo?: string) => {
      try {
        showLoading();

        await Apps.removeAppItem(appId, itemId, token);

        navigate(navigateTo || `/`);
      } catch (error) {
        newError("Houve um erro ao remover o item");
      } finally {
        hideLoading();
      }
    },
    [hideLoading, navigate, newError, showLoading, token]
  );

  const getItem = useCallback(
    async (appId: string, itemId: string) => {
      try {
        showLoading();

        const itemData = await Apps.getAppItem(appId, itemId, token);

        return itemData;
      } catch (error) {
        newError("Houve um erro ao obter os dados do item");
        navigate("/apps");
      } finally {
        hideLoading();
      }
    },
    [hideLoading, navigate, newError, showLoading, token]
  );

  const getItems = useCallback(
    async (appId: string) => {
      try {
        showLoading();

        const appItems = await Apps.getAppItems(appId, token);

        const [published, drafts] = appItems.reduce(
          (acc, curr) => {
            if (curr.isDraft) acc[1].push(curr);
            else acc[0].push(curr);

            return acc;
          },
          [[], []] as IAppItem[][]
        );

        return {
          drafts,
          published,
        };
      } catch (error) {
        newError("Houve um erro ao obter os dados do app");
        navigate("/apps");
      } finally {
        hideLoading();
      }
    },
    [hideLoading, navigate, newError, showLoading, token]
  );

  return {
    getItem,
    getItems,
    saveItemHandler,
    removeItemHandler,
    saveFreeItemHandler,
  };
};

export default {
  useApp,
};
