import axios from "axios";
import { IOrder, TProductType } from "../../types";

const { REACT_APP_ORDER_BASE_REDIRECT_URL: BASE_URL } = process.env;

export interface IIncomeReport {
  message: string;
  totalizers: {
    allTimeValue: number;
    thisMonthValue: number;
    productsWithSales: {
      id: string;
      app: TProductType;
      title: string;
    }[];
  };
  sales: {
    items: IOrder[];
    totalValue: number;
  };
}

const getIncomeReport = async (
  token: string,
  productId?: string,
  period?: "current-month" | "past-30-days"
): Promise<IIncomeReport> => {
  try {
    const url = [`${BASE_URL}/order?`];

    if (period) url.push(`period=${period}&`);
    if (productId) url.push(`product=${productId}`);

    const { data } = await axios.get(encodeURI(url.join("")), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data as IIncomeReport;
  } catch (error) {
    throw new Error("Houve um erro ao obter os dados de vendas");
  }
};

export default {
  getIncomeReport,
};
