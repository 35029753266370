import { Theme } from "../../../hooks";

import * as S from "./styles";

interface IProps {
  isOn: boolean;
  setIsOn: (isOn: boolean) => void;
  small?: boolean;
}

const Switcher: React.FC<IProps> = ({ isOn, setIsOn, small = false }) => {
  const { primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <S.Switcher
      isSmall={small}
      isOn={isOn}
      backgroundColor={primaryColor}
      onClick={() => setIsOn(!isOn)}
    >
      <S.Backgorund backgroundColor={backgroundColor} />
    </S.Switcher>
  );
};

export default Switcher;
