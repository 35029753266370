import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const DiamondIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="41"
      height="37"
      viewBox="0 0 41 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.824581 14.7141C-0.0561954 13.7164 -0.250791 12.2639 0.334776 11.058L3.95163 3.60937C5.02575 1.39731 7.22142 0 9.62323 0H31.3768C33.7786 0 35.9742 1.39731 37.0484 3.60937L40.6652 11.058C41.2508 12.2639 41.0562 13.7164 40.1754 14.7141L20.5 37L0.824581 14.7141ZM5.70425 13.0588H14.159L19.4432 28.2941L5.70425 13.0588ZM14.159 13.0588H26.841L20.5 3.26471L14.159 13.0588Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default DiamondIcon;
