import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { IAppItem } from "../../types";
import Header from "./components/Header";

import CopyLinkModal from "../../components/atoms/CopyLinkModal";
import Page from "../../components/atoms/Page";
import BookDownloadIcon from "../../components/icons/BookDownloadIcon";
import CallIcon from "../../components/icons/CallIcon";
import ImageGalleryIcon from "../../components/icons/ImageGalleryIcon";
import InstagramTwoIcon from "../../components/icons/InstagramTwoIcon";
import LinkIcon from "../../components/icons/LinkIcon";
import MentorshipIcon from "../../components/icons/MentorshipIcon";
import OnlineCallIcon from "../../components/icons/OnlineCallIcon";
import PrivateGroupsIcon from "../../components/icons/PrivateGroupsIcon";
import SocialMediaIcon from "../../components/icons/SocialMediaIcon";
import AddContent from "../../components/molecules/AddContent";
import { App, Auth, Loading, Snackbar, StantiPay } from "../../hooks";
import { Apps, Creator } from "../../services";
import Banners from "./components/Banners";
import MyCalendar from "./components/MyCalendar";
import ProfileBody from "./components/ProfileBody";
import StorefrontLink from "./components/StorefrontLink";

import * as S from "./styles";

const appBoxes = [
  {
    label: "Links",
    route: "link-externo",
    icon: <LinkIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    icon: <CallIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
    label: "Serviço",
    route: "servico",
  },
  {
    route: "arquivo",
    label: "Ebooks e Arquivos",
    icon: (
      <BookDownloadIcon blendMode="overlay" opacity={0.9} color="#fafafa" />
    ),
  },
  {
    route: "mentoria",
    label: "Mentorias",
    icon: <MentorshipIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    route: "grupo",
    label: "Grupos Privados",
    icon: (
      <PrivateGroupsIcon blendMode="overlay" opacity={0.9} color="#fafafa" />
    ),
  },
  {
    route: "arquivo/course/new",
    label: "Cursos Gravados",
    icon: <OnlineCallIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
  {
    label: "Fotos e Imagens",
    route: "galeria-imagens",
    icon: (
      <ImageGalleryIcon blendMode="overlay" opacity={0.9} color="#fafafa" />
    ),
  },
  {
    label: "Post do Instagram",
    route: "link-to-post-instagram",
    icon: (
      <InstagramTwoIcon blendMode="overlay" opacity={0.9} color="#fafafa" />
    ),
  },
  {
    route: "redes-sociais",
    label: "Redes sociais",
    icon: <SocialMediaIcon blendMode="overlay" opacity={0.9} color="#fafafa" />,
  },
];

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [appItems, setAppItems] = useState<IAppItem[]>([]);

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState<boolean>(false);

  const [initialAppItems, setInitialAppItems] = useState<IAppItem[]>([]);

  const navigate = useNavigate();
  const { search } = useLocation();
  const { saveItemHandler } = App.useApp();
  const { user, token, setUserHandler } = Auth.useAuth();
  const { saveProductHandler } = StantiPay.useStantiPay();
  const { hideLoading, showLoading } = Loading.useLoading();
  const { newError, newSuccess, newInfo } = Snackbar.useSnackbar();

  const getAppItems = async () => {
    try {
      if (user.id) {
        const items = await Apps.getAllAppItems(token);

        setAppItems(items);
        setInitialAppItems(items);
      }
    } catch (error) {
      newError("Houve um erro ao obter os itens");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (!code) return;

    const run = async () => {
      try {
        showLoading();
        const creator = await Creator.linkWithMercadoPago(code, token);
        setUserHandler(creator);

        const [, appId, itemId] = (state || "").split("/");

        appId === "stanti-pay"
          ? await saveProductHandler({}, itemId, creator.mercadoPagoIntegration)
          : await saveItemHandler(
              appId,
              {},
              false,
              itemId,
              creator.mercadoPagoIntegration
            );

        getAppItems();

        newSuccess("Conta vinculada com sucesso");
        navigate(`/`);
      } catch (error) {
        newError("Houve um erro ao conectar ao Mercado Pago");
      } finally {
        hideLoading();
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getAppItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, token]);

  useEffect(() => {
    const run = async () => {
      if (user.id && !search) {
        const creator = await Creator.getCreator(user.id, token);

        setUserHandler(creator);
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPublish = async (appItem: IAppItem) => {
    if (
      !appItem.isDraft &&
      !user.mercadoPagoIntegration &&
      [
        "mentoria",
        "curso-live",
        "arquivo",
        "grupo",
        "servico",
        "stanti-pay",
      ].includes(appItem.app || "")
    ) {
      newInfo("Conecte sua conta do Mercado Pago.");
      return navigate(`/mercado-pago/${appItem.app}/${appItem.id}`);
    }

    const updatedItem = { ...appItem };
    delete updatedItem.id;
    delete updatedItem.app;

    try {
      showLoading();
      await Apps.updateAppItem(
        appItem.app || "",
        appItem.id || "",
        token,
        updatedItem
      );
      await getAppItems();
    } catch (error) {
      newError((error as { message: string }).message);
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <Header />
      <StorefrontLink
        username={user.username || ""}
        onShare={() => setIsCopyModalOpen(true)}
      />

      <MyCalendar />

      {appItems.length > 0 && <S.HomeSeparatorLine />}

      <Banners />

      <ProfileBody
        loading={loading}
        appItems={appItems}
        setAppItems={setAppItems}
        initialAppItems={initialAppItems}
        setInitialAppItems={setInitialAppItems}
        onPublish={onPublish}
      />

      <AddContent
        items={appBoxes}
        sectionTitle="Adicionar e vender conteúdos"
      />

      {isCopyModalOpen && (
        <S.Modal
          onClick={() => {
            setIsCopyModalOpen(false);
            setIsCopied(false);
          }}
        >
          <CopyLinkModal
            isCopied={isCopied}
            setIsCopied={() => setIsCopied(true)}
          />
        </S.Modal>
      )}
    </Page>
  );
};

export default Home;
