import { ChangeEvent, useRef } from "react";
import { Theme } from "../../../hooks";

import CloseIcon from "../../icons/CloseIcon";
import { IDate, ITime } from "../AppFormDateAndTime";
import Switcher from "../Switcher";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  time?: {
    start: ITime;
    end: ITime;
  };
  dates?: IDate[];
  errors?: string[];
  isAllDay?: boolean;
  onChange: (val: any, field: string) => void;
}

const AppFormDefinedDatesSelected: React.FC<IProps> = ({
  time,
  dates,
  errors,
  isAllDay,
  onChange,
}) => {
  const datesInputs = useRef<HTMLDivElement | null>(null);
  const inputEndHour = useRef<HTMLInputElement | null>(null);
  const inputStartHour = useRef<HTMLInputElement | null>(null);
  const inputEndMinutes = useRef<HTMLInputElement | null>(null);
  const inputStartMinutes = useRef<HTMLInputElement | null>(null);

  const { textColor } = Theme.useTheme();

  const addDateHandler = () => {
    const newDates = [...(dates || [])];
    newDates.push({ day: "", month: "", year: "" });

    onChange(newDates, "dates");
  };

  const onRemoveDate = (index: number) => {
    const newDates = [...(dates || [])];
    newDates.splice(index, 1);

    onChange(newDates, "dates");
  };

  const onChangeDate = (
    index: number,
    key: "day" | "month" | "year",
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const currentValue = (dates || [])[index][key];

    const value = e.target.value;

    const normalizers = {
      day: /[^0-90-9]/g,
      month: /[^0-90-9]/g,
      year: /[^0-90-90-90-9]/g,
    };

    const errors = {
      day: (val: number) => val > 31,
      month: (val: number) => val > 12,
      year: (val: number) => val > new Date().getFullYear() + 5,
    };

    const normalizedValue = value.replace(normalizers[key], "");

    const isErasing = normalizedValue.length < (currentValue || "").length;

    const error = errors[key](+normalizedValue);

    if (error) return;

    if (normalizedValue.length === 2) {
      if (key === "day")
        datesInputs.current &&
          (
            datesInputs.current.children[index].children[0]
              .children[1] as HTMLInputElement
          ).focus();
      if (key === "month")
        datesInputs.current &&
          (
            datesInputs.current.children[index].children[0]
              .children[2] as HTMLInputElement
          ).focus();
    }

    if (isErasing && normalizedValue.length === 0) {
      if (key === "year")
        datesInputs.current &&
          (
            datesInputs.current.children[index].children[0]
              .children[1] as HTMLInputElement
          ).focus();
      if (key === "month")
        datesInputs.current &&
          (
            datesInputs.current.children[index].children[0]
              .children[0] as HTMLInputElement
          ).focus();
    }

    const newDates = [...(dates || [])];
    newDates[index] = {
      ...(newDates[index] || { day: "", month: "", year: "" }),
      [key]: normalizedValue,
    };

    onChange(newDates, "dates");
  };

  const onChangeTime = (
    key: "hours" | "minutes",
    e: ChangeEvent<HTMLInputElement>,
    type: "start" | "end"
  ) => {
    const currentValue = ((time || {})[type] || {})[key];

    const value = e.target.value;
    const normalizedValue = value.replace(/[^0-90-9]/g, "");

    const isErasing = normalizedValue.length < (currentValue || "").length;

    const error =
      key === "hours" ? +normalizedValue > 23 : +normalizedValue > 59;

    if (error) return;

    if (normalizedValue.length === 2) {
      if (type === "start") {
        key === "hours"
          ? inputStartMinutes.current && inputStartMinutes.current.focus()
          : inputEndHour.current && inputEndHour.current.focus();
      }

      if (type === "end") {
        key === "hours" &&
          inputEndMinutes.current &&
          inputEndMinutes.current.focus();
      }
    }

    if (isErasing && normalizedValue.length === 0) {
      if (type === "start") {
        key === "minutes" &&
          inputStartHour.current &&
          inputStartHour.current.focus();
      }

      if (type === "end") {
        key === "hours"
          ? inputStartMinutes.current && inputStartMinutes.current.focus()
          : inputEndHour.current && inputEndHour.current.focus();
      }
    }

    const newInputTime: IProps["time"] = {
      ...(time || {
        start: { hours: "", minutes: "" },
        end: { hours: "", minutes: "" },
      }),
    };
    newInputTime[type][key] = normalizedValue;

    onChange(newInputTime, "time");
  };

  return (
    <S.DefinedDatesSelected>
      <S.DateInputs ref={datesInputs}>
        {(dates || []).map((date, index) => {
          return (
            <S.DateRow key={`dates${index}`}>
              <S.Inputs>
                <input
                  placeholder="dd"
                  value={date.day || ""}
                  onChange={(e) => onChangeDate(index, "day", e)}
                />
                /
                <input
                  placeholder="mm"
                  value={date.month || ""}
                  onChange={(e) => onChangeDate(index, "month", e)}
                />
                /
                <input
                  placeholder="aaaa"
                  value={date.year || ""}
                  onChange={(e) => onChangeDate(index, "year", e)}
                />
              </S.Inputs>

              {(dates || []).length > 1 && (
                <S.RemoveDate onClick={() => onRemoveDate(index)}>
                  <CloseIcon />
                </S.RemoveDate>
              )}
            </S.DateRow>
          );
        })}
      </S.DateInputs>

      <S.AddDateButton onClick={addDateHandler}>
        <div className="addIcon">
          <P color="#71726F">+</P>
        </div>

        <P color="#71726F">Adicionar mais uma data</P>
      </S.AddDateButton>

      <div>
        {!isAllDay && (
          <S.InputsRow>
            <P color={textColor}>Hora</P>

            <S.InputsContainer>
              <input
                placeholder="hh"
                ref={inputStartHour}
                value={time?.start?.hours || ""}
                onChange={(e) => onChangeTime("hours", e, "start")}
              />
              :
              <input
                placeholder="mm"
                ref={inputStartMinutes}
                value={time?.start?.minutes || ""}
                onChange={(e) => onChangeTime("minutes", e, "start")}
              />
              <P color={textColor}>às</P>
              <input
                placeholder="hh"
                ref={inputEndHour}
                value={time?.end?.hours || ""}
                onChange={(e) => onChangeTime("hours", e, "end")}
              />
              :
              <input
                placeholder="mm"
                ref={inputEndMinutes}
                value={time?.end?.minutes || ""}
                onChange={(e) => onChangeTime("minutes", e, "end")}
              />
            </S.InputsContainer>
          </S.InputsRow>
        )}

        <S.InputsRow>
          <P color="#FAFAFA40">Dia inteiro</P>

          <Switcher
            isOn={isAllDay || false}
            setIsOn={(val) => onChange(val, "isAllDay")}
          />
        </S.InputsRow>
      </div>

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </S.DefinedDatesSelected>
  );
};

export default AppFormDefinedDatesSelected;
