import { Auth, Theme } from "../../hooks";
import Header from "./components/Header";

import { useNavigate } from "react-router-dom";
import AppLinkButton from "../../components/atoms/AppLinkButton";
import CameraIcon from "../../components/icons/CameraIcon";
import LinkIcon from "../../components/icons/LinkIcon";
import MoneyTwoIcon from "../../components/icons/MoneyTwoIcon";
import { Analytics } from "../../services";
import AppSection from "./components/AppSection";
import CustomizeAppList from "./components/CustomizeAppList";
import MonetizedAppList from "./components/MonetizedAppList";

import * as S from "./styles";

const StantiPlace: React.FC = () => {
  const { user } = Auth.useAuth();
  const navigate = useNavigate();
  const { backgroundColor } = Theme.useTheme();

  const onClickHandler = (app: string) => {
    Analytics.submitClickEvent({
      description: app,
      origin: "add-content",
      creator: user.id || "",
      actionDescription: `app-${app}`,
      creatorUsername: user.username || "",
    });

    navigate(`/apps/${app}`);
  };

  return (
    <S.StantiApps backgroundColor={backgroundColor}>
      <Header />

      <S.AppSectionContainer>
        <AppSection
          icon={<LinkIcon />}
          title="Adicione quantos links você quiser"
          description="Compartilhe todos os seus links em um só lugar"
        />

        <S.Links>
          <AppLinkButton
            icon="link"
            link="/apps/link-externo"
            title="Adicione um link"
            onClick={() => onClickHandler("link-externo")}
          />
          <AppLinkButton
            link="/apps/redes-sociais"
            title="Adicione suas redes sociais"
            icon="socialMedia"
            onClick={() => onClickHandler("redes-sociais")}
          />
        </S.Links>
      </S.AppSectionContainer>

      <S.AppSectionContainer>
        <AppSection
          icon={<MoneyTwoIcon />}
          title="Ganhe dinheiro vendendo na Stanti"
          description="Venda serviços e produtos e escale o seu negócio digital"
        />
        <MonetizedAppList onClick={onClickHandler} />
      </S.AppSectionContainer>

      <S.AppSectionContainer>
        <AppSection
          icon={<CameraIcon />}
          title="Dê personalidade ao seu link"
          description="Conecte suas redes sociais e deixe o seu link com a sua cara"
        />

        <CustomizeAppList onClick={onClickHandler} />
      </S.AppSectionContainer>
    </S.StantiApps>
  );
};

export default StantiPlace;
