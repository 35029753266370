import { useNavigate } from "react-router-dom";

import { Auth, Theme } from "../../../../hooks";
import { Analytics } from "../../../../services";
import P from "../../../../components/atoms/Typography/P";
import Chevron from "../../../../components/icons/Chevron";
import CalendarIcon from "../../../../components/icons/CalendarIcon";

import * as S from "./styles";

const MyCalendar: React.FC = () => {
  const navigate = useNavigate();
  const { user } = Auth.useAuth();
  const { textColor } = Theme.useTheme();

  const onClickHandler = () => {
    Analytics.submitClickEvent({
      origin: "home",
      creator: user.id || "",
      description: "my-agenda",
      creatorUsername: user.username || "",
      actionDescription: "open-my-agenda",
    });

    navigate("/calendar");
  };

  return (
    <S.MyCalendar onClick={() => onClickHandler()}>
      <S.Container>
        <div className="icon">
          <CalendarIcon color="#DADADA" />
        </div>

        <P color={textColor}>Minha agenda</P>
      </S.Container>

      <S.ChevronIcon>
        <Chevron />
      </S.ChevronIcon>
    </S.MyCalendar>
  );
};

export default MyCalendar;
