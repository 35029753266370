import { ReactNode } from "react";
import { LinkProps } from "react-router-dom";

import { Theme } from "../../../hooks";

import * as S from "./styles";

interface IProps extends LinkProps {
  to: string;
  color?: string;
  className?: string;
  children: ReactNode;
}

const Link: React.FC<IProps> = ({
  children,
  color,
  className,
  to,
  ...props
}) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <S.Link
      to={to}
      textColor={color || primaryColor}
      className={className}
      {...props}
    >
      {children}
    </S.Link>
  );
};

export default Link;
