import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BubblesIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8017 12.6C16.8017 5.64121 22.4429 0 29.4017 0C36.3605 0 42.0017 5.64121 42.0017 12.6C42.0017 19.5588 36.3605 25.2 29.4017 25.2C22.4429 25.2 16.8017 19.5588 16.8017 12.6ZM8.4 18.8998C5.39897 18.8998 2.6259 20.5008 1.12539 23.0998C-0.375129 25.6988 -0.375129 28.9008 1.12539 31.4998C2.6259 34.0988 5.39897 35.6998 8.4 35.6998C11.401 35.6998 14.1741 34.0988 15.6746 31.4998C17.1751 28.9008 17.1751 25.6988 15.6746 23.0998C14.1741 20.5008 11.401 18.8998 8.4 18.8998ZM28.3492 31.5C25.4497 31.5 23.0992 33.8505 23.0992 36.75C23.0992 39.6495 25.4497 42 28.3492 42C31.2487 42 33.5992 39.6495 33.5992 36.75C33.5992 33.8505 31.2487 31.5 28.3492 31.5Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BubblesIcon;
