import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  label: string;
  color?: string;
  onClick: () => void;
}

const AddButton: React.FC<IProps> = ({ label, onClick, color }) => {
  return (
    <S.AddButton
      onClick={onClick}
      textColor={color || "initial"}
      borderColor={color || "initial"}
    >
      <S.AddIcon borderColor={color || "initial"}>
        <P color="inherit">+</P>
      </S.AddIcon>

      <P color="inherit">{label}</P>
    </S.AddButton>
  );
};

export default AddButton;
