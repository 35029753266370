import { Navigate, Route } from "react-router-dom";

import Form from "./components/Form";

const AppVendaGrupo = () => {
  return (
    <Route path="grupo">
      <Route path="new" element={<Form />} />
      <Route path=":id" element={<Form />} />

      <Route index path="*" element={<Navigate to="/apps/grupo/new" />} />
    </Route>
  );
};

export default AppVendaGrupo;
