import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ArrowBottomIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1928 22.7394L19.5079 33.6292C17.6997 35.4721 14.7118 35.4998 12.8682 33.6908L1.97369 23.0014C0.582549 21.6364 0.560939 19.4121 1.92531 18.0216C3.28968 16.631 5.514 16.6104 6.90514 17.9753L12.3859 23.353L12.4957 3.89571C12.5108 2.03627 14.0234 0.528149 15.8826 0.51089C17.7417 0.493631 19.267 1.99015 19.3182 3.84897L19.6577 23.4183L25.1645 17.8058C26.5289 16.4153 28.7532 16.3947 30.1444 17.7596C31.5355 19.1246 31.5571 21.3489 30.1928 22.7394Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ArrowBottomIcon;
