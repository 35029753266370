import styled, { css } from "styled-components";

type SwitcherProps = {
  isSmall: boolean;
  isOn: boolean;
  backgroundColor: string;
};

export const Switcher = styled.div<SwitcherProps>`
  ${({ isSmall, isOn, backgroundColor }) => css`
    width: 52px;
    height: 28px;
    padding: 3px;
    position: relative;
    border-radius: 60px;
    background-color: #2c2c2c;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${isOn ? backgroundColor : "#2C2C2C"};
    justify-content: ${isOn ? "flex-end" : "flex-start"};

    span {
      width: 22px;
      height: 22px;
      border-radius: 50px;
      position: relative;
    }

    
      ${
        isSmall &&
        css`
          width: 25px;
          height: 16px;
          padding: 1px;
          border-radius: 40px;

          span {
            width: 14px;
            height: 14px;
          }
        `
      }}
  `}
`;

type BackgroundProps = {
  backgroundColor: string;
};

export const Backgorund = styled.span<BackgroundProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
