import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const DribbbleIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6C0 4.912 0.268008 3.908 0.804 2.988C1.34 2.068 2.06801 1.34 2.988 0.804C3.908 0.267996 4.91201 0 6 0C7.088 0 8.09201 0.267996 9.012 0.804C9.932 1.34 10.66 2.068 11.196 2.988C11.732 3.908 12 4.912 12 6C12 7.088 11.732 8.092 11.196 9.012C10.66 9.932 9.932 10.66 9.012 11.196C8.09201 11.732 7.088 12 6 12C4.91201 12 3.908 11.732 2.988 11.196C2.06801 10.66 1.34 9.932 0.804 9.012C0.268008 8.092 0 7.088 0 6ZM0.996 6C0.996 7.248 1.416 8.348 2.256 9.3C2.64 8.548 3.248 7.832 4.08 7.152C4.91201 6.472 5.724 6.044 6.516 5.868C6.396 5.588 6.28001 5.336 6.168 5.112C4.79201 5.552 3.30401 5.772 1.704 5.772C1.392 5.772 1.16 5.768 1.008 5.76C1.008 5.792 1.00601 5.832 1.002 5.88C0.998004 5.928 0.996 5.968 0.996 6ZM1.152 4.764C1.328 4.78 1.58801 4.788 1.932 4.788C3.26801 4.788 4.536 4.608 5.736 4.248C5.12801 3.168 4.46 2.268 3.732 1.548C3.10001 1.868 2.558 2.312 2.106 2.88C1.65401 3.448 1.33601 4.076 1.152 4.764ZM2.94 9.948C3.84401 10.652 4.86401 11.004 6 11.004C6.59201 11.004 7.18001 10.892 7.764 10.668C7.604 9.3 7.292 7.976 6.828 6.696C6.092 6.856 5.35001 7.26 4.602 7.908C3.854 8.556 3.3 9.236 2.94 9.948ZM4.776 1.164C5.48 1.892 6.132 2.8 6.732 3.888C7.82 3.432 8.64 2.852 9.192 2.148C8.264 1.38 7.2 0.996 6 0.996C5.592 0.996 5.184 1.052 4.776 1.164ZM7.164 4.74C7.284 4.996 7.42001 5.32 7.572 5.712C8.16401 5.656 8.808 5.628 9.504 5.628C10 5.628 10.492 5.64 10.98 5.664C10.916 4.576 10.524 3.608 9.804 2.76C9.284 3.536 8.40401 4.196 7.164 4.74ZM7.872 6.552C8.28 7.736 8.556 8.952 8.7 10.2C9.332 9.792 9.848 9.268 10.248 8.628C10.648 7.988 10.888 7.296 10.968 6.552C10.384 6.512 9.852 6.492 9.372 6.492C8.93201 6.492 8.432 6.512 7.872 6.552Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default DribbbleIcon;
