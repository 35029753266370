import PlusIcon from "../../../../components/icons/PlusIcon";

import * as S from "./styles";

const Header: React.FC = () => {
  return (
    <S.HeaderContainer>
      <S.IconContainer>
        <PlusIcon color="#fafafa" />
      </S.IconContainer>

      <span>Adicionar</span>
    </S.HeaderContainer>
  );
};

export default Header;
