import styled from "styled-components";

export const FormAgendaSelected = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 12px;
  }
`;

export const NumberSelector = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;

  input {
    width: 58px;
    outline: none;
    font-size: 14px;
    min-height: 32px;
    color: #fafafa;
    padding: 8px 16px;
    text-align: center;
    border-radius: 40px;
    text-overflow: ellipsis;
    background-color: transparent;

    &::placeholder {
      font-size: 14px;
      color: #71726f;
    }
  }
`;

export const Button = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  color: black;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  user-select: none;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: #71726f;
`;
