import { useNavigate } from "react-router-dom";

import { IForm } from "../..";
import AppFormAboutAuthor from "../../../../../../components/atoms/AppFormAboutAuthor";
import AppFormAddIcon from "../../../../../../components/atoms/AppFormAddIcon";
import AppFormDescription from "../../../../../../components/atoms/AppFormDescription";
import AppFormFAQ from "../../../../../../components/atoms/AppFormFAQ";
import AppFormHeader from "../../../../../../components/atoms/AppFormHeader";
import AppFormMedia from "../../../../../../components/atoms/AppFormMedia";
import AppFormPrice from "../../../../../../components/atoms/AppFormPrice";
import AppFormTags from "../../../../../../components/atoms/AppFormTags";
import AppFormTargetAudience from "../../../../../../components/atoms/AppFormTargetAudience";
import AppFormTitle from "../../../../../../components/atoms/AppFormTitle";
import Input from "../../../../../../components/atoms/Input";
import Page from "../../../../../../components/atoms/Page";
import H3 from "../../../../../../components/atoms/Typography/H3";
import P from "../../../../../../components/atoms/Typography/P";
import Small from "../../../../../../components/atoms/Typography/Small";
import LinkIcon from "../../../../../../components/icons/LinkIcon";
import AppFormDuration from "../../../../../../components/molecules/AppFormDuration";
import AppFormFooter from "../../../../../../components/molecules/AppFormFooter";
import AppFormSection from "../../../../../../components/molecules/AppFormSection";
import appConfig from "../../../../../../config/app-arquivo.json";
import { Theme } from "../../../../../../hooks";

import * as S from "./styles";

interface IProps {
  item: IForm;
  onSave: () => void;
  providedURL: string;
  onDelete: () => void;
  isEditingItem: boolean;
  onAddMedia: (url: string) => void;
  errors: { [key: string]: string[] };
  setProvidedUrl: (val: string) => void;
  onRemoveMedia: (index: number) => void;
  onChangeHandler: (value: any, field: keyof IForm) => void;
}

const FormCursoGravado: React.FC<IProps> = ({
  item,
  errors,
  onSave,
  onDelete,
  onAddMedia,
  providedURL,
  isEditingItem,
  onRemoveMedia,
  setProvidedUrl,
  onChangeHandler,
}) => {
  const navigate = useNavigate();
  const { textColor } = Theme.useTheme();

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps/${appConfig.id}`)}
      />

      <AppFormSection>
        <S.UploadSection>
          <H3 color="#FAFAFA">Curso Gravado</H3>

          <P color={textColor}>
            Insira abaixo o link onde o vídeo está publicado. Recomendamos que
            utilize vídeos ou playlists não-listadas no YouTube. Também pode
            inserir um link para seu Dropbox ou Google Drive.
          </P>

          <Input
            leftIcon={<LinkIcon />}
            value={providedURL || ""}
            onChange={setProvidedUrl}
            placeholder="Link para assistir o vídeo"
          />

          {errors.url && errors.url.length && (
            <Small color="#FF4D4F">Erros: {errors.url.join(", ")}</Small>
          )}
        </S.UploadSection>
      </AppFormSection>

      <AppFormSection title="Sobre o curso">
        <AppFormTitle
          errors={errors.title}
          value={item.title || ""}
          placeholder="Título do curso"
          onChange={(val) => onChangeHandler(val, "title")}
        />

        <AppFormDescription
          errors={errors.description}
          value={item.description || ""}
          placeholder="Uma breve descrição sobre o material"
          onChange={(val) => onChangeHandler(val, "description")}
        />

        <AppFormDuration
          time={item.duration || ""}
          onChange={(val) => onChangeHandler(val, "duration")}
        />

        <AppFormAddIcon
          errors={errors.icon}
          icon={item.image || ""}
          onClear={() => onChangeHandler("", "image")}
          onChange={(image) => onChangeHandler(image, "image")}
          description="Este ícone ou imagem aparecerá na sua Stanti"
        />
      </AppFormSection>

      <AppFormSection title="Informações adicionais">
        <AppFormTargetAudience
          textLabelType="curso"
          errors={errors.targetAudience}
          value={item.targetAudience || ""}
          onChange={(val) => onChangeHandler(val, "targetAudience")}
        />

        <AppFormAboutAuthor
          errors={errors.aboutAuthor}
          value={item.aboutAuthor || ""}
          onChange={(val) => onChangeHandler(val, "aboutAuthor")}
        />
      </AppFormSection>

      <AppFormSection title="Mídia">
        <AppFormMedia
          errors={errors.media}
          onChange={onAddMedia}
          images={item.media || []}
          onRemoveImage={onRemoveMedia}
          description="Você pode adicionar fotos para deixar sua página de vendas mais bonita e informativa. O formato da imagem é o mesmo do feed vertical do Instagram."
        />
      </AppFormSection>

      <AppFormSection>
        <AppFormFAQ
          errors={errors.faq}
          faqs={item.faq || []}
          onChange={(val) => onChangeHandler(val, "faq")}
        />
      </AppFormSection>

      <AppFormSection title="Tags">
        <AppFormTags
          errors={errors.tags}
          value={item.tags || []}
          onChange={(val) => onChangeHandler(val, "tags")}
        />
      </AppFormSection>

      <AppFormSection title="Preço">
        <AppFormPrice
          appId={appConfig.id}
          errors={errors.price}
          value={item.price || 0}
          onChange={(val) => onChangeHandler(val, "price")}
        />
      </AppFormSection>

      <AppFormFooter
        onSave={onSave}
        appTitle="Curso"
        saveDraft={false}
        onRemove={onDelete}
        isEditingItem={isEditingItem}
      />
    </Page>
  );
};

export default FormCursoGravado;
