import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 12px;
`;

export const RemoveItem = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  user-select: none;
  align-self: center;
`;
