import Small from "../Typography/Small";
import TextAreaWithLabel from "../../molecules/TextAreaWithLabel";

interface IProps {
  value: string;
  errors?: string[];
  placeholder?: string;
  onChange: (value: string) => void;
  charLimit?: number;
  textAreaLabel?: string;
}

const AppFormDescription: React.FC<IProps> = ({
  value,
  errors,
  onChange,
  placeholder,
  charLimit = 3000,
  textAreaLabel = "Descrição",
}) => {
  const placeholderValue =
    placeholder !== undefined
      ? placeholder
      : "Uma breve descrição e sobre o que o usuário irá encontrar neste produto/serviço";

  return (
    <>
      <TextAreaWithLabel
        value={value}
        charLimit={charLimit}
        onChange={onChange}
        textAreaLabel={textAreaLabel}
        placeholder={placeholderValue}
      />

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </>
  );
};

export default AppFormDescription;
