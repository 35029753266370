import { ReactNode } from "react";

import { Theme } from "../../../hooks";
import CreatorImageBackground from "../CreatorImageBackground";
import * as S from "./styles";

interface IProps {
  children: ReactNode;
}

const Page: React.FC<IProps> = ({ children }) => {
  const { backgroundColor } = Theme.useTheme();

  return (
    <S.Page backgroundColor={backgroundColor}>
      <CreatorImageBackground />
      <S.Container>{children}</S.Container>
    </S.Page>
  );
};

export default Page;
