import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import P from "../../atoms/Typography/P";

import * as S from "./styles";

interface IProps {
  saveDraft: boolean;
  onRemove: () => void;
  isEditingItem: boolean;
  onSave: (isDraft: boolean) => void;
}

const AppFormSocialMediaFooter: React.FC<IProps> = ({
  onSave,
  onRemove,
  saveDraft,
  isEditingItem,
}) => {
  const { textColor, primaryColor } = Theme.useTheme();

  return (
    <S.Container>
      <Button type="outline" onClick={() => onSave(false)}>
        {isEditingItem ? "Salvar alterações" : "Adicionar"}
      </Button>

      {saveDraft && (
        <Button color={textColor} onClick={() => onSave(true)}>
          Salvar como rascunho
        </Button>
      )}

      {isEditingItem && (
        <S.RemoveItemButton onClick={onRemove}>
          <P color={primaryColor}>Apagar item</P>
        </S.RemoveItemButton>
      )}
    </S.Container>
  );
};

export default AppFormSocialMediaFooter;
