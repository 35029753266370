import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SmileThreeIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.978 0C9.834 0 0 9.834 0 22C0 34.166 9.834 44 21.978 44C34.122 44 44 34.166 44 22C44 9.834 34.144 0 21.978 0ZM15.1361 17.4679L16.3021 18.6339C16.9401 19.2719 17.9961 19.2719 18.6341 18.6339C19.2721 17.9959 19.2721 16.9399 18.6341 16.3019L16.6981 14.3659C16.2871 13.954 15.729 13.7225 15.1471 13.7225C14.5652 13.7225 14.0071 13.954 13.5961 14.3659L11.6381 16.3019C11.0001 16.9399 11.0001 17.9959 11.6381 18.6339C12.2761 19.2719 13.3321 19.2719 13.9701 18.6339L15.1361 17.4679ZM21.9995 34.1C26.4655 34.1 30.3595 31.658 32.4495 28.05C32.8675 27.324 32.3395 26.4 31.4815 26.4H12.5175C11.6815 26.4 11.1315 27.324 11.5495 28.05C13.6395 31.658 17.5335 34.1 21.9995 34.1ZM27.6977 18.6339C27.0597 19.2719 26.0037 19.2719 25.3657 18.6339C24.7277 17.9959 24.7277 16.9399 25.3877 16.3019L27.3237 14.3659C27.7348 13.954 28.2928 13.7225 28.8747 13.7225C29.4567 13.7225 30.0147 13.954 30.4257 14.3659L32.3617 16.3019C32.9997 16.9399 32.9997 17.9959 32.3617 18.6339C31.7237 19.2719 30.6677 19.2719 30.0297 18.6339L28.8637 17.4679L27.6977 18.6339ZM4.4 22C4.4 31.724 12.276 39.6 22 39.6C31.724 39.6 39.6 31.724 39.6 22C39.6 12.276 31.724 4.4 22 4.4C12.276 4.4 4.4 12.276 4.4 22Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SmileThreeIcon;
