import { Theme } from "../../../hooks";
import { IAppProduct } from "../../../types";
import Switcher from "../Switcher";
import Label from "../Typography/Label";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  item: IAppProduct;
  onChange: () => void;
  paymentLabel: "PIX" | "cartão";
}

const PaymentOption: React.FC<IProps> = ({ item, onChange, paymentLabel }) => {
  const { textColor } = Theme.useTheme();

  const taxes = paymentLabel === "PIX" ? "0,99%" : "4,99%";

  const isSwitcherOn =
    paymentLabel === "PIX" ? item.allowPix : item.allowCreditCard;

  return (
    <S.PaymentOption>
      <S.TitleSection>
        <P color={textColor}>Permitir pagamento via {paymentLabel}</P>

        <Switcher isOn={isSwitcherOn || false} setIsOn={onChange} />
      </S.TitleSection>

      <Label color="#71726F">Taxas: {taxes} (Mercado Pago) + 2% Stanti</Label>
    </S.PaymentOption>
  );
};

export default PaymentOption;
