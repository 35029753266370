import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/atoms/Button";
import EditableProfileImage from "../../components/atoms/EditableProfileImage";
import Input from "../../components/atoms/Input";
import Page from "../../components/atoms/Page";
import TextArea from "../../components/atoms/TextArea";
import H4 from "../../components/atoms/Typography/H4";
import P from "../../components/atoms/Typography/P";
import { Auth, Loading, NavBar, Snackbar, Theme } from "../../hooks";
import { Creator } from "../../services";
import { ICreatorData } from "../../types";
import BackgroundSelectorList from "./components/BackgroundSelectorList";
import EditCategories from "./components/EditCategories";
import EditEmail from "./components/EditEmail";
import EditPhone from "./components/EditPhone";
import EditProfileHeader from "./components/EditProfileHeader";
import EditUsername from "./components/EditUsername";
import MonetizeSuaStanti from "./components/MonetizeSuaStanti";
import PrimarySelectorList from "./components/PrimarySelectorList";

import * as S from "./styles";

const unsavedMessage =
  "Você possui alterações não salvas, deseja descartá-las?";
const removeAccountMessage =
  "Tem certeza que deseja remover sua conta? Essa ação é irreversível, todos os seus dados serão perdidos.";

const Register: React.FC = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [creatorForm, setCreatorForm] = useState<ICreatorData>();
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isUsernameLoading, setIsUsernameLoading] = useState(false);
  const [initialCreator, setInitialCreator] = useState<ICreatorData>();

  const hasUnsavedChanges =
    JSON.stringify(initialCreator) !== JSON.stringify(creatorForm);

  const navigate = useNavigate();
  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const { hideNavBar, showNavBar } = NavBar.useNavBar();
  const { hideLoading, showLoading } = Loading.useLoading();
  const { logout, user, setUserHandler, token } = Auth.useAuth();

  const emailTimeout = useRef<NodeJS.Timeout | null>(null);
  const usernameTimeout = useRef<NodeJS.Timeout | null>(null);

  const isSaveButtonDisabled = !!(
    isUploadingImage ||
    isUsernameLoading ||
    isEmailLoading ||
    errors.length ||
    emailTimeout.current ||
    usernameTimeout.current
  );

  useEffect(() => {
    const hash = window.location.hash.slice(1).split("&")[0];

    if (!hash) return;

    document.getElementById(hash)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    showNavBar();
    hasUnsavedChanges ? hideNavBar() : showNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const beforeunload = (event: BeforeUnloadEvent) => {
      if (!hasUnsavedChanges) {
        return undefined;
      }

      event.preventDefault();

      event.returnValue = unsavedMessage;
      return unsavedMessage;
    };

    window.addEventListener("beforeunload", beforeunload);

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const get = async () => {
      try {
        showLoading();
        if (user.id) {
          const creator = await Creator.getCreator(user.id, token);
          delete creator.id;
          delete creator.password;
          delete creator.hasPersonalizedStanti;

          setCreatorForm(creator);

          const creatorDeepCopy: ICreatorData = { ...creator };

          if (creator.socialMedia && creator.socialMedia.length)
            creatorDeepCopy.socialMedia = [
              ...creator.socialMedia.map((m) => ({ ...m })),
            ];

          setInitialCreator(creatorDeepCopy);
        }
      } catch (error) {
        newError("Houve um erro ao obter seus dados");
      } finally {
        hideLoading();
      }
    };

    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const onChangeProfileImage = async (image: string) => {
    setIsUploadingImage(true);
    try {
      const url = await Creator.uploadProfileImage(image);

      setCreatorForm((current) => ({
        ...current,
        profileImage: url,
      }));
    } catch (error) {
      newError("Houve um erro ao enviar a sua foto de perfil");
    } finally {
      setIsUploadingImage(false);
    }
  };

  const onChangeUsername = (username: string) => {
    setIsUsernameLoading(false);
    setErrors((current) => current.filter((val) => val !== "username"));

    setCreatorForm((current) => ({
      ...current,
      username,
    }));

    if (usernameTimeout.current) {
      clearTimeout(usernameTimeout.current);
      usernameTimeout.current = null;
    }

    if (username && username !== initialCreator?.username) {
      usernameTimeout.current = setTimeout(async () => {
        setIsUsernameLoading(true);

        try {
          const isUsernameRegistered = await Creator.checkUsernameAvailability(
            username
          );

          if (isUsernameRegistered) {
            setErrors((current) => [...current, "username"]);
            newError("Nome de usuário indisponível");
          }
        } catch (error) {
          newError("Erro ao verificar o nome de usuário");
        } finally {
          if (usernameTimeout.current) {
            clearTimeout(usernameTimeout.current);
            usernameTimeout.current = null;
          }
          setIsUsernameLoading(false);
        }
      }, 2000);
    }
  };

  const onChangeEmail = (email: string) => {
    setIsUsernameLoading(false);
    setErrors((current) => current.filter((val) => val !== "email"));

    setCreatorForm((current) => ({
      ...current,
      email,
    }));

    if (emailTimeout.current) {
      clearTimeout(emailTimeout.current);
      emailTimeout.current = null;
    }

    if (email && email !== initialCreator?.email) {
      emailTimeout.current = setTimeout(async () => {
        setIsEmailLoading(true);

        try {
          const isEmailRegistered = await Creator.checkEmailAvailability(email);

          if (isEmailRegistered) {
            setErrors((current) => [...current, "email"]);
            newError("Email já possui cadastro");
          }
        } catch (error) {
          newError("Erro ao verificar o email");
        } finally {
          if (emailTimeout.current) {
            clearTimeout(emailTimeout.current);
            emailTimeout.current = null;
          }
          setIsEmailLoading(false);
        }
      }, 2000);
    }
  };

  const onChangeTheme = (
    key: "primaryColor" | "backgroundColor",
    value: string
  ) => {
    setCreatorForm((curr) => ({ ...curr, [key]: value }));
    setUserHandler({ [key]: value });
  };

  const discardUnsavedData = () => {
    setUserHandler({ ...initialCreator });
    navigate("/");
  };

  const onDeleteAccount = async () => {
    const result = window.confirm(removeAccountMessage);
    if (result && user.id) {
      try {
        showLoading();
        await Creator.removeCreator(user.id, token);
        logout();
      } catch (error) {
        newError("Houve um erro ao remover a sua conta");
      } finally {
        hideLoading();
      }
    }
  };

  const onBackHandler = () => {
    if (hasUnsavedChanges) {
      const result = window.confirm(unsavedMessage);

      if (result) discardUnsavedData();
    } else navigate("/");
  };

  const onSave = async () => {
    showLoading();
    try {
      const updatedCreator: ICreatorData = { ...creatorForm };

      const requiredParams = [
        "email",
        "phone",
        "username",
        "primaryColor",
        "backgroundColor",
      ] as (keyof ICreatorData)[];

      requiredParams.forEach((key) => {
        if (!updatedCreator[key]) delete updatedCreator[key];
      });

      delete updatedCreator.id;

      const creator = await Creator.updateProfile(updatedCreator, token);
      setUserHandler(creator);
      navigate("/");
    } catch (error) {
      newError("Houve um erro ao salvar o seu perfil");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <EditProfileHeader onBack={onBackHandler} />

      <S.Content>
        <S.PersonalInfoEdit>
          <S.PersonalInfoTitle color="#fafafa">
            Informações Pessoais
          </S.PersonalInfoTitle>

          <EditableProfileImage
            showPlaceholder
            isLoading={isUploadingImage}
            onChangeImage={onChangeProfileImage}
            currentImage={creatorForm?.profileImage || ""}
          />

          <S.AddProfileImageText color={textColor}>
            Adicione sua
            <br />
            foto de perfil
          </S.AddProfileImageText>

          <EditUsername
            username={creatorForm?.username}
            onChangeUsername={onChangeUsername}
            isUsernameLoading={isUsernameLoading}
            hasError={errors.includes("username")}
          />

          <Input
            value={creatorForm?.name || ""}
            placeholder="Nome completo"
            onChange={(name: string) =>
              setCreatorForm((curr) => ({ ...curr, name }))
            }
          />

          <EditEmail
            email={creatorForm?.email}
            onChangeEmail={onChangeEmail}
            isEmailLoading={isEmailLoading}
            hasError={errors.includes("email")}
          />

          <TextArea
            charLimit={500}
            value={creatorForm?.about || ""}
            placeholder="Escreva uma breve descrição..."
            onChange={(about: string) =>
              setCreatorForm((curr) => ({ ...curr, about }))
            }
          />

          <EditPhone
            phone={creatorForm?.phone}
            onChangePhone={(phone) =>
              setCreatorForm((curr) => ({ ...curr, phone }))
            }
          />

          <EditCategories
            categories={creatorForm?.categories}
            onChangeCategories={(categories) =>
              setCreatorForm((curr) => ({ ...curr, categories }))
            }
          />
        </S.PersonalInfoEdit>

        <S.StyleEdit>
          <H4 color="#fafafa">Estilo</H4>

          <P color={primaryColor}>Cor principal</P>
          <PrimarySelectorList
            value={creatorForm?.primaryColor}
            selectedBackgroundColor={creatorForm?.backgroundColor}
            onChange={(color) => onChangeTheme("primaryColor", color)}
          />

          <P color={primaryColor}>Fundo</P>
          <BackgroundSelectorList
            value={creatorForm?.backgroundColor}
            selectedPrimaryColor={creatorForm?.primaryColor}
            onChange={(color) => onChangeTheme("backgroundColor", color)}
          />
        </S.StyleEdit>

        <MonetizeSuaStanti hasLinkedAccount={!!user.mercadoPagoIntegration} />

        <S.ButtonContainer>
          <Button type="outline" color="#71726f" onClick={logout}>
            Desconectar
          </Button>

          <Button
            style={{ color: "#fafafa" }}
            color="#FF4D4F"
            onClick={onDeleteAccount}
          >
            Apagar conta
          </Button>
        </S.ButtonContainer>

        {hasUnsavedChanges && (
          <S.SaveButton>
            <Button
              disabled={isSaveButtonDisabled}
              onClick={() => !isSaveButtonDisabled && onSave()}
            >
              Salvar Alterações e Sair
            </Button>
          </S.SaveButton>
        )}
      </S.Content>
    </Page>
  );
};

export default Register;
