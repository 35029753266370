import styled, { css } from "styled-components";

export const PrimarySelectorList = styled.div`
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
`;

export const PrimarySelectorItem = styled.div`
  gap: 6px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
`;

type PreviewProps = {
  backgroundColor: string;
};

export const Preview = styled.div<PreviewProps>`
  width: 44px;
  height: 14px;
  border-radius: 20px;

  background-color: ${({ backgroundColor }) => backgroundColor};
`;

type SelectorProps = {
  isSelected: boolean;
  borderColor: string;
};

export const Selector = styled.div<SelectorProps>`
  width: 16px;
  height: 16px;
  overflow: hidden;
  border: 1px solid;
  position: relative;
  border-radius: 20px;

  border-color: ${({ borderColor }) => borderColor};
  ${({ isSelected }) =>
    !isSelected &&
    css`
      background-color: #fafafa18;
      border-color: #212121;
    `}
`;
type IndicatorProps = {
  backgroundColor: string;
};

export const Indicator = styled.div<IndicatorProps>`
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 20px;

  background-color: ${({ backgroundColor }) => backgroundColor};
`;
