import { Theme } from "../../../hooks";
import P from "../Typography/P";

import * as S from "./styles";
interface IProps {
  description: string;
  isSelected: boolean;
  onSelect: () => void;
}

const SchedulingOptionSelector: React.FC<IProps> = ({
  onSelect,
  isSelected,
  description,
}) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <>
      <S.SchedulingOption onClick={onSelect}>
        <S.InputOut isSelected={isSelected} borderColor={primaryColor}>
          <S.InputIn isSelected={isSelected} backgroundColor={primaryColor} />
        </S.InputOut>

        <P color={isSelected ? "#FAFAFA" : "#71726F"}>{description}</P>
      </S.SchedulingOption>
    </>
  );
};

export default SchedulingOptionSelector;
