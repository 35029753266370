import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { IAppProduct, IUser } from "../../types";
import { StantiPay } from "../../services";
import { Auth, Loading, Snackbar } from "..";

const useStantiPay = () => {
  const navigate = useNavigate();
  const { token, user } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();

  const saveProductHandler = useCallback(
    async (
      productData: IAppProduct,
      productId?: string,
      mercadoPagoIntegration?: IUser["mercadoPagoIntegration"]
    ) => {
      try {
        showLoading();

        const hasLinkedMercadoPago = mercadoPagoIntegration
          ? !!mercadoPagoIntegration
          : !!user.mercadoPagoIntegration;

        const item = productId
          ? await StantiPay.updateAppProduct(token, productId, {
              ...productData,
              isDraft: !hasLinkedMercadoPago,
            })
          : await StantiPay.createAppProduct(token, {
              ...productData,
              isDraft: !hasLinkedMercadoPago,
            });

        hasLinkedMercadoPago
          ? navigate("/")
          : navigate(`/mercado-pago/stanti-pay/${item.id}`);
      } catch (error) {
        newError("Houve um erro ao salvar o produto");
      } finally {
        hideLoading();
      }
    },
    [
      token,
      navigate,
      newError,
      showLoading,
      hideLoading,
      user.mercadoPagoIntegration,
    ]
  );

  const removeProductHandler = useCallback(
    async (productId: string) => {
      try {
        showLoading();

        await StantiPay.removeAppProduct(token, productId);

        navigate("/apps/stanti-pay");
      } catch (error) {
        newError("Houve um erro ao remover o produto");
      } finally {
        hideLoading();
      }
    },
    [hideLoading, navigate, newError, showLoading, token]
  );

  const getProduct = useCallback(
    async (productId: string) => {
      try {
        showLoading();

        const productData = await StantiPay.getAppProduct(token, productId);

        return productData;
      } catch (error) {
        newError("Houve um erro ao obter os dados do produto");
        navigate("/apps");
      } finally {
        hideLoading();
      }
    },
    [hideLoading, navigate, newError, showLoading, token]
  );

  const getProducts = useCallback(async () => {
    try {
      const appProducts = await StantiPay.getAppProducts(token);

      return appProducts;
    } catch (error) {
      newError("Houve um erro ao obter os dados do app");
      navigate("/apps");
    }
  }, [navigate, newError, token]);

  return {
    getProduct,
    getProducts,
    saveProductHandler,
    removeProductHandler,
  };
};

export default {
  useStantiPay,
};
