import styled from "styled-components";

export const Card = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  padding: 0 15px;
  position: relative;
  align-items: center;
  border-radius: 20px;
  margin: 20px 0 10px 0;
  mix-blend-mode: screen;
  box-sizing: border-box;
  border: 1px solid rgba($color: #fafafa, $alpha: 0.1);
  background: linear-gradient(
      112.51deg,
      rgba(255, 255, 255, 0.2) -54.06%,
      rgba(255, 255, 255, 0) 59.7%
    ),
    #fafafa18;
`;

export const ProductDetails = styled.div`
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  justify-content: center;
`;

export const ProductText = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

export const Income = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
`;
