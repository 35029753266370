import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ClockIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 19.5C0 8.775 8.775 0 19.5 0C30.225 0 39 8.775 39 19.5C39 30.225 30.225 39 19.5 39C8.775 39 0 30.225 0 19.5ZM18.4671 22.0155L26.4231 26.91C27.1251 27.339 28.0416 27.1245 28.4706 26.442C28.8996 25.74 28.6656 24.8235 27.9636 24.3945L20.4756 19.89V11.2125C20.4756 10.413 19.8126 9.75 19.0131 9.75C18.2136 9.75 17.5506 10.413 17.5311 11.2125V20.358C17.5311 21.0405 17.8821 21.6645 18.4671 22.0155Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ClockIcon;
