import { useEffect, useState } from "react";

import { Loading, Snackbar, Theme } from "../../../hooks";
import { Apps } from "../../../services";
import { TIconOptions } from "../../../types";
import EditLinkImage from "../../molecules/EditLinkImage";
import ImagePreview from "../../molecules/ImagePreview";
import Switcher from "../Switcher";
import Label from "../Typography/Label";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  errors?: string[];
  onClear: () => void;
  description?: string;
  icon: TIconOptions | string;
  onChange: (icon: TIconOptions | string) => void;
}

const AppFormAddIcon: React.FC<IProps> = ({
  icon,
  errors,
  onClear,
  onChange,
  description,
}) => {
  const [isSwitcherOn, setIsSwitcherOn] = useState(!!icon);
  const [isEditImageOpen, setIsEditImageOpen] = useState(false);

  useEffect(() => {
    setIsSwitcherOn(!!icon);
  }, [icon]);

  const { showLoading, hideLoading } = Loading.useLoading();
  const { newError } = Snackbar.useSnackbar();
  const { textColor } = Theme.useTheme();

  const onUploadImageHandler = async (base64: string) => {
    setIsEditImageOpen(false);
    showLoading();
    try {
      const url = await Apps.uploadImage(base64);
      onChange(url);
    } catch (error) {
      newError("Houve um erro ao enviar a sua imagem");
    } finally {
      hideLoading();
    }
  };

  const onSelectIconHandler = (icon: TIconOptions) => {
    onChange(icon);
    setIsEditImageOpen(false);
  };

  const switcherHandler = (isOn: boolean) => {
    if (!isOn) onClear();
    setIsSwitcherOn(isOn);
  };

  const isUploadedImage = icon.match(/https:\/\//g);

  if (isEditImageOpen)
    return (
      <EditLinkImage
        onSelectIcon={onSelectIconHandler}
        onUploadImage={onUploadImageHandler}
      />
    );

  return (
    <S.FormAddImage>
      <S.AddImageHeader>
        <P color={textColor}>Adicionar ícone ao botão</P>

        <Switcher isOn={isSwitcherOn} setIsOn={switcherHandler} />
      </S.AddImageHeader>

      {!!description && isSwitcherOn && (
        <Label color="#fafafa40">{description}</Label>
      )}

      {isSwitcherOn && (
        <ImagePreview
          uploadedImageUrl={isUploadedImage ? icon : ""}
          onOpenEditImage={() => setIsEditImageOpen(true)}
          selectedImage={isUploadedImage ? "" : (icon as TIconOptions)}
        />
      )}

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </S.FormAddImage>
  );
};

export default AppFormAddIcon;
