import styled, { css } from "styled-components";

export const ChannelVideoSelector = styled.div`
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Button = styled.button`
  gap: 8px;
  display: flex;
  cursor: pointer;
  user-select: none;
`;

type RadioButtonProps = {
  isSelected: boolean;
  borderColor: string;
};

export const RadioButton = styled.span<RadioButtonProps>`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  mix-blend-mode: screen;
  background-color: #fafafa18;

  ${({ borderColor, isSelected }) =>
    isSelected &&
    css`
      display: flex;
      border: 1px solid ${borderColor};
      align-items: center;
      justify-content: center;
      background-color: transparent;
    `}
`;

type IndicatorProps = {
  isSelected: boolean;
  backgroundColor: string;
};

export const Indicator = styled.span<IndicatorProps>`
  width: 16px;
  height: 16px;
  border-radius: 16px;

  ${({ isSelected, backgroundColor }) =>
    isSelected &&
    css`
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background-color: ${backgroundColor};
    `}
`;
