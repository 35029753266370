import styled from "styled-components";

export const Container = styled.div`
  margin-top: 24px;

  > p {
    margin-bottom: 22px;
  }
`;

export const SocialMediaButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
