import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/atoms/Page";
import SeparatorLine from "../../components/atoms/SeparatorLine";
import Income from "../../components/molecules/Income";
import { Auth, Loading, Snackbar } from "../../hooks";
import { Order } from "../../services";
import { IIncomeReport } from "../../services/order";
import Header from "./components/Header";
import LastPayments from "./components/LastPayments";

import * as S from "./styles";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  const [incomeReport, setIncomeReport] = useState<IIncomeReport | null>(null);

  const { token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  useEffect(() => {
    const run = async () => {
      try {
        showLoading();
        const dataIncomeReport = await Order.getIncomeReport(token);
        setIncomeReport(dataIncomeReport);
      } catch {
        newError("Houve um erro ao obter as vendas");
      } finally {
        hideLoading();
      }
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <Header />

      <Income
        monthValue={incomeReport?.totalizers.thisMonthValue || 0}
        totalValue={incomeReport?.totalizers.allTimeValue || 0}
      />

      <LastPayments
        sales={incomeReport?.sales.items || []}
        productsWithSales={incomeReport?.totalizers.productsWithSales || []}
      />

      {(incomeReport?.sales.items || []).length > 0 && (
        <S.SeeAllButton
          type="outline"
          onClick={() => navigate("/dashboard/statement")}
        >
          Ver todos
        </S.SeeAllButton>
      )}

      <SeparatorLine />
    </Page>
  );
};

export default Dashboard;
