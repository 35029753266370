import styled from "styled-components";

export const AppointmentsDate = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0;
  position: relative;
  border-radius: 20px;
  align-items: center;
  mix-blend-mode: screen;
  padding: 14px 0 14px 22px;
  border-top: 1px solid #ffffff1a;
  border-left: 1px solid #ffffff0d;
  border-right: 1px solid #ffffff0d;
  background: linear-gradient(
    271.89deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(250, 250, 250, 0.15) 110.96%
  );

  .weekDay {
    left: 0;
    width: 60%;
    display: flex;
    margin-left: 70px;
    position: absolute;
    border-radius: 20px;
    mix-blend-mode: screen;
    border-left: 1px solid #ffffff0d;
    background: linear-gradient(
      271.89deg,
      rgba(255, 255, 255, 0) -0.4%,
      rgba(250, 250, 250, 0.1) 100.96%
    );
    padding: 14px 0 14px 26px;
  }
`;
