import styled from "styled-components";

export const FormFrequentlyAskedQuestions = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Faq = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const FaqHeader = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
`;

export const RemoveButton = styled.div`
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const AddFaq = styled.div`
  gap: 12px;
  display: flex;
  padding: 16px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  border: 1px dashed #71726f;

  .addIcon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    border: 1px solid #71726f;
  }
`;
