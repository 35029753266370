export function normalizeNumber(value: string | number) {
  const normalizedNumber = (
    typeof value === "string" ? +value : value
  ).toLocaleString("pt", {
    minimumFractionDigits: 2,
  });
  return normalizedNumber;
}

export const maskPhoneNumber = (value: string) => {
  if (!value) return "";

  const ddd = value.substring(0, 2);
  const phone = value.substring(2);

  const firstPart = phone.substring(0, 5);
  const secondPart = phone.substring(5);

  if (!phone.length) return `(${value}`;

  if (!secondPart.length) return `(${ddd}) ${phone}`;

  return `(${ddd}) ${firstPart}-${secondPart}`;
};
