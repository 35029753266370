import styled from "styled-components";
import P from "../../components/atoms/Typography/P";

export const Content = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const TextContent = styled(P)`
  margin-bottom: 24px;

  span {
    font-weight: 500;
  }
`;
