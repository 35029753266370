import styled from "styled-components";
import H3 from "../../atoms/Typography/H3";

import Label from "../../atoms/Typography/Label";

export const Income = styled.div`
  padding: 20px 0;
`;

export const IncomeCard = styled.div`
  z-index: 0;
  display: flex;
  margin-top: 10px;
  position: relative;
  border-radius: 18px;
  box-sizing: border-box;
  border: 1px solid rgba($color: #fafafa26, $alpha: 0.1);
  background: linear-gradient(
    275.86deg,
    rgba(250, 250, 250, 0.2) -37.56%,
    rgba(113, 114, 111, 0) 139.32%
  );
`;

export const ThisMonthIncome = styled.div`
  z-index: 1;
  width: 100%;
  height: 58px;
  display: flex;
  padding: 0 15px;
  position: relative;
  align-items: center;
  border-radius: 18px;
  box-sizing: border-box;
  border-right: 1px solid rgba($color: #fafafa26, $alpha: 0.1);
  background: linear-gradient(
    275.86deg,
    rgba(250, 250, 250, 0.2) -37.56%,
    rgba(113, 114, 111, 0) 139.32%
  );
`;

export const LabelText = styled(Label)`
  padding-right: 10px;
  border-right: 1px solid #fafafa50;
  font-weight: 400;
`;

export const CoinSign = styled(Label)`
  padding: 0 10px;
`;

export const ValueHeading = styled(H3)`
  font-weight: 700;
`;

export const TotalValue = styled.div`
  height: 58px;
  display: flex;
  padding: 0 15px;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
`;
