import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BookDownloadIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="36"
      height="42"
      viewBox="0 0 36 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M4.73794 41.9505H3.35293C2.92316 41.7365 2.46207 41.5695 2.0671 41.3032C0.436751 40.2088 -0.0034595 38.5819 2.04253e-05 36.7184C0.0156801 26.524 0.00350035 16.3296 0.00872024 6.13512C0.0104602 2.59603 2.56994 0.0243693 6.11251 0.0208894C15.3482 0.0121896 24.5822 0.0278493 33.8179 9.87333e-06C34.5644 -0.00173009 34.7018 0.226205 34.7001 0.92045C34.6775 11.1149 34.6844 21.3076 34.6844 31.502C34.6844 31.676 34.6914 31.8483 34.6809 32.0223C34.6479 32.5564 34.366 32.8783 33.8336 32.9497C33.463 32.9984 33.0871 33.0419 32.7148 33.0419C23.7696 33.0349 14.8228 33.0193 5.87761 33.0088C5.3852 33.0088 4.89801 33.0558 4.43344 33.2298C3.01885 33.757 2.11929 35.2586 2.38203 36.6558C2.68304 38.2618 3.91668 39.3458 5.507 39.3458C14.252 39.3527 22.9954 39.3493 31.7404 39.3493C32.8192 39.3493 33.3586 38.8064 33.3586 37.7206C33.3586 35.972 33.3586 35.9998 35.0933 36.0068C35.6919 36.0085 35.8171 36.2312 35.7997 36.7654C35.7667 37.7468 35.7928 38.7281 35.7893 39.7112C35.7841 41.2737 35.4239 41.6321 33.8492 41.6321C24.7301 41.6321 15.611 41.6321 6.49182 41.6303C5.88457 41.6303 5.29125 41.6669 4.73968 41.9522L4.73794 41.9505ZM8.3414 18.5149C11.5447 21.8592 14.5774 25.0172 17.5963 28.1891C17.979 28.5911 18.1304 28.2448 18.3323 28.0343C21.2084 25.0398 24.0846 22.0436 26.9538 19.0404C27.0721 18.9151 27.3157 18.8229 27.18 18.5149C25.5253 18.5149 23.8549 18.4836 22.1863 18.5306C21.506 18.5497 21.3076 18.3549 21.318 17.6676C21.3581 15.129 21.3076 12.5904 21.3511 10.0518C21.3633 9.34535 21.1336 9.18005 20.4707 9.19745C18.7707 9.24095 17.0673 9.24443 15.3691 9.19745C14.6679 9.17657 14.4295 9.35057 14.4434 10.0935C14.4904 12.66 14.4452 15.2282 14.473 17.7963C14.48 18.3671 14.3095 18.5393 13.7422 18.5289C12.0197 18.4958 10.2936 18.5167 8.34314 18.5167L8.3414 18.5149Z"
          fill={color || primaryColor}
        />
      </g>
    </svg>
  );
};

export default BookDownloadIcon;
