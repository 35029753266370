import Small from "../../../../components/atoms/Typography/Small";
import { Theme } from "../../../../hooks";
import { IAppointments } from "../../../../types/calendar";
import Appointment from "../Appointment";
import AppointmentsDate from "../AppointmentsDate";

import * as S from "./styles";

interface IProps {
  weekDay: string;
  monthDate: string;
  appointments?: IAppointments[] | [];
  onRemoveAppointment: (val: string) => void;
  onAddToCalendar: (item: IAppointments) => void;
}

const AppointmentBox: React.FC<IProps> = ({
  weekDay,
  monthDate,
  appointments,
  onRemoveAppointment,
  onAddToCalendar,
}) => {
  const { textColor } = Theme.useTheme();

  return (
    <>
      <AppointmentsDate monthDate={monthDate} weekDay={weekDay} />

      {appointments &&
        appointments.map((appointment) => (
          <Appointment
            id={appointment.id}
            key={appointment.id}
            type={appointment.type}
            title={appointment.title}
            callUrl={appointment.callUrl}
            interval={appointment.interval}
            customerName={appointment.customerName}
            customerPhone={appointment.customerPhone}
            customerEmail={appointment.customerEmail}
            onRemoveAppointment={
              appointment.type === "BLOCK"
                ? undefined
                : () => onRemoveAppointment(appointment.id)
            }
            setCalendarModalOpen={
              appointment.type === "BLOCK"
                ? undefined
                : () => onAddToCalendar(appointment)
            }
          />
        ))}

      {!appointments?.length ? (
        <S.NoItems>
          <Small color={textColor}>
            Nenhum agendamento ou bloqueio para este dia
          </Small>
        </S.NoItems>
      ) : null}
    </>
  );
};

export default AppointmentBox;
