import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ArrowTopIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="32"
      height="37"
      viewBox="0 0 32 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.30862 13.4167L12.1882 1.96089C14.0293 0.0222137 17.1226 -0.0569202 19.0621 1.78503L30.5229 12.6693C31.9863 14.0591 32.0462 16.3619 30.657 17.8247C29.2678 19.2875 26.965 19.3464 25.5015 17.9566L19.7359 12.481L19.9505 32.6293C19.9662 34.5549 18.4255 36.1419 16.5008 36.1912C14.5761 36.2404 12.9716 34.7166 12.8871 32.7928L12.2054 12.5361L6.5982 18.4402C5.20896 19.903 2.90619 19.9619 1.44274 18.5721C-0.0207082 17.1822 -0.0806147 14.8795 1.30862 13.4167Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ArrowTopIcon;
