import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const FingerPrintIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.102 6.00878C32.9286 6.00878 32.7552 5.96543 32.6035 5.87874C28.4425 3.7332 24.8449 2.82297 20.5322 2.82297C16.2411 2.82297 12.1667 3.84156 8.46079 5.87874C7.94066 6.16048 7.29049 5.96543 6.98708 5.4453C6.70535 4.92517 6.9004 4.25333 7.42053 3.9716C11.4515 1.78271 15.8727 0.655762 20.5322 0.655762C25.1483 0.655762 29.1793 1.67435 33.6005 3.94992C34.1423 4.23166 34.3373 4.88183 34.0556 5.40196C33.8605 5.79206 33.4921 6.00878 33.102 6.00878Z"
        fill={color || primaryColor}
      />
      <path
        d="M2.0885 17.3867C1.87178 17.3867 1.65506 17.3217 1.46001 17.1916C0.961548 16.8449 0.853188 16.1731 1.19994 15.6746C3.34548 12.6405 6.07617 10.2566 9.32699 8.58781C16.132 5.07693 24.8442 5.05525 31.671 8.56614C34.9218 10.2349 37.6525 12.5972 39.798 15.6096C40.1448 16.0864 40.0364 16.7799 39.5379 17.1266C39.0395 17.4734 38.3676 17.365 38.0209 16.8666C36.0704 14.1359 33.5998 11.9903 30.674 10.495C24.4541 7.30916 16.5005 7.30916 10.3022 10.5166C7.35483 12.0337 4.8842 14.2009 2.93371 16.9316C2.76034 17.235 2.43525 17.3867 2.0885 17.3867Z"
        fill={color || primaryColor}
      />
      <path
        d="M14.8759 43.2198C15.071 43.4365 15.3527 43.5449 15.6344 43.5449C15.9162 43.5449 16.1979 43.4365 16.4363 43.2198C16.8481 42.7863 16.8481 42.1145 16.4363 41.681C14.7676 39.9906 13.814 38.9287 12.427 36.4364C11.105 34.0958 10.4115 31.2134 10.4115 28.0926C10.4115 22.848 14.9409 18.5786 20.5107 18.5786C26.0804 18.5786 30.6099 22.848 30.6099 28.0926C30.6099 28.6994 31.0867 29.1762 31.6935 29.1762C32.3003 29.1762 32.7771 28.6994 32.7771 28.0926C32.7771 21.656 27.2724 16.4113 20.5107 16.4113C13.749 16.4113 8.24424 21.656 8.24424 28.0926C8.24424 31.5818 9.02444 34.8327 10.5198 37.4983C11.9718 40.1207 12.9904 41.3343 14.8759 43.2198Z"
        fill={color || primaryColor}
      />
      <path
        d="M31.1732 39.5356C28.5942 39.5356 26.3186 38.8855 24.4548 37.6068C21.2256 35.4179 19.2968 31.8637 19.2968 28.0927C19.2968 27.4859 19.7736 27.0091 20.3804 27.0091C20.9873 27.0091 21.464 27.4859 21.464 28.0927C21.464 31.1485 23.0244 34.0309 25.6684 35.808C27.2072 36.8483 29.0059 37.3467 31.1732 37.3467C31.6933 37.3467 32.5602 37.2817 33.4271 37.13C34.0122 37.0216 34.5757 37.4117 34.684 38.0186C34.7924 38.6037 34.4023 39.1672 33.7955 39.2756C32.5602 39.5139 31.4766 39.5356 31.1732 39.5356Z"
        fill={color || primaryColor}
      />
      <path
        d="M26.5333 43.9567C26.62 43.9784 26.7284 44 26.8151 44C27.2702 44 27.7253 43.675 27.8337 43.1765C27.9854 42.613 27.6603 42.0062 27.0751 41.8545C24.0194 41.0093 22.0472 39.8823 19.9883 37.8452C17.366 35.2445 15.9357 31.777 15.9357 28.071C15.9357 25.7521 17.9512 23.8666 20.4435 23.8666C22.9358 23.8666 24.9513 25.7521 24.9513 28.071C24.9513 31.5819 27.942 34.4426 31.6263 34.4426C35.3105 34.4426 38.3013 31.5819 38.3013 28.071C38.3013 18.7087 30.2826 11.1017 20.4218 11.1017C13.4217 11.1017 7.02842 15.0244 4.14603 21.0709C3.17078 23.1298 2.67232 25.492 2.67232 28.0927C2.67232 31.0184 3.19245 33.8358 4.25439 36.6749C4.44944 37.2167 5.07793 37.5201 5.6414 37.3034C6.20488 37.1083 6.48662 36.4798 6.26989 35.9163C4.96957 32.4488 4.81786 29.7831 4.81786 28.0927C4.81786 25.8388 5.2513 23.7799 6.09652 22.0245C8.63216 16.7148 14.2669 13.2906 20.4218 13.2906C29.0906 13.2906 36.1341 19.9223 36.1341 28.0927C36.1341 30.4116 34.1186 32.2971 31.6263 32.2971C29.134 32.2971 27.1185 30.4116 27.1185 28.0927C27.1185 24.5818 24.1277 21.7211 20.4435 21.7211C16.7592 21.7211 13.7684 24.5818 13.7684 28.0927C13.7684 32.3838 15.4372 36.3931 18.4713 39.4055C20.8336 41.7245 23.0875 43.0031 26.5333 43.9567Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default FingerPrintIcon;
