import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const FlowerIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="37"
      height="44"
      viewBox="0 0 37 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4371 24.2758C7.6202 24.2758 5.33398 21.9896 5.33398 19.1727C5.33398 17.1314 6.53833 15.3963 8.25299 14.5798C6.53833 13.7633 5.33398 12.0283 5.33398 9.987C5.33398 7.17006 7.6202 4.88385 10.4371 4.88385C11.519 4.88385 12.5192 5.21045 13.3357 5.78201L13.2949 5.39417C13.2949 2.57723 15.5811 0.291016 18.398 0.291016C21.215 0.291016 23.5012 2.57723 23.5012 5.39417L23.4604 5.78201C24.2973 5.21045 25.2771 4.88385 26.359 4.88385C29.1759 4.88385 31.4621 7.17006 31.4621 9.987C31.4621 12.0283 30.2578 13.7633 28.5431 14.5798C30.2578 15.3963 31.4621 17.1314 31.4621 19.1727C31.4621 21.9896 29.1759 24.2758 26.359 24.2758C25.2771 24.2758 24.2769 23.9492 23.4604 23.3777L23.5012 23.7655C23.5012 26.5824 21.215 28.8687 18.398 28.8687C15.5811 28.8687 13.2949 26.5824 13.2949 23.7655L13.3357 23.3777C12.4988 23.9492 11.519 24.2758 10.4371 24.2758ZM23.5012 14.5797C23.5012 11.7628 21.215 9.47656 18.3981 9.47656C15.5811 9.47656 13.2949 11.7628 13.2949 14.5797C13.2949 17.3967 15.5811 19.6829 18.3981 19.6829C21.215 19.6829 23.5012 17.3967 23.5012 14.5797Z"
        fill={color || primaryColor}
      />
      <path
        d="M18.3991 43.1575C27.7071 43.1573 35.4024 36.2171 36.6067 27.2357C36.7904 25.9293 35.6269 24.7657 34.3205 24.9495C25.3681 26.1499 18.4438 33.7994 18.3989 43.0668C18.354 33.7994 11.4296 26.1499 2.47728 24.9495C1.17087 24.7657 0.00735078 25.9293 0.191064 27.2357C1.39539 36.2171 9.09116 43.1573 18.3991 43.1575Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default FlowerIcon;
