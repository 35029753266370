import { Theme } from "../../../hooks";
import { ICreatorData } from "../../../types";

import * as S from "./styles";

interface IProps {
  onChange: (color: string) => void;
  value: ICreatorData["primaryColor"];
  selectedPrimaryColor: ICreatorData["primaryColor"];
}

const backgroundColors = [
  {
    id: "1",
    color: "#000000",
    borderColor: "#1c1c1b",
    allowedWith: [
      "#DDFF66",
      "#FF9960",
      "#00FFB2",
      "#FFA8BD",
      "#FAFAFA",
      "#A091FF",
      "#FFC700",
      "#A0D7FF",
    ],
  },
  {
    id: "2",
    color: "#181818",
    borderColor: "#3d3d3d",
    allowedWith: [
      "#DDFF66",
      "#FF9960",
      "#00FFB2",
      "#FFA8BD",
      "#FAFAFA",
      "#A091FF",
      "#FFC700",
      "#A0D7FF",
    ],
  },
  {
    id: "3",
    color: "#090223",
    borderColor: "#1a0666",
    allowedWith: [
      "#DDFF66",
      "#FF9960",
      "#00FFB2",
      "#FFA8BD",
      "#FAFAFA",
      "#A091FF",
      "#FFC700",
      "#A0D7FF",
    ],
  },
  {
    id: "4",
    color: "#1D232E",
    borderColor: "#5b6373",
    allowedWith: [
      "#DDFF66",
      "#FF9960",
      "#00FFB2",
      "#FFA8BD",
      "#FAFAFA",
      "#A091FF",
      "#FFC700",
      "#A0D7FF",
    ],
  },
];

const ChooseBackgroundColor: React.FC<IProps> = ({
  value,
  onChange,
  selectedPrimaryColor,
}) => {
  const { primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <div>
      <S.InputCheckbox>
        {backgroundColors
          .filter(({ allowedWith }) =>
            allowedWith.includes(selectedPrimaryColor || "")
          )
          .map(({ color, id, borderColor }) => {
            return (
              <S.Inputs key={id} onClick={() => onChange(color)}>
                <S.Selector
                  borderColor={value === color ? primaryColor : borderColor}
                  backgroundColor={color}
                >
                  <S.Indicator
                    borderColor={
                      value === color ? primaryColor : backgroundColor
                    }
                    isSelected={value === color}
                  />
                </S.Selector>
              </S.Inputs>
            );
          })}
      </S.InputCheckbox>
    </div>
  );
};

export default ChooseBackgroundColor;
