import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  icon: JSX.Element;
  title: string;
  description: string;
}

const AppSection: React.FC<IProps> = ({ icon, title, description }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.Section>
      <S.IconSection color={primaryColor}>{icon}</S.IconSection>

      <S.StantiPlaceTitle color="#fafafa">{title}</S.StantiPlaceTitle>
      <S.StantiPlaceDescription color={textColor}>
        {description}
      </S.StantiPlaceDescription>
    </S.Section>
  );
};

export default AppSection;
