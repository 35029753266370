import { Theme } from "../../../hooks";
import Input from "../../atoms/Input";
import P from "../../atoms/Typography/P";

import * as S from "./styles";

interface IProps {
  value: string;
  inputLabel: string;
  autofocus?: boolean;
  placeholder?: string;
  onChange: (val: string) => void;
  charLimit?: number;
}

const InputWithLabel: React.FC<IProps> = ({
  value,
  onChange,
  autofocus,
  inputLabel,
  placeholder,
  charLimit,
}) => {
  const { textColor } = Theme.useTheme();

  const onChangeHandler = (val: string) => {
    if (charLimit) onChange(val.substring(0, charLimit));
    else onChange(val);
  };

  return (
    <S.InputWithLabel>
      <P color={textColor}>{inputLabel}</P>

      <Input
        value={value}
        onChange={(event) => onChangeHandler(event)}
        charLimit={charLimit}
        autofocus={!!autofocus}
        placeholder={placeholder}
        limit
      />
    </S.InputWithLabel>
  );
};

export default InputWithLabel;
