import styled from "styled-components";
import P from "../../../../components/atoms/Typography/P";

export const Intro = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 24px;
  flex-direction: column;

  small {
    opacity: 0.5;
    line-height: 150%;
  }
`;

export const Title = styled(P)`
  font-weight: 500;
`;
