import styled, { css } from "styled-components";
import Label from "../../../../components/atoms/Typography/Label";

type DraggableProps = {
  isMobileDragOn?: boolean;
  coordinates?: {
    x: number;
    y: number;
  };
};

export const Container = styled.div<DraggableProps>`
  ${({ isMobileDragOn, coordinates }) =>
    isMobileDragOn &&
    css`
      position: absolute;
      top: ${coordinates?.y && `${coordinates.y}px`};
      left: ${coordinates?.x && `${coordinates.x}px`};
      transition: none;
      width: calc(100% - 48px);
    `}
`;

export const DraggableItem = styled.div<DraggableProps>`
  gap: 8px 0;
  cursor: grab;
  display: flex;
  user-select: none;
  border-radius: 22px;
  background-blend-mode: multiply;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);

  ${({ isMobileDragOn }) =>
    isMobileDragOn &&
    css`
      opacity: 0.5;
    `}
`;

export const Drag = styled.div`
  gap: 4px;
  width: 60px;
  display: flex;
  padding-left: 13px;
  touch-action: none;
  margin-right: -18px;
  flex-direction: column;
  justify-content: center;

  span {
    width: 16px;
    height: 2px;
    content: "";
    background-color: #fafafa40;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 16px;
  flex-shrink: 100;
  position: relative;
  border-radius: 22px;
  justify-content: space-between;
  background-blend-mode: multiply;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    275.86deg,
    rgba(250, 250, 250, 0.2) -37.56%,
    rgba(113, 114, 111, 0) 139.32%
  );

  .text {
    gap: 4px;
    flex-grow: 0;
    display: flex;
    padding: 4px 0;
    margin-top: 4px;
    overflow: hidden;
    flex-direction: column;

    .icon {
      width: 10px;
      height: 10px;
      margin-top: -2px;
      margin-right: 8px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const ContentLabel = styled(Label)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
`;

export const Actions = styled.div`
  gap: 4px;
  display: flex;

  .editIcon,
  .copyLinkIcon {
    width: 28px;
    height: 28px;
    padding: 6px;
    display: flex;
    cursor: pointer;
    align-self: center;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
  }
`;
