import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const HeadsetIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="33"
      height="35"
      viewBox="0 0 33 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4 0.035926C6.63667 0.606994 0 8.32562 0 17.1495V29.4735C0 32.5315 2.45667 35 5.5 35H7.33333C9.35 35 11 33.3421 11 31.3157V23.9471C11 21.9207 9.35 20.2628 7.33333 20.2628H3.66667V17.1127C3.66667 10.0388 9.09333 3.88603 16.115 3.68339C23.3933 3.48075 29.3333 9.32038 29.3333 16.5785V20.2628H25.6667C23.65 20.2628 22 21.9207 22 23.9471V31.3157C22 33.3421 23.65 35 25.6667 35H27.5C30.5433 35 33 32.5315 33 29.4735V16.5785C33 7.05453 25.0067 -0.590406 15.4 0.035926Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default HeadsetIcon;
