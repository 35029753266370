import P from "../../../../components/atoms/Typography/P";
import LinkIcon from "../../../../components/icons/LinkIcon";
import { Auth, Snackbar, Theme } from "../../../../hooks";
import { Analytics } from "../../../../services";
import AppointmentActionButton from "../AppointmentActionButton";
import { getWhatsappUrl } from "./utils";

import * as S from "./styles";

interface IProps {
  id: string;
  title: string;
  callUrl?: string;
  customerName?: string;
  customerPhone?: string;
  customerEmail?: string;
  type: "BLOCK" | "APPOINTMENT";
  onRemoveAppointment?: () => void;
  setCalendarModalOpen?: () => void;
  interval: { from: string; to: string };
}

const Appointment: React.FC<IProps> = ({
  id,
  type,
  title,
  callUrl,
  interval,
  customerName,
  customerEmail,
  customerPhone,
  onRemoveAppointment,
  setCalendarModalOpen,
}) => {
  const { user } = Auth.useAuth();
  const { newSuccess } = Snackbar.useSnackbar();
  const { primaryColor, textColor } = Theme.useTheme();

  const onContactHandler = (type: string) => {
    Analytics.submitClickEvent({
      origin: "calendar",
      creator: user.id || "",
      description: "contact-customer",
      meta: { type: type, appItem: id },
      creatorUsername: user.username || "",
      actionDescription: `contact-customer-${id}`,
    });
  };

  const onCopyCallUrlHandler = () => {
    if (!callUrl) return;

    navigator.clipboard.writeText(callUrl);

    newSuccess("Link do Zoom copiado");
  };

  const whatsappUrl = getWhatsappUrl(customerPhone);

  return (
    <S.Appointment>
      {type === "BLOCK" ? (
        <>
          <S.Interval>
            <P color="#fafafa80">
              {interval.from} - {interval.to}
            </P>
          </S.Interval>

          <S.Container>
            <P color="#fafafa80">Bloqueio agenda</P>

            <P color="#fafafa80">{title}</P>
          </S.Container>
        </>
      ) : (
        <>
          <S.Interval>
            <P color={textColor}>
              {interval.from} - {interval.to}
            </P>
          </S.Interval>

          <S.Container>
            <P color={textColor}>{title}</P>

            <P color={primaryColor}>{customerName}</P>

            {callUrl ? (
              <S.CallUrl onClick={onCopyCallUrlHandler}>
                <P color="#fafafa80">Reunião online por Zoom</P>

                <div className="linkIcon">
                  <LinkIcon />
                </div>
              </S.CallUrl>
            ) : null}

            <S.ActionButtons>
              {whatsappUrl ? (
                <AppointmentActionButton
                  type="whatsapp"
                  href={whatsappUrl}
                  onClick={() => onContactHandler("whatsapp")}
                />
              ) : null}

              {customerEmail ? (
                <AppointmentActionButton
                  type="email"
                  href={`mailto:${customerEmail}`}
                  onClick={() => onContactHandler("email")}
                />
              ) : null}

              {customerPhone ? (
                <AppointmentActionButton
                  type="phone"
                  href={`tel:${customerPhone}`}
                  onClick={() => onContactHandler("phone")}
                />
              ) : null}

              <AppointmentActionButton
                type="calendar"
                href={""}
                onClick={() => setCalendarModalOpen && setCalendarModalOpen()}
              />

              <span />

              <AppointmentActionButton
                type="trash"
                href={""}
                onClick={onRemoveAppointment}
              />
            </S.ActionButtons>
          </S.Container>
        </>
      )}
    </S.Appointment>
  );
};

export default Appointment;
