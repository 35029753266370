import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
`;

export const Form = styled.div`
  gap: 12px;
  display: flex;
  padding-top: 30px;
  flex-direction: column;

  .icon {
    display: flex;
    margin-top: -80px;
    justify-content: center;
  }

  .text {
    display: flex;
    margin-top: -100px;
    margin-bottom: 20px;
    justify-content: center;
  }
`;
