import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PersonPinTwoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.19999 0C2.8745 0 1.79999 1.07452 1.79999 2.4C1.79999 3.72548 2.8745 4.8 4.19999 4.8C5.52547 4.8 6.59999 3.72548 6.59999 2.4C6.59999 1.07452 5.52547 0 4.19999 0Z"
        fill={color || primaryColor}
      />
      <path
        d="M4.5 6C2.99813 6 0 6.67 0 8V8.5C0 8.775 0.253125 9 0.5625 9H8.4375C8.74687 9 9 8.775 9 8.5V8C9 6.67 6.00187 6 4.5 6Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PersonPinTwoIcon;
