import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const HomeIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="36"
      viewBox="0 0 39 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2562 33.8385V23.0309H23.7344V33.8385C23.7344 35.0273 24.6882 36 25.8539 36H32.2126C33.3783 36 34.3321 35.0273 34.3321 33.8385V18.7079H37.9354C38.9104 18.7079 39.3767 17.4758 38.6348 16.8274L20.9154 0.551186C20.11 -0.183729 18.8806 -0.183729 18.0752 0.551186L0.35577 16.8274C-0.364876 17.4758 0.0802291 18.7079 1.05522 18.7079H4.65845V33.8385C4.65845 35.0273 5.61225 36 6.778 36H13.1366C14.3024 36 15.2562 35.0273 15.2562 33.8385Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default HomeIcon;
