import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PhoneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3747 25.2315L28.1431 24.6342C26.8867 24.49 25.6508 24.9225 24.7652 25.8082L20.9753 29.5981C15.1464 26.6321 10.3679 21.8741 7.40189 16.0246L11.2123 12.2141C12.098 11.3284 12.5305 10.0926 12.3864 8.83617L11.7891 3.64569C11.5419 1.56538 9.79115 0 7.69025 0H4.12697C1.79951 0 -0.136613 1.93613 0.00756627 4.2636C1.09921 21.8535 15.167 35.9008 32.7362 36.9924C35.0637 37.1366 36.9998 35.2005 36.9998 32.873V29.3097C37.0204 27.2294 35.455 25.4786 33.3747 25.2315Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PhoneIcon;
