import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ForwardIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="41"
      height="37"
      viewBox="0 0 41 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.58307 37H26.0568C27.7409 37 29.3214 36.1801 30.28 34.7784L40.5142 20.0472C41.1619 19.1215 41.1619 17.9049 40.5142 16.9793L30.2541 2.22159C29.3214 0.819871 27.7409 0 26.0568 0H2.58307C0.484421 0 -0.733314 2.45961 0.48442 4.20515L10.5113 18.5132L0.48442 32.8213C-0.733314 34.5668 0.484421 37 2.58307 37Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ForwardIcon;
