import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BehanceIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.45726 4.47069C6.45726 4.47069 7.97133 4.36016 7.97133 2.6075C7.97133 0.854852 6.73261 0 5.1635 0H0V9.79228H5.1635C5.1635 9.79228 8.31549 9.89064 8.31549 6.90277C8.31549 6.90277 8.45293 4.47069 6.45726 4.47069ZM5.1635 1.74049C5.1635 1.74049 5.86526 1.74049 5.86526 2.75924C5.86526 3.77799 5.45262 3.92594 4.9843 3.92594H2.27503V1.74049H5.1635ZM5.01758 8.05194H2.27503V5.43496H5.1635C5.1635 5.43496 6.20958 5.42154 6.20958 6.77977C6.20958 7.92515 5.4283 8.04294 5.01758 8.05194ZM12.5195 2.49145C8.70349 2.49145 8.70685 6.25397 8.70685 6.25397C8.70685 6.25397 8.44509 9.99722 12.5195 9.99722C12.5195 9.99722 15.9148 10.1886 15.9148 7.39319H14.1686C14.1686 7.39319 14.2268 8.44589 12.5777 8.44589C12.5777 8.44589 10.8313 8.56147 10.8313 6.74234H15.9731C15.9731 6.74234 16.5356 2.49145 12.5195 2.49145ZM10.8121 5.43496C10.8121 5.43496 11.0252 3.92594 12.5582 3.92594C14.0911 3.92594 14.0716 5.43512 14.0716 5.43512L10.8121 5.43496ZM14.4785 1.78281H10.384V0.576954H14.4785V1.78281Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BehanceIcon;
