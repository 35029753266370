import { Navigate, Route } from "react-router-dom";

import Home from "./components/Home";
import appConfig from "../../config/app-youtube.json";

const AppPostsInstagram = () => {
  return (
    <Route path={appConfig.id}>
      <Route index element={<Home />} />
      <Route path="*" element={<Navigate to={`/apps/${appConfig.id}`} />} />
    </Route>
  );
};

export default AppPostsInstagram;
