import styled from "styled-components";

export const Snackbar = styled.div`
  left: 50%;
  top: 60px;
  z-index: 1000;
  cursor: pointer;
  position: fixed;
  width: 100%;
  max-width: 320px;
  overflow: hidden;
  border-radius: 40px;
  animation: slide 0.2s ease;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);

  @keyframes slide {
    from {
      margin-top: -120px;
    }
    to {
      margin-top: 0px;
    }
  }
`;

type ContainerProps = {
  backgroundColor: string;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border-radius: 40px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 12px;
  }
`;
