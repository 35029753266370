const RegisterPhoneStepIcon: React.FC = () => {
  return (
    <svg
      width="367"
      height="394"
      viewBox="0 0 367 394"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="4.5"
        transform="matrix(0.415698 -0.909503 -0.909503 -0.415698 111.626 170.626)"
        stroke="#DDFF66"
        strokeLinejoin="round"
      />
      <circle
        cx="129.754"
        cy="244.754"
        r="2"
        transform="rotate(-58.2434 129.754 244.754)"
        fill="#DDFF66"
      />
      <path
        d="M95.4818 240.832C94.4772 240.373 93.2906 240.815 92.8314 241.819C92.3722 242.824 92.8144 244.011 93.819 244.47C94.8236 244.929 96.0102 244.487 96.4694 243.482C96.9286 242.478 96.4864 241.291 95.4818 240.832Z"
        fill="#DDFF66"
      />
      <circle
        cx="104.754"
        cy="130.754"
        r="2"
        transform="rotate(-58.2434 104.754 130.754)"
        fill="#DDFF66"
      />
      <circle
        r="2"
        transform="matrix(0.415698 -0.909503 -0.909503 -0.415698 155.65 265.651)"
        fill="#DDFF66"
      />
      <circle
        cx="134.754"
        cy="153.754"
        r="2"
        transform="rotate(-58.2434 134.754 153.754)"
        fill="#DDFF66"
      />
      <path
        d="M163.013 118.024L159.082 121.954L163.048 125.921L161.903 127.066L157.937 123.1L154.023 127.013L152.966 125.956L156.879 122.043L152.913 118.077L154.059 116.931L158.025 120.897L161.956 116.966L163.013 118.024Z"
        fill="#DDFF66"
      />
      <path
        d="M113.006 212.512L111.041 214.477L113.024 216.46L112.451 217.033L110.468 215.05L108.512 217.006L107.983 216.478L109.939 214.521L107.956 212.538L108.529 211.965L110.512 213.948L112.477 211.983L113.006 212.512Z"
        fill="#DDFF66"
      />
      <path
        d="M127.006 261.512L125.041 263.477L127.024 265.46L126.451 266.033L124.468 264.05L122.512 266.006L121.983 265.478L123.939 263.521L121.956 261.538L122.529 260.965L124.512 262.948L126.477 260.983L127.006 261.512Z"
        fill="#DDFF66"
      />
      <circle
        r="4.5"
        transform="matrix(-0.415698 0.909503 0.909503 0.415698 254.353 216.675)"
        stroke="#DDFF66"
        strokeLinejoin="round"
      />
      <circle
        cx="236.226"
        cy="142.547"
        r="2"
        transform="rotate(121.757 236.226 142.547)"
        fill="#DDFF66"
      />
      <path
        d="M270.498 146.469C271.502 146.929 272.689 146.486 273.148 145.482C273.607 144.477 273.165 143.291 272.16 142.831C271.156 142.372 269.969 142.814 269.51 143.819C269.051 144.824 269.493 146.01 270.498 146.469Z"
        fill="#DDFF66"
      />
      <circle
        cx="261.226"
        cy="256.547"
        r="2"
        transform="rotate(121.757 261.226 256.547)"
        fill="#DDFF66"
      />
      <circle
        r="2"
        transform="matrix(-0.415698 0.909503 0.909503 0.415698 210.329 121.65)"
        fill="#DDFF66"
      />
      <circle
        cx="231.226"
        cy="233.547"
        r="2"
        transform="rotate(121.757 231.226 233.547)"
        fill="#DDFF66"
      />
      <path
        d="M202.966 269.278L206.897 265.347L202.931 261.381L204.077 260.235L208.043 264.201L211.956 260.288L213.013 261.345L209.1 265.259L213.066 269.225L211.921 270.37L207.954 266.404L204.024 270.335L202.966 269.278Z"
        fill="#DDFF66"
      />
      <path
        d="M252.973 174.79L254.938 172.824L252.956 170.841L253.528 170.268L255.511 172.251L257.468 170.295L257.997 170.824L256.04 172.78L258.023 174.763L257.45 175.336L255.467 173.353L253.502 175.318L252.973 174.79Z"
        fill="#DDFF66"
      />
      <path
        d="M238.973 125.79L240.938 123.824L238.956 121.841L239.528 121.268L241.511 123.251L243.468 121.295L243.997 121.824L242.04 123.78L244.023 125.763L243.45 126.336L241.467 124.353L239.502 126.318L238.973 125.79Z"
        fill="#DDFF66"
      />
      <g filter="url(#filter0_d_4326_35840)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M172.973 140.206L216.328 150.432C221.099 151.552 224.095 156.328 222.975 161.099L204.646 239.157C203.526 243.928 198.707 246.914 193.936 245.794L150.57 235.611C145.8 234.491 142.814 229.672 143.934 224.901L162.263 146.843C163.383 142.072 168.202 139.086 172.973 140.206ZM193.45 241.104C196.676 241.862 199.905 239.86 200.663 236.634L218.285 161.585C219.043 158.359 217.042 155.13 213.816 154.372L173.459 144.896C170.233 144.138 167.004 146.139 166.246 149.365L148.624 224.415C147.866 227.641 149.867 230.87 153.093 231.628L193.45 241.104Z"
          fill="#DDFF66"
        />
      </g>
      <g filter="url(#filter1_d_4326_35840)">
        <path
          d="M198.665 159.592L184.792 156.335C183.497 156.031 182.681 154.935 182.937 153.845C183.193 152.755 184.411 152.137 185.706 152.441L199.579 155.698C200.874 156.002 201.69 157.098 201.434 158.188C201.178 159.278 199.959 159.896 198.665 159.592Z"
          fill="#DDFF66"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.569 197.536C186.984 198.517 191.356 195.736 192.337 191.32C193.318 186.904 190.536 182.533 186.12 181.552C181.705 180.571 177.333 183.352 176.352 187.768C175.371 192.184 178.153 196.555 182.569 197.536ZM181.015 213.965L179.678 211.865C177.845 208.983 178.717 205.151 181.61 203.362L183.652 202.096C182.891 201.843 182.32 201.675 181.681 201.532C176.346 200.347 165.101 200.658 163.92 205.973L163.032 209.969L181.015 213.965ZM183.419 209.656L186.627 214.751C187.233 215.703 188.512 215.987 189.465 215.381L202.034 207.313C202.962 206.722 203.238 205.484 202.652 204.536L202.636 204.511C202.03 203.559 200.771 203.279 199.819 203.885L188.987 210.83L186.858 207.484C186.272 206.536 185.013 206.257 184.061 206.863L184.036 206.878C183.108 207.469 182.833 208.708 183.419 209.656Z"
        fill="#DDFF66"
      />
      <defs>
        <filter
          id="filter0_d_4326_35840"
          x="0.698425"
          y="0.970581"
          width="365.512"
          height="392.059"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_4326_35840"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="71" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.866667 0 0 0 0 1 0 0 0 0 0.4 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4326_35840"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4326_35840"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4326_35840"
          x="39.89"
          y="13.3634"
          width="304.591"
          height="293.306"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_4326_35840"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="71" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.866667 0 0 0 0 1 0 0 0 0 0.4 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4326_35840"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4326_35840"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default RegisterPhoneStepIcon;
