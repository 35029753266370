import styled, { css } from "styled-components";

export const AppVideo = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
`;

type ContainerProps = {
  isVideoPlaying: boolean;
};

export const Container = styled.div<ContainerProps>`
  ${({ isVideoPlaying }) => css`
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    max-height: calc(1.5 * 100vw - 40px);
    height: ${isVideoPlaying ? "80vh" : "52vh"};

    video {
      max-width: 100%;
      height: 80vh;
    }
  `}
`;

type PlayProps = {
  isVideoPlaying: boolean;
};

export const Play = styled.div<PlayProps>`
  ${({ isVideoPlaying }) => css`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: ${isVideoPlaying ? 0 : 1};

    svg {
      width: 40px;
      height: 40px;
    }
  `}
`;
