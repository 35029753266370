import { Theme } from "../../../hooks";
import Button from "../Button";
import H3 from "../Typography/H3";
import Label from "../Typography/Label";

import * as S from "./styles";

interface IProps {
  appTitle: string;
  onBack: () => void;
  showLiveLabel?: boolean;
}

const AppFormHeader: React.FC<IProps> = ({
  onBack,
  appTitle,
  showLiveLabel,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.Header showLiveLabel={showLiveLabel}>
      <S.Title>
        <H3 color={primaryColor}>{appTitle}</H3>

        {showLiveLabel && (
          <S.Live>
            <span />

            <Label color={textColor}>Ao vivo</Label>
          </S.Live>
        )}
      </S.Title>

      <Button onClick={onBack} type="outline">
        Voltar
      </Button>
    </S.Header>
  );
};

export default AppFormHeader;
