import ModalBluredBackdrop from "../../../../components/atoms/ModalBluredBackdrop";
import SeparatorLine from "../../../../components/atoms/SeparatorLine";
import P from "../../../../components/atoms/Typography/P";
import TrashCanIcon from "../../../../components/icons/TrashCanIcon";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onExcludeAppointment: () => void;
}

const ExcludeAppointmentModal: React.FC<IProps> = ({
  isOpen,
  onCloseModal,
  onExcludeAppointment,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  if (!isOpen) return null;

  return (
    <ModalBluredBackdrop onClickOutside={onCloseModal}>
      <S.Container onClick={(evt) => evt.stopPropagation()}>
        <div className="icon">
          <TrashCanIcon color="#ffffff80" />
        </div>

        <S.Texts>
          <P color={textColor}>Confirmar exclusão</P>

          <S.Subtitle color={primaryColor}>
            Deseja mesmo excluir este agendamento? Essa ação é permanente
          </S.Subtitle>

          <S.Subtitle color={textColor}>
            A Stanti não se responsabiliza por avisar nem reembolsar o cliente
            pelo cancelamento. Para efetuar reembolsos de clientes, acesse seu
            Mercado Pago.{" "}
          </S.Subtitle>
        </S.Texts>

        <S.Buttons>
          <S.ModalButton
            type="outline"
            color="#fafafa"
            onClick={onExcludeAppointment}
          >
            Excluir agendamento
          </S.ModalButton>

          <S.ModalButton
            type="outline"
            color={primaryColor}
            onClick={onCloseModal}
          >
            Manter o agendamento
          </S.ModalButton>
        </S.Buttons>

        <SeparatorLine />

        <S.WarningText color={textColor}>
          <S.Atention>Atenção:</S.Atention> Excluir um evento da sua agenda
          Stanti não removerá o evento do seu calendário particular
        </S.WarningText>
      </S.Container>
    </ModalBluredBackdrop>
  );
};

export default ExcludeAppointmentModal;
