import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const MouseIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2021 4.79855C17.4529 -0.452257 25.7023 -0.851215 31.4165 3.60167L21.2109 13.8073L12.2021 4.79855Z"
        fill={color || primaryColor}
      />
      <path
        d="M33.9895 6.17563L23.7839 16.3812L32.7927 25.39C38.0435 20.1392 38.4424 11.8897 33.9895 6.17563Z"
        fill={color || primaryColor}
      />
      <path
        d="M4.47966 12.5204C-1.20871 18.2087 -1.20871 27.4234 4.47966 33.1117C10.168 38.8001 19.3827 38.8001 25.071 33.1117L30.2189 27.9639L9.6275 7.37252L4.47966 12.5204Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default MouseIcon;
