import styled, { css } from "styled-components";
import ProfileImagePlaceholder from "../../../../components/icons/ProfileImagePlaceholder";

export const Description = styled.div`
  margin-top: 28px;

  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #fafafa;
  }

  p {
    font-size: 14px;
    color: #fafafa80;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
`;

type ProfileImageProps = {
  color: string;
  imageUrl?: string;
};

export const ProfileImage = styled.div<ProfileImageProps>`
  ${({ color, imageUrl }) => css`
    width: 116px;
    height: 116px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    background-size: cover;
    justify-content: center;
    background-position: center;
    background-image: url(${imageUrl});
    background-color: ${color};
    box-shadow: 0px 0px 224px 52px ${color}80;
  `}
`;

export const ImagePlaceholder = styled(ProfileImagePlaceholder)`
  margin-top: 12px;
  margin-left: -4px;
`;

export const CreatorSocialMedia = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  svg {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
`;
