import Label from "../../../../components/atoms/Typography/Label";
import Small from "../../../../components/atoms/Typography/Small";
import CheckIcon from "../../../../components/icons/CheckIcon";
import CloseIcon from "../../../../components/icons/CloseIcon";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  title: string;
  segment: string;
  imageUrl: string;
  status?: "pending" | "approved" | "rejected";
}

const BrandCard: React.FC<IProps> = ({ title, status, segment, imageUrl }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const isApproved = status && status === "approved";

  const iconRender = () => {
    if (!status || status === "pending") return null;

    return (
      <S.Icon
        opacity={isApproved ? 1 : 0.5}
        borderColor={isApproved ? primaryColor : "#fafafa"}
      >
        {isApproved ? <CheckIcon /> : <CloseIcon color="#fafafa" />}
      </S.Icon>
    );
  };

  return (
    <S.BrandCard>
      <S.Head>
        <S.Left>
          <Label color={textColor}>{segment}</Label>
          <Small color={textColor}>{title}</Small>
        </S.Left>

        {iconRender()}
      </S.Head>

      <S.Logo imageUrl={imageUrl} />
    </S.BrandCard>
  );
};

export default BrandCard;
