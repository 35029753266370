import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const InstagramIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.22511 6.56037C9.22511 5.08752 8.0311 3.89368 6.55842 3.89368C5.08557 3.89368 3.89172 5.08752 3.89172 6.56037C3.89172 8.03305 5.08557 9.22707 6.55842 9.22707C8.0311 9.22707 9.22511 8.03305 9.22511 6.56037Z"
        fill={color || primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29176 0.00192183C6.12999 0.00184923 5.98008 0.00178196 5.84073 0.00200005V0C4.34971 0.0016667 4.06371 0.0116668 3.3257 0.0450005C2.54569 0.0808342 2.12219 0.210836 1.84019 0.320837C1.46685 0.466172 1.20018 0.639507 0.920176 0.91951C0.640173 1.19951 0.466505 1.46618 0.321503 1.83952C0.212002 2.12152 0.0816675 2.54486 0.0460005 3.32487C0.00766675 4.16821 0 4.42021 0 6.55657C0 8.69292 0.00766675 8.94626 0.0460005 9.7896C0.0815009 10.5696 0.212002 10.9929 0.321503 11.2746C0.466838 11.6481 0.640173 11.9141 0.920176 12.1941C1.20018 12.4741 1.46685 12.6475 1.84019 12.7925C2.12236 12.902 2.54569 13.0323 3.3257 13.0683C4.16904 13.1066 4.42221 13.115 6.5584 13.115C8.69442 13.115 8.94776 13.1066 9.7911 13.0683C10.5711 13.0326 10.9948 12.9026 11.2765 12.7926C11.65 12.6476 11.9158 12.4743 12.1958 12.1943C12.4758 11.9145 12.6495 11.6486 12.7945 11.2753C12.904 10.9936 13.0343 10.5703 13.07 9.79027C13.1083 8.94693 13.1166 8.69359 13.1166 6.55857C13.1166 4.42355 13.1083 4.17021 13.07 3.32687C13.0345 2.54686 12.904 2.12352 12.7945 1.84185C12.6491 1.46852 12.4758 1.20185 12.1958 0.921843C11.916 0.64184 11.6498 0.468505 11.2765 0.323503C10.9944 0.214002 10.5711 0.0836675 9.7911 0.0480005C8.94759 0.0096668 8.69442 0.00200005 6.5584 0.00200005L6.29176 0.00192183ZM9.86877 2.29002C9.86877 1.75985 10.2988 1.33035 10.8288 1.33035C11.3588 1.33035 11.7888 1.76002 11.7888 2.29002C11.7888 2.82003 11.3588 3.25003 10.8288 3.25003C10.2988 3.25003 9.86877 2.82003 9.86877 2.29002ZM6.55823 2.45203C4.28945 2.45211 2.45003 4.2916 2.45003 6.5604C2.45003 8.82926 4.28955 10.6679 6.5584 10.6679C8.82726 10.6679 10.6661 8.82926 10.6661 6.5604C10.6661 4.29154 8.82709 2.45203 6.55823 2.45203Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default InstagramIcon;
