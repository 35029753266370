import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const NotificationsIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="30"
      height="38"
      viewBox="0 0 30 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0758 16.3136V25.9098L28.5516 28.3856C29.7607 29.5948 28.8971 31.6675 27.1889 31.6675H1.91248C0.204356 31.6675 -0.640112 29.5948 0.569012 28.3856L3.04484 25.9098V16.3136C3.04484 10.4023 6.17321 5.48904 11.6814 4.18395V2.87887C11.6814 1.28589 12.9673 0 14.5603 0C16.1533 0 17.4392 1.28589 17.4392 2.87887V4.18395C22.9282 5.48904 26.0758 10.4215 26.0758 16.3136ZM18.3989 33.5388C18.3989 35.65 16.6715 37.3773 14.5604 37.3773C12.43 37.3773 10.7219 35.65 10.7219 33.5388H18.3989Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default NotificationsIcon;
