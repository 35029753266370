import { useEffect, useState } from "react";
import Button from "../../../../components/atoms/Button";

import CreatorImageBackground from "../../../../components/atoms/CreatorImageBackground";
import NavigationArrow from "../../../../components/atoms/NavigationArrow";
import Page from "../../../../components/atoms/Page";
import StepsProgressBar from "../../../../components/atoms/StepsProgressBar";
import P from "../../../../components/atoms/Typography/P";
import Small from "../../../../components/atoms/Typography/Small";
import BackgroundQualificationIcon from "../../../../components/icons/BackgroundQualificationIcon";
import StantiIcon from "../../../../components/icons/StantiIcon";
import QualificationOption from "../../../../components/molecules/QualificationOptions";
import { Auth, GA, Theme } from "../../../../hooks";
import { Analytics, Creator } from "../../../../services";
import { THasDigitalProducts } from "../../../../types";

import * as RegisterStyles from "../styles";
import * as QualificationStepStyles from "./styles";

const S = {
  ...QualificationStepStyles,
  ...RegisterStyles,
};

const QualificationStep: React.FC = () => {
  const [qualificationAnswer, setQualificationAnswer] =
    useState<THasDigitalProducts>();

  const { sendEvents } = GA.useGA();
  const { primaryColor, textColor } = Theme.useTheme();
  const { user, setUserHandler, token } = Auth.useAuth();

  useEffect(() => {
    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "qualification-step",
      creatorUsername: user.username || "",
      actionDescription: "answer-qualification-step",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackHandler = () => {
    setUserHandler({ formStep: "social-media" });
  };

  const onNextHandler = async () => {
    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      meta: { answer: qualificationAnswer },
      description: "qualification-step-concluded",
      actionDescription: `qualification-step-concluded-${user.username}`,
    });

    const updatedUser = await Creator.updateProfile(
      {
        onboarding: true,
        qualifyAnswers: { hasDigitalProducts: qualificationAnswer },
      },
      token
    );

    sendEvents({
      category: "form",
      name: "CompleteRegistration",
      buttonLocation: "register-qualification-step",
    });

    window.fbq("track", "CompleteRegistration");

    setUserHandler(updatedUser);
  };

  const isReady = qualificationAnswer;

  return (
    <Page>
      <S.Wrapper>
        <CreatorImageBackground />

        <S.Container>
          <S.RegisterFormHeader>
            <div className="stantiLogo">
              <StantiIcon color="#fafafa" />
            </div>

            <Button
              style={{ color: "#fafafa" }}
              color="#fafafa33"
              onClick={onBackHandler}
            >
              Voltar
            </Button>
          </S.RegisterFormHeader>

          <S.QualificationStepProgressBar>
            <P color={textColor}>
              Quase lá, <S.Highlight>{user.name?.split(" ")[0]}</S.Highlight>!
            </P>

            <StepsProgressBar progress={(5 / 6) * 100} quantityOfBars={6} />
          </S.QualificationStepProgressBar>

          <S.Icon>
            <BackgroundQualificationIcon />
          </S.Icon>

          <S.CallToAction>
            <Small color={primaryColor}>Selecione abaixo</Small>

            <P color={textColor}>
              Você vende algum produto ou serviço digital?
            </P>

            <Small color={primaryColor}>
              Exemplo: ebooks, mentorias, cursos gravados
            </Small>
          </S.CallToAction>

          <S.Answers>
            <QualificationOption
              answer="Sim"
              isSelected={qualificationAnswer === "yes"}
              onSelect={() => setQualificationAnswer("yes")}
            />

            <QualificationOption
              answer="Não, mas tenho interesse em vender"
              isSelected={qualificationAnswer === "intent"}
              onSelect={() => setQualificationAnswer("intent")}
            />

            <QualificationOption
              answer="Não possuo interesse em vender"
              isSelected={qualificationAnswer === "no"}
              onSelect={() => setQualificationAnswer("no")}
            />
          </S.Answers>

          <S.Footer>
            <NavigationArrow
              buttonStyle="solid"
              angle={isReady ? 0 : -90}
              onClick={() => isReady && onNextHandler()}
              color={isReady ? primaryColor : "#ffffff33"}
            />
          </S.Footer>
        </S.Container>
      </S.Wrapper>
    </Page>
  );
};

export default QualificationStep;
