import { CSSProperties } from "react";
import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  onClick: () => void;
  style?: CSSProperties;
  blendMode?: "normal" | "overlay";
}

const HidePasswordIcon: React.FC<IProps> = ({
  color,
  onClick,
  style,
  blendMode,
  opacity,
}) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{
        ...style,
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71 1.7025C1.32 1.3125 1.32 0.6825 1.71 0.2925C2.1 -0.0975 2.74 -0.0975 3.13 0.2925L19.44 16.6225C19.83 17.0125 19.83 17.6425 19.44 18.0325C19.05 18.4225 18.42 18.4225 18.03 18.0325L15.31 15.3125C13.97 15.8325 12.52 16.1325 11 16.1325C6 16.1325 1.73 13.0225 0 8.6325C0.77 6.6625 2.06 4.9625 3.68 3.6725L1.71 1.7025ZM16 8.6325C16 5.8725 13.76 3.6325 11 3.6325C10.49 3.6325 10 3.7325 9.53 3.8725L7.36 1.7025C8.51 1.3325 9.73 1.1325 11 1.1325C16 1.1325 20.27 4.2425 22 8.6225C21.31 10.3825 20.21 11.9225 18.82 13.1525L15.76 10.0925C15.9 9.6325 16 9.1425 16 8.6325ZM11 13.6325C8.24 13.6325 6 11.3925 6 8.6325C6 7.8625 6.18 7.1325 6.49 6.4925L8.06 8.0625C8.03 8.2425 8 8.4325 8 8.6325C8 10.2925 9.34 11.6325 11 11.6325C11.2 11.6325 11.38 11.6025 11.57 11.5625L13.14 13.1325C12.49 13.4525 11.77 13.6325 11 13.6325ZM13.97 8.3025C13.82 6.9025 12.72 5.8125 11.33 5.6625L13.97 8.3025Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default HidePasswordIcon;
