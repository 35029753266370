import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import Page from "../../components/atoms/Page";
import H1 from "../../components/atoms/Typography/H1";
import P from "../../components/atoms/Typography/P";
import { Loading, Snackbar, Theme } from "../../hooks";
import { Creator } from "../../services";

import * as S from "./styles";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [message, setMessage] = useState("");

  const { primaryColor, textColor } = Theme.useTheme();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const onResetHandler = async () => {
    if (!user) return;

    showLoading();
    try {
      const email = await Creator.sendEmailToResetPassword(user);

      setMessage(`E-mail enviado para ${email}!\nCheque sua caixa de entrada.`);
      newSuccess("E-mail enviado com sucesso!");
    } catch (error) {
      if (error && (error as { message: string }).message === "CREA001") {
        setMessage("Usuário/e-mail não encontrado");
        newError("Usuário não encontrado");
      } else {
        newError("Houve um erro ao redefinir a senha");
      }
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <S.LoginFormHeader>
        <img alt="stanti icon" src="/stanti-icon.svg" />

        <Button onClick={() => navigate("/")} type="outline">
          Voltar
        </Button>
      </S.LoginFormHeader>

      <S.Cta>
        <H1 color={primaryColor}>Esqueceu? Acontece :)</H1>
        <P color={textColor}>Vamos te ajudar a criar uma nova senha.</P>
      </S.Cta>

      <S.Form>
        <Input
          allLowerCase
          value={user}
          onChange={(val) => {
            setUser(val);
            if (message) setMessage("");
          }}
          placeholder="E-mail ou username"
        />

        <Button
          onClick={onResetHandler}
          disabled={!user || user.length <= 3 || message.length > 1}
        >
          Enviar e-mail de redefinição de senha
        </Button>

        <S.Message color={primaryColor}>{message}</S.Message>
      </S.Form>
    </Page>
  );
};

export default ForgotPassword;
