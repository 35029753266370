import { ReactElement } from "react";

import { useNavigate } from "react-router-dom";
import { Auth, Theme } from "../../../hooks";
import { Analytics } from "../../../services";
import PlusIcon from "../../icons/PlusIcon";

import * as S from "./styles";

interface IProps {
  label: string;
  route: string;
  icon: ReactElement;
  containedBox?: boolean;
}

const AddContentBox: React.FC<IProps> = ({
  label,
  icon,
  route,
  containedBox = false,
}) => {
  const { user } = Auth.useAuth();
  const navigate = useNavigate();
  const { textColor } = Theme.useTheme();

  const onClickHandler = () => {
    Analytics.submitClickEvent({
      origin: "home",
      meta: { app: route },
      creator: user.id || "",
      description: "add-content-home",
      creatorUsername: user.username || "",
      actionDescription: `add-content-home-${route}`,
    });

    navigate(`/apps/${route}`);
  };

  return (
    <S.Box isContained={containedBox} onClick={() => onClickHandler()}>
      <div className="plusIcon">
        <PlusIcon />
      </div>

      <S.Content>
        <S.Icon>{icon}</S.Icon>

        <S.ContentLabel color={textColor}>{label}</S.ContentLabel>
      </S.Content>
    </S.Box>
  );
};

export default AddContentBox;
