import styled from "styled-components";

export const Duration = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    width: 96px;

    input {
      text-align: center;
    }
  }
`;
