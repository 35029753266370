import styled from "styled-components";

export const SocialMediaLogin = styled.div`
  gap: 10px;
  height: auto;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 15px;
  align-items: center;
  mix-blend-mode: screen;
  padding-left: 16px;
  background: linear-gradient(
    200deg,
    rgba(250, 250, 250, 0.1) -3.98%,
    rgba(113, 114, 111, 0) 203.38%
  );
`;

export const Icon = styled.div`
  width: 22px;
  height: 22px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled.div`
  height: 44px;
  flex-grow: 1;
  display: flex;
  padding: 0 16px;
  align-items: center;
  border-radius: 15px;
  mix-blend-mode: screen;
  background: linear-gradient(
    200deg,
    rgba(250, 250, 250, 0.1) -3.98%,
    rgba(113, 114, 111, 0) 203.38%
  );

  p {
    font-size: 12px;
    font-weight: 200;
    line-height: 18px;
    font-style: normal;
    font-family: "Poppins", sans-serif;
  }
`;
