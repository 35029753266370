import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BookTwoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="45"
      height="35"
      viewBox="0 0 45 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5102 3.06958C25.4775 0.818554 29.7749 0 33.7654 0C36.7326 0 39.884 0.450205 42.5034 1.61664C43.9973 2.29195 45 3.74488 45 5.40246V28.4857C45 31.1664 42.5034 33.131 39.925 32.4557C37.9195 31.9441 35.7913 31.719 33.7449 31.719C30.5525 31.719 27.1555 32.2306 24.4134 33.6016C23.206 34.2156 21.8145 34.2156 20.5866 33.6016C17.8445 32.251 14.4475 31.719 11.2551 31.719C9.20873 31.719 7.08049 31.9441 5.07503 32.4557C2.49659 33.1105 0 31.146 0 28.4857V5.40246C0 3.74488 1.00273 2.29195 2.49659 1.61664C5.13643 0.450205 8.28786 0 11.2551 0C15.2456 0 19.543 0.818554 22.5102 3.06958ZM38.472 28.0559C39.7408 28.281 40.9277 27.3397 40.9277 26.0505V6.67121C40.9277 5.70941 40.2319 4.8704 39.2906 4.66576C37.5102 4.27694 35.648 4.09277 33.7654 4.09277C30.2865 4.09277 25.2729 5.42292 22.5102 7.16235V30.7162C25.2729 28.9768 30.2865 27.6467 33.7654 27.6467C35.3411 27.6467 36.9372 27.7694 38.472 28.0559Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BookTwoIcon;
