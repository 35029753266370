import { useNavigate } from "react-router-dom";
import { Theme } from "../../../hooks";

import { TIconOptions } from "../../../types";
import Chevron from "../../icons/Chevron";
import AvailableIcons from "../AvailableIcons";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  title: string;
  icon: TIconOptions;
  link?: string;
  onClick?: () => void;
}

const AppLinkButton: React.FC<IProps> = ({ link, title, icon, onClick }) => {
  const navigate = useNavigate();
  const { textColor } = Theme.useTheme();

  function handleClick() {
    if (onClick) onClick();

    if (link) navigate(link);
  }

  return (
    <S.FileType onClick={handleClick}>
      <S.IconAndLabel>
        <div className="iconType">
          <AvailableIcons option={icon} />
        </div>

        <div className="type">
          <Small color={textColor}>{title}</Small>
        </div>
      </S.IconAndLabel>

      <div className="chevronIcon">
        <Chevron />
      </div>
    </S.FileType>
  );
};

export default AppLinkButton;
