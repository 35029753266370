import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const HeartOutlineIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="35"
      viewBox="0 0 40 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7748 4.25069C23.2537 0.178757 29.6976 -1.48164 34.916 2.07635C37.6834 3.97395 39.4228 7.17615 39.5414 10.5365C39.7984 18.2059 33.0184 24.3534 22.641 33.7623L22.4235 33.96C20.9213 35.3436 18.6086 35.3436 17.1063 33.9797L16.9086 33.8018L16.7892 33.6933C6.47902 24.3259 -0.267528 18.1963 0.00814282 10.5562C0.126743 7.17615 1.86621 3.97395 4.63354 2.07635C9.85193 -1.50141 16.2959 0.178757 19.7748 4.25069ZM19.7745 31.0543L19.9722 30.8566C29.3811 22.3372 35.5878 16.7037 35.5878 10.9911C35.5878 7.03779 32.6228 4.07279 28.6695 4.07279C25.6254 4.07279 22.6604 6.02969 21.6326 8.73772H17.9362C16.8886 6.02969 13.9236 4.07279 10.8795 4.07279C6.92618 4.07279 3.96119 7.03779 3.96119 10.9911C3.96119 16.7037 10.1679 22.3372 19.5768 30.8566L19.7745 31.0543Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default HeartOutlineIcon;
