import styled, { css } from "styled-components";

export const VacancySelector = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const Unlimited = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NumberSelector = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;

  input {
    width: 64px;
    outline: none;
    font-size: 14px;
    min-height: 32px;
    color: #fafafa;
    padding: 8px 16px;
    text-align: center;
    border-radius: 40px;
    mix-blend-mode: screen;
    text-overflow: ellipsis;
    background-color: #fafafa18;

    &::placeholder {
      font-size: 14px;
      color: #71726f;
    }
  }
`;

type ButtonProps = {
  hasReachedLimit: boolean | 0;
};

export const Button = styled.div<ButtonProps>`
  ${({ hasReachedLimit }) => css`
    width: 24px;
    height: 24px;
    display: flex;
    color: black;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    user-select: none;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    background-color: #71726f;

    opacity: ${hasReachedLimit ? 0.5 : 1};
    cursor: ${hasReachedLimit ? "not-allowed" : "pointer"};
  `}
`;
