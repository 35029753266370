import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TicketIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7486 8.23327C30.3841 9.45238 30.6732 10.8223 31.6284 11.7775C32.5836 12.7327 33.9535 13.0218 35.1726 12.6573C36.3415 12.3179 37.6234 12.7453 38.4906 13.6125L41.683 16.8048C43.0655 18.1873 43.0655 20.4496 41.683 21.8321L21.5738 41.9413C20.1913 43.3238 17.929 43.3238 16.5465 41.9413L13.3542 38.7489C12.487 37.8817 12.0596 36.5998 12.399 35.4309C12.776 34.2244 12.4744 32.8419 11.5192 31.8867C10.564 30.9315 9.20665 30.6299 7.97496 30.9818C6.80612 31.3463 5.53672 30.9064 4.66952 30.0392L1.46461 26.8343C0.0821079 25.4517 0.0821078 23.2146 1.46461 21.8321L21.5738 1.72291C22.9563 0.340408 25.2186 0.34041 26.6011 1.72291L29.7934 4.91525C30.6606 5.78246 31.0879 7.06442 30.7486 8.23327ZM24.7159 24.9741L29.8689 23.843C30.6607 23.6796 30.8367 22.6239 30.1203 22.2091L25.5706 19.5698L26.0985 14.3163C26.1739 13.5119 25.2187 13.0343 24.6154 13.5622L20.6815 17.0687L15.8553 14.9572C15.1264 14.6305 14.3723 15.3846 14.6865 16.1261L16.7854 20.9397L13.2789 24.8736C12.7384 25.4894 13.2286 26.432 14.0329 26.3566L19.2865 25.8288L21.9635 30.391C22.3657 31.0949 23.4214 30.9189 23.5974 30.1397L24.7159 24.9741Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TicketIcon;
