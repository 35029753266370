import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppFormAddIcon from "../../../../components/atoms/AppFormAddIcon";
import AppFormDescription from "../../../../components/atoms/AppFormDescription";
import AppFormFAQ from "../../../../components/atoms/AppFormFAQ";

import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import AppFormMedia from "../../../../components/atoms/AppFormMedia";
import AppFormPrice from "../../../../components/atoms/AppFormPrice";
import AppFormTags from "../../../../components/atoms/AppFormTags";
import AppFormTitle from "../../../../components/atoms/AppFormTitle";
import Input from "../../../../components/atoms/Input";
import Page from "../../../../components/atoms/Page";
import Small from "../../../../components/atoms/Typography/Small";
import LinkIcon from "../../../../components/icons/LinkIcon";
import ProfileIconCircle from "../../../../components/icons/ProfileIconCircle";
import ProfileImagePlaceholder from "../../../../components/icons/ProfileImagePlaceholder";
import AppFormFooter from "../../../../components/molecules/AppFormFooter";
import AppFormSection from "../../../../components/molecules/AppFormSection";
import appConfig from "../../../../config/app-grupo.json";
import { App, Auth, Theme } from "../../../../hooks";
import { IForm } from "../../../AppVendaArquivos/components/Form";
import ChoiceChannel from "../ChoiceChannel";

import * as S from "./styles";

export type IChannelProvider = "whatsapp" | "telegram";

const validate = (
  data: IForm,
  type: string,
  url?: string
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!url)
    errors.url = ["other"].includes(type)
      ? [
          ...(errors.url || []),
          "O upload de um arquivo ou link para acesso é obrigatório",
        ]
      : [...(errors.url || []), "O link para acesso é obrigatório"];

  if (!data.title)
    errors.title = [...(errors.title || []), "Título obrigatório"];

  if (!data.description)
    errors.description = [
      ...(errors.description || []),
      "Descrição obrigatória",
    ];

  if (!data.price || data.price < 1)
    errors.price = [...(errors.price || []), "O valor mínimo é R$ 1"];

  return errors;
};

const Form = () => {
  const [item, setItem] = useState<IForm>({});
  const [providedURL, setProvidedURL] = useState(item.url);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [channelProvider, setChannelProvider] = useState<IChannelProvider>();

  const navigate = useNavigate();

  const { user } = Auth.useAuth();
  const { backgroundColor, primaryColor } = Theme.useTheme();
  const { getItem, saveItemHandler, removeItemHandler } = App.useApp();
  const { id, type } = useParams();

  const onChangeHandler = (value: any, field: keyof IForm) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setItem((curr) => ({ ...curr, [field]: value }));
  };

  const handleClick = (socialMedia: IChannelProvider) => {
    setChannelProvider(socialMedia);
  };

  const onSave = (isDraft: boolean) => {
    const currErrors = validate(item, type || "", providedURL);

    if (currErrors && Object.keys(currErrors).length) {
      setErrors(currErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return alert(
        "O cadastro possui erros, por favor verifique os campos para continuar"
      );
    }

    saveItemHandler(
      appConfig.id,
      {
        ...item,
        url: providedURL,
        channelProvider: channelProvider,
      },
      isDraft,
      id
    );
  };

  useEffect(() => {
    const run = async () => {
      if (!id) return;

      const itemData = await getItem(appConfig.id, id);
      setItem(itemData || {});
      setProvidedURL(itemData?.url);
    };

    run();
  }, [getItem, id]);

  const isEditingItem = !!id;

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate(`/apps`)}
      />

      {channelProvider || isEditingItem ? (
        <>
          <AppFormSection title="Informações básicas">
            <AppFormTitle
              errors={errors.title}
              value={item.title || ""}
              inputLabel="Nome do grupo"
              placeholder="Ex: Grupo Exclusivo • Fãs de XXXXX"
              onChange={(val) => onChangeHandler(val, "title")}
            />

            <S.UploadSection>
              <Input
                leftIcon={<LinkIcon />}
                value={providedURL || ""}
                onChange={setProvidedURL}
                inputLabel="Link do convite para entrar no grupo"
                placeholder="Cole aqui o link de convite para acesso"
              />

              {errors.url && errors.url.length && (
                <Small color="#FF4D4F">Erros: {errors.url.join(", ")}</Small>
              )}
            </S.UploadSection>
          </AppFormSection>

          <AppFormSection>
            <AppFormDescription
              errors={errors.description}
              value={item.description || ""}
              charLimit={500}
              placeholder="Coloque o público alvo que deseja atingir, faixa etária, profissão, idade, etc. Listas costumam converter mais vendas."
              onChange={(val) => onChangeHandler(val, "description")}
            />

            <AppFormAddIcon
              errors={errors.icon}
              icon={item.image || ""}
              onChange={(image) => onChangeHandler(image, "image")}
              onClear={() =>
                setItem((curr) => ({ ...curr, buttonImage: "", image: "" }))
              }
            />
          </AppFormSection>

          <AppFormSection title="Adicione fotos de divulgação">
            <AppFormMedia
              errors={errors.media}
              images={item.media || []}
              onRemoveImage={(index) =>
                setItem((curr) => {
                  const newMedia = curr.media || [];
                  newMedia.splice(index, 1);

                  return { ...curr, media: newMedia };
                })
              }
              onChange={(newImage) =>
                setItem((curr) => ({
                  ...curr,
                  media: [...(curr.media || []), newImage],
                }))
              }
            />
          </AppFormSection>

          <AppFormSection title="Informações Adicionais">
            <AppFormDescription
              errors={errors.targetAudience}
              value={item.targetAudience || ""}
              charLimit={500}
              textAreaLabel="Para quem é este grupo?"
              placeholder="Uma breve descrição sobre seu grupo e os conteúdos que os membros vão encontrar."
              onChange={(val) => onChangeHandler(val, "targetAudience")}
            />

            <S.ImageContainer>
              <ProfileIconCircle />

              {user.profileImage ? (
                <S.ProfileImage imageUrl={user.profileImage}>
                  <S.ProfileImageBorder color={primaryColor} />
                </S.ProfileImage>
              ) : (
                <S.ProfileImagePlaceholderContainer
                  backgroundColor={primaryColor}
                >
                  <ProfileImagePlaceholder color={backgroundColor} />

                  <S.ProfileImageBorder color={primaryColor} />
                </S.ProfileImagePlaceholderContainer>
              )}

              <ProfileIconCircle flip />
            </S.ImageContainer>

            <AppFormDescription
              errors={errors.aboutAuthor}
              value={item.aboutAuthor || ""}
              charLimit={500}
              textAreaLabel="Sobre você"
              placeholder="Conte um pouco sobre você. Isso ajuda na conversão de vendas do seu produto. Quanto mais informações, mais chances de vender!"
              onChange={(val) => onChangeHandler(val, "aboutAuthor")}
            />
          </AppFormSection>

          <AppFormSection>
            <AppFormFAQ
              errors={errors.faq}
              faqs={item.faq || []}
              onChange={(val) => onChangeHandler(val, "faq")}
            />
          </AppFormSection>

          <AppFormSection title="Tags">
            <AppFormTags
              errors={errors.tags}
              value={item.tags || []}
              onChange={(val) => onChangeHandler(val, "tags")}
            />
          </AppFormSection>

          <AppFormSection title="Preço">
            <AppFormPrice
              appId={appConfig.id}
              errors={errors.price}
              value={item.price || 0}
              onChange={(val) => onChangeHandler(val, "price")}
            />
          </AppFormSection>

          <AppFormFooter
            onSave={onSave}
            appTitle="grupo"
            saveDraft={true}
            onRemove={() => id && removeItemHandler(appConfig.id, id, "/")}
            isEditingItem={isEditingItem}
          />
        </>
      ) : (
        <ChoiceChannel handleClick={handleClick} />
      )}
    </Page>
  );
};

export default Form;
