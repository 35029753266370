import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TransmissionIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="49"
      height="36"
      viewBox="0 0 49 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00980518 17.7235C0.0868882 11.1359 2.42483 5.46658 6.9627 0.703562C7.60697 0.0273824 8.45631 -0.148296 9.35191 0.12024C10.2558 0.391285 10.8351 1.01405 11.0399 1.93797C11.2363 2.82639 10.9742 3.59436 10.3336 4.23899C8.77755 5.80431 7.53383 7.59013 6.61277 9.59429C5.24034 12.5812 4.60539 15.7204 4.86138 19.0016C5.22779 23.6969 7.08137 27.7257 10.4494 31.0384C10.7613 31.3453 11.0205 31.6813 11.1628 32.1008C11.7175 33.7338 10.4225 35.4515 8.69868 35.3788C8.04903 35.3511 7.51052 35.1117 7.05161 34.6524C3.47424 31.0725 1.28974 26.7695 0.372633 21.8075C0.124175 20.4591 0.0072955 19.0984 0.00980518 17.7235Z"
        fill={color || primaryColor}
      />
      <path
        d="M48.0091 17.7324C47.9126 24.2497 45.6786 29.9033 41.1673 34.6376C40.4316 35.4099 39.5238 35.5425 38.5612 35.2643C37.6348 34.9961 37.1106 34.3038 36.951 33.3656C36.8141 32.5596 37.0625 31.853 37.6308 31.2625C38.1675 30.705 38.6799 30.1259 39.1517 29.5121C41.2806 26.7411 42.6322 23.6305 43.0367 20.1596C43.7458 14.0744 41.8969 8.8295 37.6667 4.41497C37.2988 4.03099 36.9984 3.62119 36.8815 3.09416C36.5319 1.52059 37.7717 0.0391624 39.3829 0.101187C40.0068 0.125209 40.5298 0.3561 40.9723 0.798521C44.5801 4.40744 46.7628 8.75349 47.6692 13.756C47.9072 15.0678 48.0184 16.3937 48.0091 17.7324Z"
        fill={color || primaryColor}
      />
      <path
        d="M14.1867 7.76981C11.4407 10.5197 10.0396 13.8386 9.99121 17.7246C9.993 18.4718 10.0432 19.2129 10.1672 19.9468C10.6538 22.8293 11.9208 25.3153 13.9393 27.4303C14.5208 28.0394 15.197 28.3133 16.0291 28.2761C17.3507 28.2169 18.3886 27.0639 18.2961 25.7442C18.2506 25.0906 17.9684 24.5614 17.5224 24.0878C15.2977 21.7251 14.4506 18.9497 15.1171 15.7581C15.4734 14.0515 16.3081 12.583 17.5324 11.3414C17.9899 10.8775 18.2563 10.3358 18.2986 9.68219C18.3624 8.69517 17.7877 7.76874 16.8233 7.31843C15.989 6.92907 14.8396 7.11586 14.1867 7.76981Z"
        fill={color || primaryColor}
      />
      <path
        d="M38.0209 17.7518C37.9632 21.5955 36.5886 24.8897 33.8774 27.6188C32.9976 28.5043 31.3774 28.5151 30.4868 27.6636C29.5167 26.7361 29.4449 25.2629 30.3309 24.2673C30.4213 24.1656 30.5133 24.0653 30.6052 23.9649C30.8346 23.7146 31.064 23.4643 31.2706 23.1935C33.9057 19.742 33.6985 14.8717 30.778 11.659C30.7165 11.5914 30.6536 11.5251 30.5907 11.4587C30.4847 11.3469 30.3786 11.2349 30.2796 11.1173C29.4306 10.1098 29.5468 8.623 30.5402 7.72274C31.485 6.86694 33.0123 6.93649 33.9154 7.85575C35.9529 9.92911 37.2493 12.3872 37.7929 15.2425C37.9506 16.071 38.018 16.9071 38.0209 17.7518Z"
        fill={color || primaryColor}
      />
      <path
        d="M27.949 17.8289C27.9526 15.6501 26.1886 13.8851 24.0077 13.8862C21.8354 13.8869 20.0747 15.6415 20.0704 17.8099C20.0657 19.985 21.8365 21.7579 24.0113 21.7562C26.1793 21.7544 27.9454 19.9929 27.949 17.8289Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TransmissionIcon;
