import styled from "styled-components";

type LinkToPayProps = {
  textDecorationColor: string;
};

export const LinkToPay = styled.div<LinkToPayProps>`
  display: flex;
  border-radius: 20px;
  align-items: center;
  background-color: #fafafa18;
  mix-blend-mode: screen;
  padding: 10px 10px 10px 15px;
  justify-content: space-between;
  text-decoration: underline ${({ textDecorationColor }) => textDecorationColor};
`;

export const CopyIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  padding: 4px;
  margin-right: 10px;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
