import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TiktokIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46167 0C9.71181 2.12857 10.9125 3.39761 13 3.53261V5.92669C11.7902 6.0437 10.7306 5.65218 9.49805 4.91416V9.3918C9.49805 15.08 3.23093 16.8575 0.711344 12.7804C-0.907739 10.1568 0.0837221 5.55318 5.27752 5.36867V7.89326C4.88185 7.95626 4.45888 8.05526 4.0723 8.18577C2.91712 8.57278 2.26221 9.2973 2.44413 10.5753C2.79432 13.0234 7.33321 13.7479 6.95573 8.96429V0.00450015H9.46167V0Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TiktokIcon;
