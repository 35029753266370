import { ReactNode } from "react";

import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";

import * as S from "./styles";

interface IProps {
  title?: string;
  children: ReactNode;
}

const AppFormSection: React.FC<IProps> = ({ children, title }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <S.AppFormSection>
      {!!title && <P color={primaryColor}>{title}</P>}
      {children}
    </S.AppFormSection>
  );
};

export default AppFormSection;
