import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const MicrophoneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="30"
      height="41"
      viewBox="0 0 30 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0C11.4496 0 8.57143 2.89837 8.57143 6.47368V19.4211C8.57143 22.9964 11.4496 25.8947 15 25.8947C18.5504 25.8947 21.4286 22.9964 21.4286 19.4211V6.47368C21.4286 2.89837 18.5504 0 15 0Z"
        fill={color || primaryColor}
      />
      <path
        d="M4.28571 17.2632V19.4211C4.28571 25.3799 9.08266 30.2105 15 30.2105C20.9173 30.2105 25.7143 25.3799 25.7143 19.4211V17.2632H30V19.4211C30 27.0308 24.4121 33.3263 17.1429 34.3733V41H12.8571V34.3733C5.58794 33.3263 0 27.0308 0 19.4211V17.2632H4.28571Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default MicrophoneIcon;
