import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const OnlineCallIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="43"
      viewBox="0 0 50 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.0086 11.0364C49.0086 11.709 49.0089 12.3816 49.0092 13.0541V13.059C49.0098 14.5373 49.0105 16.0156 49.008 17.494C49.0037 20.1609 47.1619 22.0063 44.4987 22.0081C40.415 22.0118 36.3313 22.0112 32.2476 22.0045C31.9497 22.0039 31.6949 22.0653 31.4426 22.2289C30.7342 22.6885 30.0167 23.1349 29.2949 23.5733C28.215 24.2281 26.974 24.1819 26.0193 23.465C25.0343 22.725 24.6506 21.5497 24.9905 20.3111L24.9996 20.2783C25.022 20.1972 25.0445 20.1157 25.0751 20.0374C26.0005 17.6861 26.2358 15.249 26.1483 12.7341C26.081 10.803 26.0972 8.86903 26.1134 6.93517C26.1201 6.14178 26.1267 5.3484 26.1276 4.55522C26.1306 1.82081 27.953 0.00152013 30.688 0.000912078C35.2824 -0.000304026 39.8762 -0.000304026 44.4707 0.000912078C47.1723 0.00152013 49.0049 1.83115 49.0086 4.5309C49.0102 5.97396 49.0097 7.41701 49.0091 8.86025V8.86757C49.0088 9.59047 49.0086 10.3134 49.0086 11.0364ZM36.4255 6.12529C35.5583 6.12466 34.6907 6.12403 33.8237 6.12703C32.8995 6.13007 32.2513 6.73022 32.2574 7.56751C32.2628 8.38534 32.9019 8.98974 33.7951 8.99217C36.2681 8.99825 38.741 8.99765 41.214 8.99278C42.1406 8.99096 42.7876 8.38534 42.7821 7.54866C42.776 6.71137 42.1303 6.12947 41.1951 6.12703C40.3014 6.12482 39.4076 6.12518 38.5141 6.12555L37.5091 6.12582C37.148 6.12582 36.7867 6.12555 36.4255 6.12529ZM37.9778 15.8848C38.3401 15.888 38.7026 15.8911 39.0645 15.879C39.8787 15.8516 40.4922 15.2156 40.4843 14.4342C40.4764 13.6511 39.8562 13.0309 39.0359 13.0193C38.0308 13.0053 37.0257 13.0065 36.0212 13.0187C35.1754 13.029 34.5467 13.6602 34.5552 14.4628C34.5631 15.2436 35.1821 15.8583 36.0023 15.8802C36.3488 15.8896 36.6959 15.887 37.0429 15.8844C37.1904 15.8833 37.3379 15.8822 37.4854 15.882C37.6494 15.882 37.8139 15.8834 37.9778 15.8848Z"
        fill={color || primaryColor}
      />
      <path
        d="M32.6899 32.9168C31.8037 32.9168 30.9175 32.9163 30.0313 32.9159C27.0576 32.9144 24.084 32.9129 21.1108 32.9283C20.659 32.9308 20.5641 32.7958 20.5726 32.3738C20.589 31.5446 20.5865 30.7149 20.5841 29.8852C20.5826 29.3916 20.5811 28.898 20.5836 28.4044C20.5903 27.0995 20.1014 26.0148 19.1364 25.1495C18.9206 24.9562 18.8829 24.8382 19.0537 24.5755C20.1659 22.8602 20.0473 20.7855 18.7765 19.2393C17.5604 17.7593 15.4966 17.2163 13.7108 17.9064C11.8763 18.6148 10.7332 20.3769 10.8286 22.3738C10.8663 23.1697 11.1126 23.9085 11.5656 24.5646C11.7243 24.7944 11.7638 24.9209 11.5163 25.1404C10.5349 26.0087 10.0284 27.0977 10.0491 28.43C10.061 29.1784 10.059 29.9272 10.057 30.6759C10.0554 31.2983 10.0537 31.9207 10.06 32.5428C10.0631 32.8298 9.99194 32.9259 9.68974 32.9235C7.96713 32.9089 6.24451 32.907 4.52251 32.9253C4.20815 32.9283 4.10296 32.7933 4.04945 32.5325C3.97223 32.1567 3.92297 31.7803 3.92358 31.3948C3.92481 29.0939 3.92455 26.7931 3.92428 24.4922C3.92389 21.1176 3.9235 17.7431 3.92784 14.3688C3.93088 11.8228 5.79638 9.95673 8.33561 9.95308C10.2354 9.95045 12.135 9.95046 14.0347 9.95047C16.9517 9.95048 19.8687 9.9505 22.786 9.94093C23.197 9.93971 23.2937 10.0595 23.2748 10.4474C23.2426 11.1157 23.2286 11.7888 23.2724 12.4552C23.4311 14.854 23.0845 17.1609 22.2916 19.4387C21.6288 21.3425 22.0089 23.1588 23.2675 24.7482C25.0126 26.9524 28.1034 27.5434 30.5544 26.168C30.5882 26.1491 30.6225 26.1307 30.657 26.1123C30.7641 26.0551 30.8719 25.9975 30.963 25.9211C32.0332 25.0206 33.2572 24.8169 34.6399 24.851C37.2557 24.9148 39.8733 24.898 42.4908 24.8812C43.191 24.8767 43.8918 24.8722 44.5919 24.8692C44.9677 24.8674 45.11 24.9282 45.1045 25.3508C45.0877 26.6922 45.0897 28.0337 45.0916 29.3753C45.0926 30.0265 45.0935 30.6779 45.0923 31.3291C45.0893 32.8955 45.0662 32.9168 43.5528 32.9168H32.6899Z"
        fill={color || primaryColor}
      />
      <path
        d="M28.7415 35.7854L20.4954 35.7853C13.8231 35.7857 7.15056 35.7862 0.478445 35.776C0.0674024 35.7754 -0.0311007 35.8739 0.0236239 36.2807C0.533779 40.0634 3.51202 42.6762 7.3397 42.6762C18.779 42.6768 30.2176 42.6768 41.6569 42.6762C45.4773 42.6756 48.4075 40.1212 48.9669 36.3336C49.0338 35.8831 48.9426 35.7748 48.4841 35.7754C41.9034 35.7864 35.3223 35.7859 28.7415 35.7854Z"
        fill={color || primaryColor}
      />
      <path
        d="M12.9207 29.7941C12.9243 29.3373 12.9279 28.8806 12.9121 28.4241C12.8939 27.8964 13.1085 27.5036 13.5056 27.1862C14.4718 26.4145 16.1099 26.4042 17.0816 27.1624C17.4956 27.4853 17.7182 27.8872 17.7115 28.4333C17.6945 29.8038 17.6981 31.175 17.7121 32.5455C17.7151 32.8368 17.6379 32.9268 17.3394 32.9231C15.984 32.9073 14.6287 32.9061 13.2733 32.9237C12.9596 32.928 12.9055 32.8112 12.9115 32.5358C12.9238 32.021 12.9216 31.5055 12.9194 30.9899C12.9185 30.7881 12.9176 30.5863 12.9176 30.3845C12.9176 30.1878 12.9192 29.9908 12.9207 29.7941Z"
        fill={color || primaryColor}
      />
      <path
        d="M16.9375 22.113C16.943 21.2119 16.1975 20.4694 15.2946 20.4773C14.3898 20.4846 13.6674 21.2307 13.6845 22.141C13.7009 23.0226 14.4372 23.7365 15.3226 23.7298C16.2055 23.7231 16.9321 22.9959 16.9375 22.113Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default OnlineCallIcon;
