import styled from "styled-components";

export const FileType = styled.div`
  display: flex;
  padding: 18px;
  cursor: pointer;
  align-items: center;
  border-radius: 22px;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    275deg,
    rgba(250, 250, 250, 0.39) -160%,
    rgba(113, 114, 111, 0) 120%
  );

  .chevronIcon {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const IconAndLabel = styled.div`
  gap: 18px;
  display: flex;
  align-items: center;

  .iconType {
    width: 28px;
    height: 22px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .type {
    display: flex;
    align-items: center;
  }
`;
