import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";

import * as S from "./styles";

interface IProps {
  answer: string;
  isSelected: boolean;
  onSelect: () => void;
}

const QualificationOption: React.FC<IProps> = ({
  answer,
  onSelect,
  isSelected,
}) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.Container onClick={onSelect}>
      <S.InputOut isSelected={isSelected} borderColor={primaryColor}>
        <S.InputIn backgroundColor={primaryColor} isSelected={isSelected} />
      </S.InputOut>

      <P color={textColor}>{answer}</P>
    </S.Container>
  );
};

export default QualificationOption;
