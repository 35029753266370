import P from "../../../../components/atoms/Typography/P";
import Small from "../../../../components/atoms/Typography/Small";
import MoneyTwoIcon from "../../../../components/icons/MoneyTwoIcon";
import SealIcon from "../../../../components/icons/SealIcon";
import { Theme } from "../../../../hooks";

import * as S from "./styles";

interface IProps {
  discount?: string;
  comission?: string;
}

const CampaignInfo: React.FC<IProps> = ({ comission, discount }) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.CampaignInfo>
      <S.DiscountBlock>
        <P color={textColor}>Desconto para seus seguidores</P>

        <Small color={textColor}>
          Ao utilizar seu código seus seguidores também ganham!
        </Small>

        {discount ? (
          <S.Info>
            <S.Icon>
              <SealIcon />

              <P color="black">{discount}</P>
            </S.Icon>

            <P color={textColor}>{`${discount} de desconto a cada compra`}</P>
          </S.Info>
        ) : null}
      </S.DiscountBlock>

      {comission ? (
        <S.ComissionBlock>
          <P color={textColor}>Seus ganhos:</P>

          <Small color={textColor}>
            Você receberá uma porcentagem do valor de cada venda
          </Small>

          <S.Info>
            <S.ComissionIcon>
              <MoneyTwoIcon />
            </S.ComissionIcon>

            <P color={textColor}>{`${comission} de comissão por venda`}</P>
          </S.Info>
        </S.ComissionBlock>
      ) : null}
    </S.CampaignInfo>
  );
};

export default CampaignInfo;
