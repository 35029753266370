import styled from "styled-components";
import P from "../../../../components/atoms/Typography/P";

export const Info = styled.div`
  gap: 20px;
  display: flex;
  padding: 30px 0;
  flex-direction: column;
`;

export const AddMediaButton = styled.div`
  gap: 12px;
  display: flex;
  padding: 16px;
  margin: 20px 0;
  cursor: pointer;
  user-select: none;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  border: 1px dashed #71726f;

  .addIcon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    border: 1px solid #71726f;
  }

  input {
    display: none;
  }
`;

export const ImagesOrder = styled.div`
  display: flex;
  padding-top: 20px;
  flex-direction: column;
`;

export const SectionTitle = styled(P)`
  margin-bottom: 12px;
`;
