import * as S from "./styles";

interface IProps {
  children: React.ReactNode;
  onClickOutside?: () => void;
}

const ModalBluredBackdrop: React.FC<IProps> = ({
  children,
  onClickOutside,
}) => {
  return (
    <S.ModalBluredBackdrop onClick={() => onClickOutside && onClickOutside()}>
      {children}
    </S.ModalBluredBackdrop>
  );
};

export default ModalBluredBackdrop;
