import LoadingIcon from "../../icons/stanti-icon-animated.gif";

import * as S from "./styles";

const Loading: React.FC = () => {
  return (
    <S.Loading>
      <img src={LoadingIcon} alt="" />
    </S.Loading>
  );
};

export default Loading;
