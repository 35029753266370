import ModalBluredBackdrop from "../../../../components/atoms/ModalBluredBackdrop";
import P from "../../../../components/atoms/Typography/P";
import CalendarIcon from "../../../../components/icons/CalendarIcon";
import { Auth, Theme } from "../../../../hooks";
import { Analytics } from "../../../../services";

import * as S from "./styles";

interface IProps {
  appId: string;
  isOpen: boolean;
  onSaveDraft: () => void;
  onCloseModal: () => void;
}

const CreateAgendaModal: React.FC<IProps> = ({
  appId,
  isOpen,
  onSaveDraft,
  onCloseModal,
}) => {
  const { user } = Auth.useAuth();
  const { textColor } = Theme.useTheme();

  const onSetAgendaHandler = () => {
    Analytics.submitClickEvent({
      origin: appId,
      creator: user.id || "",
      description: "set-my-agenda",
      actionDescription: "open-my-agenda",
      creatorUsername: user.username || "",
    });
    onSaveDraft();
  };

  if (!isOpen) return null;

  return (
    <ModalBluredBackdrop onClickOutside={onCloseModal}>
      <S.Container onClick={(evt) => evt.stopPropagation()}>
        <div className="icon">
          <CalendarIcon color="#ffffff80" />
        </div>

        <S.Texts>
          <P color={textColor}>Agendamento via Stanti</P>

          <S.Subtitle color={textColor}>
            Para permitir agendamentos é necessário configurar antes sua agenda
            e horários disponíveis.
          </S.Subtitle>
        </S.Texts>

        <S.DefineAgendaButton
          type="outline"
          onClick={() => onSetAgendaHandler()}
        >
          Definir minha agenda Stanti
        </S.DefineAgendaButton>
      </S.Container>
    </ModalBluredBackdrop>
  );
};

export default CreateAgendaModal;
