import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Extrato, {
  TSelectedPeriodFilter,
  TSelectedProductFilter,
} from "./Extrato";
import Header from "./Header";
import { Order } from "../../services";
import { Snackbar, Auth } from "../../hooks";
import Page from "../../components/atoms/Page";
import { IIncomeReport } from "../../services/order";
import Income from "../../components/molecules/Income";
import SeparatorLine from "../../components/atoms/SeparatorLine";

const DashboardStatement: React.FC = () => {
  const [selectedPeriodFilter, setSelectedPeriodFilter] =
    useState<TSelectedPeriodFilter>("current-month");
  const [selectedProductFilter, setSelectedProductFilter] =
    useState<TSelectedProductFilter>("all");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [incomeReport, setIncomeReport] = useState<IIncomeReport | null>(null);

  const navigate = useNavigate();

  const onBackHandler = () => {
    selectedProduct ? setSelectedProduct("") : navigate("/dashboard");
  };

  const { token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();

  useEffect(() => {
    const run = async () => {
      try {
        setIsLoading(true);
        const dataIncomeReport = await Order.getIncomeReport(
          token,
          selectedProduct,
          selectedPeriodFilter === "all-time" ? undefined : selectedPeriodFilter
        );
        setIncomeReport(dataIncomeReport);
      } catch {
        newError("Houve um erro ao obter as vendas");
      } finally {
        setIsLoading(false);
      }
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriodFilter, selectedProduct]);

  return (
    <Page>
      <Header onBack={onBackHandler} />

      <Income
        monthValue={incomeReport?.totalizers.thisMonthValue || 0}
        totalValue={incomeReport?.totalizers.allTimeValue || 0}
      />

      <SeparatorLine />

      <Extrato
        isLoading={isLoading}
        selectedProduct={selectedProduct}
        sales={incomeReport?.sales.items || []}
        selectedPeriodFilter={selectedPeriodFilter}
        selectedProductFilter={selectedProductFilter}
        productTotalValue={incomeReport?.sales.totalValue}
        setSelectedProduct={(val: string) => setSelectedProduct(val)}
        productsWithSales={incomeReport?.totalizers.productsWithSales || []}
        setSelectedPeriodFilter={(val: TSelectedPeriodFilter) =>
          setSelectedPeriodFilter(val)
        }
        setSelectedProductFilter={(val: TSelectedProductFilter) => {
          if (val === "all") setSelectedProduct("");
          setSelectedProductFilter(val);
        }}
      />
    </Page>
  );
};

export default DashboardStatement;
