import { Navigate, Route } from "react-router-dom";

import Home from "./components/Home";
import Form from "./components/Form";

const AppStantiPay = () => {
  return (
    <Route path="stanti-pay">
      <Route index element={<Home />} />
      <Route path="new" element={<Form />} />
      <Route path=":id" element={<Form />} />
      <Route path="*" element={<Navigate to="/apps/stanti-pay" />} />
    </Route>
  );
};

export default AppStantiPay;
