import SeparatorLine from "../../../components/atoms/SeparatorLine";
import Label from "../../../components/atoms/Typography/Label";
import Small from "../../../components/atoms/Typography/Small";
import AppImage from "../../../components/molecules/AppImage";
import { Theme } from "../../../hooks";
import { TIconOptions } from "../../../types";
import { normalizeNumber } from "../../../utils/numbers";

import * as S from "./styles";

interface IProps {
  appColor: string;
  productLabel: string;
  appGradient?: string;
  iconOpacity?: number;
  appIcon: TIconOptions;
  iconBlendMode?: string;
  totalIncome: number | undefined;
  productTitle: string | undefined;
}

const ProductCard: React.FC<IProps> = ({
  appIcon,
  appColor,
  appGradient,
  totalIncome,
  iconOpacity,
  productTitle,
  productLabel,
  iconBlendMode,
}) => {
  const normalizedTotalValue = normalizeNumber(totalIncome || 0);
  const { textColor } = Theme.useTheme();

  return (
    <S.Card>
      <AppImage
        size={40}
        icon={appIcon}
        color={appColor}
        gradient={appGradient}
        iconOpacity={iconOpacity}
        iconBlendMode={iconBlendMode}
      />

      <S.ProductDetails>
        <S.ProductDetails>
          <Label color={textColor}>{productLabel}</Label>
          <Small color={textColor}>{productTitle}</Small>
        </S.ProductDetails>

        <SeparatorLine />

        <S.Income>
          <Label color={textColor}>Receita total</Label>
          <Small color={textColor}>R$ {normalizedTotalValue}</Small>
        </S.Income>
      </S.ProductDetails>
    </S.Card>
  );
};

export default ProductCard;
