import styled, { css } from "styled-components";

type ButtonProps = {
  iconType: string;
};

export const Button = styled.a<ButtonProps>`
  width: 26px;
  height: 26px;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
  align-items: center;
  mix-blend-mode: screen;
  justify-content: center;
  background: linear-gradient(
    182.09deg,
    rgba(250, 250, 250, 0.37) -0.44%,
    rgba(255, 255, 255, 0) 97.13%
  );

  ${({ iconType }) => css`
    width: ${iconType === "trash" ? "22px" : "26px"};
    height: ${iconType === "trash" ? "22px" : "26px"};
  `}

  svg {
    width: 42%;
    height: 42%;
  }
`;
