import { maskHourAndMinute } from "../../utils/dates";
import { IBlock, ICalendar, ISchedule, TWeekDays } from "../../types/calendar";

type TGetCalendarPayload = (calendar: {
  [key: string]: { to: string; from: string; isSelected: boolean };
}) => ISchedule[];

type TGetLunchTimePayload = (
  lunchTime: {
    to: string;
    from: string;
    isSelected: boolean;
  },
  selectedWorkDays: TWeekDays[],
  calendarId: string
) => IBlock;

export const normalizeSchedules = (schedules: ICalendar["schedules"]) => {
  return schedules.reduce((acc, curr) => {
    const weekDay = curr.type;

    acc[weekDay] = {
      isSelected: true,
      to: curr.intervals[0].to,
      from: curr.intervals[0].from,
    };

    return acc;
  }, {} as { [key in TWeekDays]: { from: string; to: string; isSelected: boolean } });
};

export const getCalendarPayload: TGetCalendarPayload = (calendar) => {
  const payload = (Object.keys(calendar) as TWeekDays[])
    .map((day) => {
      if (calendar[day].isSelected) {
        return {
          type: day,
          category: "WEEK_DAY",
          intervals: [
            {
              to: maskHourAndMinute(calendar[day].to),
              from: maskHourAndMinute(calendar[day].from),
            },
          ],
        };
      }

      return null;
    })
    .filter((i) => !!i) as ISchedule[];

  return payload;
};

export const getLunchTimePayload: TGetLunchTimePayload = (
  lunchTime,
  selectedWorkDays,
  calendarId
) => {
  const defaultTo = maskHourAndMinute(lunchTime.to);
  const defaultFrom = maskHourAndMinute(lunchTime.from);

  const intervals = Object.fromEntries(
    selectedWorkDays.map((day) => [day, [{ to: defaultTo, from: defaultFrom }]])
  );

  const payloadLunchTime: IBlock = {
    intervals,
    calendarId,
    isLunchTime: true,
    isRepeatable: true,
    repeatType: "WEEK_DAY",
    label: "Hora do almoço",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return payloadLunchTime;
};
