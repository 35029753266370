import { Theme } from "../../../hooks";
import { ICreatorData } from "../../../types";

import * as S from "./styles";

interface IProps {
  onChange: (color: string) => void;
  value: ICreatorData["primaryColor"];
  selectedBackgroundColor: ICreatorData["backgroundColor"];
}

const primaryColors = [
  {
    id: "1",
    color: "#DDFF66",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "2",
    color: "#FF9960",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "3",
    color: "#00FFB2",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "4",
    color: "#A0D7FF",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "5",
    color: "#FFA8BD",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "6",
    color: "#FAFAFA",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "7",
    color: "#A091FF",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
  {
    id: "8",
    color: "#FFC700",
    allowedWith: ["#000000", "#181818", "#1D232E", "#090223"],
  },
];

const ChoosePrimaryColor: React.FC<IProps> = ({
  value,
  onChange,
  selectedBackgroundColor,
}) => {
  const { primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <>
      <S.InputCheckbox>
        {primaryColors
          .filter(({ allowedWith }) =>
            allowedWith.includes(selectedBackgroundColor || "")
          )
          .map(({ id, color }) => {
            return (
              <S.Inputs key={id} onClick={() => onChange(color)}>
                <S.Selector
                  borderColor={value === color ? primaryColor : backgroundColor}
                  backgroundColor={color}
                >
                  <S.Indicator
                    borderColor={value === color ? "#000" : "transparent"}
                    isSelected={value === color}
                  />
                </S.Selector>
              </S.Inputs>
            );
          })}
      </S.InputCheckbox>
    </>
  );
};

export default ChoosePrimaryColor;
