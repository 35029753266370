import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TelegramIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.48476 7.91937L5.26571 11.2731C5.58757 11.2731 5.72978 11.1227 5.90943 10.9442L7.45579 9.40755L10.6729 11.8365C11.265 12.1704 11.6923 11.9975 11.8397 11.2748L13.9517 1.10432C14.1679 0.218407 13.6212 -0.1834 13.0548 0.0793841L0.653674 4.96613C-0.192821 5.3131 -0.187886 5.79573 0.499375 6.01077L3.68179 7.02585L11.0494 2.27572C11.3972 2.06018 11.7164 2.17605 11.4544 2.41369L5.48476 7.91937Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TelegramIcon;
