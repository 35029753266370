import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AppFormAddIcon from "../../../../components/atoms/AppFormAddIcon";
import AppFormCalendar from "../../../../components/atoms/AppFormCalendar";
import AppFormDescription from "../../../../components/atoms/AppFormDescription";
import AppFormFAQ from "../../../../components/atoms/AppFormFAQ";
import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import AppFormMedia from "../../../../components/atoms/AppFormMedia";
import AppFormPrice from "../../../../components/atoms/AppFormPrice";
import AppFormServiceType from "../../../../components/atoms/AppFormServiceType";
import AppFormTags from "../../../../components/atoms/AppFormTags";
import AppFormTitle from "../../../../components/atoms/AppFormTitle";
import Page from "../../../../components/atoms/Page";
import AppFormFooter from "../../../../components/molecules/AppFormFooter";
import AppFormSection from "../../../../components/molecules/AppFormSection";
import appConfig from "../../../../config/app-servico.json";
import { App, Auth } from "../../../../hooks";
import { Calendar } from "../../../../services";
import { IAppItem } from "../../../../types";
import { ICalendar, TSchedulingOption } from "../../../../types/calendar";

const validate = (
  data: IAppItem,
  schedulingOption: TSchedulingOption
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.title)
    errors.title = [...(errors.title || []), "Título obrigatório"];

  if (!data.price || data.price < 1)
    errors.price = [...(errors.price || []), "O valor mínimo é R$ 1"];

  if (!data.serviceType)
    errors.serviceType = [
      ...(errors.serviceType || []),
      "Selecione o tipo de serviço",
    ];

  if (schedulingOption === "agenda") {
    if (!data.duration)
      errors.duration = [
        ...(errors.duration || []),
        "A seleção da duração é obrigatória",
      ];

    if (!data.numberOfSessions || data.numberOfSessions < 1)
      errors.numberOfSessions = [
        ...(errors.numberOfSessions || []),
        "Número de sessões deve ser maior ou igual a 1",
      ];
  }

  return errors;
};

const normalizeData = (
  data: IAppItem,
  schedulingOption?: TSchedulingOption
): IAppItem => {
  const newData: { [key: string]: any } = { ...data };

  if (schedulingOption === "no-date") {
    delete newData.time;
    delete newData.dates;
    delete newData.duration;
    delete newData.numberOfSessions;
  }

  const arrayDataToBeChecked = ["faq", "media", "tags"];

  arrayDataToBeChecked.forEach((field) => {
    if (!newData[field] || !newData[field].length) delete newData[field];
  });

  return { ...newData, schedulingOption };
};

const Form: React.FC = () => {
  const { id } = useParams();

  const [item, setItem] = useState<IAppItem>({});
  const [schedulingOption, setSchedulingOption] =
    useState<TSchedulingOption>("no-date");
  const [calendar, setCalendar] = useState<ICalendar | null>(null);
  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [agendaModalOpen, setAgendaModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const { token } = Auth.useAuth();
  const { getItem, saveItemHandler, removeItemHandler } = App.useApp();

  useEffect(() => {
    const run = async () => {
      const [itemData, calendar] = await Promise.all([
        id ? getItem(appConfig.id, id) : null,
        Calendar.getCalendar(token),
      ]);

      setItem(itemData || {});
      setCalendar(calendar || null);

      setSchedulingOption(itemData?.schedulingOption || "no-date");
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItem, id]);

  const onSave = useCallback(
    (isDraft: boolean) => {
      const normalizedData = normalizeData(item, schedulingOption);

      if (!isDraft) {
        const currErrors = validate(normalizedData, schedulingOption);

        if (currErrors && Object.keys(currErrors).length) {
          setErrors(currErrors);

          window.scrollTo({ top: 0, behavior: "smooth" });
          return alert(
            "O cadastro possui erros, por favor verifique os campos para continuar"
          );
        }
      }
      const redirectPath = agendaModalOpen && !calendar ? "/calendar" : "/";
      saveItemHandler(
        appConfig.id,
        normalizedData,
        isDraft,
        id,
        null,
        redirectPath
      );
    },
    [item, schedulingOption, agendaModalOpen, calendar, saveItemHandler, id]
  );

  const onChangeHandler = (value: any, field: keyof IAppItem) => {
    if (errors[field])
      setErrors((curr) => {
        const newErrors = { ...curr };
        delete newErrors[field];

        return newErrors;
      });

    setItem((curr) => ({ ...curr, [field]: value }));
  };

  const isEditingItem = !!id;

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        showLiveLabel={appConfig.showIsLiveLabel}
        onBack={() => navigate(`/apps`)}
      />

      <AppFormSection title="Tipo de serviço">
        <AppFormServiceType
          isSelectedOnline={item.serviceType === "online"}
          isSelectedPresential={item.serviceType === "presencial"}
          onSelectOnline={() => onChangeHandler("online", "serviceType")}
          onSelectPresential={() =>
            onChangeHandler("presencial", "serviceType")
          }
        />
      </AppFormSection>

      <AppFormSection title="Informações básicas">
        <AppFormTitle
          errors={errors.title}
          value={item.title || ""}
          onChange={(val) => onChangeHandler(val, "title")}
          placeholder={`Nome do ${appConfig.title.toLowerCase()}`}
        />

        <AppFormDescription
          errors={errors.description}
          value={item.description || ""}
          onChange={(val) => onChangeHandler(val, "description")}
        />

        <AppFormAddIcon
          errors={errors.icon}
          icon={item.image || ""}
          onChange={(image) => onChangeHandler(image, "image")}
          onClear={() =>
            setItem((curr) => ({ ...curr, buttonImage: "", image: "" }))
          }
        />
      </AppFormSection>

      <AppFormSection title="Datas, horários e agendamentos">
        <AppFormCalendar
          errors={errors}
          calendar={calendar}
          appId={appConfig.id}
          isSelectorOpen={isSelectorOpen}
          duration={item.duration || null}
          onSaveDraft={() => onSave(true)}
          agendaModalOpen={agendaModalOpen}
          schedulingOption={schedulingOption}
          numberOfSessions={item.numberOfSessions || 0}
          setIsSelectorOpen={(val) => setIsSelectorOpen(val)}
          setAgendaModalOpen={(val) => setAgendaModalOpen(val)}
          setSchedulingOption={(val) => setSchedulingOption(val)}
          onChange={(val, field) =>
            onChangeHandler(val, field as keyof IAppItem)
          }
        />
      </AppFormSection>

      <AppFormSection title="Mídia">
        <AppFormMedia
          errors={errors.media}
          images={item.media || []}
          onRemoveImage={(index) =>
            setItem((curr) => {
              const newMedia = curr.media || [];
              newMedia.splice(index, 1);

              return { ...curr, media: newMedia };
            })
          }
          onChange={(newImage) =>
            setItem((curr) => ({
              ...curr,
              media: [...(curr.media || []), newImage],
            }))
          }
        />
      </AppFormSection>

      <AppFormSection title="Preço">
        <AppFormPrice
          appId={appConfig.id}
          errors={errors.price}
          value={item.price || 0}
          onChange={(val) => onChangeHandler(val, "price")}
        />
      </AppFormSection>

      <AppFormSection>
        <AppFormFAQ
          errors={errors.faq}
          faqs={item.faq || []}
          onChange={(val) => onChangeHandler(val, "faq")}
        />
      </AppFormSection>

      <AppFormSection title="Tags">
        <AppFormTags
          errors={errors.tags}
          value={item.tags || []}
          onChange={(val) => onChangeHandler(val, "tags")}
        />
      </AppFormSection>

      <AppFormFooter
        onSave={onSave}
        saveDraft={true}
        appTitle={appConfig.title}
        isEditingItem={isEditingItem}
        onRemove={() => id && removeItemHandler(appConfig.id, id)}
      />
    </Page>
  );
};

export default Form;
