import styled, { css } from "styled-components";

type AppImageProps = {
  size: number;
  color: string;
  gradient?: string;
};

export const AppImage = styled.div<AppImageProps>`
  ${({ size, color, gradient }) => css`
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    margin-bottom: 4px;
    position: relative;
    align-items: center;
    justify-content: center;
    width: ${size}px;
    height: ${size}px;
    background-color: ${color};
    padding: ${size * 0.22}px;
    background: ${gradient || color};
    border-radius: ${size * 0.23}px;
    box-shadow: 0px 1px ${size * 0.03}px 0px rgba(0, 0, 0, 1) inset;

    svg {
      width: 100%;
      height: 100%;
      opacity: 0.7;
      mix-blend-mode: overlay;
    }
  `}
`;

type SpanProps = {
  size: number;
};

export const Span = styled.span<SpanProps>`
  border-radius: ${({ size }) => size * 0.23}px;

  &:first-of-type {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    position: absolute;
    mix-blend-mode: overlay;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &:last-of-type {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    position: absolute;
    mix-blend-mode: soft-light;
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.7) 100%
    );
  }
`;
