import ReactDOM from "react-dom/client";

import { GlobalStyle } from "./globalStyles";
import { Auth, Loading, Snackbar, Theme } from "./hooks";
import reportWebVitals from "./reportWebVitals";
import Router from "./router";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <GlobalStyle />
    <Loading.Provider>
      <Snackbar.Provider>
        <Auth.Provider>
          <Theme.Provider>
            <Router />
          </Theme.Provider>
        </Auth.Provider>
      </Snackbar.Provider>
    </Loading.Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
