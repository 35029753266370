import styled from "styled-components";
import H1 from "../../../../components/atoms/Typography/H1";
import H5 from "../../../../components/atoms/Typography/H5";
import P from "../../../../components/atoms/Typography/P";

import bgImage from "../../../../components/assets/image_bg.png";

export const Register = styled.div`
  gap: 32px;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  &::after {
    content: "";
    width: 140%;
    height: 140%;
    background: url(${bgImage});
    background-size: cover;
    background-position: top center;
    position: absolute;
    top: -550px;
    left: -175px;
    filter: blur(100px);
    opacity: 0.7;
    cursor: none;
    pointer-events: none;

    @media (max-width: 920px) {
      width: 120%;
      height: 120%;
      filter: blur(70px);
      left: -40px;
      top: -250px;
    }

    @media (max-width: 600px) {
      filter: blur(60px);
      left: 40px;
      top: -150px;
    }
  }
`;

export const RegisterFormFirstStep = styled.div`
  gap: 12px;
  display: flex;
  justify-self: center;
  flex-direction: column;

  .loginText {
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  width: 50%;

  @media (max-width: 600px) {
    width: 80%;
  }

  span {
    font-weight: 600;
  }
`;

export const LoginText = styled(P)`
  text-align: center;
`;

export const PrivacyTerms = styled.div`
  text-align: center;
  margin-top: 30px;

  a {
    font-weight: 500;
    text-decoration: none;
    color: #ddff66;
  }
`;

export const RegisterFormFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

export const Contact = styled.div`
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  a {
    color: #fafafa;
    text-decoration: none;
  }

  .redesSociais {
    gap: 14px;
    display: flex;
    align-items: center;

    .iconeRedeSocial {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const SocialMedia = styled.div`
  gap: 14px;
  display: flex;
  align-items: center;

  & a {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Title = styled(H1)`
  font-weight: 300;
  font-family: "Poppins";
`;

export const Subtitle = styled(H5)`
  margin-top: 10px;
  margin-bottom: 20px;
`;
