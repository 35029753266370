export const getGoogleCalendarUrl = ({
  endAt,
  startAt,
  location,
  customerName,
  productTitle,
}: {
  endAt: string;
  startAt: string;
  location?: string;
  customerName: string;
  productTitle: string;
}) => {
  const title = `${customerName} - ${productTitle}`;

  const normalizedStart = startAt.replace(/[^0-9TZ]/g, "");
  const normalizedEnd = endAt.replace(/[^0-9TZ]/g, "");

  const params = [
    ["text", title],
    ["location", location],
    ["dates", `${normalizedStart}/${normalizedEnd}`],
  ]
    .filter(([_, val]) => !!val)
    .map((i) => i.join("="))
    .join("&");

  return `https://calendar.google.com/calendar/render?action=TEMPLATE&${params}`;
};

export const getOutlookAgendaUrl = ({
  endAt,
  startAt,
  location,
  customerName,
  productTitle,
}: {
  endAt: string;
  startAt: string;
  location?: string;
  customerName: string;
  productTitle: string;
}) => {
  const title = `${customerName} - ${productTitle}`;

  const params = [
    ["subject", title],
    ["location", location],
    ["startdt", startAt],
    ["enddt", endAt],
    ["allday", "false"],
    ["path", "/calendar/action/compose"],
    ["rru", "addevent"],
  ]
    .filter(([_, val]) => !!val)
    .map((i) => i.join("="))
    .join("&");

  return `https://outlook.live.com/calendar/0/deeplink/compose?${params}`;
};

export const getAppleICalendarUrl = ({
  endAt,
  startAt,
  location,
  customerName,
  productTitle,
}: {
  endAt: string;
  startAt: string;
  location?: string;
  customerName: string;
  productTitle: string;
}) => {
  const title = `${customerName} - ${productTitle}`;

  const normalizedStart = startAt
    .split(".")[0]
    .concat("Z")
    .replace(/[^0-9TZ]/g, "");

  const normalizedEnd = endAt
    .split(".")[0]
    .concat("Z")
    .replace(/[^0-9TZ]/g, "");

  const fileData = [
    `BEGIN:VCALENDAR`,
    `VERSION:2.0`,
    `BEGIN:VEVENT`,
    `DTSTART:${normalizedStart}`,
    `DTEND:${normalizedEnd}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:Agendamento via Stanti`,
    `LOCATION:${location}`,
    `END:VEVENT`,
    `END:VCALENDAR`,
  ].join("\n");

  return `data:text/calendar;charset=utf-8,${encodeURIComponent(fileData)}`;
};
