import styled from "styled-components";

export const ResponsiveIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  padding-bottom: 40px;
  cursor: not-allowed;
`;
