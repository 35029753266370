import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const QuestionIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="46"
      viewBox="0 0 39 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.25 0C8.62853 0 0 8.62853 0 19.25C0 29.8715 8.62853 38.5 19.25 38.5H20.3824V45.2941C31.3888 39.9947 38.5 29.4412 38.5 19.25C38.5 8.62853 29.8715 0 19.25 0ZM21.5145 32.8382H16.9851V28.3088H21.5145V32.8382ZM22.3522 22.1263C22.3748 22.081 22.3975 22.0357 22.4201 22.013C23.0365 21.1091 23.8951 20.3563 24.7656 19.5931C26.7737 17.8324 28.845 16.0165 28.1498 12.1616C27.4931 8.33422 24.4357 5.18628 20.6084 4.6201C15.9431 3.94069 11.8213 6.81686 10.5757 10.9386C10.1907 12.2522 11.1872 13.5883 12.546 13.5883H12.999C13.9275 13.5883 14.6748 12.9316 14.9919 12.1163C15.7166 10.1007 17.8454 8.71922 20.2007 9.21745C22.3748 9.67039 23.9601 11.8219 23.7563 14.0413C23.6029 15.7705 22.3676 16.7269 20.9967 17.7881C20.1413 18.4504 19.2332 19.1535 18.5022 20.1107L18.4795 20.088C18.4403 20.1273 18.4086 20.1817 18.3757 20.2381C18.3517 20.2793 18.3271 20.3216 18.2984 20.3598C18.2644 20.4164 18.2248 20.473 18.1851 20.5297C18.1455 20.5863 18.1059 20.6429 18.0719 20.6995C17.8681 21.0166 17.7095 21.3336 17.5737 21.696C17.5623 21.7413 17.5397 21.7752 17.517 21.8092C17.4944 21.8432 17.4717 21.8772 17.4604 21.9225C17.4378 21.9451 17.4378 21.9677 17.4378 21.9904C17.166 22.8057 16.9848 23.7795 16.9848 24.9345H21.5369C21.5369 24.3683 21.6048 23.8701 21.7634 23.3945C21.8087 23.236 21.8766 23.0775 21.9445 22.9189C21.9672 22.8283 21.9898 22.7377 22.0351 22.6698C22.1257 22.4886 22.239 22.3075 22.3522 22.1263Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default QuestionIcon;
