import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const FacebookIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="6"
      height="13"
      viewBox="0 0 6 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.98974 13V6.49924H5.76477L6 4.25904H3.98974L3.99275 3.1378C3.99275 2.55352 4.04767 2.24045 4.87776 2.24045H5.98743V0H4.21215C2.07975 0 1.3292 1.08674 1.3292 2.91429V4.25929H0V6.49949H1.3292V13H3.98974Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default FacebookIcon;
