import styled, { css } from "styled-components";
import Small from "../../../../components/atoms/Typography/Small";

export const WeekDay = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 8px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Day = styled.div`
  display: flex;
  gap: 12px;
`;

export const InputCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

type SelectedProps = {
  borderColor: string;
};

export const Selected = styled.div<SelectedProps>`
  width: 18px;
  height: 18px;
  overflow: hidden;
  border: 1px solid;
  position: relative;
  border-radius: 4px;
  background-color: rgba(250, 250, 250, 0.2);

  border-color: ${({ borderColor }) => borderColor};
`;

type IndicatorProps = {
  color: string;
  isSelected: boolean;
};

export const Indicator = styled.div<IndicatorProps>`
  border-color: ${({ color }) => color};

  ${({ isSelected, color }) =>
    isSelected &&
    css`
      width: 7px;
      height: 10px;
      border: solid;
      border-radius: 2px;
      border-width: 0 2px 2px 0;
      color: ${color};

      margin-top: 1px;
      margin-left: 5px;

      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
    `}
`;

type LabelProps = {
  textColor: string;
  opacity: number;
};

export const Label = styled.p<LabelProps>`
  ${({ textColor, opacity }) => css`
    font-size: 12px;

    color: ${textColor};
    opacity: ${opacity};
  `}
`;

export const Hours = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
`;

type HourInputProps = {
  textColor: string;
};

export const HourInput = styled.input<HourInputProps>`
  max-width: 64px;
  font-size: 12px;
  min-height: 34px;
  color: #fafafa;
  padding: 6px 12px;
  text-align: center;
  border-radius: 40px;
  mix-blend-mode: screen;
  background-color: #fafafa18;
  background: rgba(250, 250, 250, 0.2);
  color: ${({ textColor }) => textColor};

  &::placeholder {
    font-size: 12px;
    color: #71726f;
  }
`;

type HyphenProps = {
  textColor: string;
  opacity: number;
};

export const Hyphen = styled.span<HyphenProps>`
  ${({ textColor, opacity }) => css`
    color: ${textColor};
    opacity: ${opacity};
  `}
`;

export const Error = styled(Small)`
  font-size: 10px;
`;
