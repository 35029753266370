import TextArea from "../TextArea";
import Small from "../Typography/Small";

interface IProps {
  value: string[];
  errors?: string[];
  onChange: (value: string[]) => void;
}

const AppFormTags: React.FC<IProps> = ({ onChange, value, errors }) => {
  const onChangeTags = (val: string) => {
    if (!val) return onChange([]);

    const tags = val.split(" ");

    if (tags.length > 3) {
      return;
    }

    if (tags[tags.length - 1] === "") {
      if (value[value.length - 1] !== "#") return onChange([...value, "#"]);
      else tags.pop();
    }

    const normalizedTags = tags.map(
      (tag) => `#${tag.replace(/([^a-zA-Z0-9])/g, "").toLowerCase()}`
    );

    onChange(normalizedTags);
  };

  return (
    <>
      <TextArea
        charLimit={100}
        onChange={onChangeTags}
        value={value.join(" ")}
        placeholder={"Adicione até 3 hashtags"}
      />

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </>
  );
};

export default AppFormTags;
