import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const StarIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5354 12.1924L22.0109 1.33983C21.2661 -0.446608 18.7339 -0.446609 17.9891 1.33982L13.4646 12.1924L1.9502 13.4199C0.0810301 13.6192 -0.675061 15.9321 0.715861 17.1958L9.1076 24.8203L5.98879 37.2882C5.52347 39.1484 7.51729 40.658 9.18306 39.7067L20 33.5291L30.8169 39.7067C32.4827 40.658 34.4765 39.1484 34.0112 37.2883L30.8924 24.8203L39.2841 17.1958C40.6751 15.9321 39.919 13.6192 38.0498 13.4199L26.5354 12.1924Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default StarIcon;
