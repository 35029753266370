import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const GymIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.8868 25.9692L39.3895 24.4665C40.2035 23.6525 40.2035 22.3376 39.3895 21.5236L39.3478 21.4819C38.9578 21.091 38.4284 20.8714 37.8763 20.8714C37.3243 20.8714 36.7948 21.091 36.4049 21.4819L30.4357 27.4511L12.5489 9.56431L18.5181 3.59509C19.3321 2.78111 19.3321 1.46621 18.5181 0.652231L18.4764 0.610488C18.0864 0.219666 17.557 3.00427e-05 17.005 3.00427e-05C16.4529 3.00427e-05 15.9235 0.219666 15.5335 0.610488L14.0308 2.11323L12.528 0.610488C11.7141 -0.203496 10.3783 -0.203496 9.56431 0.610488L6.5797 3.59509L5.07696 2.09236C4.26298 1.27837 2.90634 1.27837 2.09236 2.09236C1.27837 2.90634 1.27837 4.26298 2.09236 5.07696L3.59509 6.5797L0.610488 9.56431C-0.203496 10.3783 -0.203496 11.6932 0.610488 12.5072L2.11323 14.0099L0.610488 15.5335C-0.203496 16.3475 -0.203496 17.6624 0.610488 18.4764L0.652231 18.5181C1.46621 19.3321 2.78111 19.3321 3.59509 18.5181L9.56431 12.5489L27.4511 30.4357L21.4819 36.4049C20.6679 37.2189 20.6679 38.5338 21.4819 39.3478L21.5236 39.3895C22.3376 40.2035 23.6525 40.2035 24.4665 39.3895L25.9692 37.8868L27.472 39.3895C28.2859 40.2035 29.6008 40.2035 30.4148 39.3895L33.3994 36.4049L34.9022 37.9076C35.7161 38.7216 37.0728 38.7216 37.8868 37.9076C38.7008 37.0937 38.7008 35.737 37.8868 34.923L36.384 33.4203L39.3895 30.4357C40.2035 29.6217 40.2035 28.3068 39.3895 27.4928L37.8868 25.9692Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default GymIcon;
