import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IIncomeReport } from "../../../services/order";
import { IOrder } from "../../../types";
import Button from "../../atoms/Button";
import Small from "../../atoms/Typography/Small";
import SalesListItem from "../SalesListItem";

import * as S from "./styles";

interface IProps {
  sales: IOrder[];
  isLoading: boolean;
  focusOn: "lead" | "product";
  productsWithSales: IIncomeReport["totalizers"]["productsWithSales"];
}

const SalesList: React.FC<IProps> = ({
  sales,
  focusOn,
  isLoading,
  productsWithSales,
}) => {
  const navigate = useNavigate();

  const [indexOpen, setIndexOpen] = useState<number | null>(null);

  const noSalesMessage = !productsWithSales.length ? (
    <S.StantiPlaceCta>
      <Small color="#71726F">
        Você ainda não possui vendas registradas.
        <br />
        Clique em adicionar para incluir serviços e produtos para venda.
      </Small>

      <Button type="outline" onClick={() => navigate("/apps")}>
        Adicionar
      </Button>
    </S.StantiPlaceCta>
  ) : (
    <S.NoSalesText>
      <Small color={"#71726F"}>
        Você não possui vendas no período selecionado
      </Small>
    </S.NoSalesText>
  );

  return (
    <S.SalesList>
      {sales.length > 0
        ? sales.map((sale, index) => (
            <SalesListItem
              sale={sale}
              key={sale.id}
              focusOn={focusOn}
              isOpen={indexOpen === index}
              onClick={() => setIndexOpen(indexOpen === index ? null : index)}
              product={productsWithSales.find(
                (product) => product.id === sale.productId
              )}
            />
          ))
        : !isLoading && noSalesMessage}
    </S.SalesList>
  );
};

export default SalesList;
