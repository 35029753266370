import { useEffect, useRef, useState } from "react";

import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import Input from "../../../../components/atoms/Input";
import NavigationArrow from "../../../../components/atoms/NavigationArrow";
import Page from "../../../../components/atoms/Page";
import StepsProgressBar from "../../../../components/atoms/StepsProgressBar";

import P from "../../../../components/atoms/Typography/P";
import StantiIcon from "../../../../components/icons/StantiIcon";
import { App, Auth, Loading, Theme } from "../../../../hooks";
import { Analytics, Apps, Creator } from "../../../../services";
import {
  IAppItem,
  TAvailableSocialMedia,
  TIconOptions,
  TSteps,
} from "../../../../types";

import socialMediaAppConfig from "../../../../config/app-redes-sociais.json";

import H2 from "../../../../components/atoms/Typography/H2";
import * as RegisterStyles from "../styles";
import * as OtherSocialMediaStyles from "./styles";
import Button from "../../../../components/atoms/Button";

const S = {
  ...OtherSocialMediaStyles,
  ...RegisterStyles,
};

export interface ISocialMediaStepForm {
  name: TAvailableSocialMedia;
  icon: TIconOptions;
  placeholder: string;
  value: string;
  normalizer: (val: string) => string;
}

const initialSocialMedia: ISocialMediaStepForm[] = [
  {
    name: "instagram",
    icon: "instagram",
    placeholder: "Usuário do Instagram",
    value: "",
    normalizer: (val: string) => val.replace(/[@( )]/g, "").toLowerCase(),
  },
  {
    name: "twitter",
    icon: "twitter",
    placeholder: "Usuário do Twitter",
    value: "",
    normalizer: (val: string) => val.replace(/\s/g, "").toLowerCase(),
  },
  {
    name: "tiktok",
    icon: "tiktok",
    placeholder: "Usuário do TikTok",
    value: "",
    normalizer: (val: string) => val.replace(/[@( )]/gm, "").toLowerCase(),
  },
  {
    name: "youtube",
    icon: "youtube",
    placeholder: "Canal do Youtube (Cole a URL)",
    value: "",
    normalizer: (val: string) => val.replace(/\s/g, ""),
  },
  {
    name: "facebook",
    icon: "facebook",
    placeholder: "Usuário do Facebook",
    value: "",
    normalizer: (val: string) => val.replace(/\s/g, ""),
  },
  {
    name: "linkedin",
    icon: "linkedin",
    placeholder: "Página do LinkedIn (Cole a URL)",
    value: "",
    normalizer: (val: string) => val.replace(/\s/g, "").toLowerCase(),
  },
  {
    name: "whatsapp",
    icon: "whatsapp",
    placeholder: "Número de telefone com DDI e DDD",
    value: "",
    normalizer: (val: string) =>
      val.replace(/[(]*[)]*[-]*[\s]*[a-z]*/g, "").toLowerCase(),
  },
  {
    name: "telegram",
    icon: "telegram",
    placeholder: "Usuário do Telegram",
    value: "",
    normalizer: (val: string) => val.replace(/\s/g, "").toLowerCase(),
  },
  {
    name: "behance",
    icon: "behance",
    placeholder: "Usuário do Behance",
    value: "",
    normalizer: (val: string) => val.replace(/\s/g, "").toLowerCase(),
  },
  {
    name: "pinterest",
    icon: "pinterest",
    placeholder: "Usuário do Pinterest",
    value: "",
    normalizer: (val: string) => val.replace(/\s/g, "").toLowerCase(),
  },
];

const OtherSocialMediaStep: React.FC = () => {
  const { user, setUserHandler, token } = Auth.useAuth();
  const { saveFreeItemHandler } = App.useApp();
  const { showLoading, hideLoading, isLoading } = Loading.useLoading();

  const userSocialMediaRef = useRef<IAppItem[]>([]);
  const [socialMediaStepForm, setSocialMediaStepForm] =
    useState<ISocialMediaStepForm[]>(initialSocialMedia);

  useEffect(() => {
    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "social-media-step",
      creatorUsername: user.username || "",
      actionDescription: "social-media-step",
    });

    const run = async () => {
      const userSocialMedia = await Apps.getAppItems("redes-sociais", token);

      initialSocialMedia.forEach((social) => {
        userSocialMedia.forEach(({ socialMedia, username, url }) => {
          if (social.name === socialMedia && username) social.value = username;
          if (social.name === socialMedia && url) social.value = url;
        });
      });

      setSocialMediaStepForm([...initialSocialMedia]);
      userSocialMediaRef.current = userSocialMedia;
    };

    run();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const creatorHasSocialMedia = socialMediaStepForm.some(
    (social) => social.value
  );
  const isReady = !isLoading && creatorHasSocialMedia;

  const { primaryColor, textColor } = Theme.useTheme();

  const socialMediaStepFormChangeHandler = (index: number, value: string) => {
    setSocialMediaStepForm((curr) => {
      curr[index].value = value;
      return [...curr];
    });
  };

  const onBackHandler = () => {
    setUserHandler({ formStep: "colors" });
  };

  const onNextHandler = async () => {
    showLoading();

    const creatorSocialMedia = socialMediaStepForm
      .filter((social) => social.value)
      .map((social) => ({
        type: social.name,
        link: social.normalizer(social.value),
      }));

    const newSocialMedia = creatorSocialMedia.filter(
      (socialMedia) =>
        !userSocialMediaRef.current.some(
          (userSocialMedia) => userSocialMedia.socialMedia === socialMedia.type
        )
    );

    const payload = {
      formStep: "qualification" as TSteps,
    };

    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      description: "social-media-step-concluded",
      meta: {
        numberOfSocialMedia: socialMediaStepForm.filter(
          (item) => item.value !== ""
        ).length,
      },
      actionDescription: `social-media-step-concluded-${user.username}`,
    });

    const socialMediaPromises = newSocialMedia.map((item) => {
      const payload: IAppItem = {
        socialMedia: item.type,
      };

      if (["linkedin", "youtube"].includes(item.type)) {
        payload.url = item.link;

        return saveFreeItemHandler(socialMediaAppConfig.id, payload, false);
      }

      payload.username = item.link;
      return saveFreeItemHandler(socialMediaAppConfig.id, payload, false);
    });

    await Promise.all(socialMediaPromises);
    const updatedUser = await Creator.updateProfile(payload, token);

    setUserHandler(updatedUser);
    hideLoading();
  };

  return (
    <Page>
      <S.Wrapper>
        <S.Container>
          <S.RegisterFormHeader>
            <div className="stantiLogo">
              <StantiIcon color="#fafafa" />
            </div>

            <Button
              style={{ color: "#fafafa" }}
              color="#fafafa33"
              onClick={onBackHandler}
            >
              Voltar
            </Button>
          </S.RegisterFormHeader>

          <S.OtherSocialMediaStepProgressBar>
            <P color={textColor}>
              Quase lá, <S.Highlight>{user.name?.split(" ")[0]}</S.Highlight>!
            </P>

            <StepsProgressBar progress={(5 / 6) * 100} quantityOfBars={6} />
          </S.OtherSocialMediaStepProgressBar>

          <S.CallToAction color={primaryColor}>
            <span>Quanto mais conectado, melhor!</span>
            <H2 color="#fafafa">Adicione outras redes sociais!</H2>
          </S.CallToAction>

          <S.Form>
            {socialMediaStepForm.map((socialMedia, index) => {
              const success = socialMedia.value.length >= 2;

              return (
                <S.InputContainer
                  key={socialMedia.name}
                  color={primaryColor}
                  isSuccess={success}
                >
                  {success ? <span /> : null}

                  <S.Icon>
                    <AvailableIcons
                      option={socialMedia.icon}
                      color={success ? primaryColor : "#fafafa33"}
                    />
                  </S.Icon>

                  <Input
                    large
                    color={success ? primaryColor : "#fafafa33"}
                    value={socialMedia.value}
                    placeholder={socialMedia.placeholder}
                    status={success ? "success" : ""}
                    onChange={(val) =>
                      socialMediaStepFormChangeHandler(
                        index,
                        socialMedia.normalizer(val)
                      )
                    }
                  />
                </S.InputContainer>
              );
            })}
          </S.Form>

          <S.Footer>
            <NavigationArrow
              buttonStyle="solid"
              angle={creatorHasSocialMedia ? 0 : -90}
              onClick={() => isReady && onNextHandler()}
              color={isReady ? primaryColor : "#ffffff33"}
            />
          </S.Footer>
        </S.Container>
      </S.Wrapper>
    </Page>
  );
};

export default OtherSocialMediaStep;
