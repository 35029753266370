import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ArrowBottomLeftIcon: React.FC<IProps> = ({
  color,
  opacity,
  blendMode,
}) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3668 26L4.5569 26C2.05061 26 -4.38404e-07 23.9485 0 21.4411L2.59057e-06 6.62478C2.92137e-06 4.73285 1.52657 3.20563 3.41768 3.20563C5.3088 3.20563 6.83536 4.73285 6.83536 6.62478L6.83536 14.0785L20.3921 0.926194C21.6909 -0.327496 23.7643 -0.304701 25.0402 0.971784C26.3161 2.24827 26.3161 4.32256 25.063 5.62183L11.734 19.1617L19.3668 19.1617C21.258 19.1617 22.7845 20.6889 22.7845 22.5808C22.7845 24.4728 21.258 26 19.3668 26Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ArrowBottomLeftIcon;
