import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ClockWatchIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="29"
      height="37"
      viewBox="0 0 29 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34375 7.4L7.4698 2.80262C7.87323 1.1555 9.32317 0 10.9866 0H18.0134C19.6768 0 21.1268 1.1555 21.5302 2.80262L22.6562 7.4H23.5625C26.5655 7.4 29 9.88482 29 12.95V24.05C29 27.1152 26.5655 29.6 23.5625 29.6H22.6562L21.5302 34.1974C21.1268 35.8445 19.6768 37 18.0134 37H10.9866C9.32317 37 7.87323 35.8445 7.4698 34.1974L6.34375 29.6H5.4375C2.43445 29.6 0 27.1152 0 24.05V12.95C0 9.88482 2.43445 7.4 5.4375 7.4H6.34375ZM14.5 11.1C15.501 11.1 16.3125 11.9283 16.3125 12.95V17.7337L20.8438 22.3587C21.5516 23.0812 21.5516 24.2525 20.8438 24.975C20.1359 25.6975 18.9883 25.6975 18.2805 24.975L13.2184 19.8081C12.8785 19.4612 12.6875 18.9907 12.6875 18.5V12.95C12.6875 11.9283 13.499 11.1 14.5 11.1Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ClockWatchIcon;
