import { Navigate, Route } from "react-router-dom";

import Form from "./components/Form";

const AppServico = () => {
  return (
    <Route path="servico">
      <Route path="new" element={<Form />} />
      <Route path=":id" element={<Form />} />
      <Route index path="*" element={<Navigate to="/apps/servico/new" />} />
    </Route>
  );
};

export default AppServico;
