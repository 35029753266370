import { useEffect, useState } from "react";
import Button from "../../../../components/atoms/Button";

import ChooseBackgroundColor from "../../../../components/atoms/ChooseBackgroundColor";
import ChoosePrimaryColor from "../../../../components/atoms/ChoosePrimaryColor";
import NavigationArrow from "../../../../components/atoms/NavigationArrow";
import Page from "../../../../components/atoms/Page";
import StepsProgressBar from "../../../../components/atoms/StepsProgressBar";
import P from "../../../../components/atoms/Typography/P";
import BackgroundProfileImageIcon from "../../../../components/icons/BackgroundProfileImageIcon";
import StantiIcon from "../../../../components/icons/StantiIcon";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import { Analytics, Creator } from "../../../../services";
import { ICreatorData, TSteps } from "../../../../types";

import * as RegisterStyles from "../styles";
import * as ColorStepStyles from "./styles";

const S = {
  ...ColorStepStyles,
  ...RegisterStyles,
};

const ColorStep: React.FC = () => {
  const [creatorForm, setCreatorForm] = useState<ICreatorData>();
  const [hasChangedTheme, setHasChangedTheme] = useState<boolean>(false);

  const { primaryColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const { user, setUserHandler, token } = Auth.useAuth();
  const { showLoading, hideLoading } = Loading.useLoading();

  useEffect(() => {
    setCreatorForm(user);
  }, [user]);

  useEffect(() => {
    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "colors-step",
      actionDescription: "select-colors",
      creatorUsername: user.username || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTheme = (
    key: "primaryColor" | "backgroundColor",
    value: string
  ) => {
    setCreatorForm((curr) => ({ ...curr, [key]: value }));
    setUserHandler({ [key]: value });
    setHasChangedTheme(true);
  };

  const onBackHandler = () => {
    setUserHandler({ formStep: "profile-image" });
  };

  const onNextHandler = async () => {
    try {
      showLoading();
      const payload = {
        formStep: "social-media" as TSteps,
        primaryColor: creatorForm?.primaryColor,
        backgroundColor: creatorForm?.backgroundColor,
      };

      (Object.keys(payload) as (keyof typeof payload)[]).forEach((key) => {
        if (!payload[key]) delete payload[key];
      });

      const updateUser = await Creator.updateProfile(payload, token);

      Analytics.submitClickEvent({
        creator: user.id || "",
        origin: document.referrer,
        creatorUsername: user.username || "",
        description: "colors-step-concluded",
        meta: { hasChangedTheme: hasChangedTheme },
        actionDescription: `colors-step-concluded-${user.username}`,
      });

      setUserHandler(updateUser);
    } catch (error) {
      newError("Houve um erro ao salvar os dados.");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <S.Wrapper>
        <S.Container>
          <S.RegisterFormHeader>
            <div className="stantiLogo">
              <StantiIcon color="#fafafa" />
            </div>

            <Button
              style={{ color: "#fafafa" }}
              color="#fafafa05"
              onClick={() => onBackHandler()}
            >
              Voltar
            </Button>
          </S.RegisterFormHeader>

          <S.ColorStepProgressBar>
            <S.IntroText color="#fafafa">
              Quase lá, <strong>{creatorForm?.name?.split(" ")[0]}</strong>!
            </S.IntroText>

            <StepsProgressBar progress={66.66} quantityOfBars={6} />
          </S.ColorStepProgressBar>

          <S.Content>
            <S.ImageContainer>
              <div className="background">
                <BackgroundProfileImageIcon color={primaryColor} />
              </div>

              {user.profileImage && (
                <S.ProfileImage imageUrl={creatorForm?.profileImage}>
                  <div className="profileImageBorder" />
                </S.ProfileImage>
              )}
            </S.ImageContainer>

            <span>Escolha uma cor que combine com sua foto!</span>

            <S.ChooseColors>
              <div>
                <P color={primaryColor}>Cor de destaque</P>

                <ChoosePrimaryColor
                  value={creatorForm?.primaryColor}
                  onChange={(color) => onChangeTheme("primaryColor", color)}
                  selectedBackgroundColor={creatorForm?.backgroundColor}
                />
              </div>

              <div>
                <P color={primaryColor}>Cor de fundo</P>

                <ChooseBackgroundColor
                  value={creatorForm?.backgroundColor}
                  onChange={(color) => onChangeTheme("backgroundColor", color)}
                  selectedPrimaryColor={creatorForm?.primaryColor}
                />
              </div>
            </S.ChooseColors>
          </S.Content>

          <S.Footer>
            <NavigationArrow
              buttonStyle="solid"
              angle={0}
              onClick={() => onNextHandler()}
              color={primaryColor}
            />
          </S.Footer>
        </S.Container>
      </S.Wrapper>
    </Page>
  );
};

export default ColorStep;
