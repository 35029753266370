import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const StoreIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="32"
      viewBox="0 0 38 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.65256 4H32.6526C33.7526 4 34.6526 3.1 34.6526 2C34.6526 0.9 33.7526 0 32.6526 0H4.65256C3.55256 0 2.65256 0.9 2.65256 2C2.65256 3.1 3.55256 4 4.65256 4ZM34.9725 7.6C34.7925 6.68 33.9725 6 33.0125 6H4.29249C3.33249 6 2.51249 6.68 2.33249 7.6L0.332491 17.6C0.0924909 18.84 1.03249 20 2.29249 20H2.65249V30C2.65249 31.1 3.55249 32 4.65249 32H20.6525C21.7525 32 22.6525 31.1 22.6525 30V20H30.6525V30C30.6525 31.1 31.5525 32 32.6525 32C33.7525 32 34.6525 31.1 34.6525 30V20H35.0125C36.2725 20 37.2125 18.84 36.9725 17.6L34.9725 7.6ZM6.65256 28H18.6526V20H6.65256V28Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default StoreIcon;
