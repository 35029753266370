import { ReactElement } from "react";
import AddContentBox from "../../atoms/AddContentBox";
import Label from "../../atoms/Typography/Label";

import * as S from "./styles";

interface IItem {
  label: string;
  route: string;
  icon: ReactElement;
}

interface IProps {
  items: IItem[];
  sectionTitle?: string;
}

const AddContent: React.FC<IProps> = ({ items, sectionTitle = "" }) => {
  return (
    <S.AddContent>
      {sectionTitle ? (
        <S.SectionTitle>
          <hr />
          <Label color="#fafafa60">{sectionTitle}</Label>
          <hr />
        </S.SectionTitle>
      ) : null}

      <S.Content>
        {items.map((item) => (
          <AddContentBox
            key={item.label}
            icon={item.icon}
            label={item.label}
            route={item.route}
          />
        ))}
      </S.Content>
    </S.AddContent>
  );
};

export default AddContent;
