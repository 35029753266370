import styled from "styled-components";

type HeaderProps = {
  showLiveLabel?: boolean;
};

export const Header = styled.header<HeaderProps>`
  display: flex;
  align-items: ${({ showLiveLabel }) =>
    showLiveLabel ? "flex-start" : "center"};
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const Live = styled.div`
  gap: 8px;
  display: flex;

  span {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #fafafa;
  }
`;
