import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Auth, Snackbar, Theme } from "../../hooks";
import { OAuth } from "../../services";

import Small from "../../components/atoms/Typography/Small";

import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import Link from "../../components/atoms/Link";
import Page from "../../components/atoms/Page";
import StantiIcon from "../../components/icons/StantiIcon";
import SocialMediaLogin from "../../components/molecules/SocialMediaLogin";

import { LOCAL_STORAGE_AUTHENTICATION_METHOD } from "../../constants";

import * as S from "./styles";

const RegisterFormSecondStep: React.FC = () => {
  const [formData, setFormData] = useState({
    user: "",
    password: "",
  });

  const navigate = useNavigate();
  const { login } = Auth.useAuth();
  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();

  useEffect(() => {
    if (window.location.hash) {
      const params: { access_token: string; error: string; state: "" } =
        window.location.hash
          .substring(1)
          .split("&")
          .reduce(
            (acc, curr: any) => {
              const [key, ...value] = curr.split("=");
              return { ...acc, [key]: value.join("=") };
            },
            { access_token: "", error: "", state: "" }
          );

      if (params.error) {
        newError("Houve um erro ao fazer o login");
      } else {
        const provider = params.state ? "facebook" : "google";
        window.location.hash = "";
        login(null, null, provider, params.access_token);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlParams = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );

    if (urlParams && urlParams.username) delete urlParams.username;

    if (Object.keys(urlParams).length > 0)
      localStorage.setItem("@searchParams", JSON.stringify(urlParams));
  }, []);

  const onLogin = () => {
    login(formData.user, formData.password);
  };

  const onLoginWithGoogle = () => {
    localStorage.setItem(
      LOCAL_STORAGE_AUTHENTICATION_METHOD,
      "authenticate-with-google"
    );
    window.location.href = OAuth.getGoogleOAuthURI("login");
  };

  const onLoginWithFacebook = () => {
    localStorage.setItem(
      LOCAL_STORAGE_AUTHENTICATION_METHOD,
      "authenticate-with-facebook"
    );
    window.location.href = OAuth.getFacebookOAuthURI("login");
  };

  const isReady = formData.user && formData.password;

  return (
    <Page>
      <S.Information>
        <S.Content>
          <S.RegisterFormHeader>
            <div className="stantiLogo">
              <StantiIcon color="#fafafa" />
            </div>
          </S.RegisterFormHeader>

          <S.Login>
            <S.TextContainer>
              <S.Title color={primaryColor}>
                Acesse a <br /> sua conta
              </S.Title>

              <S.Subtitle color="#fafafa">Que bom te ver novamente!</S.Subtitle>
            </S.TextContainer>

            <S.LoginForm>
              <Input
                allLowerCase
                value={formData.user}
                placeholder="E-mail ou username"
                onChange={(val) =>
                  setFormData((curr) => ({
                    ...curr,
                    user: val.toLowerCase().trim(),
                  }))
                }
              />

              <Input
                value={formData.password}
                placeholder="Digite sua senha"
                type="password"
                onChange={(val) =>
                  setFormData((curr) => ({ ...curr, password: val }))
                }
              />

              <div
                className="forgotPassword"
                onClick={() => navigate("/forgot-password")}
              >
                <Small color={primaryColor}>Esqueci minha senha</Small>
              </div>

              <Button
                type="outline"
                color={primaryColor}
                onClick={onLogin}
                disabled={!isReady}
              >
                Entrar
              </Button>

              <S.Or color={primaryColor}>ou</S.Or>

              <S.GoogleOrFacebook>
                <SocialMediaLogin
                  socialMedia="Google"
                  onClick={onLoginWithGoogle}
                />

                <SocialMediaLogin
                  socialMedia="Facebook"
                  onClick={onLoginWithFacebook}
                />
              </S.GoogleOrFacebook>
            </S.LoginForm>

            <S.LinkText color={textColor}>
              Ainda não possui uma conta? <br />
              <Link to="/register"> Clique aqui para criar gratuitamente.</Link>
            </S.LinkText>
          </S.Login>
        </S.Content>
      </S.Information>
    </Page>
  );
};

export default RegisterFormSecondStep;
