import styled, { css } from "styled-components";

export const ServiceType = styled.div`
  gap: 30px;
  width: 100%;
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  user-select: none;
  align-items: center;
`;

export const Type = styled.div`
  gap: 8px;
  display: flex;
`;

type InputOutProps = {
  isSelected?: boolean;
  borderColor?: string;
};

export const InputOut = styled.div<InputOutProps>`
  ${({ isSelected, borderColor }) => css`
    width: 18px;
    height: 18px;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    align-items: center;
    mix-blend-mode: screen;
    justify-content: center;
    background: ${isSelected ? "none" : "#fafafa18"};
    border: ${isSelected ? `1px solid ${borderColor}` : "none"};
  `}
`;

type InputInProps = {
  isSelected?: boolean;
  backgroundColor?: string;
};

export const InputIn = styled.div<InputInProps>`
  ${({ isSelected, backgroundColor }) => css`
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    background: ${isSelected ? backgroundColor : "none"};
  `}
`;
