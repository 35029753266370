import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LinkIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="37"
      height="38"
      viewBox="0 0 37 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8284 31.8661L21.0218 27.6788C21.7906 26.9112 23.2197 27.0649 24.1977 28.0206C25.1756 28.9762 25.3468 30.3861 24.578 31.1538L20.3846 35.341C16.5267 39.1933 9.41266 38.4278 4.50511 33.6323C-0.402449 28.8369 -1.25433 21.8184 2.60358 17.9662L6.79697 13.7789C7.56576 13.0113 8.99491 13.165 9.97287 14.1207C10.9508 15.0763 11.122 16.4862 10.3532 17.2539L6.15978 21.4411C3.85342 23.7441 4.36683 27.974 7.3007 30.8408C10.2346 33.7077 14.522 34.169 16.8284 31.8661Z"
        fill={color || primaryColor}
      />
      <path
        d="M16.3374 2.54942L12.1441 6.73666C11.3753 7.50432 11.5464 8.91428 12.5244 9.86989C13.5023 10.8255 14.9315 10.9793 15.7003 10.2116L19.8936 6.0244C22.2 3.72142 26.4875 4.18277 29.4213 7.04962C32.3552 9.91648 32.8686 14.1463 30.5622 16.4493L26.3689 20.6366C25.6001 21.4042 25.7712 22.8142 26.7492 23.7698C27.7271 24.7254 29.1563 24.8792 29.9251 24.1115L34.1184 19.9243C37.9764 16.072 37.1245 9.0536 32.2169 4.25813C27.3094 -0.537336 20.1954 -1.30284 16.3374 2.54942Z"
        fill={color || primaryColor}
      />
      <path
        d="M12.8309 24.8392C13.8104 25.8543 15.2341 26.0218 15.9946 25.2114L24.2905 16.3701C25.051 15.5596 24.8717 14.066 23.8922 13.0508C22.9126 12.0356 21.489 11.8682 20.7285 12.6786L12.4325 21.5199C11.672 22.3303 11.8513 23.824 12.8309 24.8392Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default LinkIcon;
