import styled from "styled-components";
import Small from "../../components/atoms/Typography/Small";

export const LoginFormHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  img {
    height: 42px;
  }
`;

export const Cta = styled.div`
  gap: 12px;
  display: flex;
  padding: 110px 0 32px;
  flex-direction: column;
  justify-content: space-between;
`;

export const Form = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const Message = styled(Small)`
  white-space: pre-wrap;
  text-align: center;
`;
