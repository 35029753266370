import {
  useState,
  useEffect,
  TouchEventHandler,
  TouchEvent,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

import { Apps } from "../../../../services";
import DraggableItem from "../DraggableItem";
import Button from "../../../../components/atoms/Button";
import { IAppItem, TIconOptions } from "../../../../types";
import DropZone from "../../../../components/molecules/DropZone";
import { formatData } from "../../../AppGaleriaImagens/components/Form";
import { Auth, GA, Loading, NavBar, Snackbar } from "../../../../hooks";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeLetter";
import ExternalLinkSkeleton from "../../../../components/atoms/ExternalLinkSkeleton";

import appGrupo from "../../../../config/app-grupo.json";
import appServico from "../../../../config/app-servico.json";
import appYoutube from "../../../../config/app-youtube.json";
import appMentoria from "../../../../config/app-mentoria.json";
import appAfiliados from "../../../../config/app-afiliados.json";
import appCursoLive from "../../../../config/app-curso-live.json";
import appVendaArquivos from "../../../../config/app-arquivo.json";
import appLinkExterno from "../../../../config/app-link-externo.json";
import appRedesSociais from "../../../../config/app-redes-sociais.json";
import appGaleriaImagens from "../../../../config/app-galeria-imagens.json";
import appLinkToPostInstagram from "../../../../config/app-link-to-post-instagram.json";

import * as S from "./styles";

const appConfig = {
  grupo: appGrupo,
  servico: appServico,
  youtube: appYoutube,
  mentoria: appMentoria,
  afiliados: appAfiliados,
  arquivo: appVendaArquivos,
  "curso-live": appCursoLive,
  "link-externo": appLinkExterno,
  "redes-sociais": appRedesSociais,
  "galeria-imagens": appGaleriaImagens,
  "link-to-post-instagram": appLinkToPostInstagram,
};

interface IProps {
  loading: boolean;
  appItems: IAppItem[];
  initialAppItems: IAppItem[];
  setInitialAppItems: (items: IAppItem[]) => void;
  setAppItems: (items: IAppItem[] | ((arg: IAppItem[]) => IAppItem[])) => void;
  onPublish: (appItem: IAppItem) => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const ProfileBody: React.FC<IProps> = ({
  loading,
  appItems,
  setAppItems,
  initialAppItems,
  setInitialAppItems,
  onPublish,
}) => {
  const [hoveredDropZone, setHoveredDropZone] = useState(-1);
  const [isMobileDragOn, setIsMobileDragOn] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [indexOfItemOnDrag, setIndexOfItemOnDrag] = useState(-1);

  const navigate = useNavigate();
  const { sendEvents } = GA.useGA();
  const { token, user } = Auth.useAuth();
  const { hideNavBar, showNavBar } = NavBar.useNavBar();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();

  const linksContainer = useRef<HTMLDivElement>(null);
  const scrollInterval = useRef<NodeJS.Timer | null>(null);

  const hasUnsavedChanges =
    JSON.stringify(initialAppItems) !== JSON.stringify(appItems);

  useEffect(() => {
    if (hasUnsavedChanges) hideNavBar();
    else showNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnsavedChanges]);

  const touchCancelHandler: TouchEventHandler<HTMLDivElement> = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    setIsMobileDragOn(false);
    setIndexOfItemOnDrag(-1);
    setHoveredDropZone(-1);
  };

  const touchStartHandler = (e: TouchEvent<HTMLDivElement>, index: number) => {
    setIndexOfItemOnDrag(index);
    setCoordinates({
      x: e.targetTouches[0].pageX - 20,
      y: e.targetTouches[0].pageY - 80,
    });
    setIsMobileDragOn(true);
  };

  const touchEndHandler: TouchEventHandler<HTMLDivElement> = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    setIsMobileDragOn(false);
    setIndexOfItemOnDrag(-1);
    setHoveredDropZone(-1);

    onDropHandler();
  };

  const touchMoveHandler: TouchEventHandler<HTMLDivElement> = (e) => {
    if (!linksContainer.current) return;

    setCoordinates({
      x: e.targetTouches[0].pageX - 20,
      y: e.targetTouches[0].pageY - 80,
    });

    const hoveredIndex = Math.floor(
      (e.targetTouches[0].pageY - (linksContainer.current.offsetTop || 0)) /
        (linksContainer.current.offsetHeight / initialAppItems.length)
    );
    const finalHoveredIndex =
      hoveredIndex > indexOfItemOnDrag ? hoveredIndex + 1 : hoveredIndex;

    setHoveredDropZone(finalHoveredIndex);

    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }

    if (e.targetTouches[0].clientY > window.innerHeight - 60) {
      scrollInterval.current = setInterval(() => {
        window.scrollTo({ top: window.scrollY + 6 });
      }, 20);
    }
    if (e.targetTouches[0].clientY < 60) {
      scrollInterval.current = setInterval(() => {
        window.scrollTo({ top: window.scrollY - 6 });
      }, 20);
    }
  };

  const onDropHandler = () => {
    if (hoveredDropZone <= -1) return;

    setAppItems((curr) => {
      const newArr = [...curr];
      const draggedElement = newArr.splice(indexOfItemOnDrag, 1)[0];

      const indexToInsertItem =
        indexOfItemOnDrag < hoveredDropZone
          ? hoveredDropZone - 1
          : hoveredDropZone;

      newArr.splice(indexToInsertItem, 0, draggedElement);
      return newArr;
    });

    setHoveredDropZone(-1);
    setIndexOfItemOnDrag(-1);
  };

  const onEditHandler = (item: IAppItem) => {
    sendEvents({
      name: "edit-sku",
      category: item.app,
      buttonLocation: "home-stanti",
      action: `go-to-edit-${item.app}`,
    });

    if (item.app === "arquivo")
      navigate(`/apps/${item.app}/${item.type}/${item.id}`);
    else navigate(`/apps/${item.app}/${item.id}`);
  };

  const renderAppItems = () => {
    if (appItems && appItems.length)
      return (
        <S.ExternalLinks ref={linksContainer}>
          <DropZone
            isDroppable
            onDrop={onDropHandler}
            isHovered={hoveredDropZone === 0}
            onDragEnter={() => setHoveredDropZone(0)}
            onDragLeave={() => setHoveredDropZone(-1)}
          />
          {appItems.map((item, index) => {
            const title = (() => {
              if (item.app === "galeria-imagens")
                return `${formatData[item.orientation || "portrait"].title} (${
                  item.images?.length
                } foto${item.images?.length === 1 ? "" : "s"})`;

              if (item.app === "link-to-post-instagram")
                return `${item.posts?.length || 0} post${
                  (item.posts?.length || 0) === 1 ? "" : "s"
                }`;

              if (item.app === "youtube")
                return item.type === "video"
                  ? `Vídeo | ${item.title}`
                  : `Canal | ${item.title}`;

              if (item.app === "redes-sociais")
                return capitalizeFirstLetter(
                  item.socialMedia as string
                ) as string;

              return item.title || "";
            })();
            return (
              <DraggableItem
                key={item.id}
                title={title}
                app={item.app || ""}
                onDragEnd={() => null}
                coordinates={coordinates}
                onDropHandler={onDropHandler}
                touchEndHandler={touchEndHandler}
                touchMoveHandler={touchMoveHandler}
                touchCancelHandler={touchCancelHandler}
                onEditHandler={() => onEditHandler(item)}
                onCopyHandler={() => onCopyHandler(item)}
                onDragLeave={() => setHoveredDropZone(-1)}
                onDragStart={() => setIndexOfItemOnDrag(index)}
                isDropZoneHovered={index + 1 === hoveredDropZone}
                onDragEnter={() => setHoveredDropZone(index + 1)}
                touchStartHandler={(e) => touchStartHandler(e, index)}
                label={appConfig[item.app as keyof typeof appConfig]?.title}
                isMobileDragOn={isMobileDragOn && index === indexOfItemOnDrag}
                icon={
                  <AvailableIcons
                    color={item.isDraft ? "#DADADA" : ""}
                    option={
                      appConfig[item.app as keyof typeof appConfig]
                        ?.icon as TIconOptions
                    }
                  />
                }
                isPublished={!item.isDraft}
                onPublishItem={(publish) =>
                  onPublish({ ...item, isDraft: publish })
                }
              />
            );
          })}
        </S.ExternalLinks>
      );

    return null;
  };

  const onSaveHandler = async () => {
    try {
      showLoading();
      const reorderedItems = appItems.map((item) => ({
        id: item.id || "",
        app: item.app || "",
      }));

      await Apps.reorderStorefront(reorderedItems, token);

      setInitialAppItems(appItems);
    } catch (error) {
      newError("Houve um erro ao atualizar a ordem dos itens");
    } finally {
      hideLoading();
    }
  };

  const onCopyHandler = (appItem: IAppItem) => {
    if (appItem.app === "redes-sociais") {
      navigator.clipboard.writeText((appItem.url || appItem.link) as string);

      return newSuccess("Link copiado");
    }

    navigator.clipboard.writeText(
      `${REACT_APP_STOREFRONT_BASE_URL}/${user.username}/${appItem.id}`
    );

    newSuccess("Link copiado");
  };

  return (
    <S.ProfileBody>
      {loading && <ExternalLinkSkeleton />}

      {renderAppItems()}

      {hasUnsavedChanges && (
        <S.SaveButton>
          <Button onClick={onSaveHandler}>Salvar Alterações</Button>
        </S.SaveButton>
      )}
    </S.ProfileBody>
  );
};

export default ProfileBody;
