import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const DocumentIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="30"
      height="36"
      viewBox="0 0 30 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1061 0C18.0601 0 18.9781 0.378 19.6621 1.062L28.3381 9.756C29.0221 10.422 29.4001 11.34 29.4001 12.294V32.4C29.4001 34.38 27.7801 36 25.8001 36H4.1821C2.2021 36 0.600098 34.38 0.600098 32.4V3.6C0.600098 1.62 2.2201 0 4.2001 0H17.1061ZM9.6001 28.8H20.4001C21.3901 28.8 22.2001 27.99 22.2001 27C22.2001 26.01 21.3901 25.2 20.4001 25.2H9.6001C8.6101 25.2 7.8001 26.01 7.8001 27C7.8001 27.99 8.6101 28.8 9.6001 28.8ZM20.4001 21.6H9.6001C8.6101 21.6 7.8001 20.79 7.8001 19.8C7.8001 18.81 8.6101 18 9.6001 18H20.4001C21.3901 18 22.2001 18.81 22.2001 19.8C22.2001 20.79 21.3901 21.6 20.4001 21.6ZM16.8001 2.7V10.8C16.8001 11.79 17.6101 12.6 18.6001 12.6H26.7001L16.8001 2.7Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default DocumentIcon;
