import styled from "styled-components";

export const StorefrontLink = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
`;

export const Link = styled.div`
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  border-radius: 20px;
  align-items: center;
  mix-blend-mode: screen;
  background-color: #fafafa18;
  justify-content: space-between;
  gap: 12px;

  .url {
    display: flex;
    overflow: hidden;
    width: 100%;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      font-size: 12px;
      text-decoration: underline;
    }
  }
`;

export const ShareButton = styled.button`
  height: 100%;
  display: flex;
  color: white;
  font-size: 12px;
  line-height: 100%;
  padding: 12px 20px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(rgba(255, 255, 255, 0.25), transparent);
  cursor: pointer;
`;
