import { useLocation, useNavigate } from "react-router-dom";

import { Auth, Theme } from "../../../hooks";
import { Analytics } from "../../../services";
import ChartBarsIcon from "../../icons/ChartBarsIcon";
import HomeIcon from "../../icons/HomeIcon";
import PersonPinTwoIcon from "../../icons/PersonPinTwoIcon";
import PlusIcon from "../../icons/PlusIcon";
import ShowPasswordIcon from "../../icons/ShowPasswordIcon";

import * as S from "./styles";

const NavBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = Auth.useAuth();
  const { primaryColor, backgroundColor } = Theme.useTheme();

  const buttons = [
    {
      id: "home",
      label: "Home",
      path: "/",
      selectable: true,
      action: () => navigate("/"),
      icon: (color?: string) => <HomeIcon color={color} />,
    },
    {
      id: "dash",
      path: "/dashboard",
      label: "Dashboard",
      selectable: true,
      action: () => navigate("/dashboard"),
      icon: (color?: string) => <ChartBarsIcon color={color} />,
    },
    {
      id: "apps",
      path: "/apps",
      label: "Adicionar",
      selectable: true,
      action: () => navigate("/apps"),
      icon: (color?: string) => <PlusIcon color={color} />,
    },
    {
      id: "preview",
      path: "/preview",
      label: "Preview",
      selectable: true,
      action: () => navigate("/preview"),
      icon: (color?: string) => <ShowPasswordIcon color={color} />,
    },
    {
      id: "profile",
      path: "/edit",
      label: "Meu perfil",
      selectable: true,
      action: () => navigate("/edit"),
      icon: (color?: string) => <PersonPinTwoIcon color={color} />,
    },
  ];

  const isSelected = (button: typeof buttons[number]) => {
    if (location.pathname === "/") return button.path === location.pathname;

    return location.pathname.split("/")[1] === button.path?.substring(1);
  };

  const onClickHandler = (id: string, path: string) => {
    Analytics.submitClickEvent({
      meta: { button: id },
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      description: "click-on-navbar",
      actionDescription: `click-on-navbar-${id}`,
    });

    navigate(path);
  };

  return (
    <S.NavBar backgroundColor={backgroundColor}>
      {buttons.map((button) => (
        <S.Button
          key={button.id}
          onClick={() => onClickHandler(button.id, button.path)}
          isSelected={isSelected(button)}
          textColor={primaryColor}
        >
          {isSelected(button) && (
            <S.Span backgroundColor={primaryColor} textColor={primaryColor} />
          )}
          <>
            {button.icon(isSelected(button) ? `${primaryColor}` : "#fafafa")}
            <p>{button.label}</p>
          </>
        </S.Button>
      ))}
    </S.NavBar>
  );
};

export default NavBar;
