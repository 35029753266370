import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const FolderIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="50"
      height="41"
      viewBox="0 0 50 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 5.6001H45C47.75 5.6001 50 7.8501 50 10.6001V35.6001C50 38.3501 47.75 40.6001 45 40.6001H5C2.25 40.6001 0 38.3501 0 35.6001L0.025 5.6001C0.025 2.8501 2.25 0.600098 5 0.600098H17.925C19.25 0.600098 20.525 1.1251 21.475 2.0751L25 5.6001ZM7.50002 35.6001H42.5C43.875 35.6001 45 34.4751 45 33.1001V13.1001C45 11.7251 43.875 10.6001 42.5 10.6001H7.50002C6.12502 10.6001 5.00002 11.7251 5.00002 13.1001V33.1001C5.00002 34.4751 6.12502 35.6001 7.50002 35.6001Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default FolderIcon;
