import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  padding: 30px;
  margin: 0 auto;

  h2 {
    color: white;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  color: white;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-height: 200px;
  gap: 20px;
  width: 100%;
  border: 1px solid white;

  svg {
    width: auto;
    height: 50px;
  }
`;
