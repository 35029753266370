import styled from "styled-components";
import Button from "../../../../components/atoms/Button";
import P from "../../../../components/atoms/Typography/P";
import { Container, Wrapper } from "../styles";

import bgImage from "../../../../components/assets/image_bg.png";

export const InformationStep = styled(Wrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;

  &::after {
    content: "";
    width: 140%;
    top: -550px;
    left: -175px;
    height: 140%;
    opacity: 0.7;
    cursor: none;
    position: absolute;
    filter: blur(100px);
    pointer-events: none;
    background: url(${bgImage});
    background-size: cover;
    background-position: top center;

    @media (max-width: 920px) {
      width: 120%;
      left: -40px;
      top: -250px;
      height: 120%;
      filter: blur(70px);
    }

    @media (max-width: 600px) {
      left: 40px;
      top: -150px;
      filter: blur(60px);
    }
  }
`;

export const InformationContainer = styled(Container)`
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;

  .googleOrFacebook {
    gap: 12px;
    display: flex;
    flex-direction: column;
  }
`;

export const Form = styled.div`
  gap: 12px;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
`;

export const Text = styled(P)`
  align-self: center;
  padding: 16px 0;
`;

export const GoogleOrFacebook = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled(Button)`
  color: #fafafa;
`;
