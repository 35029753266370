import Input from "../../../../components/atoms/Input";
import { ICreatorData } from "../../../../types";

interface IProps {
  hasError: boolean;
  isEmailLoading: boolean;
  email: ICreatorData["email"];
  onChangeEmail: (email: string) => void;
}

const EditEmail: React.FC<IProps> = ({
  email,
  hasError,
  onChangeEmail,
  isEmailLoading,
}) => {
  const status = isEmailLoading ? "loading" : hasError ? "error" : "";

  return (
    <Input
      allLowerCase
      status={status}
      value={email || ""}
      onChange={onChangeEmail}
      placeholder="Seu email"
    />
  );
};

export default EditEmail;
