import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const MessengerIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5388 0C7.65766 0 0 7.22194 0 16.9766C0 22.0786 2.09647 26.4877 5.50897 29.5335C6.09819 30.0634 5.97681 30.3704 6.07702 33.6426C6.08491 33.8719 6.14875 34.0956 6.26299 34.2945C6.37722 34.4934 6.53838 34.6613 6.7324 34.7835C6.92641 34.9058 7.1474 34.9788 7.37608 34.996C7.60476 35.0133 7.83419 34.9743 8.04435 34.8825C11.7779 33.2383 11.8259 33.1084 12.4589 33.2806C23.2757 36.2579 35 29.3352 35 16.9766C35 7.22194 27.4207 0 17.5388 0ZM28.0699 13.0643L22.9186 21.2198C22.7241 21.5265 22.4684 21.7897 22.1674 21.9929C21.8664 22.1961 21.5267 22.335 21.1696 22.4007C20.8124 22.4665 20.4455 22.4578 20.0919 22.3752C19.7383 22.2926 19.4055 22.1378 19.1145 21.9206L15.0161 18.853C14.8329 18.7156 14.6101 18.6413 14.381 18.6413C14.152 18.6413 13.9292 18.7156 13.746 18.853L8.21583 23.0475C7.47772 23.6072 6.51099 22.7229 7.00847 21.9417L12.1597 13.7862C12.3541 13.4794 12.6099 13.2162 12.9108 13.0129C13.2118 12.8096 13.5515 12.6707 13.9087 12.6049C14.2659 12.5392 14.6328 12.5479 14.9864 12.6306C15.3401 12.7132 15.6728 12.8681 15.9638 13.0854L20.0608 16.1523C20.244 16.2898 20.4668 16.364 20.6959 16.364C20.9249 16.364 21.1477 16.2898 21.3309 16.1523L26.8639 11.962C27.6006 11.3989 28.5673 12.2824 28.0699 13.0643Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default MessengerIcon;
