import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const MentorshipIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9671 0H26.0204C26.4617 0.314918 26.9801 0.171384 27.4621 0.24208C40.528 2.16158 49.6628 14.4112 47.7433 27.4514C45.8709 40.1617 34.3796 49.1229 21.6115 47.829C11.7741 46.8328 3.65905 40.061 0.910477 30.5813C0.47559 29.0839 0.364191 27.5285 0 26.0225V21.9693C0.182095 21.8086 0.15853 21.5858 0.179953 21.378C1.15684 11.9347 7.83225 3.84543 16.9027 1.06472C18.563 0.554855 20.2833 0.379187 21.9671 0ZM20.8981 32.3851C23.5267 32.3851 26.1553 32.3894 28.7839 32.383C30.0265 32.3787 30.2728 32.1302 30.275 30.8898C30.2793 27.445 30.2857 24.0002 30.275 20.5532C30.2643 17.599 28.2998 15.6216 25.3198 15.6088C20.1333 15.5874 14.949 15.5981 9.76246 15.6045C8.57991 15.6045 8.32926 15.8637 8.32712 17.042C8.32069 20.4525 8.32069 23.8609 8.32712 27.2715C8.3314 30.4292 10.2723 32.368 13.4429 32.3808C15.9301 32.3916 18.4152 32.3808 20.9024 32.3808L20.8981 32.3851ZM39.1227 23.9937C39.1227 21.5408 39.1269 19.0879 39.1162 16.635C39.1162 16.29 39.1698 15.8616 38.7756 15.6945C38.4436 15.5531 38.1779 15.8487 37.9273 16.0308C35.8856 17.5112 33.8612 19.0129 31.811 20.4804C31.4104 20.7674 31.2926 21.0888 31.2968 21.5515C31.3161 23.1861 31.3226 24.8228 31.2947 26.4574C31.2861 26.9801 31.4768 27.2907 31.8967 27.5907C33.8912 29.0132 35.8578 30.4763 37.8416 31.916C38.1201 32.1173 38.4136 32.4515 38.7799 32.2909C39.2105 32.1002 39.1205 31.6268 39.1205 31.2476C39.1269 28.8311 39.1248 26.4124 39.1205 23.9959L39.1227 23.9937Z"
        fill="url(#paint0_linear_5200_35308)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5200_35308"
          x1="23.9991"
          y1="0"
          x2="23.9991"
          y2="61.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || primaryColor} />
          <stop offset="1" stopColor={color || primaryColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MentorshipIcon;
