import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CheckIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.1725 10.1625L1.7025 6.69249C1.51567 6.50523 1.26202 6.4 0.9975 6.4C0.732982 6.4 0.479331 6.50523 0.2925 6.69249C-0.0975 7.08249 -0.0975 7.71249 0.2925 8.10249L4.4725 12.2825C4.8625 12.6725 5.4925 12.6725 5.8825 12.2825L16.4625 1.70249C16.8525 1.31249 16.8525 0.682486 16.4625 0.292486C16.2757 0.105233 16.022 0 15.7575 0C15.493 0 15.2393 0.105233 15.0525 0.292486L5.1725 10.1625Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CheckIcon;
