import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import Page from "../../../../components/atoms/Page";
import appConfig from "../../../../config/app-afiliados.json";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import { Analytics } from "../../../../services";
import affiliates from "../../../../services/affiliates";
import { ICreatorCampaign } from "../../../../services/affiliates/types";
import AffiliationInfo from "../AffiliationInfo";
import BrandCard from "../BrandCard";
import CampaignInfo from "../CampaignInfo";

import * as S from "./styles";

const Details: React.FC = () => {
  const [campaign, setCampaign] = useState<ICreatorCampaign>();

  const { campaignId } = useParams();

  const navigate = useNavigate();
  const { token, user } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { hideLoading, showLoading, isLoading } = Loading.useLoading();
  const { textColor } = Theme.useTheme();

  const getCampaign = useCallback(async () => {
    if (!campaignId) return;

    try {
      showLoading();
      const currentCampaign = await affiliates.getCreatorCampaign(
        campaignId,
        token
      );

      setCampaign(currentCampaign);
      return currentCampaign;
    } catch (error) {
      newError("Houve um erro ao buscar a campanha");
      return null;
    } finally {
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLeaveCampaign = useCallback(async () => {
    if (!campaign?.affiliation?.id) return;

    try {
      showLoading();

      if (campaign) {
        Analytics.submitClickEvent({
          origin: "home-app-afiliados",
          creator: user.id || "",
          description: `leave-affiliation-${campaign.brand}`,
          creatorUsername: user.username || "",
          actionDescription: `leave-affiliation-${campaign.brand}`,
        });
      }

      await affiliates.leaveCampaign(campaign.affiliation.id, token);
      navigate("/apps/afiliados");

      newSuccess("Você deixou a campanha com sucesso");
    } catch (error) {
      newError("Houve um erro ao sair da campanha");
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const onApplyForCampaign = useCallback(async () => {
    if (!campaignId) return;

    try {
      showLoading();

      if (campaign) {
        Analytics.submitClickEvent({
          creator: user.id || "",
          origin: "home-app-afiliados",
          creatorUsername: user.username || "",
          description: `affiliation-request-${campaign.brand}`,
          actionDescription: `affiliation-request-details-${campaign.brand}`,
        });
      }

      await affiliates.applyForCampaign(campaignId, token);
      await getCampaign();

      newSuccess("Recebemos sua solicitação! Entraremos em contato em breve");
    } catch (error) {
      newError("Houve um erro ao enviar sua solicitação");
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const onCopyHandler = useCallback(() => {
    if (!campaign?.affiliation?.coupon && !campaign?.affiliation?.link) return;

    if (campaign) {
      Analytics.submitClickEvent({
        origin: "home-app-afiliados",
        creator: user.id || "",
        description: `copy-code-affiliation-${campaign.brand}`,
        creatorUsername: user.username || "",
        actionDescription: `copy-code-affiliation-details-${campaign.brand}`,
      });
    }

    navigator.clipboard.writeText(
      campaign?.affiliation?.coupon || campaign?.affiliation?.link || ""
    );

    newSuccess("Código copiado para área de transferência");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  useEffect(() => {
    const run = async () => {
      const currCampaign = await getCampaign();

      if (currCampaign) {
        Analytics.submitPageViewEvent({
          origin: "home-app-afiliados",
          creator: user.id || "",
          creatorUsername: user.username || "",
          description: `afiliados-${currCampaign.brand}`,
          actionDescription: `afiliados-${currCampaign.brand}-details`,
        });
      }
    };

    run();
  }, [getCampaign, user.id, user.username]);

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        showLiveLabel={appConfig.showIsLiveLabel}
        onBack={() => navigate(`/apps/afiliados`)}
      />

      <S.Intro>
        <S.Title color={textColor}>
          Adicione um link de afiliado em sua Stanti
        </S.Title>

        <BrandCard
          title={campaign?.title || ""}
          segment={campaign?.segment || ""}
          imageUrl={campaign?.imageUrl || ""}
          status={campaign?.affiliation?.status}
        />
      </S.Intro>

      <CampaignInfo
        discount={campaign?.campaignRules.discount}
        comission={campaign?.campaignRules.comission}
      />

      <AffiliationInfo
        disabled={isLoading}
        onCopyCode={onCopyHandler}
        campaignStatus={campaign?.status}
        onLeaveCampaign={onLeaveCampaign}
        status={campaign?.affiliation?.status}
        onApplyForCampaign={onApplyForCampaign}
        type={campaign?.campaignRules.affiliateType}
        linkOrCoupon={campaign?.affiliation?.coupon}
      />
    </Page>
  );
};

export default Details;
