import styled from "styled-components";

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NumberSelector = styled.div`
  display: flex;
  align-items: center;
`;

type InputProps = {
  textColor: string;
};

export const Input = styled.input<InputProps>`
  width: 60px;
  outline: none;
  font-size: 16px;
  min-height: 32px;
  padding: 8px 16px;
  text-align: center;
  border-radius: 40px;
  mix-blend-mode: screen;
  text-overflow: ellipsis;
  color: ${({ textColor }) => textColor};

  &::placeholder {
    font-size: 14px;
    color: #71726f;
  }
`;

export const Button = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  color: black;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  user-select: none;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: #71726f;
`;
