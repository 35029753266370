import { useState } from "react";

import { Theme } from "../../../hooks";
import { IFAQ } from "../../../types";
import CloseIcon from "../../icons/CloseIcon";
import Switcher from "../Switcher";
import TextArea from "../TextArea";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  faqs: IFAQ[];
  errors?: string[];
  onChange: (newFaq: IFAQ[]) => void;
}

const AppFormFAQ: React.FC<IProps> = ({ faqs, errors, onChange }) => {
  const [isSwitcherOn, setIsSwitcherOn] = useState(true);

  const { primaryColor, textColor } = Theme.useTheme();

  const onRemove = (index: number) => {
    const newFaqs = [...faqs];
    newFaqs.splice(index, 1);

    onChange(newFaqs);
  };

  const onChangeHandler = (
    value: string,
    index: number,
    type: "question" | "answer"
  ) => {
    const newFaqs = [...faqs];
    newFaqs[index][type] = value;

    onChange(newFaqs);
  };

  const addQuestionHandler = () => {
    const newFaqs = [...faqs];
    newFaqs.push({ question: "", answer: "" });
    onChange(newFaqs);
  };

  const onSwitcherHandler = () => {
    if (!isSwitcherOn) return setIsSwitcherOn(true);

    const isConfirmed = window.confirm(
      "Ao clicar em confirmar, todas as perguntas frequentes serão removidas. Deseja continuar?"
    );

    if (isConfirmed) {
      setIsSwitcherOn(false);
      onChange([]);
    }
  };

  return (
    <S.FormFrequentlyAskedQuestions>
      <S.Header>
        <P color={primaryColor}>Criar lista de perguntas frequentes</P>

        <Switcher isOn={isSwitcherOn} setIsOn={onSwitcherHandler} />
      </S.Header>

      {isSwitcherOn &&
        faqs.map((faq, index) => (
          <S.Faq key={`faq#${index}`}>
            <S.FaqHeader>
              <P color={textColor}>Pergunta {index + 1}</P>

              <S.RemoveButton onClick={() => onRemove(index)}>
                <CloseIcon />
              </S.RemoveButton>
            </S.FaqHeader>

            <TextArea
              rows={3}
              charLimit={200}
              value={faq.question}
              placeholder="Pergunta"
              onChange={(val: string) =>
                onChangeHandler(val, index, "question")
              }
            />

            <TextArea
              charLimit={500}
              value={faq.answer}
              placeholder="Resposta"
              onChange={(val: string) => onChangeHandler(val, index, "answer")}
            />
          </S.Faq>
        ))}

      {isSwitcherOn && (
        <S.AddFaq onClick={addQuestionHandler}>
          <div className="addIcon">
            <P color="#71726F">+</P>
          </div>

          <P color="#71726F">Adicionar uma pergunta</P>
        </S.AddFaq>
      )}

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </S.FormFrequentlyAskedQuestions>
  );
};

export default AppFormFAQ;
