import Small from "../Typography/Small";
import TextAreaWithLabel from "../../molecules/TextAreaWithLabel";

interface IProps {
  value: string;
  errors?: string[];
  onChange: (value: string) => void;
}

const AppFormAboutAuthor: React.FC<IProps> = ({ value, errors, onChange }) => {
  return (
    <>
      <TextAreaWithLabel
        value={value}
        charLimit={1000}
        onChange={onChange}
        textAreaLabel="Sobre o autor"
        placeholder="Uma breve descrição sobre quem você é e o por que você tem autoridade sobre o assunto. (Formação acadêmica, outros conteúdos publicados, números, etc)"
      />

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </>
  );
};

export default AppFormAboutAuthor;
