import styled from "styled-components";

export const ImageStep = styled.div`
  gap: 12px;
  display: flex;
  padding-top: 16px;
  flex-direction: column;
`;

export const IconOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 12px;

  .iconOption {
    width: 64px;
    height: 64px;
    background-color: #2c2c2c;
    box-shadow: 0px 2px 4px 0px #00000099 inset;
    padding: 20px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

type UploadButtonProps = {
  borderColor: string;
};

export const UploadButton = styled.div<UploadButtonProps>`
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin-top: 24px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ borderColor }) => borderColor};
  cursor: pointer;
  user-select: none;
  justify-content: space-between;

  input {
    display: none;
  }
`;

export const UploadButtonChevron = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
