import styled from "styled-components";
import { Container } from "../styles";

export const ProfileImageContainer = styled(Container)`
  position: relative;
`;

export const Text = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 400;
  }
`;

export const Form = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

type ImageProps = {
  hasProfileImage: boolean;
};

export const Image = styled.div<ImageProps>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 70px 0 20px;
  justify-content: center;

  padding: ${({ hasProfileImage }) =>
    hasProfileImage ? "50px 0 0" : "70px 0 20px"};

  .background {
    position: absolute;
  }
`;

export const Cta = styled.div`
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  small {
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    font-family: "Poppins", sans-serif;
  }

  p {
    font-size: 16px;
    font-weight: 275;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    font-family: "Poppins", sans-serif;
  }

  @media (max-width: 600px) {
    p {
      max-width: 200px;
    }
  }
`;

export const SocialMedia = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddImageManually = styled.div`
  display: none;
  cursor: pointer;
  padding-top: 8px;
  user-select: none;
  align-items: center;
  justify-content: center;

  input {
    display: none;
  }
`;
