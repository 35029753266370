import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CameraSpecialIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="36"
      viewBox="0 0 40 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4738 3.94154H29.2265L26.7828 1.281C26.0536 0.472985 24.9894 0 23.8857 0H15.5297C14.426 0 13.3618 0.472985 12.6129 1.281L10.1889 3.94154H3.94154C1.77369 3.94154 0 5.71523 0 7.88308V31.5323C0 33.7002 1.77369 35.4738 3.94154 35.4738H35.4738C37.6417 35.4738 39.4154 33.7002 39.4154 31.5323V7.88308C39.4154 5.71523 37.6417 3.94154 35.4738 3.94154ZM19.7077 29.5615C14.2684 29.5615 9.85385 25.147 9.85385 19.7077C9.85385 14.2684 14.2684 9.85385 19.7077 9.85385C25.147 9.85385 29.5615 14.2684 29.5615 19.7077C29.5615 25.147 25.147 29.5615 19.7077 29.5615ZM17.2439 17.2442L19.7073 11.8246L22.1708 17.2442L27.5904 19.7077L22.1708 22.1711L19.7073 27.5907L17.2439 22.1711L11.8242 19.7077L17.2439 17.2442Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CameraSpecialIcon;
