export const emptyCalendar = {
  monday: { isSelected: false, to: "", from: "" },
  tuesday: { isSelected: false, to: "", from: "" },
  wednesday: { isSelected: false, to: "", from: "" },
  thursday: { isSelected: false, to: "", from: "" },
  friday: { isSelected: false, to: "", from: "" },
  saturday: { isSelected: false, to: "", from: "" },
  sunday: { isSelected: false, to: "", from: "" },
};

export const emptyLunchTime = {
  to: "",
  from: "",
  isSelected: false,
};
