import Small from "../Typography/Small";
import InputWithLabel from "../../molecules/InputWithLabel";

interface IProps {
  value: string;
  errors?: string[];
  placeholder: string;
  onChange: (val: string) => void;
  charLimit?: number;
  inputLabel?: string;
}

const AppFormTitle: React.FC<IProps> = ({
  value,
  errors,
  onChange,
  placeholder,
  inputLabel = "Título",
  charLimit = 50,
}) => {
  return (
    <>
      <InputWithLabel
        autofocus
        value={value}
        inputLabel={inputLabel}
        placeholder={placeholder}
        charLimit={charLimit}
        onChange={onChange}
      />

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </>
  );
};

export default AppFormTitle;
