import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const WorldIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 0C9.856 0 0 9.856 0 22C0 34.144 9.856 44 22 44C34.144 44 44 34.144 44 22C44 9.856 34.144 0 22 0ZM19.8 39.446C11.11 38.368 4.4 30.976 4.4 22C4.4 20.636 4.576 19.338 4.862 18.062L15.4 28.6V30.8C15.4 33.22 17.38 35.2 19.8 35.2V39.446ZM30.8 30.8001C32.78 30.8001 34.408 32.0761 34.98 33.8581C37.84 30.7341 39.6 26.5761 39.6 22.0001C39.6 14.6301 35.046 8.31606 28.6 5.69806V6.60006C28.6 9.02006 26.62 11.0001 24.2 11.0001H19.8V15.4001C19.8 16.6101 18.81 17.6001 17.6 17.6001H13.2V22.0001H26.4C27.61 22.0001 28.6 22.9901 28.6 24.2001V30.8001H30.8Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default WorldIcon;
