import { Theme } from "../../../hooks";
import CheckCircleIcon from "../../icons/CheckCircleIcon";
import Chevron from "../../icons/Chevron";
import MercadoPagoLogo from "../../icons/mercado-pago-logo.png";
import P from "../Typography/P";

import * as S from "./styles";

interface IProps {
  onClick: () => void;
  hasLinkedAccount: boolean;
}

const MercadoPagoButton: React.FC<IProps> = ({ hasLinkedAccount, onClick }) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.MercadoPagoButton onClick={onClick}>
      <div className="mercadoPagoLogo">
        <img src={MercadoPagoLogo} alt="" />
      </div>

      <S.ButtonLabel>
        <P color={textColor}>
          {hasLinkedAccount ? "Conta vinculada" : "Vincular conta"}
        </P>

        <S.Icon>
          {hasLinkedAccount ? (
            <CheckCircleIcon color="#fafafa" />
          ) : (
            <Chevron color="#fafafa" />
          )}
        </S.Icon>
      </S.ButtonLabel>
    </S.MercadoPagoButton>
  );
};

export default MercadoPagoButton;
