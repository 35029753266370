import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CoffeeIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.8889 0H4.11111C1.85 0 0 1.85 0 4.11111V20.5556C0 25.0983 3.67944 28.7778 8.22222 28.7778H20.5556C25.0983 28.7778 28.7778 25.0983 28.7778 20.5556V14.3889H32.8889C35.1706 14.3889 37 12.5389 37 10.2778V4.11111C37 1.85 35.15 0 32.8889 0ZM32.8889 10.2778H28.7778V4.11111H32.8889V10.2778ZM30.8333 32.8889H2.05556C0.925 32.8889 0 33.8139 0 34.9444C0 36.075 0.925 37 2.05556 37H30.8333C31.9639 37 32.8889 36.075 32.8889 34.9444C32.8889 33.8139 31.9639 32.8889 30.8333 32.8889Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CoffeeIcon;
