import { Navigate, Route } from "react-router-dom";

import Form from "./components/Form";

const AppMentoria = () => {
  return (
    <Route path="mentoria">
      <Route path="new" element={<Form />} />
      <Route path=":id" element={<Form />} />
      <Route index path="*" element={<Navigate to="/apps/mentoria/new" />} />
    </Route>
  );
};

export default AppMentoria;
