import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AheadButton from "../../../../components/atoms/AheadButton";
import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import AppImageSelector from "../../../../components/atoms/AppImageSelector";
import Page from "../../../../components/atoms/Page";
import appConfig from "../../../../config/app-link-to-post-instagram.json";
import { Auth, Loading, NavBar, Snackbar, Theme } from "../../../../hooks";
import { Instagram } from "../../../../services";
import { IInstagramPosts, ILinkToPostInstagram } from "../../../../types";

import * as S from "./styles";

const {
  REACT_APP_INSTAGRAM_BASE_URL,
  REACT_APP_INSTAGRAM_CLIENT_ID,
  REACT_APP_INSTAGRAM_URL_REDIRECT,
} = process.env;

const Form: React.FC = () => {
  const [posts, setPosts] = useState<IInstagramPosts[]>([]);
  const [currInstaUsername, setCurrInstaUsername] = useState<string>("");
  const [currentPosts, setCurrentPosts] = useState<ILinkToPostInstagram[]>([]);

  const navigate = useNavigate();
  const { token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { hideNavBar, showNavBar } = NavBar.useNavBar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { textColor } = Theme.useTheme();

  useEffect(() => {
    hideNavBar();

    const getPostsFromInstagram = async (code: string) => {
      showLoading();
      try {
        const [{ posts: postsFromInstagram }, { posts: currentPosts }] =
          await Promise.all([
            Instagram.getPostsFromInstagram(code, token),
            Instagram.getPosts(token),
          ]);

        setPosts(postsFromInstagram);
        setCurrInstaUsername(postsFromInstagram[0].username);

        if (currentPosts) setCurrentPosts(currentPosts);
      } catch (error) {
        newError("Houve um erro ao obter os posts");
        navigate(`/apps/${appConfig.id}`);
      } finally {
        hideLoading();
      }
    };
    if (window.location.search && window.location.search !== "?") {
      const { code } = window.location.search
        .substring(1)
        .split("&")
        .reduce(
          (acc, curr) => {
            const [key, ...value] = curr.split("=");
            return { ...acc, [key]: value.join("=") };
          },
          { code: "" }
        );

      if (code) {
        getPostsFromInstagram(code);
      } else {
        newError("Não foi possível conectar à conta do Instagram");
        navigate(`/apps/${appConfig.id}`);
      }
    } else {
      const params = [
        `client_id=${REACT_APP_INSTAGRAM_CLIENT_ID}`,
        `redirect_uri=${REACT_APP_INSTAGRAM_URL_REDIRECT}`,
        `scope=user_profile,user_media`,
        `response_type=code`,
      ].join("&");

      const url = `${REACT_APP_INSTAGRAM_BASE_URL}?${params}`;

      window.location.href = url;
    }

    return () => showNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectHandler = (index: number) => {
    setPosts((curr) => {
      const newPosts = [...curr];

      newPosts[index].isUsed = !newPosts[index].isUsed;

      return newPosts;
    });
  };

  const selectedPostsAmount = posts.filter((i) => i.isUsed).length;

  const onSaveHandler = async () => {
    showLoading();

    try {
      const postsDiffOrWithoutUser: ILinkToPostInstagram[] =
        currentPosts.filter((post) => post.username !== currInstaUsername);

      const postsId: string[] = posts.map((p) => {
        return p.id;
      });

      const postsDiffUser: ILinkToPostInstagram[] =
        postsDiffOrWithoutUser.filter((post) => !postsId.includes(post.id));

      const selectedPostsCurrUser: ILinkToPostInstagram[] = posts
        .filter((post) => post.isUsed)
        .map((post) => {
          const currentPostIndex = currentPosts.findIndex(
            (p) => p.id === post.id
          );

          if (currentPostIndex >= 0) {
            if (currentPosts[currentPostIndex].username === undefined) {
              return {
                ...currentPosts[currentPostIndex],
                username: currInstaUsername,
              };
            }
            return currentPosts[currentPostIndex];
          }

          return {
            link: null,
            id: post.id,
            url: post.media_url,
            postUrl: post.permalink,
            username: post.username,
          };
        });

      const selectedPosts = postsDiffUser.concat(selectedPostsCurrUser);

      await Instagram.savePostsSelected(token, selectedPosts);

      navigate(`/apps/${appConfig.id}`);
    } catch (error) {
      newError("Houve um erro ao salvar as publicações");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <AppFormHeader
        appTitle="Posts do Instagram"
        onBack={() => navigate(`/apps/${appConfig.id}`)}
      />

      <S.Description color={textColor}>
        {selectedPostsAmount > 0
          ? "Selecione abaixo quais posts deseja exibir na sua Stanti."
          : "Selecione abaixo quais posts deseja exibir na sua Stanti, em seguida, clique em avançar."}
      </S.Description>

      <S.Images>
        {posts.map((item, index) => (
          <AppImageSelector
            key={item.id}
            images={item.media_url}
            isSelected={item.isUsed}
            onClick={() => onSelectHandler(index)}
          />
        ))}
      </S.Images>

      {selectedPostsAmount > 0 && (
        <AheadButton
          onClick={onSaveHandler}
          selectedPostsAmount={selectedPostsAmount}
        />
      )}
    </Page>
  );
};

export default Form;
