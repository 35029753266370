import styled from "styled-components";

export const DefinedDatesSelected = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 80px;
    font-size: 14px;
    min-height: 40px;
    color: #fafafa;
    padding: 8px 16px;
    text-align: center;
    border-radius: 40px;
    mix-blend-mode: screen;
    background-color: #fafafa18;

    &::placeholder {
      font-size: 14px;
      color: #71726f;
    }
  }
`;

export const DateInputs = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const DateRow = styled.div`
  width: 100%;
  display: flex;
  color: #fafafa;
  align-items: center;
  justify-content: space-between;
`;

export const Inputs = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
`;

export const RemoveDate = styled.div`
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const AddDateButton = styled.div`
  gap: 12px;
  display: flex;
  padding: 16px;
  cursor: pointer;
  margin-top: 24px;
  user-select: none;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  border: 1px dashed #71726f;

  .addIcon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    border: 1px solid #71726f;
  }
`;

export const InputsRow = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: space-between;
`;

export const InputsContainer = styled.div`
  gap: 4px;
  display: flex;
  color: #fafafa;
  align-items: center;

  input {
    width: 64px;
  }
`;
