import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PrivateGroupsIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="58"
      height="47"
      viewBox="0 0 58 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: "overlay" }}>
        <path
          d="M51.5127 6.45551C48.0095 2.75329 43.2696 0.642732 38.1767 0.508149C27.6645 0.230358 18.8844 8.55836 18.6066 19.0706C18.5179 22.4286 19.3072 25.7325 20.8969 28.6709L17.9305 38.476L28.1092 36.0893C30.8517 37.683 33.9653 38.5646 37.1602 38.649L37.1688 38.6492C47.6724 38.9268 56.6414 30.6038 56.9192 20.0916C57.0538 14.9987 55.016 10.1577 51.5127 6.45551V6.45551ZM37.2537 35.4372C34.4023 35.3618 31.6313 34.5237 29.2389 33.0166L28.6726 32.6579L22.6363 34.0711L24.3883 28.2647L24.0263 27.6535C22.5042 25.0865 21.7481 22.153 21.8273 19.1557C22.0581 10.4213 29.3572 3.49799 38.1002 3.72903C42.3343 3.84091 46.2671 5.59497 49.1767 8.6713C52.0862 11.7476 53.8191 15.7726 53.6986 20.0065C53.4675 28.7495 45.9881 35.668 37.2537 35.4372V35.4372ZM46.2588 23.7977C45.7928 23.5447 43.4786 22.3319 43.0446 22.1658C42.6109 21.991 42.2952 21.9053 41.9647 22.3779C41.6342 22.8504 40.6957 23.8913 40.4037 24.2102C40.1205 24.5207 39.8359 24.5561 39.3699 24.3032C36.6071 22.8293 34.7982 21.6814 33.0355 18.4635C32.5682 17.6089 33.5456 17.6949 34.5041 15.8982C34.6671 15.5845 34.5971 15.3076 34.4832 15.064C34.3693 14.8203 33.478 12.4505 33.1081 11.4868C32.7461 10.549 32.3477 10.6674 32.0561 10.6425C31.7817 10.6181 31.464 10.6097 31.1462 10.6013C30.8284 10.5929 30.3099 10.6995 29.8594 11.1603C29.4087 11.6296 28.1502 12.748 28.0882 15.0927C28.0263 17.4373 29.6754 19.7498 29.8989 20.0737C30.131 20.3978 33.1215 25.2897 37.8505 27.4859C40.8392 28.8713 42.0214 29.0142 43.5389 28.8309C44.4615 28.7177 46.3863 27.7545 46.8108 26.6484C47.2354 25.5424 47.2606 24.5891 47.1456 24.3883C47.0396 24.1707 46.725 24.042 46.2588 23.7977Z"
          fill="url(#paint0_linear_1_4)"
        />
      </g>
      <path
        d="M0.000363616 19.0282C0.304699 20.4414 0.611769 21.8547 0.91246 23.2689C2.13891 29.0403 3.36263 34.8117 4.58909 40.5823C4.87429 41.9254 5.15676 43.2694 5.45471 44.6097C5.85017 46.3893 7.10031 46.8458 8.58372 45.7542C11.0703 43.9245 13.5561 42.0939 16.0308 40.2479C16.306 40.0429 16.4509 40.052 16.6924 40.2925C17.9061 41.4989 19.1425 42.6816 20.3672 43.8771C20.6879 44.1897 21.0387 44.4402 21.4861 44.5304C21.713 44.576 21.8551 44.5623 21.8333 44.2516C21.6811 42.0484 21.5444 39.8433 21.4023 37.6391C21.3968 37.5489 21.3594 37.4551 21.3749 37.3694C21.5262 36.5302 21.0205 36.0582 20.4574 35.5544C15.7967 31.3848 11.1542 27.1942 6.50987 23.0073C6.3495 22.8625 6.08343 22.7039 6.22649 22.457C6.3659 22.2164 6.64472 22.2893 6.88892 22.3531C7.1887 22.4315 7.4411 22.5928 7.69714 22.754C13.567 26.4607 19.4396 30.1638 25.3021 33.8823C25.652 34.1047 25.9372 34.1557 26.3391 34.0245C28.8066 33.219 31.2841 32.4436 33.7588 31.6609C34.0778 31.5597 34.3903 31.4486 34.6491 31.2299C35.081 30.8654 35.1165 30.4362 34.7429 30.0034C34.3976 29.6034 33.9438 29.3583 33.4609 29.1706C30.2244 27.9141 26.986 26.6612 23.7458 25.412C16.6623 22.6793 9.576 19.9494 2.48972 17.2186C1.16577 16.7083 0.435909 17.0801 0.0568566 18.4532C0.0450112 18.4952 0.0194985 18.5334 0.000363616 18.5744V19.0282Z"
        fill="url(#paint1_linear_1_4)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_4"
          x1="38.1853"
          y1="0.508376"
          x2="37.1685"
          y2="38.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || primaryColor} />
          <stop
            offset="1"
            stopColor={color || primaryColor}
            stopOpacity="0.5"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_4"
          x1="17.5002"
          y1="17"
          x2="17.5002"
          y2="53.0196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || primaryColor} />
          <stop offset="1" stopColor={color || primaryColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PrivateGroupsIcon;
