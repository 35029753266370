import { useEffect, useState } from "react";

import { Auth } from "../../../../hooks";
import Banner from "../../../../components/atoms/Banner";
import { Analytics, Creator } from "../../../../services";
import { IBanner, ICreatorData, TIconOptions } from "../../../../types";
import ExternalLinkSkeleton from "../../../../components/atoms/ExternalLinkSkeleton";

import * as S from "./styles";

const Banners = () => {
  const [loading, setLoading] = useState(true);
  const { user, token, setUserHandler } = Auth.useAuth();
  const [homeBanners, setHomeBanners] = useState<IBanner[]>([]);

  const onClose = async (id: string) => {
    const userHiddenBanners = user.hiddenBanners || [];

    setHomeBanners((curr) => curr.filter((banner) => banner.id !== id));

    const payload: Pick<ICreatorData, "hiddenBanners"> = {
      hiddenBanners: [...userHiddenBanners, id],
    };

    Analytics.submitClickEvent({
      creator: user.id || "",
      origin: document.referrer,
      creatorUsername: user.username || "",
      description: `close-banner-${id}`,
      actionDescription: `close-banner-${id}`,
    });

    const uptadedCreator = await Creator.updateProfile(payload, token);
    setUserHandler(uptadedCreator);
  };

  useEffect(() => {
    (async () => {
      const banners = await Creator.getUserBanners("home", token);
      setHomeBanners(banners);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!homeBanners || !homeBanners.length) return null;

  return (
    <S.Container>
      {loading && <ExternalLinkSkeleton />}

      {homeBanners.map((banner) => (
        <Banner
          id={banner.id}
          key={banner.id}
          onClose={onClose}
          image={banner.image}
          link={banner.href || ""}
          label={banner.label || ""}
          icon={banner.icon as TIconOptions}
          isExternalLink={banner.isExternalLink}
        />
      ))}
    </S.Container>
  );
};

export default Banners;
