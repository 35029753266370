import { Theme } from "../../../hooks";
import { normalizeNumber } from "../../../utils/numbers";
import Label from "../../atoms/Typography/Label";
import P from "../../atoms/Typography/P";
import Small from "../../atoms/Typography/Small";

import * as S from "./styles";

interface IProps {
  totalValue: number;
  monthValue: number;
}

const Income: React.FC<IProps> = ({ totalValue, monthValue }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  const totalValueNorm = normalizeNumber(totalValue);
  const monthValueNorm = normalizeNumber(monthValue);

  return (
    <S.Income>
      <Small color={textColor}>Faturamento</Small>

      <S.IncomeCard>
        <S.ThisMonthIncome>
          <S.LabelText color={textColor}>Este mês</S.LabelText>

          <S.CoinSign color={primaryColor}>R$</S.CoinSign>

          <S.ValueHeading color={primaryColor}>{monthValueNorm}</S.ValueHeading>
        </S.ThisMonthIncome>

        {parseFloat(totalValueNorm) > 0 && (
          <S.TotalValue>
            <Label color={"#71726F"}>Total (R$)</Label>
            <P color={textColor}>{totalValueNorm}</P>
          </S.TotalValue>
        )}
      </S.IncomeCard>
    </S.Income>
  );
};

export default Income;
