import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ArrowLeftIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4606 28.3764L2.2315 18.1473C0.500424 16.4162 0.501036 13.5829 2.23287 11.8511L12.4664 1.61756C13.7731 0.31082 15.8824 0.310364 17.1886 1.61654C18.4947 2.92272 18.4943 5.03195 17.1875 6.33869L12.0393 11.487L30.4871 11.7663C32.25 11.7974 33.6663 13.2452 33.666 15.0081C33.6656 16.7711 32.2329 18.2038 30.4699 18.2356L11.9119 18.3813L17.1838 23.6532C18.49 24.9594 18.4895 27.0687 17.1828 28.3754C15.876 29.6821 13.7668 29.6826 12.4606 28.3764Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ArrowLeftIcon;
