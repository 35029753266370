import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const NightIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3009 1.01808C18.688 1.70485 18.6355 2.55491 18.1669 3.18885C16.6761 5.20564 15.9588 7.69051 16.1453 10.1915C16.3318 12.6925 17.4098 15.0435 19.1832 16.8169C20.9565 18.5903 23.3075 19.6682 25.8085 19.8547C28.3095 20.0412 30.7944 19.3239 32.8112 17.8332C33.4451 17.3646 34.2952 17.3121 34.982 17.6991C35.6687 18.0862 36.0641 18.8405 35.9915 19.6255C35.6763 23.0364 34.3962 26.2869 32.301 28.9968C30.2059 31.7066 27.3823 33.7638 24.1606 34.9275C20.939 36.0911 17.4525 36.3132 14.1092 35.5678C10.766 34.8223 7.70415 33.1401 5.28204 30.718C2.85994 28.2959 1.17773 25.2341 0.432261 21.8908C-0.313209 18.5475 -0.0911089 15.0611 1.07257 11.8394C2.23626 8.61778 4.29339 5.79416 7.00327 3.69899C9.71314 1.60381 12.9637 0.323725 16.3745 0.00851944C17.1595 -0.0640225 17.9139 0.331317 18.3009 1.01808ZM12.8269 4.93375C11.6231 5.41473 10.4858 6.06257 9.44992 6.86346C7.3411 8.49392 5.74025 10.6912 4.83467 13.1983C3.9291 15.7054 3.75626 18.4185 4.33639 21.0203C4.91651 23.622 6.2256 26.0047 8.11047 27.8896C9.99535 29.7744 12.378 31.0835 14.9798 31.6636C17.5815 32.2438 20.2946 32.0709 22.8017 31.1654C25.3088 30.2598 27.5061 28.6589 29.1366 26.5501C29.9375 25.5143 30.5853 24.3769 31.0663 23.1731C29.2896 23.7514 27.4037 23.9848 25.5111 23.8437C22.0547 23.5859 18.8056 22.0961 16.3547 19.6453C13.9039 17.1945 12.4141 13.9454 12.1564 10.489C12.0152 8.59637 12.2486 6.71045 12.8269 4.93375Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default NightIcon;
