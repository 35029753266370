import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const AtIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0539207 22.5055C-0.849182 9.71502 9.71502 -0.849182 22.5055 0.0539207C33.6157 0.810007 41.9957 10.5131 41.9957 21.6654V23.9546C41.9957 26.979 40.2735 29.8563 37.4592 30.9694C34.0358 32.3346 30.2974 30.9904 28.4281 28.4281C26.0129 30.8854 22.3375 32.1246 18.473 31.2215C14.5036 30.2764 11.3742 26.958 10.6601 22.9465C9.37898 15.7637 15.4067 9.589 22.5475 10.6181C27.7561 11.3532 31.4945 16.0997 31.4945 21.3713V23.9966C31.4945 25.6558 32.9857 27.294 34.6449 27.294C36.304 27.294 37.7952 25.6558 37.7952 23.9966V21.6864C37.7952 13.8525 32.7126 6.66967 25.1098 4.73745C12.9284 1.58709 1.58709 12.9494 4.71645 25.1308C6.66967 32.7126 13.8525 37.7952 21.6864 37.7952H29.3943C30.5494 37.7952 31.4945 38.7403 31.4945 39.8954C31.4945 41.0506 30.5494 41.9957 29.3943 41.9957H21.6444C10.5131 41.9957 0.810007 33.6157 0.0539207 22.5055ZM14.693 20.9931C14.693 24.4795 17.5073 27.2938 20.9937 27.2938C24.4801 27.2938 27.2944 24.4795 27.2944 20.9931C27.2944 17.5067 24.4801 14.6924 20.9937 14.6924C17.5073 14.6924 14.693 17.5067 14.693 20.9931Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default AtIcon;
