import styled, { css } from "styled-components";
import P from "../Typography/P";

type FormatSelectorProps = {
  isDisabled?: boolean;
};

export const FormatSelector = styled.div<FormatSelectorProps>`
  ${({ isDisabled }) => css`
    z-index: 1;
    display: flex;
    cursor: pointer;
    user-select: none;
    position: relative;
    opacity: ${isDisabled ? 0.6 : 1};
    cursor: ${isDisabled ? "not-allowed" : "pointer"};

    &::before {
      content: "";
      width: 100%;
      z-index: -1;
      height: 64px;
      border-bottom: 1px solid #fafafa20;
      border-radius: 20px;
      position: absolute;
      background: linear-gradient(
        90deg,
        rgba(250, 250, 250, 0.4) -140%,
        rgba(113, 114, 111, 0) 120%
      );
    }

    .indicator {
      width: 40px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
        transform: rotateZ(90deg);
      }
    }
  `}
`;

export const FormatList = styled.div`
  width: 100%;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(250, 250, 250, 0.1) 100%
  );
`;

export const SelectedFormat = styled.div`
  gap: 16px;
  height: 64px;
  display: flex;
  padding-left: 10px;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 48px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`;

type IconProps = {
  borderColor: string;
  width: number;
  height: number;
};

export const Icon = styled.span<IconProps>`
  ${({ borderColor, width, height }) => css`
    border: 1px solid ${borderColor};
    border-radius: 4px;
    width: ${width}px;
    height: ${height}px;
  `}
`;

export const FormatItem = styled.div`
  gap: 16px;
  height: 64px;
  display: flex;
  cursor: pointer;
  user-select: none;
  padding-left: 10px;
  align-items: center;
  padding-right: 12px;
`;

export const FormatTitle = styled(P)`
  flex-grow: 1;
`;
