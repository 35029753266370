import * as S from "./styles";

const ExternalLinkSkeleton: React.FC = () => {
  return (
    <S.ExternalLinkSkeleton>
      <S.ExternalLink />
      <S.ExternalLink />
      <S.ExternalLink />
    </S.ExternalLinkSkeleton>
  );
};

export default ExternalLinkSkeleton;
