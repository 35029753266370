import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const GiftIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9 4C11.237 4 10.6011 4.26339 10.1322 4.73223C9.66339 5.20107 9.4 5.83696 9.4 6.5C9.4 7.16304 9.66339 7.79893 10.1322 8.26777C10.6011 8.73661 11.237 9 11.9 9H17.2839C17.0856 8.50792 16.8457 7.98325 16.5601 7.46384C15.4396 5.42669 13.9378 4 11.9 4ZM18 13V18H4V13H18ZM5.9 9C5.57305 8.21531 5.4 7.36657 5.4 6.5C5.4 4.77609 6.08482 3.12279 7.30381 1.90381C8.52279 0.684819 10.1761 0 11.9 0C16.095 0 18.6296 2.97721 20 5.41929C21.3704 2.97721 23.905 0 28.1 0C29.8239 0 31.4772 0.684819 32.6962 1.90381C33.9152 3.12279 34.6 4.77609 34.6 6.5C34.6 7.36657 34.427 8.21531 34.1 9H38C39.1046 9 40 9.89543 40 11V20C40 21.1046 39.1046 22 38 22H36.4V38C36.4 39.1046 35.5046 40 34.4 40H5.6C4.49543 40 3.6 39.1046 3.6 38V22H2C0.89543 22 0 21.1046 0 20V11C0 9.89543 0.89543 9 2 9H5.9ZM7.6 22V36H18V22H7.6ZM22 22V36H32.4V22H22ZM36 18H22V13H36V18ZM28.1 9C28.763 9 29.3989 8.73661 29.8678 8.26777C30.3366 7.79893 30.6 7.16304 30.6 6.5C30.6 5.83696 30.3366 5.20107 29.8678 4.73223C29.3989 4.26339 28.763 4 28.1 4C26.0622 4 24.5604 5.42669 23.4399 7.46384C23.1543 7.98325 22.9144 8.50792 22.7161 9H28.1Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default GiftIcon;
