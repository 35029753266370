import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PizzaIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="35"
      height="38"
      viewBox="0 0 35 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0037 0C11.0174 0 5.55167 2.16228 1.32722 5.76608C-0.0942827 6.98736 -0.394599 9.06956 0.526371 10.6913L15.2619 36.9389C16.0227 38.3004 17.9848 38.3004 18.7456 36.9389L33.4811 10.6913C34.4021 9.06956 34.1018 6.96734 32.6803 5.76608C28.4758 2.1823 22.99 0 17.0037 0ZM6.99416 10.0106C6.99416 7.80825 8.79606 6.00635 10.9984 6.00635C13.2007 6.00635 15.0026 7.80825 15.0026 10.0106C15.0026 12.2129 13.2007 14.0148 10.9984 14.0148C8.79606 14.0148 6.99416 12.2129 6.99416 10.0106ZM13.0001 22.0233C13.0001 24.2256 14.802 26.0275 17.0043 26.0275C19.2066 26.0275 21.0085 24.2256 21.0085 22.0233C21.0085 19.8209 19.2066 18.019 17.0043 18.019C14.802 18.019 13.0001 19.8209 13.0001 22.0233Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PizzaIcon;
