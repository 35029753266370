import styled from "styled-components";

export const Container = styled.div`
  z-index: 10;
  width: 100%;
  flex-grow: 1;
  display: flex;
  max-width: 960px;
  flex-direction: column;
`;

export const QualificationStepProgressBar = styled.div`
  gap: 18px;
  display: flex;
  padding-top: 80px;
  flex-direction: column;
`;

export const Highlight = styled.span`
  font-weight: 500;
`;

export const Icon = styled.div`
  z-index: 100;
  display: flex;
  position: relative;
  margin: 40px 0 16px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const CallToAction = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  small {
    font-size: 10px;
    font-weight: 275;
    line-height: 15px;
    font-style: normal;
    font-family: "Poppins";

    &:first-of-type {
      font-weight: 300;
    }
  }

  p {
    font-size: 16px;
    font-weight: 275;
    max-width: 220px;
    line-height: 24px;
    font-style: normal;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
`;

export const Answers = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 22px;
  flex-direction: column;
`;
