import SeparatorLine from "../../../../components/atoms/SeparatorLine";
import P from "../../../../components/atoms/Typography/P";
import Small from "../../../../components/atoms/Typography/Small";
import CalendarIcon from "../../../../components/icons/CalendarIcon";
import { Auth, Theme } from "../../../../hooks";
import { Analytics } from "../../../../services";

import GoogleCalendar from "../../../../components/assets/Google_Calendar_icon.png";
import ICloud from "../../../../components/assets/Icloud_icon.png";
import Outlook from "../../../../components/assets/Outlook_icon.png";
import ModalBluredBackdrop from "../../../../components/atoms/ModalBluredBackdrop";
import { IAppointments } from "../../../../types/calendar";
import {
  getAppleICalendarUrl,
  getGoogleCalendarUrl,
  getOutlookAgendaUrl,
} from "./utils";

import * as S from "./styles";

interface IProps {
  isOpen: boolean;
  onCloseModal: () => void;
  appointment: IAppointments | null;
}

// const iCloudURL = "";

const CalendarAppointmentModal: React.FC<IProps> = ({
  isOpen,
  appointment,
  onCloseModal,
}) => {
  const { user } = Auth.useAuth();
  const { primaryColor, textColor } = Theme.useTheme();

  const onClickHandler = (type: string) => {
    Analytics.submitClickEvent({
      origin: "calendar",
      meta: { type: type },
      creator: user.id || "",
      creatorUsername: user.username || "",
      description: "appointment-to-external-email",
      actionDescription: `appointment-to-external-email-${type}`,
    });
  };

  if (!isOpen) return null;

  const googleUrl = getGoogleCalendarUrl({
    location: appointment?.callUrl,
    endAt: appointment?.endAt || "",
    startAt: appointment?.startAt || "",
    productTitle: appointment?.title || "",
    customerName: appointment?.customerName || "",
  });

  const outlookUrl = getOutlookAgendaUrl({
    location: appointment?.callUrl,
    endAt: appointment?.endAt || "",
    startAt: appointment?.startAt || "",
    productTitle: appointment?.title || "",
    customerName: appointment?.customerName || "",
  });

  const iCalendarUrl = getAppleICalendarUrl({
    location: appointment?.callUrl,
    endAt: appointment?.endAt || "",
    startAt: appointment?.startAt || "",
    productTitle: appointment?.title || "",
    customerName: appointment?.customerName || "",
  });

  return (
    <ModalBluredBackdrop onClickOutside={onCloseModal}>
      <S.Container onClick={(evt) => evt.stopPropagation()}>
        <div className="icon">
          <CalendarIcon color="#ffffff80" />
        </div>

        <S.Texts>
          <P color={textColor}>Adicionar no seu calendário</P>

          <S.Subtitle color={textColor}>
            Você pode adicionar eventos da sua agenda Stanti no seu calendário
            particular!
          </S.Subtitle>

          <S.ChooseCalendarText color={primaryColor}>
            Escolha abaixo qual calendário deseja adicionar este evento:
          </S.ChooseCalendarText>
        </S.Texts>

        <S.Calendars>
          <S.Calendar
            target="_blank"
            href={outlookUrl}
            rel="noopener noreferrer"
            onClick={() => onClickHandler("outlook")}
          >
            <div className="calendarIcon">
              <img src={Outlook} />
            </div>

            <Small color={textColor}>Calendário do Outlook</Small>
          </S.Calendar>

          <S.Calendar
            target="_blank"
            href={googleUrl}
            rel="noopener noreferrer"
            onClick={() => onClickHandler("google-agenda")}
          >
            <div className="calendarIcon">
              <img src={GoogleCalendar} />
            </div>

            <Small color={textColor}>Google Agenda</Small>
          </S.Calendar>

          <S.Calendar
            target="_blank"
            href={iCalendarUrl}
            rel="noopener noreferrer"
            onClick={() => onClickHandler("icloud")}
            download={`${appointment?.customerName}-${appointment?.title}.ics`
              .toLowerCase()
              .replace(/[^a-z0-9-.]/g, "")}
          >
            <div className="calendarIcon">
              <img src={ICloud} />
            </div>

            <Small color={textColor}>Calendário do iCloud</Small>
          </S.Calendar>
        </S.Calendars>

        <SeparatorLine />

        <S.WarningText color={textColor}>
          <S.Atention>Atenção:</S.Atention> Excluir um evento da sua agenda
          Stanti não removerá o evento do seu calendário particular
        </S.WarningText>
      </S.Container>
    </ModalBluredBackdrop>
  );
};

export default CalendarAppointmentModal;
