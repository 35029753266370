import styled from "styled-components";

export const UploadSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    text-align: center;
  }
`;
