import styled, { css } from "styled-components";
import Small from "../Typography/Small";

type TextAreaContainerProps = {
  isDisabled: boolean;
};

export const TextAreaContainer = styled.div<TextAreaContainerProps>`
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 12px 16px;
  border-radius: 20px;
  flex-direction: row;
  mix-blend-mode: screen;
  background-color: #fafafa18;
  justify-content: flex-start;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
      color: #71726f;
      border-color: #71726f !important;
    `}
`;

type TextAreaProps = {
  isDisabled: boolean;
  textColor: string;
};

export const TextArea = styled.textarea<TextAreaProps>`
  ${({ isDisabled, textColor }) => css`
    flex-grow: 1;
    resize: none;
    outline: none;
    font-size: 14px;
    margin-right: 4px;
    color: ${textColor};

    &::placeholder {
      font-size: 14px;
      color: #71726f;
    }

    ${isDisabled &&
    css`
      color: #71726f !important;
    `}
  `}
`;

export const RightBar = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  justify-content: space-between;
`;

export const CharLimit = styled(Small)`
  font-size: 10px;
`;
