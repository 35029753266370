import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SocialMediaIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();

  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0662 0C22.5394 0 29.0058 6.96647 28.4763 15.3149C28.0582 21.7737 22.9297 27.3723 16.6026 28.303C13.2022 28.8107 9.99687 28.2748 7.01452 26.498C6.70792 26.3146 6.4292 26.3005 6.09473 26.4275C5.09133 26.7941 4.07398 27.1185 3.07058 27.471C2.44345 27.6966 1.84419 27.7249 1.32855 27.2172C0.812912 26.7095 0.826848 26.1031 1.04983 25.4685C1.41217 24.4531 1.76057 23.4378 2.12291 22.4224C2.2344 22.1122 2.20653 21.8584 2.02536 21.5622C-2.94986 13.2419 1.64908 2.36916 11.3626 0.296145C12.3103 0.0987151 13.2858 0.0141022 14.0662 0ZM21.536 13.3971C21.55 11.1971 20.6163 9.73049 19.139 9.09589C17.606 8.43309 15.8501 8.79975 14.6655 10.0548C14.345 10.3933 14.2195 10.4497 13.8572 10.0548C12.1152 8.12285 9.0074 8.40489 7.61378 10.6189C6.61037 12.1984 6.86122 13.8201 7.64165 15.3714C9.06314 18.1636 11.4323 20.011 13.9687 21.6609C14.1638 21.7878 14.331 21.8019 14.5401 21.6609C16.7281 20.2507 18.7488 18.6572 20.24 16.4713C20.9925 15.3714 21.5082 14.1868 21.5221 13.3971H21.536Z"
        fill={color || primaryColor}
      />
      <path
        d="M30.3712 45C23.7375 44.9294 18.0516 40.1488 16.7276 33.2246C16.5604 32.3221 16.5325 31.4055 16.5046 30.4888C16.4907 29.9106 16.6719 29.685 17.2433 29.5722C20.6437 28.9376 23.5006 27.3017 25.8419 24.7351C27.793 22.6057 29.0751 20.0955 29.6047 17.2469C29.7162 16.6546 29.9391 16.4995 30.4966 16.4854C38.5517 16.2315 45.269 23.057 44.9902 31.2503C44.9066 33.6477 44.2795 35.8759 43.0531 37.9207C42.8301 38.3014 42.7883 38.6117 42.9416 39.0206C43.3318 40.0219 43.6384 41.0513 44.0008 42.0667C44.2098 42.6449 44.1541 43.1808 43.736 43.6461C43.304 44.1256 42.7326 44.2102 42.1333 44.0128C41.1439 43.6884 40.1544 43.3641 39.1788 42.9833C38.6632 42.7859 38.2451 42.8141 37.7574 43.1103C35.5833 44.3936 33.2142 44.9717 30.3712 44.9858V45ZM28.8939 32.5195C28.8939 33.831 28.8939 35.1566 28.8939 36.4681C28.8939 36.8912 29.0054 37.0322 29.4374 37.0322C31.6254 37.004 33.7995 37.0322 35.9875 37.0181C37.0884 37.0181 37.8131 36.454 38.05 35.3964C38.3845 33.9298 38.6911 32.4631 38.9419 30.9824C39.1788 29.5581 38.3009 28.5568 36.8654 28.5427C36.1268 28.5427 35.3743 28.5427 34.6356 28.5427C34.2454 28.5427 34.1479 28.4017 34.2454 28.035C34.3709 27.5556 34.5102 27.0902 34.6078 26.6107C34.9144 25.1159 34.0225 23.7339 32.5731 23.4236C32.2247 23.3531 32.0296 23.3672 31.946 23.8044C31.7927 24.5095 31.5557 25.2005 31.3746 25.9056C31.0819 26.9774 30.4827 27.753 29.3817 28.0209C28.9915 28.1197 28.8939 28.3453 28.9079 28.7261C28.9357 29.9952 28.9079 31.2644 28.9079 32.5336L28.8939 32.5195ZM24.8385 32.8016C24.8385 34.2541 24.8385 35.7066 24.8385 37.1733C24.8385 37.9912 25.1172 38.2732 25.9255 38.2873C26.1624 38.2873 26.3854 38.2873 26.6223 38.2873C27.6257 38.2873 27.9045 38.0194 27.9045 37.004C27.9045 34.4233 27.9045 31.8426 27.9045 29.2619C27.9045 27.4146 27.7651 27.2735 25.9255 27.3299C25.1312 27.3581 24.8524 27.6261 24.8385 28.444C24.8385 29.8965 24.8385 31.3491 24.8385 32.8157V32.8016Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SocialMediaIcon;
