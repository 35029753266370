import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ThumbIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="36"
      height="31"
      viewBox="0 0 36 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3979 9.45576L19.1524 0.701243C20.069 -0.231097 21.5544 -0.231097 22.4867 0.685441C23.0556 1.25433 23.2927 2.06025 23.1346 2.85037L21.6334 10.0879H30.5618C33.9593 10.0879 36.2506 13.5644 34.9232 16.6933L29.7716 28.7189C29.266 29.8724 28.1282 30.631 26.864 30.631H12.6419C10.9036 30.631 9.48138 29.2087 9.48138 27.4705V11.6839C9.48138 10.8464 9.81323 10.0405 10.3979 9.45576Z"
        fill={color || primaryColor}
      />
      <path
        d="M6.32095 27.4705C6.32095 29.2087 4.89874 30.631 3.16048 30.631C1.42221 30.631 0 29.2087 0 27.4705V14.8286C0 13.0903 1.42221 11.6681 3.16048 11.6681C4.89874 11.6681 6.32095 13.0903 6.32095 14.8286V27.4705Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ThumbIcon;
