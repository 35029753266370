import styled from "styled-components";
import Button from "../../../../components/atoms/Button";
import ProfileImagePlaceholder from "../../../../components/icons/ProfileImagePlaceholder";

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 120px 0 32px;
  flex-direction: column;
`;

type ProfileImageProps = {
  color: string;
  imageUrl: string | undefined;
};

export const ProfileImage = styled.div<ProfileImageProps>`
  width: 116px;
  height: 116px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;

  background-color: ${({ color }) => color};
  background-image: url(${({ imageUrl }) => imageUrl});
  box-shadow: 0px 0px 224px 52px ${({ color }) => color}80;
`;

export const ImagePlaceholder = styled(ProfileImagePlaceholder)`
  margin-top: 12px;
  margin-left: -4px;
`;

export const Cta = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  padding: 30px 0 60px;
  flex-direction: column;
`;

export const AccessAccountButton = styled(Button)`
  width: 100%;
`;
