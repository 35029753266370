import Small from "../../../../components/atoms/Typography/Small";
import SalesList from "../../../../components/molecules/SalesList";
import { Loading, Theme } from "../../../../hooks";
import { IIncomeReport } from "../../../../services/order";
import { IOrder } from "../../../../types";

interface IProps {
  sales: IOrder[];
  productsWithSales: IIncomeReport["totalizers"]["productsWithSales"];
}

const LastPayments: React.FC<IProps> = ({ sales, productsWithSales }) => {
  const { isLoading } = Loading.useLoading();
  const { textColor } = Theme.useTheme();

  return (
    <div>
      <Small color={textColor}>Últimos pagamentos</Small>

      <SalesList
        focusOn="product"
        isLoading={isLoading}
        sales={(sales || []).slice(0, 3)}
        productsWithSales={productsWithSales}
      />
    </div>
  );
};

export default LastPayments;
