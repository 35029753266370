import styled from "styled-components";

export const PaymentOption = styled.div`
  gap: 15px;
  display: flex;
  padding: 25px 0;
  flex-direction: column;
`;

export const TitleSection = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
`;
