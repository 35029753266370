import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LoadingIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      id="Layer_1"
      version="1.1"
      x="0px"
      y="0px"
      xmlSpace="preserve"
      viewBox="0 0 15.6 15.6"
      style={{
        fill: "none",
        width: "20px",
        height: "20px",
        strokeWidth: 1.8,
        strokeMiterlimit: 10,
        strokeLinecap: "round",
        stroke: color || primaryColor,
        opacity: opacity,
        mixBlendMode: blendMode,
        animation: "loading 1s ease infinite normal both",
      }}
    >
      <path d="M8.8,1C6.7,0.7,4.6,1.3,3,2.9l0,0c-2.7,2.6-2.8,7-0.2,9.8l0,0c2.6,2.7,7,2.8,9.8,0.2l0,0c1.4-1.3,2-3.1,2.1-4.8" />
    </svg>
  );
};

export default LoadingIcon;
