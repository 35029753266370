import { Navigate, Route } from "react-router-dom";
import Form from "./components/Form";
import Home from "./components/Home";

const AppRedesSociais = () => {
  return (
    <Route path="redes-sociais">
      <Route index element={<Home />} />

      <Route path="new/:rede" element={<Form />} />
      <Route path=":id" element={<Form />} />

      <Route path="*" element={<Navigate to="/apps/redes-sociais" />} />
    </Route>
  );
};

export default AppRedesSociais;
