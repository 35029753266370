import styled, { css } from "styled-components";

export const StepsProgressBar = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

export const BarContainer = styled.span`
  width: 100%;
  height: 3px;
  overflow: hidden;
  border-radius: 2px;
  position: relative;
  background-color: #ffffff30;
`;

type BarProps = {
  backgroundColor: string;
  width: string;
};

export const Bar = styled.span<BarProps>`
  ${({ backgroundColor, width }) => css`
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
    border-radius: 2px;
    background-color: ${backgroundColor};
    width: ${width};
  `}
`;
