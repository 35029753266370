import styled from "styled-components";
import Small from "../../../../components/atoms/Typography/Small";

export const WorkHoursText = styled.div`
  z-index: 10;
  display: flex;
  position: relative;
  margin: 40px 0 20px;
  flex-direction: column;

  small {
    font-size: 10px;
    padding-top: 20px;
  }
`;

export const LunchTime = styled.div`
  display: flex;
  flex-direction: column;

  small {
    font-size: 10px;
  }
`;

export const Disclaimer = styled(Small)`
  padding: 20px 0;
`;

export const ExpandWorkHours = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  button {
    z-index: 10;
    width: 100%;
    font-size: 12px;
    position: relative;
  }
`;

type ShadowEffectProps = {
  backgroundColor: string;
};

export const ShadowEffect = styled.span<ShadowEffectProps>`
  top: -165%;
  width: 100%;
  height: 100px;
  position: absolute;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  background: ${({ backgroundColor }) =>
    `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${backgroundColor} 100%)`};
`;
