import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const IdeaIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="29"
      height="42"
      viewBox="0 0 29 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 0C6.50429 0 0 6.50429 0 14.5C0 19.43 2.465 23.7593 6.21429 26.39V31.0714C6.21429 32.2107 7.14643 33.1429 8.28571 33.1429H20.7143C21.8536 33.1429 22.7857 32.2107 22.7857 31.0714V26.39C26.535 23.7593 29 19.43 29 14.5C29 6.50429 22.4957 0 14.5 0ZM8.28554 39.3573C8.28554 40.4966 9.21768 41.4287 10.357 41.4287H18.6427C19.782 41.4287 20.7141 40.4966 20.7141 39.3573V37.2858H8.28554V39.3573ZM18.6434 24.2358L20.4041 22.9929C23.2005 21.0458 24.8577 17.8765 24.8577 14.5001C24.8577 8.78292 20.2177 4.14292 14.5005 4.14292C8.78339 4.14292 4.14338 8.78292 4.14338 14.5001C4.14338 17.8765 5.80053 21.0458 8.59696 22.9929L10.3577 24.2358V29.0001H18.6434V24.2358Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default IdeaIcon;
