import axios from "axios";

import { logoutUserIfTokenInvalid } from "../../utils";
import { IGallery, TImageGalleryOrientation } from "../../types";

const { REACT_APP_APPS_API_URL } = process.env;

const uploadImage = async (base64: string, token: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/image/image-gallery`,
      base64,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.imageUrl;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao enviar a imagem");
  }
};

const createGallery = async (
  orientation: TImageGalleryOrientation,
  images: { url: string; link?: string }[],
  token: string
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_APPS_API_URL}/image-gallery`,
      {
        images,
        orientation,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.imageUrl;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao criar a galeria");
  }
};

const updateGallery = async (
  galleryId: string,
  orientation: string,
  images: { url: string; link?: string }[],
  token: string
) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_APPS_API_URL}/image-gallery/${galleryId}`,
      {
        images,
        orientation,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.imageUrl;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao enviar a imagem");
  }
};

const removeGallery = async (galleryId: string, token: string) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_APPS_API_URL}/image-gallery/${galleryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.id;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao remover a galeria");
  }
};

const getGallery = async (
  galleryId: string,
  token: string
): Promise<IGallery> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_APPS_API_URL}/image-gallery/${galleryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.item;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter a galeria");
  }
};

const getGalleries = async (token: string): Promise<IGallery[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_APPS_API_URL}/image-gallery`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.items;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter as galerias");
  }
};

export default {
  getGallery,
  uploadImage,
  getGalleries,
  updateGallery,
  removeGallery,
  createGallery,
};
