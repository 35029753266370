export const LOCAL_STORAGE_USERNAME_KEY = "@username";
export const LOCAL_STORAGE_TOKEN_KEY = "@token";
export const LOCAL_STORAGE_USER_KEY = "@user";
export const LOCAL_STORAGE_AUTHENTICATION_METHOD = "@authenticationMethod";
export const LOCAL_STORAGE_INFO_STEP_DURATION = "@infoStepDuration";

export const MENTORIA_APP_HOME = "/apps/mentorias";
export const MENTORIA_NEW = "/apps/mentorias/new";

export const PAGE_VIEW_ACTION = "PAGE_VIEW";
export const BTN_CLICK_ACTION = "BTN_CLICK";

export const MP_PIX_FEE = 0.01;
export const MP_CREDIT_CARD_FEE = 0.0499;
