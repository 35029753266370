import { Theme } from "../../../hooks";
import { IAppItem, TIconOptions } from "../../../types";
import StantiPayProduct from "../StantiPayProduct";

import * as S from "./styles";

interface IProps {
  appAlias: string;
  appTitle: string;
  items: IAppItem[];
  isDraft?: boolean;
  sectionTitle: string;
  appIcon: TIconOptions;
  onItemClick: (id: string) => void;
}

const AppHomeProducts: React.FC<IProps> = ({
  items,
  onItemClick,
  sectionTitle,
}) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.Items>
      <S.Title color={textColor}>{sectionTitle}</S.Title>

      {items.map((item, index) => {
        return (
          <StantiPayProduct
            key={item.id}
            index={index + 1}
            productTitle={item.title || ""}
            onClick={() => item.id && onItemClick(item.id)}
          />
        );
      })}
    </S.Items>
  );
};

export default AppHomeProducts;
