import { Auth } from "../../../hooks";

import defaultImage from "../../assets/image_bg.png";

import * as S from "./styles";

const CreatorImageBackground = () => {
  const { user } = Auth.useAuth();

  return <S.BackgroundImage imageUrl={user.profileImage || defaultImage} />;
};

export default CreatorImageBackground;
