import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const StarOutlineIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5065 16.2486L20 7.83798L16.4935 16.2486L7.23657 17.2355L13.9664 23.35L11.4538 33.3946L20 28.5139L28.5462 33.3946L26.0336 23.35L32.7634 17.2355L23.5065 16.2486ZM30.8924 24.8203L34.0112 37.2883C34.4765 39.1484 32.4827 40.658 30.8169 39.7067L20 33.5291L9.18306 39.7067C7.51729 40.658 5.52347 39.1484 5.98879 37.2882L9.1076 24.8203L0.715861 17.1958C-0.675061 15.9321 0.0810301 13.6192 1.9502 13.4199L13.4646 12.1924L17.9891 1.33982C18.7339 -0.446609 21.2661 -0.446608 22.0109 1.33983L26.5354 12.1924L38.0498 13.4199C39.919 13.6192 40.6751 15.9321 39.2841 17.1958L30.8924 24.8203Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default StarOutlineIcon;
