import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AddButton from "../../../../components/atoms/AddButton";
import AppFormHeader from "../../../../components/atoms/AppFormHeader";
import ExternalLinkSkeleton from "../../../../components/atoms/ExternalLinkSkeleton";
import Page from "../../../../components/atoms/Page";
import SeparatorLine from "../../../../components/atoms/SeparatorLine";
import P from "../../../../components/atoms/Typography/P";
import appConfig from "../../../../config/app-youtube.json";
import { Auth, GA, Loading, NavBar, Snackbar, Theme } from "../../../../hooks";
import { Youtube } from "../../../../services";
import { IYoutubeAppItem } from "../../types";
import YoutubeItem from "./components/YoutubeItem";

import * as S from "./styles";

const Home: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<IYoutubeAppItem[]>([]);
  const [initialContent, setInitialContent] = useState<IYoutubeAppItem[]>([]);

  const navigate = useNavigate();

  const { token } = Auth.useAuth();
  const { sendEvents } = GA.useGA();
  const { primaryColor, textColor } = Theme.useTheme();
  const { showNavBar } = NavBar.useNavBar();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true);
        const data = await Youtube.getContent(token);

        setContent(data.map((i: IYoutubeAppItem) => ({ ...i })));
        setInitialContent(data.map((i: IYoutubeAppItem) => ({ ...i })));
      } catch (error) {
        newError("Houve um erro ao obter os posts");
      } finally {
        setLoading(false);
      }
    };

    run();

    return () => showNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTypeHandler = (
    index: number,
    type: IYoutubeAppItem["type"]
  ) => {
    setContent((curr) => {
      const newContent = [...curr];
      newContent[index].type = type;

      return newContent;
    });
  };

  const onChangeUrlHandler = (index: number, value: string) => {
    setContent((curr) => {
      const newContent = [...curr];
      newContent[index].url = value;

      return newContent;
    });
  };

  const onRemoveHandler = async (index: number) => {
    try {
      showLoading();
      const currContent = content[index];

      if (currContent.id) await Youtube.deleteContent(token, currContent.id);

      setContent((curr) => curr.filter((_, idx) => idx !== index));
      setInitialContent((curr) => curr.filter((_, idx) => idx !== index));

      newSuccess("Conteudo removido com sucesso");
    } catch (error) {
      newError("Houve um erro ao remover o conteúdo");
    } finally {
      hideLoading();
    }
  };

  const onSaveHandler = async (index: number) => {
    try {
      showLoading();

      const currContent = content[index];
      const item = currContent.id
        ? await Youtube.updateContent(token, currContent.id, {
            url: currContent.url,
            type: currContent.type,
          })
        : await Youtube.createContent(token, {
            url: currContent.url,
            type: currContent.type,
          });

      setContent(() => {
        const newContent = content.map((i, idx) => {
          if (idx === index) return { ...item };

          return { ...i };
        });

        return newContent;
      });

      setInitialContent(() => {
        const newContent = content.map((i, idx) => {
          if (idx === index) return { ...item };

          return { ...i };
        });

        return newContent;
      });

      newSuccess("Alterações salvas com sucesso");
    } catch (error) {
      newError("Houve um erro ao salvar as alterações");
    } finally {
      hideLoading();
    }
  };

  const onAddContentHandler = () => {
    setContent((curr) => {
      return [...curr, { url: "", type: "video" } as IYoutubeAppItem];
    });

    setInitialContent((curr) => {
      return [...curr, { url: "", type: "video" } as IYoutubeAppItem];
    });
    sendEvents({ name: "new-sku", category: appConfig.id });
  };

  return (
    <Page>
      <AppFormHeader
        appTitle={appConfig.title}
        onBack={() => navigate("/apps")}
      />

      <S.Description color={textColor}>
        Exiba em sua Stanti vídeos ou canais do Youtube. Prático, rápido e
        direto.
      </S.Description>

      <SeparatorLine />

      <S.Body>
        <P color={primaryColor}>Meus conteúdos do Youtube</P>

        {(!content || !content.length) && !loading && (
          <S.NoPosts color="#71726F">
            Ainda não publicou nenhum conteúdo
          </S.NoPosts>
        )}

        {loading && <ExternalLinkSkeleton />}

        {!loading &&
          content.length > 0 &&
          content.map((item, index) => (
            <YoutubeItem
              index={index}
              url={item.url}
              type={item.type}
              key={item.id || `new#${index}`}
              onSave={() => onSaveHandler(index)}
              onRemove={() => onRemoveHandler(index)}
              onChangeUrl={(value) => onChangeUrlHandler(index, value)}
              onChangeType={(type) => onChangeTypeHandler(index, type)}
              hasChanges={
                JSON.stringify(content[index]) !==
                JSON.stringify(initialContent[index])
              }
            />
          ))}

        <AddButton
          onClick={onAddContentHandler}
          label="Adicionar vídeo/canal do YT"
          color={content && content.length > 0 ? "#fafafa" : primaryColor}
        />
      </S.Body>
    </Page>
  );
};

export default Home;
