import CalendarIcon from "../../../../components/icons/CalendarIcon";
import EmailTwoIcon from "../../../../components/icons/EmailTwoIcon";
import PhoneIcon from "../../../../components/icons/PhoneIcon";
import TrashCanIcon from "../../../../components/icons/TrashCanIcon";
import WhatsappIcon from "../../../../components/icons/WhatsappIcon";

import * as S from "./styles";

const buttonOptions = {
  whatsapp: {
    icon: <WhatsappIcon />,
  },
  email: {
    icon: <EmailTwoIcon />,
  },
  phone: {
    icon: <PhoneIcon />,
  },
  calendar: {
    icon: <CalendarIcon />,
  },
  trash: {
    icon: <TrashCanIcon />,
  },
};

interface IProps {
  href: string;
  onClick?: () => void;
  type: keyof typeof buttonOptions;
}

const AppointmentActionButton: React.FC<IProps> = ({ type, href, onClick }) => {
  if (href) {
    return (
      <S.Button
        as="a"
        href={href}
        target="_blank"
        rel="noreferrer"
        onClick={() => onClick && onClick()}
        iconType={type}
      >
        {buttonOptions[type].icon}
      </S.Button>
    );
  }

  return (
    <S.Button iconType={type} onClick={() => onClick && onClick()}>
      {buttonOptions[type].icon}
    </S.Button>
  );
};

export default AppointmentActionButton;
