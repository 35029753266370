import styled from "styled-components";
import H4 from "../../components/atoms/Typography/H4";

import Small from "../../components/atoms/Typography/Small";

export const Content = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 80px;
  flex-direction: column;
  justify-content: center;
`;

export const PersonalInfoEdit = styled.div`
  gap: 12px;
  display: flex;
  margin-top: 32px;
  flex-direction: column;
`;

export const PersonalInfoTitle = styled(H4)`
  margin-bottom: 24px;
`;

export const AddProfileImageText = styled(Small)`
  text-align: center;
  opacity: 0.6;
`;

export const StyleEdit = styled.div`
  gap: 12px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 12px;
`;

export const SaveButton = styled.div`
  left: 50%;
  width: 100%;
  bottom: 40px;
  display: flex;
  position: fixed;
  padding: 0 24px;
  max-width: 960px;
  flex-direction: column;

  button {
    height: 56px;
    transform: translate(calc(-50% - 24px), 0%);
    box-shadow: 4px 10px 10px -2px rgba(0, 0, 0, 0.6);
    animation: buttonPop 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;

    @keyframes buttonPop {
      0% {
        transform: translate(calc(-50% - 24px), 200%);
      }
      60% {
        transform: translate(calc(-50% - 24px), -60%);
      }
      100% {
        transform: translate(calc(-50% - 24px), 0%);
      }
    }
  }
`;
