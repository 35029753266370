import { useNavigate } from "react-router-dom";

import Button from "../../../../components/atoms/Button";
import Page from "../../../../components/atoms/Page";
import H2 from "../../../../components/atoms/Typography/H2";
import H4 from "../../../../components/atoms/Typography/H4";
import { Theme } from "../../../../hooks";

import * as ForgotPassowrdStyles from "../../styles";
import * as SuccessfulChangeStyles from "./styles";

const S = {
  ...SuccessfulChangeStyles,
  ...ForgotPassowrdStyles,
};

interface IProps {
  username: string;
  profileImage?: string;
}

const SuccessfulChange: React.FC<IProps> = ({ username, profileImage }) => {
  const navigate = useNavigate();

  const { primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <Page>
      <S.LoginFormHeader>
        <img alt="stanti icon" src="/stanti-icon.svg" className="stantiLogo" />

        <Button onClick={() => navigate("/")} type="outline">
          Voltar
        </Button>
      </S.LoginFormHeader>

      <S.Content>
        <S.ProfileImage color={primaryColor} imageUrl={profileImage}>
          {!profileImage && <S.ImagePlaceholder color={backgroundColor} />}
        </S.ProfileImage>

        <S.Cta>
          <H4 color={primaryColor}>Senha atualizada!</H4>

          <H2 color={primaryColor}>{username}</H2>
        </S.Cta>

        <S.AccessAccountButton onClick={() => navigate("/login")}>
          Acesse sua conta!
        </S.AccessAccountButton>
      </S.Content>
    </Page>
  );
};

export default SuccessfulChange;
