import styled from "styled-components";
import P from "../../../../components/atoms/Typography/P";

export const CampaingList = styled.div`
  gap: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
`;

export const NoCampaign = styled(P)`
  text-align: center;
`;
