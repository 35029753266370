import styled, { css } from "styled-components";
import P from "../Typography/P";

type BoxProps = {
  isContained: boolean;
};

export const Box = styled.div<BoxProps>`
  width: 100%;
  height: 106px;
  display: flex;
  cursor: pointer;
  user-select: none;
  position: relative;
  border-radius: 16px;
  justify-content: center;
  border-top: 1px solid #fafafa33;
  border-left: 1px solid #fafafa0d;
  border-right: 1px solid #fafafa0d;
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  mix-blend-mode: normal;

  ${({ isContained }) =>
    isContained &&
    css`
      width: 106px;
    `}

  .plusIcon {
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 16px;
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 16px;

    svg {
      width: 40%;
      height: 40%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  padding-top: 14px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const Icon = styled.div`
  width: 36px;
  height: 36px;

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.25 !important;
    mix-blend-mode: normal !important;
  }
`;

export const ContentLabel = styled(P)`
  font-size: 10px;
  font-weight: 275;
  line-height: 15px;
  text-align: center;
  font-style: normal;
  text-align: center;
  font-family: "Poppins", sans-serif;
`;
