import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const WarningOutlineIcon: React.FC<IProps> = ({
  color,
  opacity,
  blendMode,
}) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="45"
      height="41"
      viewBox="0 0 45 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70791 40.0075C1.0853 40.0075 -1.17295 36.0791 0.638359 32.9505L18.3515 2.34646C20.1628 -0.782155 24.6793 -0.782155 26.4906 2.34646L44.2038 32.9505C46.0151 36.0791 43.7568 40.0075 40.1342 40.0075H4.70791ZM40.1334 35.3029L22.4202 4.69894L4.70707 35.3029H40.1334ZM20.0681 16.4839V21.1886C20.0681 22.4824 21.1266 23.5409 22.4204 23.5409C23.7142 23.5409 24.7728 22.4824 24.7728 21.1886V16.4839C24.7728 15.1901 23.7142 14.1316 22.4204 14.1316C21.1266 14.1316 20.0681 15.1901 20.0681 16.4839ZM24.7728 32.9504V28.2457H20.0681V32.9504H24.7728Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default WarningOutlineIcon;
