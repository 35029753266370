export const getWhatsappUrl = (phone?: string): string | null => {
  const onlyNumbersPhone = phone?.replace(/[^0-9]/g, "");

  if (!onlyNumbersPhone) return null;

  const normalizedPhoneWithDDI =
    onlyNumbersPhone.substring(0, 2) === "55"
      ? onlyNumbersPhone
      : `55${onlyNumbersPhone}`;

  return `https://wa.me/${normalizedPhoneWithDDI}`;
};
