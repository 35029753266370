import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LabelIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="26"
      height="35"
      viewBox="0 0 26 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5.52729C0 2.47466 2.49441 0 5.57143 0H20.4286C23.5056 0 26 2.47465 26 5.52728V33.1728C26 34.6491 24.3243 35.5138 23.1059 34.6663L13 27.6364L2.89411 34.6663C1.67568 35.5138 0 34.6491 0 33.1728V5.52729Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default LabelIcon;
