import { Theme } from "../../../../hooks";
import P from "../../../../components/atoms/Typography/P";

import * as S from "./styles";

interface IProps {
  username: string;
  onShare: () => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const StorefrontLink: React.FC<IProps> = ({ username, onShare }) => {
  const { primaryColor, textColor } = Theme.useTheme();

  return (
    <S.StorefrontLink>
      <P color={textColor}>Seu link:</P>

      <S.Link>
        <div
          className="url"
          onClick={() =>
            window.open(`https://${REACT_APP_STOREFRONT_BASE_URL}/${username}`)
          }
        >
          <P color={primaryColor}>
            {REACT_APP_STOREFRONT_BASE_URL}/{username}
          </P>
        </div>

        <S.ShareButton onClick={onShare}>Compartilhar</S.ShareButton>
      </S.Link>
    </S.StorefrontLink>
  );
};

export default StorefrontLink;
