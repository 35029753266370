import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PuzzleIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.3571 18.5714H31.5714V11.1429C31.5714 9.1 29.9 7.42857 27.8571 7.42857H20.4286V4.64286C20.4286 2.08 18.3486 0 15.7857 0C13.2229 0 11.1429 2.08 11.1429 4.64286V7.42857H3.71429C1.67143 7.42857 0.0185714 9.1 0.0185714 11.1429V18.2H2.78571C5.55286 18.2 7.8 20.4471 7.8 23.2143C7.8 25.9814 5.55286 28.2286 2.78571 28.2286H0V35.2857C0 37.3286 1.67143 39 3.71429 39H10.7714V36.2143C10.7714 33.4471 13.0186 31.2 15.7857 31.2C18.5529 31.2 20.8 33.4471 20.8 36.2143V39H27.8571C29.9 39 31.5714 37.3286 31.5714 35.2857V27.8571H34.3571C36.92 27.8571 39 25.7771 39 23.2143C39 20.6514 36.92 18.5714 34.3571 18.5714Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PuzzleIcon;
