import styled from "styled-components";
import Link from "../Link";
import P from "../Typography/P";

type BannerProps = {
  borderColor?: string;
};

type ImageBannerProps = {
  image: string;
};

export const ImageBanner = styled.div<ImageBannerProps>`
  width: 100%;
  display: flex;
  justify-content: center;

  .container {
    gap: 8px;
    width: 100%;
    display: flex;
    max-width: 480px;
    align-items: flex-start;

    a {
      width: 100%;
      cursor: pointer;
      padding-bottom: 30%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(${({ image }) => image});
    }
  }
`;

export const Banner = styled.div<BannerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0px 14px;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 15px;
  white-space: pre-wrap;

  + & {
    margin-top: 16px;
  }
`;

export const BannerLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .iconContainer {
    display: flex;
    align-items: center;
    width: 20px;
    height: auto;
  }
`;

export const Content = styled(BannerLink)``;

type LabelProps = {
  borderColor: string;
};

export const Label = styled(P)<LabelProps>`
  padding: 20px;
  border-left: 1px solid ${({ borderColor }) => borderColor};
`;

export const CloseButton = styled.button`
  cursor: pointer;

  svg {
    width: 10px;
    height: auto;
  }
`;
