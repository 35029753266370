import { DragEventHandler, ReactElement, TouchEventHandler } from "react";

import Switcher from "../../../../components/atoms/Switcher";
import P from "../../../../components/atoms/Typography/P";
import EditIcon from "../../../../components/icons/EditIcon";
import DropZone from "../../../../components/molecules/DropZone";

import * as S from "./styles";

interface IProps {
  app: string;
  label: string;
  title: string;
  icon: ReactElement;
  isMobileDragOn: boolean;
  onEditHandler: () => void;
  onCopyHandler: () => void;
  onDropHandler: () => void;
  isDropZoneHovered: boolean;
  coordinates: { x: number; y: number };
  onDragEnd: DragEventHandler<HTMLDivElement>;
  onDragStart: DragEventHandler<HTMLDivElement>;
  onDragEnter: DragEventHandler<HTMLDivElement>;
  onDragLeave: DragEventHandler<HTMLDivElement>;
  touchEndHandler: TouchEventHandler<HTMLDivElement>;
  touchMoveHandler: TouchEventHandler<HTMLDivElement>;
  touchStartHandler: TouchEventHandler<HTMLDivElement>;
  touchCancelHandler: TouchEventHandler<HTMLDivElement>;
  onPublishItem: (publish: boolean) => void;
  isPublished: boolean;
}

const DraggableItem: React.FC<IProps> = ({
  icon,
  label,
  title,
  onDragEnd,
  onDragEnter,
  onDragLeave,
  onDragStart,
  coordinates,
  onDropHandler,
  onEditHandler,
  isMobileDragOn,
  touchEndHandler,
  touchMoveHandler,
  isDropZoneHovered,
  touchStartHandler,
  touchCancelHandler,
  onPublishItem,
  isPublished,
}) => {
  const switcherHandler = (isPublished: boolean) => {
    onPublishItem(!isPublished);
  };

  return (
    <S.Container
      onDrop={onDropHandler}
      onDragEnd={onDragLeave}
      onDragEnter={onDragEnter}
      isMobileDragOn={isMobileDragOn}
      coordinates={coordinates}
      onDragOver={(e) => e.preventDefault()}
    >
      <S.DraggableItem
        draggable
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        <S.Drag
          onTouchEnd={touchEndHandler}
          onTouchMove={touchMoveHandler}
          onTouchStart={touchStartHandler}
          onTouchCancel={touchCancelHandler}
        >
          <span />
          <span />
          <span />
        </S.Drag>

        <S.Content>
          <div className="text">
            <S.ContentLabel color={!isPublished ? "#dadada" : "#fafafa"}>
              <div className="icon">{icon}</div>

              {label}
            </S.ContentLabel>

            <P color={!isPublished ? "#DADADA" : "#fafafa"}>{title}</P>
          </div>

          <S.Actions>
            <div className="editIcon" onClick={onEditHandler}>
              <EditIcon color={!isPublished ? "#DADADA" : ""} />
            </div>

            <div className="switcher">
              <Switcher isOn={isPublished} setIsOn={switcherHandler} small />
            </div>
          </S.Actions>
        </S.Content>
      </S.DraggableItem>

      <DropZone isDroppable={false} isHovered={isDropZoneHovered} />
    </S.Container>
  );
};

export default DraggableItem;
