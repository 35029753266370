import styled from "styled-components";

type H5Props = {
  color: string;
};

const H5 = styled.h5<H5Props>`
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: ${({ color }) => color};
`;

export default H5;
