import { CSSProperties, MouseEventHandler, ReactNode } from "react";

import { Theme } from "../../../hooks";

import * as S from "./styles";

interface IProps {
  style?: CSSProperties;
  color?: string;
  disabled?: boolean;
  children: ReactNode;
  type?: "solid" | "outline";
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const Button: React.FC<IProps> = ({
  style,
  color,
  onClick,
  children,
  disabled,
  type = "solid",
}) => {
  const { primaryColor, backgroundColor } = Theme.useTheme();

  const textColor = type === "solid" ? backgroundColor : color || primaryColor;

  return (
    <S.Button
      isDisabled={disabled}
      color={color || primaryColor}
      textColor={textColor}
      variant={type}
      onClick={onClick}
      disabled={disabled}
      style={{ ...style }}
    >
      {children}
    </S.Button>
  );
};

export default Button;
