import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const GoogleIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 10.3838C21 16.173 16.8301 20.2929 10.6721 20.2929C4.76803 20.2929 0 15.7597 0 10.1465C0 4.53318 4.76803 0 10.6721 0C13.5467 0 15.9652 1.00237 17.8285 2.65526L14.9238 5.31053C11.124 1.82473 4.05799 4.44317 4.05799 10.1465C4.05799 13.6854 7.03156 16.5535 10.6721 16.5535C14.898 16.5535 16.4816 13.6732 16.7311 12.1798H10.6721V8.68995H20.8322C20.9311 9.20955 21 9.70869 21 10.3838Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default GoogleIcon;
