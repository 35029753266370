import styled from "styled-components";

export const Form = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const FormPassword = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;
