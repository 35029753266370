import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const AttachmentIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="35"
      height="45"
      viewBox="0 0 35 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5115 18.1086L19.5038 37.1097C17.1318 40.8633 12.2726 42.467 8.3095 40.4652C3.75554 38.1654 2.28548 32.5367 4.94125 28.3342L18.9691 6.13628C20.4559 3.78359 23.4949 2.7134 25.9775 3.95556C28.8421 5.38882 29.7774 8.92031 28.109 11.5604L16.1921 30.4178C15.5679 31.4056 14.2489 31.703 13.261 31.0787C12.2731 30.4544 11.9755 29.1354 12.5997 28.1476L22.5305 12.4331C22.9958 11.6967 22.771 10.7002 22.0345 10.2348C21.2981 9.76938 20.3015 9.99407 19.8362 10.7304L10.0643 26.1935C8.57756 28.5462 8.9154 31.7504 11.1029 33.4595C13.627 35.4316 17.2181 34.7605 18.8864 32.1205L30.6104 13.5683C32.9824 9.81479 32.3452 4.73762 28.8361 2.01742C24.8222 -1.0972 19.0895 -0.0203297 16.4337 4.18219L2.50799 26.2185C-0.749301 31.3729 0.105918 38.3722 4.93662 42.1035C10.5056 46.3767 18.3506 44.9006 22.0052 39.1177L34.2058 19.8112C34.6711 19.0749 34.4463 18.0783 33.7099 17.6129C32.9734 17.1476 31.9768 17.3722 31.5115 18.1086Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default AttachmentIcon;
