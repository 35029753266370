import { useEffect, useState } from "react";
import Button from "../../../../components/atoms/Button";

import NavigationArrow from "../../../../components/atoms/NavigationArrow";
import Page from "../../../../components/atoms/Page";
import StepsProgressBar from "../../../../components/atoms/StepsProgressBar";
import P from "../../../../components/atoms/Typography/P";
import UsernameInput from "../../../../components/atoms/UsernameInput";
import RegisterPhoneStepIcon from "../../../../components/icons/RegisterPhoneStepIcon";
import StantiIcon from "../../../../components/icons/StantiIcon";
import { LOCAL_STORAGE_AUTHENTICATION_METHOD } from "../../../../constants";
import { Auth, Loading, Snackbar, Theme } from "../../../../hooks";
import { Analytics, Creator } from "../../../../services";
import { TSteps } from "../../../../types";

import * as RegisterStyles from "../styles";
import * as MissingUsernameStyles from "./styles";

const S = {
  ...MissingUsernameStyles,
  ...RegisterStyles,
};

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const authenticationMethod =
  localStorage.getItem(LOCAL_STORAGE_AUTHENTICATION_METHOD) || "";

const MissingUsernameStep: React.FC = () => {
  const [username, setUsername] = useState<string>("");

  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();
  const { user, setUserHandler, token, logout } = Auth.useAuth();

  const isReady = username.length >= 3;

  useEffect(() => {
    Analytics.submitPageViewEvent({
      creator: user.id || "",
      origin: document.referrer,
      description: "information-step",
      creatorUsername: username || "",
      actionDescription: "missing-username-information-step",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNextHandler = async () => {
    showLoading();
    try {
      const isUsernameRegistered = await Creator.checkUsernameAvailability(
        username
      );

      if (!isUsernameRegistered) {
        const payload = {
          username,
          formStep: "profile-image" as TSteps,
        };

        (Object.keys(payload) as (keyof typeof payload)[]).forEach((key) => {
          if (!payload[key]) delete payload[key];
        });

        Analytics.submitClickEvent({
          creator: user.id || "",
          origin: document.referrer,
          creatorUsername: username || "",
          description: authenticationMethod,
          actionDescription: authenticationMethod,
        });

        Analytics.submitClickEvent({
          creator: user.id || "",
          origin: document.referrer,
          creatorUsername: username || "",
          description: "missing-username-information-step-concluded",
          actionDescription: `missing-username-information-step-concluded-${username}`,
        });

        const updateUser = await Creator.updateProfile(payload, token);

        setUserHandler(updateUser);
      }
    } catch (error) {
      newError("Houve um erro ao verificar o username escolhido");
      setUsername("");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page>
      <S.MissingUsernameWrapper>
        <S.Container>
          <S.RegisterFormHeader>
            <div className="stantiLogo">
              <StantiIcon color="#fafafa" />
            </div>

            <Button
              style={{ color: "#fafafa" }}
              color="#fafafa05"
              onClick={logout}
            >
              Sair
            </Button>
          </S.RegisterFormHeader>

          <S.ProgressBar>
            <P color={textColor}>Criando sua Stanti</P>

            <StepsProgressBar progress={(1.5 / 6) * 100} quantityOfBars={6} />
          </S.ProgressBar>

          <S.Form>
            <div className="icon">
              <RegisterPhoneStepIcon />
            </div>

            <div className="text">
              <P color={textColor}>Defina seu nome de usuário para continuar</P>
            </div>

            <UsernameInput
              value={username}
              baseUrl={`${REACT_APP_STOREFRONT_BASE_URL}/`}
              onChange={(val) => setUsername((val || "").trim())}
            />
          </S.Form>

          <S.Footer>
            <NavigationArrow
              buttonStyle="solid"
              angle={isReady ? 0 : -90}
              onClick={() => isReady && onNextHandler()}
              color={isReady ? primaryColor : "#ffffff33"}
            />
          </S.Footer>
        </S.Container>
      </S.MissingUsernameWrapper>
    </Page>
  );
};

export default MissingUsernameStep;
