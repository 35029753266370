import styled from "styled-components";

export const MercadoPagoButton = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  cursor: pointer;
  user-select: none;
  position: relative;
  border-radius: 22px;
  align-items: center;
  padding-right: 24px;
  justify-content: space-between;

  &::before {
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    border-radius: 22px;
    background-image: linear-gradient(
      275.86deg,
      rgba(250, 250, 250, 0.39) -30%,
      rgba(113, 114, 111, 0) 140%
    );
  }

  .mercadoPagoLogo {
    z-index: 0;
    width: 33%;
    height: 100%;
    display: flex;
    min-width: 140px;
    padding: 8px 16px;
    position: relative;
    border-radius: 22px;
    align-items: center;
    justify-content: center;
    background-color: #19b4e9;

    &::before {
      content: "";
      width: calc(100% - 8px);
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      left: 0;
      border-radius: 22px;
      background-color: #fafafaee;
    }

    img {
      width: 100%;
      max-width: 120px;
      margin-left: -4px;
    }
  }
`;

export const ButtonLabel = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
