import styled from "styled-components";

export const FormPrice = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const PriceIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalValues = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
`;

export const TotalValue = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;

  .icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

type HighlightProps = {
  textColor: string;
};

export const Highlight = styled.span<HighlightProps>`
  color: ${({ textColor }) => textColor};
`;

export const Price = styled.span`
  color: #fafafa;
`;
