import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
  flip?: boolean;
}

const ProfileIconCircle: React.FC<IProps> = ({
  color,
  opacity,
  blendMode,
  flip = false,
}) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
        transform: `scaleX(${flip ? "-1" : "1"})`,
      }}
      width="81"
      height="55"
      viewBox="0 0 81 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="64.7067"
        cy="30.8184"
        r="4.5"
        transform="rotate(-15.7504 64.7067 30.8184)"
        stroke={color || primaryColor}
        strokeWidth="9"
        strokeLinejoin="round"
      />
      <circle
        cx="6.1695"
        cy="36.9379"
        r="4.5"
        transform="rotate(-15.7504 6.1695 36.9379)"
        stroke={color || primaryColor}
        strokeLinejoin="round"
      />
      <circle
        r="4.5"
        transform="matrix(-0.962453 -0.271447 -0.271447 0.962453 61.1703 6.16939)"
        stroke={color || primaryColor}
        strokeLinejoin="round"
      />
      <circle
        cx="30.6006"
        cy="42.5153"
        r="2"
        transform="rotate(-15.7504 30.6006 42.5153)"
        stroke={color || primaryColor}
      />
      <circle
        cx="77.958"
        cy="52.0173"
        r="2"
        transform="rotate(-15.7504 77.958 52.0173)"
        stroke={color || primaryColor}
      />
      <circle
        cx="16.4678"
        cy="10.468"
        r="2"
        transform="rotate(-15.7504 16.4678 10.468)"
        stroke={color || primaryColor}
      />
      <circle
        cx="37.9557"
        cy="20.6998"
        r="4.5"
        transform="rotate(-15.7504 37.9557 20.6998)"
        stroke={color || primaryColor}
        strokeWidth="5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProfileIconCircle;
