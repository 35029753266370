import { Theme } from "../../../hooks";
import Switcher from "../Switcher";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  max?: number;
  min?: number;
  value: number;
  errors?: string[];
  isUnlimited: boolean;
  onChangeValue: (val: number) => void;
  setIsUnlimited: (val: boolean) => void;
}

const AppFormVacancySelector: React.FC<IProps> = ({
  min,
  max,
  value,
  errors,
  isUnlimited,
  onChangeValue,
  setIsUnlimited,
}) => {
  const { textColor } = Theme.useTheme();

  const onChangeHandler = (val: string) => {
    const normalizedValue = +val.replace(/([^0-9-])/g, "");

    if (normalizedValue < 0) return 0;

    if ((max || max === 0) && normalizedValue > max) return onChangeValue(max);

    onChangeValue(normalizedValue);
  };

  const hasReachedMin = (min || min === 0) && (value || 0) <= min;
  const hasReachedMax = (max || max === 0) && (value || 0) >= max;

  return (
    <S.VacancySelector>
      <S.Unlimited>
        <P color="#FAFAFA40">Vagas ilimitadas</P>

        <Switcher isOn={isUnlimited} setIsOn={setIsUnlimited} />
      </S.Unlimited>

      {!isUnlimited && (
        <S.Quantity>
          <P color={textColor}>Quantidade</P>

          <S.NumberSelector>
            <S.Button
              onClick={() => onChangeHandler(String((value || 0) - 1))}
              hasReachedLimit={hasReachedMin}
            >
              -
            </S.Button>

            <input
              value={value}
              placeholder="0"
              onChange={({ target }) => onChangeHandler(target.value)}
            />

            <S.Button
              onClick={() => onChangeHandler(String((value || 0) + 1))}
              hasReachedLimit={hasReachedMax}
            >
              +
            </S.Button>
          </S.NumberSelector>
        </S.Quantity>
      )}

      {errors && errors.length && (
        <Small color="#FF4D4F">Erros: {errors.join(", ")}</Small>
      )}
    </S.VacancySelector>
  );
};

export default AppFormVacancySelector;
