import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PersonOneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 0C8.512 0 0 8.512 0 19C0 29.488 8.512 38 19 38C29.488 38 38 29.488 38 19C38 8.512 29.488 0 19 0ZM10.925 20.9C10.925 19.589 11.989 18.525 13.3 18.525C14.611 18.525 15.675 19.589 15.675 20.9C15.675 22.211 14.611 23.275 13.3 23.275C11.989 23.275 10.925 22.211 10.925 20.9ZM22.325 20.9C22.325 19.589 23.389 18.525 24.7 18.525C26.011 18.525 27.075 19.589 27.075 20.9C27.075 22.211 26.011 23.275 24.7 23.275C23.389 23.275 22.325 22.211 22.325 20.9ZM3.8 19C3.8 27.379 10.621 34.2 19 34.2C27.379 34.2 34.2 27.379 34.2 19C34.2 17.499 33.972 16.055 33.573 14.706C32.205 15.029 30.78 15.2 29.298 15.2C22.895 15.2 17.233 12.027 13.794 7.16304C11.932 11.704 8.379 15.371 3.895 17.366C3.838 17.898 3.8 18.449 3.8 19Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PersonOneIcon;
