import { Theme } from "../../../hooks";
import { TAvailableSocialMedia, TIconOptions } from "../../../types";
import AvailableIcons from "../AvailableIcons";

import * as S from "./styles";

interface IProps {
  boxWidth?: string;
  boxHeight?: string;
  iconWidth?: string;
  iconHeight?: string;
  isSelected?: boolean;
  onClick?: () => void;
  disableShadow?: boolean;
  boxBorderRadius?: string;
  socialMedia: TAvailableSocialMedia;
}

const SocialMediaIconBox: React.FC<IProps> = ({
  onClick,
  boxWidth,
  boxHeight,
  iconWidth,
  iconHeight,
  isSelected,
  socialMedia,
  disableShadow,
  boxBorderRadius,
}) => {
  const onClickHandler = () => {
    onClick ? onClick() : null;
  };

  const { primaryColor } = Theme.useTheme();

  const iconOption = (() => {
    if (socialMedia === "instagram") {
      return "instagramTwo" as TIconOptions;
    } else if (socialMedia === "linkedin") {
      return "linkedin" as TIconOptions;
    } else {
      return socialMedia as TIconOptions;
    }
  })();

  return (
    <S.Box
      onClick={onClickHandler}
      width={boxWidth || "40px"}
      height={boxHeight || "40px"}
      borderRadius={boxBorderRadius || "16px"}
    >
      <S.Icon width={iconWidth || "16px"} height={iconHeight || "16px"}>
        {
          <AvailableIcons
            option={iconOption}
            color={isSelected ? primaryColor : "#ffffff30"}
          />
        }
      </S.Icon>

      {isSelected && !disableShadow && (
        <S.SelectedLight
          backgroundColor={primaryColor}
          shadowColor={primaryColor}
        />
      )}
    </S.Box>
  );
};

export default SocialMediaIconBox;
