import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ArrowTopLeftIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 19.3668L6.47364e-07 4.5569C7.56917e-07 2.05061 2.05149 -1.096e-07 4.55887 0L19.3752 6.47644e-07C21.2671 7.30343e-07 22.7944 1.52656 22.7944 3.41768C22.7944 5.30879 21.2671 6.83536 19.3752 6.83536L11.9215 6.83536L25.0738 20.3921C26.3275 21.6909 26.3047 23.7643 25.0282 25.0402C23.7517 26.3161 21.6774 26.3161 20.3782 25.063L6.83831 11.734L6.83831 19.3668C6.83831 21.258 5.31109 22.7845 3.41915 22.7845C1.52722 22.7845 -8.26623e-08 21.258 0 19.3668Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default ArrowTopLeftIcon;
