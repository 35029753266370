import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const DiceIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11111 0H32.8889C35.15 0 37 1.85 37 4.11111V32.8889C37 35.15 35.15 37 32.8889 37H4.11111C1.85 37 0 35.15 0 32.8889V4.11111C0 1.85 1.85 0 4.11111 0ZM6.16706 27.75C6.16706 29.4561 7.54428 30.8334 9.25039 30.8334C10.9565 30.8334 12.3337 29.4561 12.3337 27.75C12.3337 26.0439 10.9565 24.6667 9.25039 24.6667C7.54428 24.6667 6.16706 26.0439 6.16706 27.75ZM9.25039 12.3334C7.54428 12.3334 6.16706 10.9561 6.16706 9.25002C6.16706 7.54391 7.54428 6.16669 9.25039 6.16669C10.9565 6.16669 12.3337 7.54391 12.3337 9.25002C12.3337 10.9561 10.9565 12.3334 9.25039 12.3334ZM15.4171 18.5C15.4171 20.2061 16.7943 21.5834 18.5004 21.5834C20.2065 21.5834 21.5837 20.2061 21.5837 18.5C21.5837 16.7939 20.2065 15.4167 18.5004 15.4167C16.7943 15.4167 15.4171 16.7939 15.4171 18.5ZM27.7504 30.8334C26.0443 30.8334 24.6671 29.4561 24.6671 27.75C24.6671 26.0439 26.0443 24.6667 27.7504 24.6667C29.4565 24.6667 30.8337 26.0439 30.8337 27.75C30.8337 29.4561 29.4565 30.8334 27.7504 30.8334ZM24.6671 9.25002C24.6671 10.9561 26.0443 12.3334 27.7504 12.3334C29.4565 12.3334 30.8337 10.9561 30.8337 9.25002C30.8337 7.54391 29.4565 6.16669 27.7504 6.16669C26.0443 6.16669 24.6671 7.54391 24.6671 9.25002Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default DiceIcon;
