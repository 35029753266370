import LinkIcon from "../../../components/icons/LinkIcon";
import { Auth, Theme } from "../../../hooks";
import Button from "../Button";
import SocialMediaIconBox from "../SocialMediaIconBox";
import P from "../Typography/P";
import Small from "../Typography/Small";

import * as S from "./styles";
interface IProps {
  isCopied: boolean;
  setIsCopied: () => void;
  socialMediaOpened?: (socialMedia: string) => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const CopyLinkModal: React.FC<IProps> = ({
  isCopied,
  setIsCopied,
  socialMediaOpened,
}) => {
  const { user } = Auth.useAuth();
  const { primaryColor, textColor } = Theme.useTheme();

  const onCopyHandler = () => {
    navigator.clipboard.writeText(
      `${REACT_APP_STOREFRONT_BASE_URL}/${user.username}`
    );
    setIsCopied();
  };

  return (
    <S.Container onClick={(evt) => evt.stopPropagation()}>
      {!!user.profileImage && <S.Image imageUrl={user.profileImage} />}

      <S.UserInfo>
        <P color={textColor}>{user.name}</P>

        <S.Username>{`@${user.username}`}</S.Username>
      </S.UserInfo>

      <S.LinkBox>
        <div className="text">
          <Small color={textColor}>Seu link</Small>
        </div>

        <S.Link>
          <S.LinkText color={primaryColor}>
            {REACT_APP_STOREFRONT_BASE_URL}/<span>{user.username}</span>
          </S.LinkText>

          <div className="icon">
            <LinkIcon color={primaryColor} />
          </div>
        </S.Link>

        <S.CopyLink>
          <Button
            color={primaryColor}
            onClick={onCopyHandler}
            type={isCopied ? "solid" : "outline"}
          >
            {isCopied ? "Link copiado!" : "Copiar Link"}
          </Button>
        </S.CopyLink>
      </S.LinkBox>

      <S.SocialMedia>
        <div className="text">
          <Small color={textColor}>Acesso rápido a suas redes</Small>
        </div>

        <S.SocialMediaBoxes>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com"
            onClick={() => socialMediaOpened && socialMediaOpened("instagram")}
          >
            <SocialMediaIconBox
              boxWidth="54px"
              boxHeight="54px"
              iconWidth="22px"
              iconHeight="22px"
              isSelected={true}
              disableShadow={true}
              boxBorderRadius="18px"
              socialMedia="instagram"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.tiktok.com"
            onClick={() => socialMediaOpened && socialMediaOpened("tiktok")}
          >
            <SocialMediaIconBox
              boxWidth="54px"
              boxHeight="54px"
              iconWidth="22px"
              iconHeight="22px"
              isSelected={true}
              socialMedia="tiktok"
              disableShadow={true}
              boxBorderRadius="18px"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.twitter.com"
            onClick={() => socialMediaOpened && socialMediaOpened("twitter")}
          >
            <SocialMediaIconBox
              boxWidth="54px"
              boxHeight="54px"
              iconWidth="22px"
              iconHeight="22px"
              isSelected={true}
              disableShadow={true}
              socialMedia="twitter"
              boxBorderRadius="18px"
            />
          </a>
        </S.SocialMediaBoxes>
      </S.SocialMedia>
    </S.Container>
  );
};

export default CopyLinkModal;
