import styled from "styled-components";

type LabelProps = {
  color: string;
};

const Label = styled.small<LabelProps>`
  font-size: 8px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.38em;
  text-transform: uppercase;
  color: ${({ color }) => color};
`;
export default Label;
