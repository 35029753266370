import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BoxIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3545 0H21.581C21.5105 0.036942 21.4403 0.07448 21.3702 0.112018C21.2299 0.18709 21.0895 0.262173 20.9468 0.332478C16.5719 2.49363 12.1968 4.65413 7.8218 6.81458L2.20965 9.586C2.10233 9.63921 1.99562 9.69323 1.87415 9.75472L1.68457 9.85056C1.74561 9.88354 1.79893 9.91308 1.84757 9.94003C1.93142 9.98649 2.00136 10.0252 2.07291 10.0606C2.69406 10.3665 3.31545 10.6719 3.93685 10.9773C5.70302 11.8453 7.46973 12.7137 9.23057 13.5923C9.50853 13.7313 9.71141 13.7147 9.97775 13.578C14.1705 11.424 18.3666 9.27612 22.5628 7.12822L25.9421 5.3983C26.7647 4.97702 27.5867 4.55443 28.4383 4.11664L29.5694 3.53526L22.3545 0Z"
        fill={color || primaryColor}
      />
      <path
        d="M0.278985 12.8902C0.194345 12.8411 0.108266 12.7911 0 12.8096C0 19.4578 0 26.106 0.000891903 32.7533C0.251144 33.3598 0.686406 33.7535 1.27629 34.0596C5.69882 36.3537 10.1157 38.659 14.5326 40.9642L14.5347 40.9653L14.5367 40.9663C16.3328 41.9038 18.129 42.8412 19.9254 43.7778C19.9574 43.7946 19.9886 43.8146 20.0199 43.8347C20.1184 43.8979 20.219 43.9625 20.3536 43.932C20.3556 43.9163 20.3582 43.9011 20.3607 43.8863C20.3658 43.8567 20.3705 43.8285 20.3705 43.8002L20.3732 39.8009C20.3767 34.2433 20.3803 28.6858 20.3911 23.128C20.392 22.8577 20.2686 22.7611 20.06 22.6588C18.099 21.6962 16.14 20.7298 14.181 19.7635C13.4463 19.4011 12.7111 19.0384 11.9764 18.6762C11.5478 18.4648 11.5447 18.4675 11.5442 18.935C11.544 19.3119 11.5436 19.6889 11.5432 20.0659C11.5418 21.3222 11.5404 22.5785 11.5469 23.8345C11.5496 24.3806 11.3985 24.8552 10.9829 25.2176C10.4722 25.6632 9.8796 25.7722 9.26246 25.5001C8.59974 25.2082 8.2552 24.6684 8.25341 23.9378C8.24849 21.7029 8.2467 19.4681 8.25698 17.2332C8.25832 16.9428 8.18638 16.781 7.90529 16.6443C6.14801 15.7896 4.39572 14.925 2.64343 14.0603C1.88159 13.6844 1.11975 13.3085 0.357503 12.9334C0.331062 12.9204 0.305092 12.9053 0.278985 12.8902Z"
        fill={color || primaryColor}
      />
      <path
        d="M43.997 12.8022L43.9995 16.974C44.0029 22.2803 44.0063 27.5866 44.0211 32.8926C44.022 33.2644 43.9018 33.4409 43.5805 33.608C37.0708 36.994 30.5661 40.3899 24.0662 43.7942C23.7118 43.9797 23.6519 43.9175 23.6528 43.5408C23.6716 36.7514 23.6823 29.9624 23.6864 23.173C23.6864 22.9115 23.7583 22.7654 24.0028 22.6452C29.2916 20.041 34.5781 17.4312 39.8645 14.8216L43.6645 12.9457C43.7211 12.9178 43.7794 12.8936 43.8556 12.8618C43.8966 12.8447 43.943 12.8254 43.997 12.8022Z"
        fill={color || primaryColor}
      />
      <path
        d="M38.5816 11.7243L42.3395 9.86166C42.2631 9.82243 42.1975 9.78833 42.1385 9.75764C42.0435 9.70825 41.9656 9.66769 41.8868 9.62883C41.2798 9.3294 40.6728 9.03024 40.0657 8.73109C37.9404 7.68376 35.8152 6.63645 33.6959 5.57698C33.4019 5.42996 33.1892 5.44515 32.909 5.5886C28.0463 8.08169 23.1807 10.5696 18.3153 13.0575C16.7881 13.8383 15.2609 14.6192 13.7338 15.4003C13.6546 15.4409 13.5776 15.486 13.4879 15.5385C13.4434 15.5645 13.3958 15.5923 13.3433 15.6224C13.4137 15.6592 13.4787 15.6938 13.5403 15.7265C13.6617 15.7909 13.7698 15.8483 13.8795 15.9026C14.5426 16.2301 15.2059 16.5572 15.8692 16.8843C17.7841 17.8286 19.6989 18.7728 21.6087 19.7274C21.909 19.8776 22.1365 19.8798 22.4377 19.7301C26.8023 17.5583 31.1708 15.3947 35.5395 13.231C36.5536 12.7288 37.5676 12.2266 38.5816 11.7243Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BoxIcon;
