import { Theme } from "../../../hooks";
import Input from "../../atoms/Input";
import P from "../../atoms/Typography/P";

import * as S from "./styles";

interface IProps {
  time: string;
  onChange: (val: string) => void;
}

const AppFormDuration: React.FC<IProps> = ({ time, onChange }) => {
  const { textColor } = Theme.useTheme();

  const onChangeTime = (val: string) => {
    const numbersOnly = val.replace(/[^0-9]/g, "");

    const filteredZeros = numbersOnly
      .split("")
      .reduce((acc, curr) => {
        if (!acc.length && curr === "0") return acc;

        acc.push(curr);
        return acc;
      }, [] as string[])
      .join("");

    if (filteredZeros.length > 4) return;

    const withZeros = Array(4 - filteredZeros.length)
      .fill("0")
      .join("")
      .concat(filteredZeros)
      .split("");

    withZeros.splice(2, 0, ":");

    const normalizedValue =
      withZeros.join("") === "00:00" ? "" : withZeros.join("");

    onChange(normalizedValue);
  };

  return (
    <S.Duration>
      <P color={textColor}>Duração total do curso/vídeo</P>

      <span>
        <Input value={time} placeholder="hh:mm" onChange={onChangeTime} />
      </span>
    </S.Duration>
  );
};

export default AppFormDuration;
