import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const NearIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0757 0.163991L1.28489 13.048C-0.45601 13.7825 -0.41406 16.2586 1.32684 16.93L12.5273 21.2737C13.0727 21.4835 13.5131 21.9242 13.7229 22.4698L18.0437 33.6541C18.7148 35.4168 21.2108 35.4587 21.9449 33.7171L34.8444 2.93385C35.5365 1.1922 33.7956 -0.549458 32.0757 0.163991Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default NearIcon;
