import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TodoIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.0556 0H1.94444C0.875 0 0 0.875 0 1.94444V33.0556C0 34.125 0.875 35 1.94444 35H33.0556C34.125 35 35 34.125 35 33.0556V1.94444C35 0.875 34.125 0 33.0556 0ZM11.667 7.77774H7.77807V11.6666H11.667V7.77774ZM25.2775 11.6666H17.4997C16.4303 11.6666 15.5553 10.7916 15.5553 9.72219C15.5553 8.65274 16.4303 7.77774 17.4997 7.77774H25.2775C26.3469 7.77774 27.2219 8.65274 27.2219 9.72219C27.2219 10.7916 26.3469 11.6666 25.2775 11.6666ZM25.2775 19.4445H17.4997C16.4303 19.4445 15.5553 18.5695 15.5553 17.5C15.5553 16.4306 16.4303 15.5556 17.4997 15.5556H25.2775C26.3469 15.5556 27.2219 16.4306 27.2219 17.5C27.2219 18.5695 26.3469 19.4445 25.2775 19.4445ZM17.4997 27.2222H25.2775C26.3469 27.2222 27.2219 26.3472 27.2219 25.2778C27.2219 24.2083 26.3469 23.3333 25.2775 23.3333H17.4997C16.4303 23.3333 15.5553 24.2083 15.5553 25.2778C15.5553 26.3472 16.4303 27.2222 17.4997 27.2222ZM7.77807 15.5556H11.667V19.4445H7.77807V15.5556ZM11.667 23.3333H7.77807V27.2222H11.667V23.3333ZM3.88947 31.111H31.1117V3.88882H3.88947V31.111Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TodoIcon;
