import styled from "styled-components";
import P from "../../../../components/atoms/Typography/P";

export const Intro = styled.div`
  gap: 16px;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
`;

export const Title = styled(P)`
  font-weight: 500;
`;
