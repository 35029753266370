import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const BabyIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="39"
      height="40"
      viewBox="0 0 39 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0379 15.8891V2.02525C22.0379 0.803151 23.1198 -0.158503 24.3218 0.0218074C32.0952 1.1237 38.0655 7.81521 38.0655 15.8891H22.0379ZM38.0655 17.8926C38.0655 21.5789 36.8033 24.9848 34.6997 27.6894C36.5428 29.3323 37.5245 31.9167 36.8233 34.7015C36.1822 37.2258 34.0986 39.2293 31.5543 39.7702C27.3871 40.6517 23.6407 37.8068 23.0997 33.9202H18.9326C18.3916 37.8269 14.6252 40.6718 10.4179 39.7502C7.89358 39.1892 5.80999 37.1657 5.20896 34.6414C4.40758 31.2956 6.03037 28.1703 8.69495 26.7478C8.23416 26.0466 4.44765 17.8926 4.44765 17.8926H2.00345C0.901551 17.8926 0 16.991 0 15.8891C0 14.7872 0.901551 13.8857 2.00345 13.8857H5.72985C6.49116 13.8857 7.2124 14.3265 7.53296 15.0277L8.8953 17.8926H38.0655ZM12.0207 35.9236C10.3578 35.9236 9.01551 34.5813 9.01551 32.9184C9.01551 31.2556 10.3578 29.9133 12.0207 29.9133C13.6835 29.9133 15.0258 31.2556 15.0258 32.9184C15.0258 34.5813 13.6835 35.9236 12.0207 35.9236ZM27.0465 32.9184C27.0465 34.5813 28.3888 35.9236 30.0517 35.9236C31.7145 35.9236 33.0569 34.5813 33.0569 32.9184C33.0569 31.2556 31.7145 29.9133 30.0517 29.9133C28.3888 29.9133 27.0465 31.2556 27.0465 32.9184Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default BabyIcon;
