import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CallIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 0H34.2C36.29 0 38 1.71 38 3.8V19C38 29.678 29.241 38 18.468 38C8.531 38 0 29.45 0 19.532C0 8.854 8.322 0 19 0ZM22.8 20.9L28.5 24.7V13.3L22.8 17.1V13.3H9.5V24.7H22.8V20.9Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CallIcon;
