import styled from "styled-components";
import H2 from "../../../../components/atoms/Typography/H2";

import P from "../../../../components/atoms/Typography/P";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type IconSectionProps = {
  color: string;
};

export const IconSection = styled.div<IconSectionProps>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px;
  border-radius: 50%;
  margin-bottom: 9px;

  border: 1px solid ${({ color }) => color};

  svg {
    width: 16px;
    height: auto;
  }
`;

export const StantiPlaceTitle = styled(H2)`
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  line-height: 118%;
  margin-bottom: 6px;

  h2 {
    color: #fafafa;
  }
`;

export const StantiPlaceDescription = styled(P)`
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
`;
