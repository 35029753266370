import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

import { Theme } from "../../../hooks";
import H3 from "../../atoms/Typography/H3";

import * as S from "./styles";

interface IProps {
  width?: number;
  height?: number;
  initialImage: string;
  borderRadius?: number;
  onDiscardImage: () => void;
  onChangeImage: (img: string) => void;
}

const ImageReposition: React.FC<IProps> = ({
  width = 320,
  height = 320,
  initialImage,
  onChangeImage,
  onDiscardImage,
  borderRadius = 160,
}) => {
  const [imageZoom, setImageZoom] = useState(1);
  const { backgroundColor, primaryColor } = Theme.useTheme();

  const avatarEditor = useRef<AvatarEditor | null>(null);

  const onSaveImageHandler = () => {
    if (avatarEditor.current) {
      avatarEditor.current.getImage().toBlob((data) => {
        if (data) {
          const reader = new FileReader();
          reader.readAsDataURL(data);

          reader.onload = async () => {
            const base64 = reader.result?.toString();

            if (base64) onChangeImage(base64);
          };
        }
      }, "image/jpeg");
    }
    onDiscardImage();
  };

  return (
    <S.CropModal backgroundColor={backgroundColor} onClick={onDiscardImage}>
      <S.ModalContainer
        backgroundColor={backgroundColor}
        borderColor={primaryColor}
        onClick={(e) => e.stopPropagation()}
      >
        <H3 color={primaryColor}>Posicionar Imagem</H3>

        <AvatarEditor
          border={0}
          width={width}
          height={height}
          scale={imageZoom}
          ref={avatarEditor}
          image={initialImage}
          borderRadius={borderRadius}
        />

        <input
          type="range"
          min={1}
          max={2}
          step={0.05}
          value={imageZoom}
          onChange={(e) => {
            setImageZoom(+e.target.value);
          }}
        />

        <S.SaveButton onClick={onSaveImageHandler}>Salvar</S.SaveButton>
      </S.ModalContainer>
    </S.CropModal>
  );
};

export default ImageReposition;
