import styled from "styled-components";

export const ProfileBody = styled.div`
  display: flex;
  margin-top: 16px;
  padding-bottom: 24px;
  flex-direction: column;
`;

export const ExternalLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SaveButton = styled.div`
  left: 50%;
  width: 100%;
  bottom: 40px;
  z-index: 200;
  display: flex;
  position: fixed;
  padding: 0 24px;
  max-width: 960px;
  flex-direction: column;

  button {
    height: 56px;
    transform: translate(calc(-50% - 24px), 0%);
    box-shadow: 4px 10px 10px -2px rgba(0, 0, 0, 0.6);
    animation: buttonPop 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;

    @keyframes buttonPop {
      0% {
        transform: translate(calc(-50% - 24px), 200%);
      }
      60% {
        transform: translate(calc(-50% - 24px), -60%);
      }
      100% {
        transform: translate(calc(-50% - 24px), 0%);
      }
    }
  }
`;
