import Dropdown from "../../../components/atoms/Dropdown";
import ExternalLinkSkeleton from "../../../components/atoms/ExternalLinkSkeleton";
import Small from "../../../components/atoms/Typography/Small";
import SalesList from "../../../components/molecules/SalesList";
import { Theme } from "../../../hooks";
import { IIncomeReport } from "../../../services/order";
import { IOrder } from "../../../types";
import ProductCard from "../ProductCard";
import ProductList from "../ProductList";

import * as S from "./styles";

const productFilterOpt = [
  { id: "all", label: "Todos" },
  { id: "by-product", label: "Por produto" },
];

const periodFilterOpt = [
  { id: "current-month", label: "Este mês" },
  { id: "all-time", label: "Sempre" },
  { id: "past-30-days", label: "Últimos 30 dias" },
];

export type TSelectedProductFilter = "all" | "by-product";

export type TSelectedPeriodFilter =
  | "current-month"
  | "past-30-days"
  | "all-time";

interface IProps {
  sales: IOrder[];
  isLoading: boolean;
  selectedProduct: string;
  productTotalValue?: number;
  selectedPeriodFilter: string;
  selectedProductFilter: string;
  setSelectedProduct: (val: string) => void;
  setSelectedPeriodFilter: (val: TSelectedPeriodFilter) => void;
  setSelectedProductFilter: (val: TSelectedProductFilter) => void;
  productsWithSales: IIncomeReport["totalizers"]["productsWithSales"];
}

const Extrato: React.FC<IProps> = ({
  sales,
  isLoading,
  selectedProduct,
  productsWithSales,
  productTotalValue,
  setSelectedProduct,
  selectedPeriodFilter,
  selectedProductFilter,
  setSelectedPeriodFilter,
  setSelectedProductFilter,
}) => {
  const { textColor } = Theme.useTheme();

  const selectedProductData = (productsWithSales || []).find(
    (product) => product.id === selectedProduct.split("||")[1]
  );

  const appData = selectedProductData
    ? require(`../../../config/app-${selectedProductData.app}.json`)
    : {};

  const body = (
    <>
      {selectedProduct && selectedProductData && (
        <ProductCard
          appIcon={appData.icon}
          appColor={appData.color}
          productLabel={appData.title}
          appGradient={appData.gradient}
          totalIncome={productTotalValue}
          productTitle={selectedProductData.title}
        />
      )}

      {selectedProductFilter === "by-product" && !selectedProduct ? (
        <ProductList
          products={productsWithSales}
          onProductClick={(id) => setSelectedProduct(id)}
        />
      ) : (
        <SalesList
          sales={sales}
          isLoading={isLoading}
          productsWithSales={productsWithSales}
          focusOn={selectedProduct ? "lead" : "product"}
        />
      )}
    </>
  );

  return (
    <S.Extrato>
      <Small color={textColor}>Extrato</Small>

      <S.Filters>
        <Dropdown
          options={productFilterOpt}
          selectedOptionId={selectedProductFilter}
          onClickOption={(optionId) =>
            setSelectedProductFilter(optionId as TSelectedProductFilter)
          }
        />

        <Dropdown
          options={periodFilterOpt}
          selectedOptionId={selectedPeriodFilter}
          onClickOption={(optionId) =>
            setSelectedPeriodFilter(optionId as TSelectedPeriodFilter)
          }
        />
      </S.Filters>

      {isLoading ? <ExternalLinkSkeleton /> : body}
    </S.Extrato>
  );
};

export default Extrato;
