import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const WorkIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="43"
      height="38"
      viewBox="0 0 43 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0778 8.28889H29.7889C29.7889 3.70928 26.0797 0 21.5001 0C16.9204 0 13.2112 3.70928 13.2112 8.28889H4.92228C2.64283 8.28889 0.777832 10.1539 0.777832 12.4333V18.65C0.777832 20.9502 2.62211 22.7944 4.92228 22.7944H17.3556V20.7222C17.3556 19.5825 18.2881 18.65 19.4278 18.65H23.5723C24.712 18.65 25.6445 19.5825 25.6445 20.7222V22.7944H38.0778C40.3573 22.7944 42.2223 20.9294 42.2223 18.65V12.4333C42.2223 10.1539 40.3573 8.28889 38.0778 8.28889ZM23.5719 26.9389H19.4275C18.2877 26.9389 17.3552 26.0064 17.3552 24.8667H2.8704V33.1556C2.8704 35.435 4.7354 37.3 7.01484 37.3H36.0052C38.2847 37.3 40.1497 35.435 40.1497 33.1556V24.8667H25.6441C25.6441 26.0064 24.7116 26.9389 23.5719 26.9389ZM21.5009 4.14446C19.2214 4.14446 17.3564 6.00946 17.3564 8.2889H25.6453C25.6453 6.00946 23.7803 4.14446 21.5009 4.14446Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default WorkIcon;
