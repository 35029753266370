import styled, { css } from "styled-components";

export const BrandCard = styled.div`
  display: flex;
  padding: 16px;
  height: 112px;
  cursor: pointer;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid #fafafa20;
  border-right: 1px solid #fafafa20;
  background-color: rgba(255, 255, 255, 0.15);
`;

export const Head = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Left = styled.div`
  gap: 2px;
  display: flex;
  min-height: 26px;
  padding-left: 6px;
  flex-direction: column;

  small:first-of-type {
    opacity: 0.5;
  }
`;

type IconProps = {
  opacity: number;
  borderColor: string;
};

export const Icon = styled.div<IconProps>`
  ${({ opacity, borderColor }) => css`
    width: 26px;
    height: 26px;
    padding: 6px;
    display: flex;
    border: 1px solid ${borderColor};
    align-items: center;
    border-radius: 12px;
    justify-content: center;

    opacity: ${opacity};
  `}
`;

type LogoProps = {
  imageUrl: string;
};

export const Logo = styled.div<LogoProps>`
  width: 100%;
  flex-grow: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
`;
