import { useEffect, useState } from "react";
import Button from "../../../../components/atoms/Button";

import Input from "../../../../components/atoms/Input";
import NavigationArrow from "../../../../components/atoms/NavigationArrow";
import StepsProgressBar from "../../../../components/atoms/StepsProgressBar";
import P from "../../../../components/atoms/Typography/P";
import UsernameInput from "../../../../components/atoms/UsernameInput";
import StantiIcon from "../../../../components/icons/StantiIcon";
import SocialMediaLogin from "../../../../components/molecules/SocialMediaLogin";
import { LOCAL_STORAGE_INFO_STEP_DURATION } from "../../../../constants";
import { GA, Snackbar, Theme } from "../../../../hooks";
import { maskPhoneNumber } from "../../../../utils/numbers";

import * as RegisterStyles from "../styles";
import * as InformationStyles from "./styles";

const S = {
  ...InformationStyles,
  ...RegisterStyles,
};

export interface IInformationStepForm {
  name: string;
  email: string;
  phone: string;
  password: string;
}

interface IProps {
  username: string;
  onNext: () => void;
  onBack: () => void;
  isUsernameAvailable: boolean;
  onBlurUsernameInput: () => void;
  onRegisterWithGoogle: () => void;
  onRegisterWithFacebook: () => void;
  onChangeEmail: (val: string) => void;
  informationStepForm: IInformationStepForm;
  onChangeUsername: (value: string) => void;
  emailFieldStatus: "loading" | "error" | "success" | "";
  informationStepFormHandler: (key: string, val: string) => void;
}

const { REACT_APP_STOREFRONT_BASE_URL } = process.env;

const InformationStep: React.FC<IProps> = ({
  onNext,
  onBack,
  username,
  onChangeEmail,
  onChangeUsername,
  emailFieldStatus,
  isUsernameAvailable,
  onBlurUsernameInput,
  onRegisterWithGoogle,
  onRegisterWithFacebook,
  informationStepFormHandler,
  informationStepForm: { email, name, password, phone },
}) => {
  const [pageLoadedTime, setPageLoadedTime] = useState<number>(0);

  const isNameValid = name && name.length > 2;
  const isPasswordValid = password && password.length > 5;
  const isCellphoneValid = phone && phone.length >= 11;

  const isReady =
    isUsernameAvailable &&
    isNameValid &&
    isPasswordValid &&
    isCellphoneValid &&
    emailFieldStatus === "success";

  const { sendEvents } = GA.useGA();
  const { primaryColor, textColor } = Theme.useTheme();
  const { newError } = Snackbar.useSnackbar();

  useEffect(() => {
    setPageLoadedTime(new Date().getTime());
  }, []);

  const onNextHandler = () => {
    try {
      informationStepFormHandler("name", name.trim());
      informationStepFormHandler("email", email.trim());
      informationStepFormHandler("phone", phone.trim());

      const stepConcludedTime = new Date();

      const stepDurationInSeconds =
        (stepConcludedTime.getTime() - pageLoadedTime) / 1000;

      localStorage.setItem(
        LOCAL_STORAGE_INFO_STEP_DURATION,
        stepDurationInSeconds.toString()
      );

      sendEvents({
        name: "Lead",
        category: "form",
        buttonLocation: "register-information-step",
      });

      window.fbq("track", "Lead");
      onNext();
    } catch (error) {
      newError("Houve um erro ao cadastrar seu perfil");
    }
  };

  const progress =
    ([username, name, email, phone, password].filter((i) => !!i).length / 30 +
      1 / 6) *
    100;

  return (
    <S.InformationStep>
      <S.InformationContainer>
        <S.RegisterFormHeader>
          <div className="stantiLogo">
            <StantiIcon color="#fafafa" />
          </div>

          <Button
            style={{ color: "#fafafa" }}
            color="#fafafa05"
            onClick={onBack}
          >
            Voltar
          </Button>
        </S.RegisterFormHeader>

        <S.ProgressBar>
          <P color={textColor}>Criando sua Stanti</P>

          <StepsProgressBar progress={progress} quantityOfBars={6} />
        </S.ProgressBar>

        <S.Form>
          <UsernameInput
            value={username}
            onChange={onChangeUsername}
            onBlur={onBlurUsernameInput}
            status={isUsernameAvailable ? "success" : ""}
            baseUrl={`${REACT_APP_STOREFRONT_BASE_URL}/`}
          />

          <Input
            value={name}
            placeholder="Nome completo"
            status={isNameValid ? "success" : ""}
            onChange={(val) => informationStepFormHandler("name", val)}
          />

          <Input
            value={email}
            placeholder="E-mail"
            status={emailFieldStatus}
            onChange={(val) => onChangeEmail(val)}
          />

          <Input
            type="password"
            value={password}
            placeholder="Digite uma senha"
            status={isPasswordValid ? "success" : ""}
            onChange={(val) => informationStepFormHandler("password", val)}
          />

          <Input
            type="tel"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="Celular"
            value={maskPhoneNumber(phone)}
            status={isCellphoneValid ? "success" : ""}
            onChange={(val) =>
              informationStepFormHandler(
                "phone",
                (val || "").replace(/[^0-9]/g, "")
              )
            }
          />
        </S.Form>

        <S.Text color="#ddff66">ou</S.Text>

        <S.GoogleOrFacebook>
          <SocialMediaLogin
            socialMedia="Google"
            onClick={onRegisterWithGoogle}
          />

          <SocialMediaLogin
            socialMedia="Facebook"
            onClick={onRegisterWithFacebook}
          />
        </S.GoogleOrFacebook>

        <S.Footer>
          <NavigationArrow
            buttonStyle="solid"
            angle={isReady ? 0 : -90}
            onClick={() => isReady && onNextHandler()}
            color={isReady ? primaryColor : "#ffffff33"}
          />
        </S.Footer>
      </S.InformationContainer>
    </S.InformationStep>
  );
};

export default InformationStep;
