import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const MoneyOneIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 21C0 9.408 9.408 0 21 0C32.592 0 42 9.408 42 21C42 32.592 32.592 42 21 42C9.408 42 0 32.592 0 21ZM23.959 35.007V33.789C27.613 33.096 30.49 30.996 30.532 27.153C30.532 21.861 25.996 20.034 21.754 18.942C17.512 17.85 16.147 16.695 16.147 14.931C16.147 12.915 18.037 11.487 21.187 11.487C23.833 11.487 25.156 12.516 25.639 14.112C25.849 14.868 26.479 15.414 27.256 15.414H27.802C29.041 15.414 29.923 14.196 29.503 13.041C28.705 10.815 26.878 9.00902 23.98 8.29502V6.99302C23.98 5.46002 22.72 4.20002 21.187 4.20002H21.166C19.633 4.20002 18.373 5.46002 18.373 6.99302V8.25302C14.761 9.03002 11.842 11.382 11.842 14.994C11.842 19.299 15.412 21.441 20.62 22.701C25.303 23.814 26.227 25.452 26.227 27.195C26.227 28.476 25.303 30.534 21.187 30.534C18.121 30.534 16.525 29.442 15.916 27.867C15.622 27.132 14.992 26.607 14.215 26.607H13.711C12.451 26.607 11.548 27.888 12.031 29.043C13.081 31.626 15.559 33.159 18.352 33.747V35.007C18.352 36.54 19.612 37.8 21.145 37.8H21.166C22.699 37.8 23.959 36.54 23.959 35.007Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default MoneyOneIcon;
