import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LockOpenIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="31"
      height="41"
      viewBox="0 0 31 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9167 13.4583H24.994V9.6131C24.994 4.30667 20.6874 0 15.381 0C10.9974 0 7.17137 2.96083 6.07548 7.20982C5.80631 8.24804 6.42155 9.28625 7.45976 9.55542C8.47875 9.82458 9.53619 9.20935 9.80536 8.17113C10.459 5.63327 12.747 3.84524 15.381 3.84524C18.5533 3.84524 21.1488 6.44077 21.1488 9.6131V13.4583H3.84524C1.73036 13.4583 0 15.1887 0 17.3036V36.5298C0 38.6446 1.73036 40.375 3.84524 40.375H26.9167C29.0315 40.375 30.7619 38.6446 30.7619 36.5298V17.3036C30.7619 15.1887 29.0315 13.4583 26.9167 13.4583ZM15.381 23.0714C13.2661 23.0714 11.5357 24.8018 11.5357 26.9167C11.5357 29.0315 13.2661 30.7619 15.381 30.7619C17.4958 30.7619 19.2262 29.0315 19.2262 26.9167C19.2262 24.8018 17.4958 23.0714 15.381 23.0714ZM24.994 36.5298C26.0515 36.5298 26.9167 35.6646 26.9167 34.6071V19.2262C26.9167 18.1688 26.0515 17.3036 24.994 17.3036H5.76786C4.71042 17.3036 3.84524 18.1688 3.84524 19.2262V34.6071C3.84524 35.6646 4.71042 36.5298 5.76786 36.5298H24.994Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default LockOpenIcon;
